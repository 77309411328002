import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Cookies from 'js-cookie';
import URL from './URL';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { DebounceInput } from 'react-debounce-input';

import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import { AddCoupon, SellingPriceCalculator, validateGSTNo } from './Invoice';
import { fullDialogDispatcher } from './FullDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { fullDialogV2Dispatcher } from './FullDialogV2';

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')

var { DateTime } = require('luxon');


var addCouponDialogIndex = null
var sellingPriceCalculatorDialogIndex = null
var clearDataDialogIndex = null
var resetDataDialogIndex = null
var updateInvoiceDialogIndex = null
var deleteInvoiceDialogIndex = null

class Handlers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "handlers": {} };
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "handlers": response['data']['payload'] });
            })

        this.selectedHandler = props.handler;
    }
    render() {
        var handlers = []
        for (var handler in this.state.handlers) {
            var name_ = this.state.handlers[handler].firstname + " " + this.state.handlers[handler].lastname;
            if (name_ == this.selectedHandler) {

                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_} selected>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
            else {
                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_}>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
        }
        return (

            handlers
        );
    }
}

class EditApprovalInvoice extends React.Component {
    constructor(props) {
        super()

        this.invoiceID = props.invoiceID

        this.state = {
            customer: "",
            customerID: "",
            invoiceNumber: "",
            // invoiceGST:"",
            orderID: "",
            handlerID: "",
            handler: "",
            note: "",
            invoiceItems: {},
            approvalVoucherBool: false,
            invoiceDate: null,
            response: {},
            coupon: {},
            gst: "",
            couponData: null,
            itemInputTypeBool: false, // true for item search mode, false for punch mode
            punchText: "",
            punchModeInputIndicatorClassname: "punchModeAnimationError w-100 rounded",
            punchModeInputIndicatorFocus: false,

            shippingAmount: 0,
            couponDiscountAmount: 0,

            isLoading: true,
            isSuccess: false,
            isError: false,

            originalData: {}

        }
        this.typeahead_items_search = null
    }

    componentDidMount() {
        this.fetchInvoice()
    }

    handleFetchInvoice = (response) => {
        this.setState({ isLoading: false })

        if (!("_status" in response) || !("payload" in response)) {
            alert("Unable to get invoice data")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["_status"] !== "success") {
            alert("Unable to get invoice data")
            this.setState({ isSuccess: false, isError: true, })
            return
        }

        const payload = response.payload

        /* prepare items data */

        var tmpItems = {}
        for (var i in payload.items) {
            const tmpItem = payload.items[i]

            tmpItem.data = tmpItem.data

            var discountValue = 0
            var discount = payload.items[i].discount

            if (discount === "") {
                discountValue = 0
            } else if (parseInt(discount) < 0) {
                discountValue = 0
            }

            else if ((payload.items[i].discount_type === "d")) {
                if ((parseFloat(discount) > payload.items[i].data.price.sp)) {
                    discountValue = payload.items[i].data.price.sp
                } else {
                    discountValue = parseFloat(discount)
                }
            }
            else if (payload.items[i].discount_type === "%") {
                if ((parseFloat(discount) > 100)) {
                    discountValue = payload.items[i].data.price.sp
                }
                else {
                    discountValue = (parseFloat(discount) / 100) * payload.items[i].data.price.sp
                }
            }
            else {
                discountValue = 0
            }
            tmpItem.discountValue = discountValue
            tmpItem.discount = discount

            tmpItems[tmpItem._id] = tmpItem
            // tmpItems.push(tmpItem)
        }

        /* prepare coupon data */

        const tmpCoupon = payload.coupon
        if (tmpCoupon !== null) {
            tmpCoupon._id = {
                "$oid": tmpCoupon._id
            }
        }

        /* prepare invoice date */
        var invoiceDate = DateTime.fromISO(payload.invoice_date).toJSDate()

        /* process and populate data */
        this.setState({
            note: payload.note,
            approvalVoucherBool: payload.isav,
            shippingAmount: payload.shippingAmount,
            gst: payload.gst,
            couponDiscountAmount: payload.couponDiscountAmount,
            couponData: tmpCoupon,
            invoiceNumber: payload.invoice_number,
            customer: payload.customer,
            customerID: payload.customer_id,
            invoiceItems: tmpItems,
            invoiceDate: invoiceDate,


            originalData: { ...payload },

            isSuccess: true,
            isError: false,
        })


    }

    fetchInvoice = () => {
        this.setState({ isLoading: true, isSuccess: false, isError: false })
        const that = this;

        var params = { "_id": this.invoiceID }
        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.handleFetchInvoice(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleFetchInvoice({})
            })

    }

    resetFields = () => {
        this.setState({
            customer: "",
            customerID: "",
            invoiceNumber: "",
            // invoiceGST:"",
            orderID: "",
            handlerID: "",
            handler: "",
            note: "",
            invoiceItems: {},
            approvalVoucherBool: false,
            invoiceDate: new Date(),
            response: {},
            coupon: {},
            gst: "",
            couponData: null,
            itemInputTypeBool: false, // true for item search mode, false for punch mode
            punchText: "",
            punchModeInputIndicatorClassname: "punchModeAnimationError w-100 rounded",
            punchModeInputIndicatorFocus: false,

            shippingAmount: 0,
            couponDiscountAmount: 0
        })
    }

    resetData = () => {
        const payload = this.state.originalData


        /* prepare items data */

        var tmpItems = {}
        for (var i in payload.items) {
            const tmpItem = payload.items[i]

            tmpItem.data = tmpItem

            var discountValue = 0
            var discount = payload.items[i].discount

            if (discount === "") {
                discountValue = 0
            } else if (parseInt(discount) < 0) {
                discountValue = 0
            }

            else if ((payload.items[i].discount_type === "d")) {
                if ((parseFloat(discount) > payload.items[i].data.price.sp)) {
                    discountValue = payload.items[i].data.price.sp
                } else {
                    discountValue = parseFloat(discount)
                }
            }
            else if (payload.items[i].discount_type === "%") {
                if ((parseFloat(discount) > 100)) {
                    discountValue = payload.items[i].data.price.sp
                }
                else {
                    discountValue = (parseFloat(discount) / 100) * payload.items[i].data.price.sp
                }
            }
            else {
                discountValue = 0
            }
            tmpItem.discountValue = discountValue
            tmpItem.discount = discount

            tmpItems[tmpItem._id] = tmpItem
            // tmpItems.push(tmpItem)
        }

        /* prepare coupon data */

        const tmpCoupon = payload.coupon
        if (tmpCoupon !== null) {
            tmpCoupon._id = {
                "$oid": tmpCoupon._id
            }
        }

        /* process and populate data */
        this.setState({
            note: payload.note,
            approvalVoucherBool: payload.isav,
            shippingAmount: payload.shippingAmount,
            gst: payload.gst,
            couponDiscountAmount: payload.couponDiscountAmount,
            couponData: tmpCoupon,
            invoiceNumber: payload.invoice_number,
            customer: payload.customer,
            customerID: payload.customer_id,
            invoiceItems: tmpItems,
            invoicedate: DateTime.fromISO(payload.invoice_date).toJSDate(),

        })
    }

    saveInvoice = (couponDiscountValue) => {

        var error = {
            "_status": "",
            "payload": []
        }
        var tmpPayload = {}
        var errorSet = false
        if (this.state.customerID.length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Customer"] = "Please Add A Customer Or Order ID."

        }
        // if (this.state.orderID.length < 1) {
        //     if (!errorSet) {
        //         error["_status"] = "fail"
        //         errorSet = true
        //     }
        //     tmpPayload["Order ID"] = "Please Add Order ID Or A Customer."
        // }
        if (this.state.invoiceDate == null || this.state.invoiceDate.length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Invoice Date"] = "Please Add Invoice Date."
        }

        // if (this.state.handlerID === null || this.state.handlerID.length < 1) {
        //     if (!errorSet) {
        //         error["_status"] = "fail"
        //         errorSet = true
        //     }
        //     tmpPayload["Handler"] = "Please Select A Handler."
        // }

        if (Object.keys(this.state.invoiceItems).length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Items"] = "Please Add Atleast One Item."
        }


        if (this.state.gst.length > 0 && !validateGSTNo(this.state.gst)) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["GST"] = "Invalid GST Number"
        }



        if (errorSet) {
            error["payload"] = [tmpPayload]
            // this.setState({ response: error })

            var ret = []
            for (var i in error["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {error["payload"][0][i]}<br /></span>
                );
            }
            var message =
                <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>

            fullDialogV2Dispatcher.dispatch({
                actionType: "show-dialog",
                title: "Create Invoice",
                message: message
            })

            return
        }


        $("#updateInvoiceButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Update")
        const that = this
        var invoiceItems = []

        for (var item in this.state.invoiceItems) {

            /* prepare tax */

            var tmpTax = this.state.invoiceItems[item].tax
            tmpTax.p = parseFloat(tmpTax.p)


            /* prepare data */


            var tmp_item =
            {
                "_id": item,
                "count": this.state.invoiceItems[item].count,
                "discount": parseFloat(this.state.invoiceItems[item].discount),
                "discount_type": this.state.invoiceItems[item].discount_type,
                "price": this.state.invoiceItems[item].data.price,
                "tax": tmpTax

                // "data": this.state.invoiceItems[item].data
            }
            invoiceItems.push(
                tmp_item
            )
        }

        /* prepare shipping amount */

        var shippingAmount = parseFloat(this.state.shippingAmount)
        if (isNaN(shippingAmount)) {
            shippingAmount = 0
        }
        if (shippingAmount <= 0) {
            shippingAmount = 0
        }

        /* prepare coupon data */

        var tmpCouponData = { ...this.state.couponData }
        if (this.state.couponData !== null) {
            delete tmpCouponData._created
            tmpCouponData._id = tmpCouponData._id.$oid
        } else {
            tmpCouponData = null
        }


        axios.patch(URL + "invoice", {
            _id: this.invoiceID,
            customer_id: this.state.customerID,
            invoice_number: this.state.invoiceNumber,
            coupon: tmpCouponData,
            invoice_date: this.state.invoiceDate,
            note: this.state.note,
            // handler_id: this.state.handlerID,
            items: invoiceItems,
            isav: this.state.approvalVoucherBool,
            couponDiscountAmount: couponDiscountValue,
            shippingAmount: shippingAmount,
            gst: this.state.gst
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response_) {

                const response = response_.data
                // that.setState({ response: response["data"] })
                // console.log("server", response["data"])
                var message = null
                if ("_status" in response && response["_status"] === "success") {
                    // $("#add_inquiry_button").prop('disabled', true);
                    // setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
                    message = <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                        {response["payload"]}
                    </div>

                    // that.resetFields()

                } else if ("_status" in response && response["_status"] === "fail") {

                    var ret = []
                    for (var i in response["payload"][0]) {
                        // console.log(this.state.response.payload[0][i])
                        ret.push(
                            <span>{i}: {response["payload"][0][i]}<br /></span>
                        );
                    }
                    message =
                        <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                            {ret}
                        </div>

                }

                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Create Invoice",
                    message: message
                })



                $("#updateInvoiceButton").html("Update")

            })
    }

    viewInvoice = () => {
        const that = this;
        var params = { _id: this.invoiceID, pr: "yes" }

        if (this.state.panCheck) {
            params["pc"] = '1'
        } else {
            delete params["pc"];
        }

        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedInvoice: response["data"]["payload"] })
                if (response["data"]["_status"] === "success") {
                    var WinPrint = window.open('', 'Invoice', 'width=1280px,height=720px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    WinPrint.focus();

                }
            })
    }

    handleDeleteInvoice = (response) => {
        this.setState({ isLoading: false })

        if (!("_status" in response) || !("payload" in response)) {
            alert("Unable to delete invoice")
            this.setState({ isSuccess: false, isError: true })
            return
        }
        if (response["_status"] !== "success") {
            alert("Unable to delete invoice")
            this.setState({ isSuccess: false, isError: true, })
            return
        }


        fullDialogV2Dispatcher.dispatch({
            actionType: "close-all-dialogs",
            callback: () => {
            }
        })
        fullDialogV2Dispatcher.dispatch({
            actionType: "show-dialog",
            title: "Invoice Deleted",
            message: <div><span className="text-success"><FontAwesomeIcon icon={faCheckCircle} />&nbsp;</span>Invoice succesfully deleted!</div>
        })


    }

    deleteInvoice = () => {
        const that = this;
        this.setState({ isLoading: true })
        var params = { _id: this.invoiceID }
        axios.delete(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.handleDeleteInvoice(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleDeleteInvoice({})
            })
    }

    printInvoice = () => {
        const that = this;
        var params = { _id: this.invoiceID, pr: "yes" }

        if (this.state.panCheck) {
            params["pc"] = '1'
        } else {
            delete params["pc"];
        }


        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedInvoice: response["data"]["payload"] })
                if (response["data"]["_status"] === "success") {
                    var WinPrint = window.open('', 'Invoice', 'width=1280px,height=720px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }
            })
    }

    getCustomerFromOrder = () => {
        $("#getOrderCustomerButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Ok")
        const that = this


        axios.post(URL + "orders/search", {
            orderId: this.state.orderID,
            pageNumber: 0,
            searchFilters: { "description": false, "tracking": false, "to": false, "from": false },
            searchTerm: ""
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                if (response.data.payload.orders.length === 1) {
                    const params = { _id: response.data.payload.orders[0].to_id }
                    axios.get(URL + "data", {
                        params: params,
                        headers: { Authorization: ACCT }
                    }).then(function (response_) {
                        console.log(response_.data)
                        that.setState({ customerID: response_.data.payload._id.$oid, customer: response_.data.payload, handlerID: response_.data.payload.handlerId, handler: response_.data.payload.handler, response: {} })
                        $("#getOrderCustomerButton").html("Ok")
                    })
                }
                else {
                    var error = {
                        "_status": "",
                        "payload": []
                    }
                    var tmpPayload = {}
                    var errorSet = false
                    if (response.data.payload.orders.length < 1) {
                        if (!errorSet) {
                            error["_status"] = "fail"
                            errorSet = true
                        }
                        tmpPayload["Order"] = "No Such Order Found."

                    }
                    $("#getOrderCustomerButton").html("Ok")
                    if (errorSet) {
                        error["payload"] = [tmpPayload]
                        that.setState({ response: error, customerID: "", customer: {} })
                        error["payload"] = [tmpPayload]
                        // this.setState({ response: error })
            
                        var ret = []
                        for (var i in error["payload"][0]) {
                            // console.log(this.state.response.payload[0][i])
                            ret.push(
                                <span>{i}: {error["payload"][0][i]}<br /></span>
                            );
                        }
                        var message =
                            <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                                {ret}
                            </div>
            
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Create Invoice",
                            message: message
                        })
            
                        return
                        
                    }


                    $("#getOrderCustomerButton").html("Ok")
                }

                // that.setState({ response: response["data"] })
                // console.log("server", response["data"])


            })
            .catch(error_ => {
                var error = {
                    "_status": "",
                    "payload": []
                }
                var tmpPayload = {}
                var errorSet = false
                if (!errorSet) {
                    error["_status"] = "fail"
                    errorSet = true
                }
                tmpPayload["Order"] = "No Such Order Found."
                $("#getOrderCustomerButton").html("Ok")
                if (errorSet) {
                    error["payload"] = [tmpPayload]
                    that.setState({ response: error })
                    error["payload"] = [tmpPayload]
                    // this.setState({ response: error })
        
                    var ret = []
                    for (var i in error["payload"][0]) {
                        // console.log(this.state.response.payload[0][i])
                        ret.push(
                            <span>{i}: {error["payload"][0][i]}<br /></span>
                        );
                    }
                    var message =
                        <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                            {ret}
                        </div>
        
                    fullDialogV2Dispatcher.dispatch({
                        actionType: "show-dialog",
                        title: "Create Invoice",
                        message: message
                    })
        
                    return
                    return
                }
            });
    }

    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }

    handle_inventory_search = (query) => {

        this.setState({ asyncinventorysearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'inventory', {
            params: {
                page_number: 0,
                st: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncinventorysearch: { isLoading: false, options: response["data"]["payload"][0] } })
            })

    }

    SelectedCustomer = () => {
        if (this.state.customerID.length > 0) {
            const customer = this.state.customer
            return (
                <div>
                    <div class="row mt-2">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="container w-100 p-0">
                                <div className="card border-primary" >
                                    <div class="card-body">
                                        <div class="container m-0 p-0">
                                            <div class="row">
                                                <div class="card m-1 mt-0 border-0 p-0">
                                                    <div class="card-body">
                                                        <h5 class="card-title"><b>{customer['firstname']} {customer['lastname']}</b></h5>
                                                        <label>Email:</label> {customer['email']}<br />
                                                        <label>Phone:</label> {customer['phone']}<br />
                                                        <label>Handler:</label>&nbsp;{customer['handler']}<br />
                                                        <label>Brand:</label>&nbsp;{customer['brand']}
                                                    </div>
                                                </div>
                                                <div class="card m-1 border-0 p-0">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Shipping Address</h5>
                                                        {/* <h5 class="card-subtitle mb-2 text-muted">Shipping Address</h5> */}
                                                        <label></label>
                                                        <label>{customer['shippingAddressAddress1']}</label><br />
                                                        <label>{customer['shippingAddressAddress2']}</label><br />
                                                        <label>{customer['shippingAddressCity']}, {customer['shippingAddressState']}</label><br />
                                                        <label>{customer['shippingAddressPincode']}, {customer['shippingAddressCountry']}</label>
                                                    </div>
                                                </div>
                                                <div class="card m-1 border-0 p-0">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Billing Address</h5>
                                                        {/* <h5 class="card-subtitle mb-2 text-muted">Billing Address</h5> */}
                                                        <label>{customer['billingAddressAddress1']}</label><br />
                                                        <label>{customer['billingAddressAddress2']}</label><br />
                                                        <label>{customer['billingAddressCity']}, {customer['billingAddressState']}</label><br />
                                                        <label>{customer['billingAddressPincode']}, {customer['billingAddressCountry']}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    Status = (e) => {
        // useEffect(() => {
        // Update the document title using the browser API
        // if ("_status" in this.state.response) {
        // document.getElementById('addInvoiceStatusDiv').focus()
        // }
        // });
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }

    handleGrabItemFromPunch = (response) => {
        if (!("_status" in response) || !("payload" in response)) {
            this.setState({ punchText: "" })
            alert("Some server side error has occurred. Please try again.")
            return
        }

        if (response["_status"] !== "success") {
            this.setState({ punchText: "" })
            alert("Such item probably does not exist.")
            return
        }

        const payload = response.payload

        var tmp_invoice_items = this.state.invoiceItems

        // if (payload._id.$oid in this.state.invoiceItems) {
        //     const tmpItems = this.state.invoiceItems
        //     tmpItems[payload._id.$oid]["count"] += 1
        //     this.setState({ invoiceItems: tmpItems, punchText: "" })
        //     return
        // }

        tmp_invoice_items[payload._id.$oid] = {
            "count": 1, "discount": 0, "discountValue": 0, "discount_type": "%", "data": payload, "price": payload.price, "tax": payload.tax
        } // 0 for percentage 1 for direct 
        // tmp_invoice_items[payload._id.$oid] = payload

        this.setState({ invoiceItems: tmp_invoice_items, punchText: "" })
    }

    grabItemFromPunch = () => {
        if (this.state.punchText === "") {
            return
        }

        for (var i in this.state.invoiceItems) {
            if (this.state.punchText === this.state.invoiceItems[i].sku) {
                const tmpItems = this.state.invoiceItems
                tmpItems[i]["count"] += 1
                this.setState({ invoiceItems: tmpItems, punchText: "" })
                return
            }
        }


        this.setState({ asyncinventorysearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'inventory', {
            params: {
                sku: this.state.punchText
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response.data)
                that.handleGrabItemFromPunch(response.data)
                // that.setState({ asyncinventorysearch: { isLoading: false, options: response["data"]["payload"][0] } })
            })
            .catch(function (error) {
                // alert(error)
                // console.log(error)
                that.handleGrabItemFromPunch({})
                // that.setState({})
            })
    }

    render() {

        if (this.state.isLoading) {
            return <div>
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        }

        if (this.state.isError) {
            return <div className='text-danger'>
                Failed to fetch invoice data.
            </div>
        }

        if (this.state.isSuccess) {

            var items = []

            var totalOfAllItems = 0

            for (var item in this.state.invoiceItems) {

                /* calculate rate */

                var taxPercentage = parseFloat(this.state.invoiceItems[item].tax.p)
                var taxType = String(this.state.invoiceItems[item].tax.t).toUpperCase()
                var taxDecimal = (taxPercentage / 100)
                var originalRate = parseFloat(this.state.invoiceItems[item].data.price.sp)
                var originalSellingPrice = parseFloat(this.state.invoiceItems[item].data.price.sp)
                var sellingPrice = originalSellingPrice - this.state.invoiceItems[item].discountValue
                var itemCount = parseInt(this.state.invoiceItems[item].count)

                // var rate = originalRate - this.state.invoiceItems[item].discountValue
                var rate = 0

                /* calculate tax amount on rate */
                var taxAmount = 0
                var originalTaxAmount = 0

                if (taxType === "INC") {
                    taxAmount = sellingPrice - (sellingPrice / (1.0 + taxDecimal))
                    originalTaxAmount = originalSellingPrice - (originalSellingPrice / (1.0 + taxDecimal))

                    originalRate = originalSellingPrice - originalTaxAmount
                    rate = sellingPrice - taxAmount
                }
                if (taxType === "EXC") {
                    taxAmount = sellingPrice * taxDecimal
                    originalTaxAmount = originalSellingPrice * taxDecimal

                    rate = sellingPrice
                }

                /* calibrate rate,tax */
                rate = parseFloat(rate.toFixed(2))
                taxAmount = parseFloat(taxAmount.toFixed(2))

                /* get total rate and total tax */

                const totalRate = rate * itemCount
                const totalTax = taxAmount * itemCount
                /* get total amount */
                const totalAmount = totalRate + totalTax

                /* set total of all items */
                totalOfAllItems += totalAmount

                items.push(
                    <p key={this.state.invoiceItems[item]._id}>
                        <hr />

                        <span className="d-flex justify-content-between align-items-center">
                            <span>
                                <h5>{this.state.invoiceItems[item].data.name}</h5>
                            </span>
                            <span>
                                <i class="far fa-trash-alt" style={{ cursor: "pointer" }} lid={item} onClick={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.invoiceItems
                                    delete tmp_items[item_]
                                    this.setState({ invoiceItems: tmp_items })
                                }}></i>
                            </span>
                        </span>

                        <span className="d-flex justify-content-between align-items-center">
                            <span>
                                SKU: {this.state.invoiceItems[item].data.sku} <br />
                            </span>
                            <span>
                                &#8377;&nbsp;{parseFloat(this.state.invoiceItems[item].data.price.sp).toLocaleString('en-IN')} <br />
                            </span>
                        </span>

                        <span className="d-flex justify-content-between align-items-center">
                            <div>
                                <div className="form-group">
                                    <b>Tax Percentage</b>
                                </div>
                                <div className="form-group">
                                    <span className="d-flex flex-inline  align-items-center">
                                        {/* Quantity:&nbsp; */}
                                        <div class="input-group input-group-sm">
                                            <div class="input-group-prepend">

                                                <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                    const item_ = e.target.getAttribute('lid')

                                                    var tmp_items = this.state.invoiceItems

                                                    var newTaxValue = parseFloat(tmp_items[item_].tax.p)
                                                    if (newTaxValue - 1 < 0) {
                                                        newTaxValue = 0
                                                    }
                                                    else {
                                                        newTaxValue -= 1
                                                    }
                                                    tmp_items[item_].tax.p = newTaxValue
                                                    this.setState({ invoiceItems: tmp_items })
                                                }}>-</button>
                                            </div>
                                            <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" value={this.state.invoiceItems[item].tax.p} onChange={(e) => {
                                                const item_ = e.target.getAttribute('lid')
                                                var tmp_items = this.state.invoiceItems

                                                if (e.target.value === "") {
                                                    tmp_items[item_].tax.p = 0
                                                } else if (parseFloat(e.target.value) < 0) {
                                                    tmp_items[item_].tax.p = 0
                                                } else if (parseFloat(e.target.value) >= 100) {
                                                    tmp_items[item_].tax.p = 100
                                                }
                                                else {
                                                    if (parseFloat(e.target.value) > 0) {
                                                        tmp_items[item_].tax.p = e.target.value
                                                    }
                                                }
                                                this.setState({ invoiceItems: tmp_items })
                                            }} />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                    const item_ = e.target.getAttribute('lid')

                                                    var tmp_items = this.state.invoiceItems

                                                    var newTaxValue = parseFloat(tmp_items[item_].tax.p)
                                                    if (newTaxValue + 1 >= 100) {
                                                        newTaxValue = 100
                                                    }
                                                    else {
                                                        newTaxValue += 1
                                                    }
                                                    tmp_items[item_].tax.p = newTaxValue
                                                    this.setState({ invoiceItems: tmp_items })
                                                }}>+</button>
                                            </div>
                                        </div>

                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <b>Tax Type</b>
                                </div>
                                <div className="form-group">
                                    <select class="custom-select" lid={item} defaultValue="inc" value={this.state.invoiceItems[item].tax.t} onChange={(e) => {
                                        const item_ = e.target.getAttribute('lid')
                                        var tmp_items = this.state.invoiceItems
                                        tmp_items[item_].tax.t = e.target.value
                                        this.setState({ invoiceItems: tmp_items })
                                    }}>
                                        <option value="inc">Inclusive</option>
                                        <option value="exc">Exclusive</option>
                                    </select>
                                </div>
                            </div>
                        </span>

                        <span className="d-flex justify-content-between align-items-center">
                            <div>
                                <div className="form-group">
                                    <b>Quantity</b>
                                </div>
                                <span className="d-flex flex-inline  align-items-center">
                                    <div className="form-group">
                                        <div class="input-group input-group-sm">
                                            <div class="input-group-prepend">

                                                <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                    const item_ = e.target.getAttribute('lid')
                                                    var discount = 0
                                                    var tmp_items = this.state.invoiceItems
                                                    if (parseInt(tmp_items[item_].count) < 2) {
                                                        tmp_items[item_].count = 1
                                                    }
                                                    else {
                                                        tmp_items[item_].count -= 1
                                                    }
                                                    if ((tmp_items[item_].discount_type === "d")) {
                                                        discount = tmp_items[item_].count * tmp_items[item_].discount
                                                    }
                                                    if (tmp_items[item_].discount_type === "%") {
                                                        discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].data.price.sp
                                                    }
                                                    tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].data.price.sp) - discount)
                                                    this.setState({ invoiceItems: tmp_items })
                                                }}>-</button>
                                            </div>
                                            <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" value={this.state.invoiceItems[item].count} onChange={(e) => {
                                                const item_ = e.target.getAttribute('lid')
                                                var tmp_items = this.state.invoiceItems
                                                // if (tmp_items[item_].count > 1) {
                                                var discount = 0
                                                if (e.target.value === "") {
                                                    tmp_items[item_].count = 0
                                                } else if (parseInt(e.target.value) < 1) {

                                                    tmp_items[item_].count = 0
                                                }
                                                else {
                                                    tmp_items[item_].count = parseInt(e.target.value)
                                                }
                                                // }
                                                if ((tmp_items[item_].discount_type === "d")) {
                                                    discount = tmp_items[item_].count * tmp_items[item_].discount
                                                }
                                                if (tmp_items[item_].discount_type === "%") {
                                                    discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].data.price.sp
                                                }
                                                tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].data.price.sp) - discount)
                                                this.setState({ invoiceItems: tmp_items })
                                            }} />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                    const item_ = e.target.getAttribute('lid')
                                                    var discount = 0
                                                    var tmp_items = this.state.invoiceItems
                                                    tmp_items[item_].count += 1
                                                    if ((tmp_items[item_].discount_type === "d")) {
                                                        discount = tmp_items[item_].count * tmp_items[item_].discount
                                                    }
                                                    if (tmp_items[item_].discount_type === "%") {
                                                        discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].data.price.sp
                                                    }
                                                    tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].data.price.sp) - discount)
                                                    this.setState({ invoiceItems: tmp_items })
                                                }}>+</button>
                                            </div>
                                        </div>

                                    </div>
                                </span>
                            </div>
                            <span>
                                <div className="form-group">
                                    <b>Selling Price Discount</b>
                                </div>
                                <div class="input-group input-group-sm">
                                    {/* <div class="input-group-prepend"> */}
                                    <select class="custom-select" style={{ width: '50px' }} lid={item} defaultValue="%" value={this.state.invoiceItems[item].discount_type} onChange={(e) => {
                                        const item_ = e.target.getAttribute('lid')
                                        var tmp_items = this.state.invoiceItems
                                        tmp_items[item_].discount_type = e.target.value
                                        this.setState({ invoiceItems: tmp_items }, () => {
                                            var tmp_items = this.state.invoiceItems
                                            var discount = tmp_items[item_].discount

                                            var discountValue = 0

                                            if (discount === "") {
                                                discountValue = 0
                                            } else if (parseInt(discount) < 0) {
                                                discountValue = 0
                                            }

                                            else if ((tmp_items[item_].discount_type === "d")) {
                                                if ((parseFloat(discount) > tmp_items[item_].data.price.sp)) {
                                                    discountValue = tmp_items[item_].data.price.sp
                                                } else {
                                                    discountValue = parseFloat(discount)
                                                }
                                            }
                                            else if (tmp_items[item_].discount_type === "%") {
                                                if ((parseFloat(discount) > 100)) {
                                                    discountValue = tmp_items[item_].data.price.sp
                                                }
                                                else {
                                                    discountValue = (parseFloat(discount) / 100) * tmp_items[item_].data.price.sp
                                                }
                                            }
                                            else {
                                                discountValue = 0
                                            }
                                            tmp_items[item_].discountValue = discountValue
                                            tmp_items[item_].discount = discount

                                            this.setState({ invoiceItems: tmp_items })
                                        })
                                    }}>
                                        <option value="%">%</option>
                                        <option value="d">d</option>
                                    </select>
                                    {/* </div> */}
                                    <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="discount on single item" aria-describedby="button-discount" value={this.state.invoiceItems[item].discount} onChange={(e) => {
                                        const item_ = e.target.getAttribute('lid')
                                        var tmp_items = this.state.invoiceItems

                                        var discountValue = 0

                                        if (e.target.value === "") {
                                            discountValue = 0
                                        } else if (parseInt(e.target.value) < 0) {
                                            discountValue = 0
                                        }

                                        else if ((tmp_items[item_].discount_type === "d")) {
                                            if ((parseFloat(e.target.value) > tmp_items[item_].data.price.sp)) {
                                                discountValue = tmp_items[item_].data.price.sp
                                            } else {
                                                discountValue = parseFloat(e.target.value)
                                            }
                                        }
                                        else if (tmp_items[item_].discount_type === "%") {
                                            if ((parseFloat(e.target.value) > 100)) {
                                                discountValue = tmp_items[item_].data.price.sp
                                            }
                                            else {
                                                discountValue = (parseFloat(e.target.value) / 100) * tmp_items[item_].data.price.sp
                                            }
                                        }
                                        else {
                                            discountValue = 0
                                        }
                                        tmp_items[item_].discountValue = discountValue
                                        tmp_items[item_].discount = e.target.value

                                        this.setState({ invoiceItems: tmp_items })
                                    }} />
                                </div>
                                <span lid={item} className="btn-link" style={{
                                    cursor: 'pointer'
                                }} onClick={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    fullDialogV2Dispatcher.dispatch({
                                        actionType: "show-dialog",
                                        title: "Calculate Selling Price Discount",
                                        message: <SellingPriceCalculator data={this.state.invoiceItems[item_]} onSuccessCallback={(d) => {

                                            var tmp_items = this.state.invoiceItems
                                            tmp_items[item_].discount = d
                                            var discount = tmp_items[item_].discount

                                            var discountValue = 0

                                            if (discount === "") {
                                                discountValue = 0
                                            } else if (parseInt(discount) < 0) {
                                                discountValue = 0
                                            }

                                            else if ((tmp_items[item_].discount_type === "d")) {
                                                if ((parseFloat(discount) > tmp_items[item_].data.price.sp)) {
                                                    discountValue = tmp_items[item_].data.price.sp
                                                } else {
                                                    discountValue = parseFloat(discount)
                                                }
                                            }
                                            else if (tmp_items[item_].discount_type === "%") {
                                                if ((parseFloat(discount) > 100)) {
                                                    discountValue = tmp_items[item_].data.price.sp
                                                }
                                                else {
                                                    discountValue = (parseFloat(discount) / 100) * tmp_items[item_].data.price.sp
                                                }
                                            }
                                            else {
                                                discountValue = 0
                                            }
                                            tmp_items[item_].discountValue = discountValue
                                            tmp_items[item_].discount = discount

                                            this.setState({ invoiceItems: tmp_items })

                                            fullDialogV2Dispatcher.dispatch({
                                                actionType: "close-dialog",
                                                dialogIndex: sellingPriceCalculatorDialogIndex
                                            })
                                        }} />,
                                        dialogIndexCallback: (idx) => {
                                            sellingPriceCalculatorDialogIndex = idx
                                        }
                                    })
                                }}>
                                    <small lid={item}>Calculate</small>
                                </span>
                            </span>
                        </span>

                        <div class="table-responsive">
                            <table className="table table-borderless w-100">
                                <tr>
                                    <td>
                                        <b>MRP</b>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <b>Original Seller Price</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(this.state.invoiceItems[item].data.price.mrp).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(originalSellingPrice).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Selling Price</b>
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(sellingPrice).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Rate</b>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <b>Tax</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(rate).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(taxAmount).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Total Rate</b>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <b>Total Tax</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(totalRate).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="">
                                            &#8377;&nbsp;{parseFloat(totalTax).toLocaleFixed(2)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <b>Item Total</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <h5>
                                            &#8377;&nbsp;{totalAmount.toLocaleFixed(2)}
                                        </h5>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </p >
                )
            }

            /* prepare shipping dom */
            var shippingAmountDOM = null
            var shippingAmount = parseFloat(this.state.shippingAmount)
            if (isNaN(shippingAmount)) {
                shippingAmount = 0
            }
            if (shippingAmount > 0) {
                shippingAmountDOM = [<hr />,
                <span className="d-flex justify-content-end justify-align-center" style={{
                    textAlign: 'right'
                }}>
                    <span>
                        {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                        <span>Shipping: &#8377;&nbsp;{shippingAmount.toLocaleFixed(2)}</span>
                    </span>
                </span>,
                <hr />]
            }

            /* prepare couponDOM */

            var couponValue = 0
            var couponDiscountDOM = null
            var couponDOM = <span className="btn-link" style={{
                cursor: 'pointer'
            }} onClick={() => {
                const that = this
                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Add Coupon",
                    message: <AddCoupon customerID={this.state.customerID} onSuccessCallback={(d) => {
                        that.setState({ couponData: d })
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "close-dialog",
                            dialogIndex: addCouponDialogIndex
                        })
                    }} />,
                    dialogIndexCallback: (idx) => {
                        addCouponDialogIndex = idx
                    }
                })
            }} >Add Coupon</span>

            if (this.state.couponData !== null) {
                couponDOM = <div>
                    <span><b><u>{this.state.couponData.coupon}</u></b></span>
                    <br />
                    <span hidden={!(this.state.couponData.coupon_discount_type === "%")}>{this.state.couponData.coupon_discount}% OFF</span>
                    <span hidden={!(this.state.couponData.coupon_discount_type === "d")}>Direct &#8377;{this.state.couponData.coupon_discount} OFF</span>
                    <br />
                    <span className="btn-link" style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        this.setState({ couponData: null })
                    }} ><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove Coupon</span>
                </div>

                /* calculate coupon value */

                if ((this.state.couponData.coupon_discount_type === "%")) {
                    couponValue = parseFloat(parseFloat(this.state.couponData.coupon_discount) / 100) * totalOfAllItems
                } else if ((this.state.couponData.coupon_discount_type === "d")) {
                    couponValue = parseFloat(this.state.couponData.coupon_discount)
                }

                /* calibrate coupon value */
                couponValue = parseFloat(couponValue.toFixed(2))

                couponDiscountDOM = [<hr />,
                <span className="d-flex justify-content-end justify-align-center" style={{
                    textAlign: 'right'
                }}>
                    <span>
                        {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                        <span>Coupon Discount: &#8377;&nbsp;{couponValue.toLocaleFixed(2)}</span>
                        <br />
                        <small>Rates will be adjusted in invoice according to this coupon price</small>
                    </span>
                </span>,
                <hr />]

            }


            /* total sum DOM */

            var totalSumDOM = null
            if (totalOfAllItems > 0) {
                totalSumDOM = [<hr />,
                <span className="d-flex justify-content-end justify-align-center">
                    <span>
                        {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                        <span>Total: &#8377;&nbsp;{totalOfAllItems.toLocaleFixed(2)}</span>
                    </span>
                </span>,
                <hr />]
            }

            /* final total */
            var finalTotalSumDOM = null
            if (totalOfAllItems > 0) {
                finalTotalSumDOM = [<hr />,
                <span className="d-flex justify-content-end justify-align-center">
                    <span style={{
                        fontWeight: 'bolder'
                    }}>
                        {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                        <span>Final Total: &#8377;&nbsp;{(totalOfAllItems - couponValue + shippingAmount).toLocaleFixed(2)}</span>
                    </span>
                </span>,
                <hr />]
            }


            /* prepare status DOM */

            var statusDOM = null
            // if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            //     // $("#add_inquiry_button").prop('disabled', true);
            //     // setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            //     return (
            //         <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
            //             {this.state.response["payload"]}
            //         </div>
            //     );

            // }

            return (
                <div className="p-4">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <this.SelectedCustomer />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="invoiceCustomer"><b>Customer</b></label>
                            <AsyncTypeahead type="text" placeholder="Search" name="to" id="invoiceCustomer"
                                labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                                filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                                onSearch={this.handle_search}
                                {...this.state.asyncsearch}
                                maxResults="10"
                                ref={(typeahead) => this.typeahead_customer = typeahead}
                                renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps} className="search_menu" id="search_menu">
                                        {results.map((result, index) => (
                                            <MenuItem option={result} position={index} className="search_item m-0">
                                                <div className="w-100 p-2 search_item" onClick={(e) => {
                                                    //   $("#search_menu").hide();
                                                    this.setState({ customerID: result._id.$oid, customer: result, orderID: "", response: {}, handler: result.handler, handlerID: result.handlerId, couponData: null, gst: result.gstin })
                                                }}>

                                                    {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                )}
                            />
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label for="invoiceInvoice"><b>Order ID</b></label>
                            <div class="input-group">
                                <input type="text" id="inventoryOrderID" className="form-control border-1 small" name="orderID" value={this.state.orderID} onChange={(e) => {
                                    this.setState({ orderID: e.target.value })
                                }} onKeyDown={(e) => {
                                    if (e.keyCode == 13) {
                                        this.getCustomerFromOrder()
                                    }
                                }} />
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-primary" id="getOrderCustomerButton" onClick={this.getCustomerFromOrder}>
                                        Ok
                            </button>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label for="invoiceInvoiceDate"><b>Invoice Date</b></label>
                            <DatePicker
                                selected={this.state.invoiceDate}
                                onChange={(date) => { this.setState({ invoiceDate: date }) }}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                value={this.state.invoiceDate}
                            />
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label for="invoiceInvoice"><b>Invoice Number</b></label>
                            <input type="text" id="inventoryInvoiceNumber" className="form-control border-1 small" name="invoiceNumber" value={this.state.invoiceNumber} onChange={(e) => {
                                this.setState({ invoiceNumber: e.target.value })
                            }} />
                            <small id="invoiceNumberHelp" class="form-text text-muted">Use Only When Custom Invoice Number Is Needed.</small>
                            {/* <label for="invoiceInvoiceHandler"><b>Handler</b></label>
                            <select class="custom-select" name="handler" id="inputGroupSelect01" value={this.state.handler} defaultValue={this.state.handler} onChange={(e) => {
                                const lid = e.target.options[e.target.selectedIndex].getAttribute('lid')
                                this.setState({ handlerID: lid, handler: e.target.value })
                            }}>
                                <option selected value="">Select Handler</option>
                                <Handlers />
                            </select> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="invoiceNote"><b>Note</b></label>
                            <textarea maxLength="300" type="text" id="invoiceNote" className="form-control border-1 small" name="invoiceNote" value={this.state.note} onChange={(e) => {
                                this.setState({ note: e.target.value })
                            }} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="invoiceGST"><b>GST</b></label>
                            <input maxLength="20" type="text" id="invoiceGST" className="form-control border-1 small" name="invoiceGST" value={this.state.gst} onChange={(e) => {
                                this.setState({ gst: e.target.value })
                            }} />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="invoiceShippingAmount"><b>Shipping Amount</b></label>
                            <input type="number" id="invoiceShippingAmount" className="form-control border-1 small" name="invoiceShippingAmount" value={this.state.shippingAmount} onChange={(e) => {
                                this.setState({ shippingAmount: e.target.value })
                            }} />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="invoiceCoupon"><b>Coupon</b></label>
                            <br />
                            {couponDOM}
                            {/* <textarea type="text" id="invoiceCoupon" className="form-control border-1 small" name="invoiceCoupon" value={this.state.coupon} onChange={(e) => {
                                this.setState({ coupon: e.target.value })
                            }} /> */}

                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex flex-row col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="invoiceItems"><b>Items</b></label>
                        </div>
                        <div className="d-flex flex-row col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="form-check m-1">
                                <input class="form-check-input" type="radio" name="editInvoiceItemInputRadio" id="editInvoiceItemInputRadioPunchMode" checked={!this.state.itemInputTypeBool} onChange={(e) => {
                                    this.setState({ itemInputTypeBool: !e.target.checked })
                                    if (e.target.checked) {
                                        const ele = document.getElementById("punchModeInput")
                                        window.setTimeout(function () {
                                            ele.focus()
                                        }, 0);
                                    }
                                }} />
                                <label class="form-check-label" for="editInvoiceItemInputRadioPunchMode">
                                    Punch Mode
                                </label>
                            </div>
                            <div class="form-check m-1">
                                <input class="form-check-input" type="radio" name="editInvoiceItemInputRadio" id="editInvoiceItemInputRadioSearchMode" checked={this.state.itemInputTypeBool} onChange={(e) => {
                                    this.setState({ itemInputTypeBool: e.target.checked })
                                }} />
                                <label class="form-check-label" for="editInvoiceItemInputRadioSearchMode">
                                    Search Mode
                                </label>
                            </div>
                        </div>
                        <div hidden={this.state.itemInputTypeBool} className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <span>
                                Punch mode enabled
                            </span>
                            <span hidden={this.state.punchModeInputIndicatorFocus}>
                                <br />
                                <span style={{
                                    cursor: 'pointer'
                                }} className="btn-link" onClick={(e) => {
                                    const ele = document.getElementById("punchModeInput")
                                    window.setTimeout(function () {
                                        ele.focus()
                                    }, 0);
                                }}>
                                    Click here to enter punch mode
                                </span>
                            </span>
                            {/* <div className={this.state.punchModeInputIndicatorClassname} id="punchModeInputIndicator" style={{
                                height: '1.5rem'
                            }}> */}
                            <input style={{
                                caretColor: 'transparent',
                                cursor: 'pointer'
                            }} autocomplete="off" className={this.state.punchModeInputIndicatorClassname + ` form-control`} type="text" value={this.state.punchText} id="punchModeInput" onChange={(e) => {
                                this.setState({ punchText: e.target.value })
                            }} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    this.grabItemFromPunch()
                                }
                            }} onBlur={(e) => {
                                this.setState({ punchModeInputIndicatorClassname: "punchModeAnimationError w-100 rounded", punchModeInputIndicatorFocus: false })
                            }} onFocus={(e) => {
                                this.setState({ punchModeInputIndicatorClassname: "punchModeAnimation w-100 rounded", punchModeInputIndicatorFocus: true })
                            }} />
                            {/* </div> */}
                        </div>
                        <div hidden={!this.state.itemInputTypeBool} className="col-sm-12 col-md-12 col-lg-12 col-xl-12">

                            <AsyncTypeahead type="text" placeholder="Search" name="items" id="invoiceItems"
                                labelKey={option => `${option.name} | ${option.sku}`}
                                filterBy={['name', 'sku']}
                                onSearch={this.handle_inventory_search}
                                {...this.state.asyncinventorysearch}
                                maxResults="10"
                                ref={(typeahead) => this.typeahead_items_search = typeahead}
                                renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps} className="search_menu" id="search_menu">
                                        {results.map((result, index) => (
                                            <MenuItem option={result} position={index} className="search_item m-0">
                                                <div className="w-100 p-2 search_item" onClick={(e) => {
                                                    var tmp_invoice_items = this.state.invoiceItems
                                                    tmp_invoice_items[result._id.$oid] = {
                                                        "count": 1, "discount": 0, "discountValue": 0, "discount_type": "%", "data": result, "tax": result.tax, "price": result.price
                                                    } // 0 for percentage 1 for direct 
                                                    // tmp_invoice_items[result._id.$oid] = result

                                                    this.setState({ invoiceItems: tmp_invoice_items }, () => {
                                                        const ref_ = this.typeahead_items_search.getInstance()
                                                        ref_.clear()
                                                    })

                                                }}>
                                                    {`${result.name} | ${result.sku}`}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                )}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            {items}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            {totalSumDOM}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            {couponDiscountDOM}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            {shippingAmountDOM}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            {finalTotalSumDOM}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 ">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="editInvoiceApprovalVoucherCheckbox"
                                    checked={this.state.approvalVoucherBool} onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ approvalVoucherBool: e.target.checked })
                                        }
                                        else {
                                            this.setState({ approvalVoucherBool: e.target.checked })
                                        }

                                    }}
                                />
                                <label class="form-check-label" for="editInvoiceApprovalVoucherCheckbox">Approval Voucher</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 ">
                            <button type="button" id="updateInvoiceButton" class="btn btn-primary mr-1" onClick={() => {

                                const that = this
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Update Invoice",
                                    message: <div>
                                        <div className='form-group'>
                                            Do you want to update invoice?
                                        </div>
                                        <div hidden={this.state.approvalVoucherBool} className='form-group text-danger'>
                                            <span>Approval tag will be removed! Do this only when you need to finalize the invoice.</span>
                                        </div>
                                        <div className='form-group'>
                                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: updateInvoiceDialogIndex
                                                })
                                            }}>Cancel</button>
                                            <button type="button" class="btn btn-success m-1" onClick={() => {
                                                this.saveInvoice(couponValue)

                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: updateInvoiceDialogIndex
                                                })
                                            }}>Update</button>
                                        </div>
                                    </div>,
                                    dialogIndexCallback: (idx) => {
                                        updateInvoiceDialogIndex = idx
                                    }
                                })

                            }}>Update</button>
                            {/* <button type="button" class="btn btn-primary m-1">Print</button> */}
                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                const that = this
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Clear Data?",
                                    message: <div>
                                        <div className='form-group'>
                                            Do you want to clear data?
                                        </div>
                                        <div className='form-group'>
                                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: clearDataDialogIndex
                                                })
                                            }}>Cancel</button>
                                            <button type="button" class="btn btn-danger m-1" onClick={() => {
                                                that.typeahead_customer.getInstance().clear()
                                                that.typeahead_items_search.getInstance().clear()
                                                that.resetFields()
                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: clearDataDialogIndex
                                                })
                                            }}>Clear</button>
                                        </div>
                                    </div>,
                                    dialogIndexCallback: (idx) => {
                                        clearDataDialogIndex = idx
                                    }
                                })


                            }}>Clear</button>
                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                this.viewInvoice()
                            }}>View</button>
                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                this.printInvoice()
                            }}>Print</button>

                            <button type="button" class="btn btn-danger m-1" onClick={() => {
                                const that = this
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Reset Data?",
                                    message: <div>
                                        <div className='form-group'>
                                            Do you want to reset data to original state?
                                        </div>
                                        <div className='form-group'>
                                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: resetDataDialogIndex
                                                })
                                            }}>Cancel</button>
                                            <button type="button" class="btn btn-danger m-1" onClick={() => {
                                                // that.typeahead_customer.getInstance().clear()
                                                // that.typeahead_items_search.getInstance().clear()
                                                // that.resetFields()
                                                that.fetchInvoice()

                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: resetDataDialogIndex
                                                })
                                            }}>Reset</button>
                                        </div>
                                    </div>,
                                    dialogIndexCallback: (idx) => {
                                        resetDataDialogIndex = idx
                                    }
                                })

                            }}>Reset Data</button>


                            <button type="button" class="btn btn-danger m-1" onClick={() => {
                                const that = this
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Delete Invoice?",
                                    message: <div>
                                        <div className='form-group'>
                                            Do you want to DELETE this invoice?
                                        </div>
                                        <div className='form-group'>
                                            <button type="button" class="btn btn-primary m-1" onClick={() => {
                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: deleteInvoiceDialogIndex
                                                })
                                            }}>Cancel</button>
                                            <button type="button" class="btn btn-danger m-1" onClick={() => {
                                                that.deleteInvoice()

                                                fullDialogV2Dispatcher.dispatch({
                                                    actionType: "close-dialog",
                                                    dialogIndex: deleteInvoiceDialogIndex
                                                })
                                            }}>DELETE</button>
                                        </div>
                                    </div>,
                                    dialogIndexCallback: (idx) => {
                                        deleteInvoiceDialogIndex = idx
                                    }
                                })

                            }}>Delete Invoice</button>

                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2">
                            <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                                {statusDOM}
                            </div>
                        </div>
                    </div> */}

                </div>
            )
        }

        return null
    }
}

class CreateInvoice extends React.Component {
    constructor(props) {
        super()
        this.state = {
            customer: "",
            customerID: "",
            invoiceNumber: "",
            // invoiceGST:"",
            orderID: "",
            handlerID: "",
            handler: "",
            note: "",
            invoiceItems: {},
            approvalVoucherBool: false,
            invoiceDate: new Date(),
            response: {},
            coupon: {},
            gst: "",
            couponData: null,
            itemInputTypeBool: false, // true for item search mode, false for punch mode
            punchText: "",
            punchModeInputIndicatorClassname: "punchModeAnimationError w-100 rounded",
            punchModeInputIndicatorFocus: false,

            shippingAmount: 0,
            couponDiscountAmount: 0,

        }
        this.typeahead_items_search = null
    }

    resetFields = () => {
        this.setState({
            customer: "",
            customerID: "",
            invoiceNumber: "",
            // invoiceGST:"",
            orderID: "",
            handlerID: "",
            handler: "",
            note: "",
            invoiceItems: {},
            approvalVoucherBool: false,
            invoiceDate: new Date(),
            response: {},
            coupon: {},
            gst: "",
            couponData: null,
            itemInputTypeBool: false, // true for item search mode, false for punch mode
            punchText: "",
            punchModeInputIndicatorClassname: "punchModeAnimationError w-100 rounded",
            punchModeInputIndicatorFocus: false,

            shippingAmount: 0,
            couponDiscountAmount: 0
        })
    }

    saveInvoice = (couponDiscountValue) => {

        var error = {
            "_status": "",
            "payload": []
        }
        var tmpPayload = {}
        var errorSet = false
        if (this.state.customerID.length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Customer"] = "Please Add A Customer Or Order ID."

        }
        // if (this.state.orderID.length < 1) {
        //     if (!errorSet) {
        //         error["_status"] = "fail"
        //         errorSet = true
        //     }
        //     tmpPayload["Order ID"] = "Please Add Order ID Or A Customer."
        // }
        if (this.state.invoiceDate == null || this.state.invoiceDate.length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Invoice Date"] = "Please Add Invoice Date."
        }

        // if (this.state.handlerID === null || this.state.handlerID.length < 1) {
        //     if (!errorSet) {
        //         error["_status"] = "fail"
        //         errorSet = true
        //     }
        //     tmpPayload["Handler"] = "Please Select A Handler."
        // }

        if (Object.keys(this.state.invoiceItems).length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Items"] = "Please Add Atleast One Item."
        }


        if (this.state.gst.length > 0 && !validateGSTNo(this.state.gst)) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["GST"] = "Invalid GST Number"
        }



        if (errorSet) {
            error["payload"] = [tmpPayload]
            this.setState({ response: error })
            error["payload"] = [tmpPayload]
            // this.setState({ response: error })

            var ret = []
            for (var i in error["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {error["payload"][0][i]}<br /></span>
                );
            }
            var message =
                <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>

            fullDialogV2Dispatcher.dispatch({
                actionType: "show-dialog",
                title: "Create Invoice",
                message: message
            })

            return
            return
        }


        $("#saveInvoiceButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Save And Print")
        const that = this
        var invoiceItems = []

        for (var item in this.state.invoiceItems) {

            /* prepare tax */

            var tmpTax = this.state.invoiceItems[item].tax
            tmpTax.p = parseFloat(tmpTax.p)

            var tmp_item =
            {
                "_id": item,
                "count": this.state.invoiceItems[item].count,
                "discount": parseFloat(this.state.invoiceItems[item].discount),
                "discount_type": this.state.invoiceItems[item].discount_type,
                // "data": itemData
                "price": this.state.invoiceItems[item].data.price,
                "tax": tmpTax
            }
            invoiceItems.push(
                tmp_item
            )
        }

        /* prepare shipping amount */

        var shippingAmount = parseFloat(this.state.shippingAmount)
        if (isNaN(shippingAmount)) {
            shippingAmount = 0
        }
        if (shippingAmount <= 0) {
            shippingAmount = 0
        }

        /* prepare coupon data */

        var tmpCouponData = this.state.couponData
        if (this.state.couponData !== null) {
            delete tmpCouponData._created
            tmpCouponData._id = tmpCouponData._id.$oid
        }


        axios.post(URL + "invoice", {
            customer_id: this.state.customerID,
            invoice_number: this.state.invoiceNumber,
            coupon: tmpCouponData,
            invoice_date: this.state.invoiceDate,
            note: this.state.note,
            // handler_id: this.state.handlerID,
            items: invoiceItems,
            isav: true,
            couponDiscountAmount: couponDiscountValue,
            shippingAmount: shippingAmount,
            gst: this.state.gst
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response_) {

                const response = response_.data
                // that.setState({ response: response["data"] })
                // console.log("server", response["data"])
                var message = null
                if ("_status" in response && response["_status"] === "success") {
                    // $("#add_inquiry_button").prop('disabled', true);
                    // setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
                    message = <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                        {response["payload"]}
                    </div>

                    that.resetFields()

                } else if ("_status" in response && response["_status"] === "fail") {

                    var ret = []
                    for (var i in response["payload"][0]) {
                        // console.log(this.state.response.payload[0][i])
                        ret.push(
                            <span>{i}: {response["payload"][0][i]}<br /></span>
                        );
                    }
                    message =
                        <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                            {ret}
                        </div>

                }

                fullDialogV2Dispatcher.dispatch({
                    actionType: "show-dialog",
                    title: "Create Invoice",
                    message: message
                })



                $("#saveInvoiceButton").html("Save And Print")

            })
    }

    printInvoice = () => {

    }

    getCustomerFromOrder = () => {
        $("#getOrderCustomerButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Ok")
        const that = this


        axios.post(URL + "orders/search", {
            orderId: this.state.orderID,
            pageNumber: 0,
            searchFilters: { "description": false, "tracking": false, "to": false, "from": false },
            searchTerm: ""
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                if (response.data.payload.orders.length === 1) {
                    const params = { _id: response.data.payload.orders[0].to_id }
                    axios.get(URL + "data", {
                        params: params,
                        headers: { Authorization: ACCT }
                    }).then(function (response_) {
                        console.log(response_.data)
                        that.setState({ customerID: response_.data.payload._id.$oid, customer: response_.data.payload, handlerID: response_.data.payload.handlerId, handler: response_.data.payload.handler, response: {} })
                        $("#getOrderCustomerButton").html("Ok")
                    })
                }
                else {
                    var error = {
                        "_status": "",
                        "payload": []
                    }
                    var tmpPayload = {}
                    var errorSet = false
                    if (response.data.payload.orders.length < 1) {
                        if (!errorSet) {
                            error["_status"] = "fail"
                            errorSet = true
                        }
                        tmpPayload["Order"] = "No Such Order Found."

                    }
                    $("#getOrderCustomerButton").html("Ok")
                    if (errorSet) {
                        error["payload"] = [tmpPayload]
                        that.setState({ response: error, customerID: "", customer: {} })
                        error["payload"] = [tmpPayload]
                        // this.setState({ response: error })
            
                        var ret = []
                        for (var i in error["payload"][0]) {
                            // console.log(this.state.response.payload[0][i])
                            ret.push(
                                <span>{i}: {error["payload"][0][i]}<br /></span>
                            );
                        }
                        var message =
                            <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                                {ret}
                            </div>
            
                        fullDialogV2Dispatcher.dispatch({
                            actionType: "show-dialog",
                            title: "Create Invoice",
                            message: message
                        })
            
                        return
                        return
                    }


                    $("#getOrderCustomerButton").html("Ok")
                }

                // that.setState({ response: response["data"] })
                // console.log("server", response["data"])


            })
            .catch(error_ => {
                var error = {
                    "_status": "",
                    "payload": []
                }
                var tmpPayload = {}
                var errorSet = false
                if (!errorSet) {
                    error["_status"] = "fail"
                    errorSet = true
                }
                tmpPayload["Order"] = "No Such Order Found."
                $("#getOrderCustomerButton").html("Ok")
                if (errorSet) {
                    error["payload"] = [tmpPayload]
                    that.setState({ response: error })
                    error["payload"] = [tmpPayload]
                    // this.setState({ response: error })
        
                    var ret = []
                    for (var i in error["payload"][0]) {
                        // console.log(this.state.response.payload[0][i])
                        ret.push(
                            <span>{i}: {error["payload"][0][i]}<br /></span>
                        );
                    }
                    var message =
                        <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                            {ret}
                        </div>
        
                    fullDialogV2Dispatcher.dispatch({
                        actionType: "show-dialog",
                        title: "Create Invoice",
                        message: message
                    })
        
                    return
                    return
                }
            });
    }

    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }

    handle_inventory_search = (query) => {

        this.setState({ asyncinventorysearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'inventory', {
            params: {
                page_number: 0,
                st: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncinventorysearch: { isLoading: false, options: response["data"]["payload"][0] } })
            })

    }

    SelectedCustomer = () => {
        if (this.state.customerID.length > 0) {
            const customer = this.state.customer
            return (
                <div>
                    <div class="row mt-2">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="container w-100 p-0">
                                <div className="card border-primary" >
                                    <div class="card-body">
                                        <div class="container m-0 p-0">
                                            <div class="row">
                                                <div class="card m-1 mt-0 border-0 p-0">
                                                    <div class="card-body">
                                                        <h5 class="card-title"><b>{customer['firstname']} {customer['lastname']}</b></h5>
                                                        <label>Email:</label> {customer['email']}<br />
                                                        <label>Phone:</label> {customer['phone']}<br />
                                                        <label>Handler:</label>&nbsp;{customer['handler']}<br />
                                                        <label>Brand:</label>&nbsp;{customer['brand']}
                                                    </div>
                                                </div>
                                                <div class="card m-1 border-0 p-0">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Shipping Address</h5>
                                                        {/* <h5 class="card-subtitle mb-2 text-muted">Shipping Address</h5> */}
                                                        <label></label>
                                                        <label>{customer['shippingAddressAddress1']}</label><br />
                                                        <label>{customer['shippingAddressAddress2']}</label><br />
                                                        <label>{customer['shippingAddressCity']}, {customer['shippingAddressState']}</label><br />
                                                        <label>{customer['shippingAddressPincode']}, {customer['shippingAddressCountry']}</label>
                                                    </div>
                                                </div>
                                                <div class="card m-1 border-0 p-0">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Billing Address</h5>
                                                        {/* <h5 class="card-subtitle mb-2 text-muted">Billing Address</h5> */}
                                                        <label>{customer['billingAddressAddress1']}</label><br />
                                                        <label>{customer['billingAddressAddress2']}</label><br />
                                                        <label>{customer['billingAddressCity']}, {customer['billingAddressState']}</label><br />
                                                        <label>{customer['billingAddressPincode']}, {customer['billingAddressCountry']}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return ""
    }

    Status = (e) => {
        // useEffect(() => {
        // Update the document title using the browser API
        // if ("_status" in this.state.response) {
        // document.getElementById('addInvoiceStatusDiv').focus()
        // }
        // });
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="addInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }

    handleGrabItemFromPunch = (response) => {
        if (!("_status" in response) || !("payload" in response)) {
            this.setState({ punchText: "" })
            alert("Some server side error has occurred. Please try again.")
            return
        }

        if (response["_status"] !== "success") {
            this.setState({ punchText: "" })
            alert("Such item probably does not exist.")
            return
        }

        const payload = response.payload

        var tmp_invoice_items = this.state.invoiceItems

        // if (payload._id.$oid in this.state.invoiceItems) {
        //     const tmpItems = this.state.invoiceItems
        //     tmpItems[payload._id.$oid]["count"] += 1
        //     this.setState({ invoiceItems: tmpItems, punchText: "" })
        //     return
        // }

        tmp_invoice_items[payload._id.$oid] = {
            "count": 1, "discount": 0, "discountValue": 0, "discount_type": "%", "data": payload, "price": payload.price, "tax": payload.tax
        } // 0 for percentage 1 for direct 
        // tmp_invoice_items[payload._id.$oid] = payload

        this.setState({ invoiceItems: tmp_invoice_items, punchText: "" })
    }

    grabItemFromPunch = () => {
        if (this.state.punchText === "") {
            return
        }

        for (var i in this.state.invoiceItems) {
            if (this.state.punchText === this.state.invoiceItems[i].sku) {
                const tmpItems = this.state.invoiceItems
                tmpItems[i]["count"] += 1
                this.setState({ invoiceItems: tmpItems, punchText: "" })
                return
            }
        }


        this.setState({ asyncinventorysearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'inventory', {
            params: {
                sku: this.state.punchText
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response.data)
                that.handleGrabItemFromPunch(response.data)
                // that.setState({ asyncinventorysearch: { isLoading: false, options: response["data"]["payload"][0] } })
            })
            .catch(function (error) {
                // alert(error)
                // console.log(error)
                that.handleGrabItemFromPunch({})
                // that.setState({})
            })
    }

    render() {

        var items = []

        var totalOfAllItems = 0

        for (var item in this.state.invoiceItems) {

            /* calculate rate */

            var taxPercentage = parseFloat(this.state.invoiceItems[item].tax.p)
            var taxType = String(this.state.invoiceItems[item].tax.t).toUpperCase()
            var taxDecimal = (taxPercentage / 100)
            var originalRate = parseFloat(this.state.invoiceItems[item].data.price.sp)
            var originalSellingPrice = parseFloat(this.state.invoiceItems[item].data.price.sp)
            var sellingPrice = originalSellingPrice - this.state.invoiceItems[item].discountValue
            var itemCount = parseInt(this.state.invoiceItems[item].count)
            // var rate = originalRate - this.state.invoiceItems[item].discountValue
            var rate = 0

            /* calculate tax amount on rate */
            var taxAmount = sellingPrice - (sellingPrice / (1.0 + taxDecimal))
            var originalTaxAmount = originalSellingPrice - (originalSellingPrice / (1.0 + taxDecimal))

            if (taxType === "INC") {
                originalRate = originalSellingPrice - originalTaxAmount
                rate = sellingPrice - taxAmount
            }
            if (taxType === "EXC") {
                rate = sellingPrice
            }

            /* calibrate rate,tax */
            rate = parseFloat(rate.toFixed(2))
            taxAmount = parseFloat(taxAmount.toFixed(2))

            /* get total rate and total tax */

            const totalRate = rate * itemCount
            const totalTax = taxAmount * itemCount
            /* get total amount */
            const totalAmount = totalRate + totalTax

            /* set total of all items */
            totalOfAllItems += totalAmount

            items.push(
                <p>
                    <hr />

                    <span className="d-flex justify-content-between align-items-center">
                        <span>
                            <h5>{this.state.invoiceItems[item].data.name}</h5>
                        </span>
                        <span>
                            <i class="far fa-trash-alt" style={{ cursor: "pointer" }} lid={item} onClick={(e) => {
                                const item_ = e.target.getAttribute('lid')
                                var tmp_items = this.state.invoiceItems
                                delete tmp_items[item_]
                                this.setState({ invoiceItems: tmp_items })
                            }}></i>
                        </span>
                    </span>

                    <span className="d-flex justify-content-between align-items-center">
                        <span>
                            SKU: {this.state.invoiceItems[item].data.sku} <br />
                        </span>
                        <span>
                            &#8377;&nbsp;{parseFloat(this.state.invoiceItems[item].data.price.sp).toLocaleString('en-IN')} <br />
                        </span>
                    </span>

                    <span className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="form-group">
                                <b>Tax Percentage</b>
                            </div>
                            <div className="form-group">
                                <span className="d-flex flex-inline  align-items-center">
                                    {/* Quantity:&nbsp; */}
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend">

                                            <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                const item_ = e.target.getAttribute('lid')

                                                var tmp_items = this.state.invoiceItems

                                                var newTaxValue = parseFloat(tmp_items[item_].tax.p)
                                                if (newTaxValue - 1 < 0) {
                                                    newTaxValue = 0
                                                }
                                                else {
                                                    newTaxValue -= 1
                                                }
                                                tmp_items[item_].tax.p = newTaxValue
                                                this.setState({ invoiceItems: tmp_items })
                                            }}>-</button>
                                        </div>
                                        <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" value={this.state.invoiceItems[item].tax.p} onChange={(e) => {
                                            const item_ = e.target.getAttribute('lid')
                                            var tmp_items = this.state.invoiceItems

                                            if (e.target.value === "") {
                                                tmp_items[item_].tax.p = 0
                                            } else if (parseFloat(e.target.value) < 0) {
                                                tmp_items[item_].tax.p = 0
                                            } else if (parseFloat(e.target.value) >= 100) {
                                                tmp_items[item_].tax.p = 100
                                            }
                                            else {
                                                if (parseFloat(e.target.value) > 0) {
                                                    tmp_items[item_].tax.p = e.target.value
                                                }
                                            }
                                            this.setState({ invoiceItems: tmp_items })
                                        }} />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                const item_ = e.target.getAttribute('lid')

                                                var tmp_items = this.state.invoiceItems

                                                var newTaxValue = parseFloat(tmp_items[item_].tax.p)
                                                if (newTaxValue + 1 >= 100) {
                                                    newTaxValue = 100
                                                }
                                                else {
                                                    newTaxValue += 1
                                                }
                                                tmp_items[item_].tax.p = newTaxValue
                                                this.setState({ invoiceItems: tmp_items })
                                            }}>+</button>
                                        </div>
                                    </div>

                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="form-group">
                                <b>Tax Type</b>
                            </div>
                            <div className="form-group">
                                <select class="custom-select" lid={item} defaultValue="inc" value={this.state.invoiceItems[item].tax.t} onChange={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.invoiceItems
                                    tmp_items[item_].tax.t = e.target.value
                                    this.setState({ invoiceItems: tmp_items })
                                }}>
                                    <option value="inc">Inclusive</option>
                                    <option value="exc">Exclusive</option>
                                </select>
                            </div>
                        </div>
                    </span>

                    <span className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="form-group">
                                <b>Quantity</b>
                            </div>
                            <span className="d-flex flex-inline  align-items-center">
                                <div className="form-group">
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend">

                                            <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                const item_ = e.target.getAttribute('lid')
                                                var discount = 0
                                                var tmp_items = this.state.invoiceItems
                                                if (parseInt(tmp_items[item_].count) < 2) {
                                                    tmp_items[item_].count = 1
                                                }
                                                else {
                                                    tmp_items[item_].count -= 1
                                                }
                                                if ((tmp_items[item_].discount_type === "d")) {
                                                    discount = tmp_items[item_].count * tmp_items[item_].discount
                                                }
                                                if (tmp_items[item_].discount_type === "%") {
                                                    discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].data.price.sp
                                                }
                                                tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].data.price.sp) - discount)
                                                this.setState({ invoiceItems: tmp_items })
                                            }}>-</button>
                                        </div>
                                        <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" value={this.state.invoiceItems[item].count} onChange={(e) => {
                                            const item_ = e.target.getAttribute('lid')
                                            var tmp_items = this.state.invoiceItems
                                            // if (tmp_items[item_].count > 1) {
                                            var discount = 0
                                            if (e.target.value === "") {
                                                tmp_items[item_].count = 0
                                            } else if (parseInt(e.target.value) < 1) {

                                                tmp_items[item_].count = 0
                                            }
                                            else {
                                                tmp_items[item_].count = parseInt(e.target.value)
                                            }
                                            // }
                                            if ((tmp_items[item_].discount_type === "d")) {
                                                discount = tmp_items[item_].count * tmp_items[item_].discount
                                            }
                                            if (tmp_items[item_].discount_type === "%") {
                                                discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].data.price.sp
                                            }
                                            tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].data.price.sp) - discount)
                                            this.setState({ invoiceItems: tmp_items })
                                        }} />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                                const item_ = e.target.getAttribute('lid')
                                                var discount = 0
                                                var tmp_items = this.state.invoiceItems
                                                tmp_items[item_].count += 1
                                                if ((tmp_items[item_].discount_type === "d")) {
                                                    discount = tmp_items[item_].count * tmp_items[item_].discount
                                                }
                                                if (tmp_items[item_].discount_type === "%") {
                                                    discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].data.price.sp
                                                }
                                                tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].data.price.sp) - discount)
                                                this.setState({ invoiceItems: tmp_items })
                                            }}>+</button>
                                        </div>
                                    </div>

                                </div>
                            </span>
                        </div>
                        <span>
                            <div className="form-group">
                                <b>Selling Price Discount</b>
                            </div>
                            <div class="input-group input-group-sm">
                                {/* <div class="input-group-prepend"> */}
                                <select class="custom-select" style={{ width: '50px' }} lid={item} defaultValue="%" value={this.state.invoiceItems[item].discount_type} onChange={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.invoiceItems
                                    tmp_items[item_].discount_type = e.target.value
                                    this.setState({ invoiceItems: tmp_items }, () => {
                                        var tmp_items = this.state.invoiceItems
                                        var discount = tmp_items[item_].discount

                                        var discountValue = 0

                                        if (discount === "") {
                                            discountValue = 0
                                        } else if (parseInt(discount) < 0) {
                                            discountValue = 0
                                        }

                                        else if ((tmp_items[item_].discount_type === "d")) {
                                            if ((parseFloat(discount) > tmp_items[item_].data.price.sp)) {
                                                discountValue = tmp_items[item_].data.price.sp
                                            } else {
                                                discountValue = parseFloat(discount)
                                            }
                                        }
                                        else if (tmp_items[item_].discount_type === "%") {
                                            if ((parseFloat(discount) > 100)) {
                                                discountValue = tmp_items[item_].data.price.sp
                                            }
                                            else {
                                                discountValue = (parseFloat(discount) / 100) * tmp_items[item_].data.price.sp
                                            }
                                        }
                                        else {
                                            discountValue = 0
                                        }
                                        tmp_items[item_].discountValue = discountValue
                                        tmp_items[item_].discount = discount

                                        this.setState({ invoiceItems: tmp_items })
                                    })
                                }}>
                                    <option value="%">%</option>
                                    <option value="d">d</option>
                                </select>
                                {/* </div> */}
                                <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="discount on single item" aria-describedby="button-discount" value={this.state.invoiceItems[item].discount} onChange={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.invoiceItems

                                    var discountValue = 0

                                    if (e.target.value === "") {
                                        discountValue = 0
                                    } else if (parseInt(e.target.value) < 0) {
                                        discountValue = 0
                                    }

                                    else if ((tmp_items[item_].discount_type === "d")) {
                                        if ((parseFloat(e.target.value) > tmp_items[item_].data.price.sp)) {
                                            discountValue = tmp_items[item_].data.price.sp
                                        } else {
                                            discountValue = parseFloat(e.target.value)
                                        }
                                    }
                                    else if (tmp_items[item_].discount_type === "%") {
                                        if ((parseFloat(e.target.value) > 100)) {
                                            discountValue = tmp_items[item_].data.price.sp
                                        }
                                        else {
                                            discountValue = (parseFloat(e.target.value) / 100) * tmp_items[item_].data.price.sp
                                        }
                                    }
                                    else {
                                        discountValue = 0
                                    }
                                    tmp_items[item_].discountValue = discountValue
                                    tmp_items[item_].discount = e.target.value

                                    this.setState({ invoiceItems: tmp_items })
                                }} />
                            </div>
                            <span lid={item} className="btn-link" style={{
                                cursor: 'pointer'
                            }} onClick={(e) => {
                                const item_ = e.target.getAttribute('lid')
                                fullDialogV2Dispatcher.dispatch({
                                    actionType: "show-dialog",
                                    title: "Calculate Selling Price Discount",
                                    message: <SellingPriceCalculator data={this.state.invoiceItems[item_]} onSuccessCallback={(d) => {

                                        var tmp_items = this.state.invoiceItems
                                        tmp_items[item_].discount = d
                                        var discount = tmp_items[item_].discount

                                        var discountValue = 0

                                        if (discount === "") {
                                            discountValue = 0
                                        } else if (parseInt(discount) < 0) {
                                            discountValue = 0
                                        }

                                        else if ((tmp_items[item_].discount_type === "d")) {
                                            if ((parseFloat(discount) > tmp_items[item_].data.price.sp)) {
                                                discountValue = tmp_items[item_].data.price.sp
                                            } else {
                                                discountValue = parseFloat(discount)
                                            }
                                        }
                                        else if (tmp_items[item_].discount_type === "%") {
                                            if ((parseFloat(discount) > 100)) {
                                                discountValue = tmp_items[item_].data.price.sp
                                            }
                                            else {
                                                discountValue = (parseFloat(discount) / 100) * tmp_items[item_].data.price.sp
                                            }
                                        }
                                        else {
                                            discountValue = 0
                                        }
                                        tmp_items[item_].discountValue = discountValue
                                        tmp_items[item_].discount = discount

                                        this.setState({ invoiceItems: tmp_items })

                                        fullDialogV2Dispatcher.dispatch({
                                            actionType: "close-dialog",
                                            dialogIndex: sellingPriceCalculatorDialogIndex
                                        })
                                    }} />,
                                    dialogIndexCallback: (idx) => {
                                        sellingPriceCalculatorDialogIndex = idx
                                    }
                                })
                            }}>
                                <small lid={item}>Calculate</small>
                            </span>
                        </span>
                    </span>

                    <div class="table-responsive">
                        <table className="table table-borderless w-100">
                            <tr>
                                <td>
                                    <b>MRP</b>
                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <b>Original Seller Price</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(this.state.invoiceItems[item].data.price.mrp).toLocaleFixed(2)}
                                    </span>
                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(originalSellingPrice).toLocaleFixed(2)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Selling Price</b>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(sellingPrice).toLocaleFixed(2)}
                                    </span>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Rate</b>
                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <b>Tax</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(rate).toLocaleFixed(2)}
                                    </span>
                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(taxAmount).toLocaleFixed(2)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Total Rate</b>
                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <b>Total Tax</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(totalRate).toLocaleFixed(2)}
                                    </span>
                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <span className="">
                                        &#8377;&nbsp;{parseFloat(totalTax).toLocaleFixed(2)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <b>Item Total</b>
                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                                <td style={{ textAlign: 'end' }}>
                                    <h5>
                                        &#8377;&nbsp;{totalAmount.toLocaleFixed(2)}
                                    </h5>
                                </td>
                            </tr>
                        </table>
                    </div>

                </p >
            )
        }

        /* prepare shipping dom */
        var shippingAmountDOM = null
        var shippingAmount = parseFloat(this.state.shippingAmount)
        if (isNaN(shippingAmount)) {
            shippingAmount = 0
        }
        if (shippingAmount > 0) {
            shippingAmountDOM = [<hr />,
            <span className="d-flex justify-content-end justify-align-center" style={{
                textAlign: 'right'
            }}>
                <span>
                    {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                    <span>Shipping: &#8377;&nbsp;{shippingAmount.toLocaleFixed(2)}</span>
                </span>
            </span>,
            <hr />]
        }

        /* prepare couponDOM */

        var couponValue = 0
        var couponDiscountDOM = null
        var couponDOM = <span className="btn-link" style={{
            cursor: 'pointer'
        }} onClick={() => {
            const that = this
            fullDialogV2Dispatcher.dispatch({
                actionType: "show-dialog",
                title: "Add Coupon",
                message: <AddCoupon customerID={this.state.customerID} onSuccessCallback={(d) => {
                    that.setState({ couponData: d })
                    fullDialogV2Dispatcher.dispatch({
                        actionType: "close-dialog",
                        dialogIndex: addCouponDialogIndex
                    })
                }} />,
                dialogIndexCallback: (idx) => {
                    addCouponDialogIndex = idx
                }
            })
        }} >Add Coupon</span>

        if (this.state.couponData !== null) {
            couponDOM = <div>
                <span><b><u>{this.state.couponData.coupon}</u></b></span>
                <br />
                <span hidden={!(this.state.couponData.coupon_discount_type === "%")}>{this.state.couponData.coupon_discount}% OFF</span>
                <span hidden={!(this.state.couponData.coupon_discount_type === "d")}>Direct &#8377;{this.state.couponData.coupon_discount} OFF</span>
                <br />
                <span className="btn-link" style={{
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({ couponData: null })
                }} ><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Remove Coupon</span>
            </div>

            /* calculate coupon value */

            if ((this.state.couponData.coupon_discount_type === "%")) {
                couponValue = parseFloat(parseFloat(this.state.couponData.coupon_discount) / 100) * totalOfAllItems
            } else if ((this.state.couponData.coupon_discount_type === "d")) {
                couponValue = parseFloat(this.state.couponData.coupon_discount)
            }

            /* calibrate coupon value */
            couponValue = parseFloat(couponValue.toFixed(2))

            couponDiscountDOM = [<hr />,
            <span className="d-flex justify-content-end justify-align-center" style={{
                textAlign: 'right'
            }}>
                <span>
                    {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                    <span>Coupon Discount: &#8377;&nbsp;{couponValue.toLocaleFixed(2)}</span>
                    <br />
                    <small>Rates will be adjusted in invoice according to this coupon price</small>
                </span>
            </span>,
            <hr />]

        }


        /* total sum DOM */

        var totalSumDOM = null
        if (totalOfAllItems > 0) {
            totalSumDOM = [<hr />,
            <span className="d-flex justify-content-end justify-align-center">
                <span>
                    {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                    <span>Total: &#8377;&nbsp;{totalOfAllItems.toLocaleFixed(2)}</span>
                </span>
            </span>,
            <hr />]
        }

        /* final total */
        var finalTotalSumDOM = null
        if (totalOfAllItems > 0) {
            finalTotalSumDOM = [<hr />,
            <span className="d-flex justify-content-end justify-align-center">
                <span style={{
                    fontWeight: 'bolder'
                }}>
                    {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                    <span>Final Total: &#8377;&nbsp;{(totalOfAllItems - couponValue + shippingAmount).toLocaleFixed(2)}</span>
                </span>
            </span>,
            <hr />]
        }


        /* prepare status DOM */

        var statusDOM = null
        // if ("_status" in this.state.response && this.state.response["_status"] === "success") {
        //     // $("#add_inquiry_button").prop('disabled', true);
        //     // setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
        //     return (
        //         <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
        //             {this.state.response["payload"]}
        //         </div>
        //     );

        // }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <this.SelectedCustomer />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceCustomer"><b>Customer</b></label>
                        <AsyncTypeahead type="text" placeholder="Search" name="to" id="invoiceCustomer"
                            labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                            filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                            onSearch={this.handle_search}
                            {...this.state.asyncsearch}
                            maxResults="10"
                            ref={(typeahead) => this.typeahead_customer = typeahead}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps} className="search_menu" id="search_menu">
                                    {results.map((result, index) => (
                                        <MenuItem option={result} position={index} className="search_item m-0">
                                            <div className="w-100 p-2 search_item" onClick={(e) => {
                                                //   $("#search_menu").hide();
                                                this.setState({ customerID: result._id.$oid, customer: result, orderID: "", response: {}, handler: result.handler, handlerID: result.handlerId, couponData: null, gst: result.gstin })
                                            }}>

                                                {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        />
                    </div>
                    {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="invoiceInvoice"><b>Order ID</b></label>
                        <div class="input-group">
                            <input type="text" id="inventoryOrderID" className="form-control border-1 small" name="orderID" value={this.state.orderID} onChange={(e) => {
                                this.setState({ orderID: e.target.value })
                            }} onKeyDown={(e) => {
                                if (e.keyCode == 13) {
                                    this.getCustomerFromOrder()
                                }
                            }} />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-primary" id="getOrderCustomerButton" onClick={this.getCustomerFromOrder}>
                                    Ok
                        </button>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="invoiceInvoiceDate"><b>Invoice Date</b></label>
                        <DatePicker
                            selected={this.state.invoiceDate}
                            onChange={(date) => { this.setState({ invoiceDate: date }) }}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="invoiceInvoice"><b>Invoice Number</b></label>
                        <input type="text" id="inventoryInvoiceNumber" className="form-control border-1 small" name="invoiceNumber" value={this.state.invoiceNumber} onChange={(e) => {
                            this.setState({ invoiceNumber: e.target.value })
                        }} />
                        <small id="invoiceNumberHelp" class="form-text text-muted">Use Only When Custom Invoice Number Is Needed.</small>
                        {/* <label for="invoiceInvoiceHandler"><b>Handler</b></label>
                        <select class="custom-select" name="handler" id="inputGroupSelect01" value={this.state.handler} defaultValue={this.state.handler} onChange={(e) => {
                            const lid = e.target.options[e.target.selectedIndex].getAttribute('lid')
                            this.setState({ handlerID: lid, handler: e.target.value })
                        }}>
                            <option selected value="">Select Handler</option>
                            <Handlers />
                        </select> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceNote"><b>Note</b></label>
                        <textarea maxLength="300" type="text" id="invoiceNote" className="form-control border-1 small" name="invoiceNote" value={this.state.note} onChange={(e) => {
                            this.setState({ note: e.target.value })
                        }} />

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceGST"><b>GST</b></label>
                        <input maxLength="20" type="text" id="invoiceGST" className="form-control border-1 small" name="invoiceGST" value={this.state.gst} onChange={(e) => {
                            this.setState({ gst: e.target.value })
                        }} />

                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceShippingAmount"><b>Shipping Amount</b></label>
                        <input type="number" id="invoiceShippingAmount" className="form-control border-1 small" name="invoiceShippingAmount" value={this.state.shippingAmount} onChange={(e) => {
                            this.setState({ shippingAmount: e.target.value })
                        }} />

                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceCoupon"><b>Coupon</b></label>
                        <br />
                        {couponDOM}
                        {/* <textarea type="text" id="invoiceCoupon" className="form-control border-1 small" name="invoiceCoupon" value={this.state.coupon} onChange={(e) => {
                            this.setState({ coupon: e.target.value })
                        }} /> */}

                    </div>
                </div>
                <div className="row">
                    <div className="d-flex flex-row col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceItems"><b>Items</b></label>
                    </div>
                    <div className="d-flex flex-row col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="form-check m-1">
                            <input class="form-check-input" type="radio" name="itemInputRadio" id="itemInputRadioPunchMode" checked={!this.state.itemInputTypeBool} onChange={(e) => {
                                this.setState({ itemInputTypeBool: !e.target.checked })
                                if (e.target.checked) {
                                    const ele = document.getElementById("punchModeInput")
                                    window.setTimeout(function () {
                                        ele.focus()
                                    }, 0);
                                }
                            }} />
                            <label class="form-check-label" for="itemInputRadioPunchMode">
                                Punch Mode
                            </label>
                        </div>
                        <div class="form-check m-1">
                            <input class="form-check-input" type="radio" name="itemInputRadio" id="itemInputRadioSearchMode" checked={this.state.itemInputTypeBool} onChange={(e) => {
                                this.setState({ itemInputTypeBool: e.target.checked })
                            }} />
                            <label class="form-check-label" for="itemInputRadioSearchMode">
                                Search Mode
                            </label>
                        </div>
                    </div>
                    <div hidden={this.state.itemInputTypeBool} className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                        <span>
                            Punch mode enabled
                        </span>
                        <span hidden={this.state.punchModeInputIndicatorFocus}>
                            <br />
                            <span style={{
                                cursor: 'pointer'
                            }} className="btn-link" onClick={(e) => {
                                const ele = document.getElementById("punchModeInput")
                                window.setTimeout(function () {
                                    ele.focus()
                                }, 0);
                            }}>
                                Click here to enter punch mode
                            </span>
                        </span>
                        {/* <div className={this.state.punchModeInputIndicatorClassname} id="punchModeInputIndicator" style={{
                            height: '1.5rem'
                        }}> */}
                        <input style={{
                            caretColor: 'transparent',
                            cursor: 'pointer'
                        }} autocomplete="off" className={this.state.punchModeInputIndicatorClassname + ` form-control`} type="text" value={this.state.punchText} id="punchModeInput" onChange={(e) => {
                            this.setState({ punchText: e.target.value })
                        }} onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                this.grabItemFromPunch()
                                //     customer: "",
                                //     customerID: "",
                                //     invoiceNumber: "",
                                //     orderID: "",
                                //     handlerID: "",
                                //     handler: "",
                                //     invoiceItems: {},
                                //     invoiceDate: new Date(),
                                //     response: {}
                                // })()
                            }
                        }} onBlur={(e) => {
                            this.setState({ punchModeInputIndicatorClassname: "punchModeAnimationError w-100 rounded", punchModeInputIndicatorFocus: false })
                        }} onFocus={(e) => {
                            this.setState({ punchModeInputIndicatorClassname: "punchModeAnimation w-100 rounded", punchModeInputIndicatorFocus: true })
                        }} />
                        {/* </div> */}
                    </div>
                    <div hidden={!this.state.itemInputTypeBool} className="col-sm-12 col-md-12 col-lg-12 col-xl-12">

                        <AsyncTypeahead type="text" placeholder="Search" name="items" id="invoiceItems"
                            labelKey={option => `${option.name} | ${option.sku}`}
                            filterBy={['name', 'sku']}
                            onSearch={this.handle_inventory_search}
                            {...this.state.asyncinventorysearch}
                            maxResults="10"
                            ref={(typeahead) => this.typeahead_items_search = typeahead}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps} className="search_menu" id="search_menu">
                                    {results.map((result, index) => (
                                        <MenuItem option={result} position={index} className="search_item m-0">
                                            <div className="w-100 p-2 search_item" onClick={(e) => {
                                                var tmp_invoice_items = this.state.invoiceItems
                                                tmp_invoice_items[result._id.$oid] = {
                                                    "count": 1, "discount": 0, "discountValue": 0, "discount_type": "%", "data": result, "price": result.price, "tax": result.tax
                                                } // 0 for percentage 1 for direct 
                                                // tmp_invoice_items[result._id.$oid] = result

                                                this.setState({ invoiceItems: tmp_invoice_items }, () => {
                                                    const ref_ = this.typeahead_items_search.getInstance()
                                                    ref_.clear()
                                                })

                                            }}>
                                                {`${result.name} | ${result.sku}`}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {items}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {totalSumDOM}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {couponDiscountDOM}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {shippingAmountDOM}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {finalTotalSumDOM}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 ">
                        <button type="button" id="saveInvoiceButton" class="btn btn-primary mr-1" onClick={() => {
                            this.saveInvoice(couponValue)
                        }}>Save And Print</button>
                        {/* <button type="button" class="btn btn-primary m-1">Print</button> */}
                        <button type="button" class="btn btn-primary m-1" onClick={() => {
                            this.typeahead_customer.getInstance().clear()
                            this.typeahead_items_search.getInstance().clear()
                            // this.setState({
                            //     customer: "",
                            //     customerID: "",
                            //     invoiceNumber: "",
                            //     orderID: "",
                            //     handlerID: "",
                            //     handler: "",
                            //     invoiceItems: {},
                            //     invoiceDate: new Date(),
                            //     response: {}
                            // })
                            this.resetFields()
                        }}>Clear</button>

                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2">
                        <div id="addInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                            {statusDOM}
                        </div>
                    </div>
                </div> */}

            </div>
        )
    }
}

class SearchInvoices extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            page_number: 1,
            search_term: "",
            handler_id: "",
            invoiceID: "",
            panCheck: false,
            retrivedInvoices: {},
            response: {},

            editInvoiceItems: {},

            approvalInvoiceSelectedBool: false,
            selectedInvoice: {
                invoice_date: new Date()
            }
        }
    }

    componentDidMount() {
        this.fetchAllInvoices();
        this.initTimer();
    }

    componentWillUnmount() {
        this.deInitTimer()
    }

    initTimer = () => {
        this.timer = setInterval(() => this.fetchAllInvoices(), 5000);
    }

    deInitTimer = () => {
        clearInterval(this.timer)
        this.timer = null; // here...
    }

    fetchAllInvoices = () => {
        const that = this;
        var params = { page_number: this.state.page_number - 1, s: "a" }

        if (this.state.search_term.length > 0) {
            params["st"] = this.state.search_term
        } else {
            delete params["st"];
        }

        if (this.state.handler_id.length > 0) {
            params["hi"] = this.state.handler_id
        } else {
            delete params["hi"];
        }


        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedInvoices: response["data"]["payload"] })
            })
    }

    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }
    fetchInvoiceView = () => {
        const that = this;
        var params = { _id: this.state.invoiceID, pr: "yes" }

        if (this.state.panCheck) {
            params["pc"] = '1'
        } else {
            delete params["pc"];
        }

        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedInvoice: response["data"]["payload"] })
                if (response["data"]["_status"] === "success") {
                    var WinPrint = window.open('', 'Invoice', 'width=1280px,height=720px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    WinPrint.focus();

                }
            })
    }

    fetchInvoicePrint = () => {
        const that = this;
        var params = { _id: this.state.invoiceID, pr: "yes" }

        if (this.state.panCheck) {
            params["pc"] = '1'
        } else {
            delete params["pc"];
        }


        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedInvoice: response["data"]["payload"] })
                if (response["data"]["_status"] === "success") {
                    var WinPrint = window.open('', 'Invoice', 'width=1280px,height=720px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }
            })
    }
    fetchSingleInvoice = () => {

        const that = this;

        var params = { "_id": this.state.invoiceID }
        axios.get(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {

                that.setState({ selectedInvoice: response["data"]["payload"] },
                    () => {
                        var items_json = {}
                        // prepare items json
                        for (var item in that.state.selectedInvoice.items) {
                            items_json[that.state.selectedInvoice.items[item]._id] = that.state.selectedInvoice.items[item]
                        }
                        that.setState({ editInvoiceItems: items_json, approvalInvoiceSelectedBool: true })
                        that.deInitTimer()
                    }
                )
            })

    }
    handle_delete = () => {
        const that = this;

        var params = { _id: this.state.invoiceID }
        axios.delete(URL + "invoice", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] })
            })
    }

    Pagination = () => {
        const total_pages = this.state.retrivedInvoices[1]
        if (total_pages < 1) {
            return ""
        }
        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.page_number}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ page_number: parseInt(pageNumber) }, this.fetchAllInvoices)
                        }}
                    />
                </div>
            </div>
        )
    }

    Status = (e) => {
        useEffect(() => {
            // Update the document title using the browser API
            if ("_status" in this.state.response) {
                document.getElementById('editInvoiceStatusDiv').focus()
            }
        });
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="editInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="editInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }
    Section_header_search_reset = (e) => {
        if (this.state.search_term === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ search_term: "" }, this.fetchAllInvoices);
                }}></i>
            )
        }

    }

    SearchBar = () => {

        var searchClass = "col-md-12 col-sm-12 col-xl-12 col-lg-12"
        var handlerSearch = ""
        var section_header_search_reset = <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
            this.setState({ search_term: "" }, this.fetchAllInvoices);
        }}></i>
        if (this.state.search_term === "") {
            section_header_search_reset = ""
        }


        if (TP === "owner") {
            searchClass = "col-md-12 col-sm-12 col-xl-6 col-lg-6"
            handlerSearch = <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6">
                <span class="d-inline-flex align-items-center w-100">
                    {/* <label class="my-1 mr-2" for="inputGroupSelectHandlerSectionHeader">Handler:</label> */}
                    <select class="custom-select w-100" name="handler" id="inputGroupSelectHandlerSectionHeader" onChange={(e) => {
                        // const handler_ = e.target.value
                        var index = e.target.selectedIndex;
                        const h_lid = e.target[index].getAttribute("lid");
                        this.setState({ handler_id: h_lid }, this.fetchAllInvoices)
                    }}>
                        <option selected lid="" value="">All</option>
                        <Handlers />
                    </select>
                </span>
            </div>
        }

        return (
            <div className="d-inline-flex align-items-center w-100">

                <div class={searchClass}>
                    <div className="d-inline-flex align-items-center w-100">
                        <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search Invoices" placeholder="Search Invoices" value={this.state.search_term} onChange={(e) => {
                            this.setState({ search_term: e.target.value }, () => {
                                this.setState({ page_number: 1 }, this.fetchAllInvoices)
                            })
                        }} />
                        {section_header_search_reset}
                    </div>
                </div>
                {handlerSearch}
            </div>
        )
    }

    Dialog = () => {
        return (
            <div class="modal fade" id="viewPrintInvoice" tabindex="-1" role="dialog" aria-labelledby="viewPrintInvoice" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="viewPrintInvoiceModalLongTitle">View Or Print Invoice</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="">
                                <div class="form-group form-check d-flex w-100 align-items-center justify-content-center p-0">
                                    <div>
                                        <input type="checkbox" class="form-check-input" id="panCheck" value={this.state.panCheck} onChange={(e) => {

                                            this.setState({ panCheck: e.target.checked })
                                        }} />
                                        <label class="form-check-label" for="panCheck">View/Print PAN</label>
                                    </div>
                                </div>
                                <div class="form-group row  w-100 d-flex align-items-center justify-content-center">
                                    <button type="button" id="viewInvoiceButton" class="btn btn-primary m-2" onClick={this.fetchInvoiceView}>View</button>
                                    <button type="button" id="printInvoiceButton" class="btn btn-primary m-2" onClick={this.fetchInvoicePrint}>Print</button>
                                    <button type="button" id="deleteInvoiceButton" class="btn btn-danger m-2" data-toggle="collapse" href="#invoiceDeleteConfirmCollapse" role="button" aria-expanded="false" aria-controls="invoiceDeleteConfirmCollapse">Delete</button>
                                </div>
                                <div class="form-group row  w-100 d-flex align-items-center justify-content-center">
                                    <div class="collapse" id="invoiceDeleteConfirmCollapse">
                                        <small id="invoiceDialogHelp2" class="form-text text-muted"><b>Are You Sure?</b></small>
                                        <button type="button" id="deleteInvoiceButton2" class="btn btn-danger" onClick={this.handle_delete}>Yes, Delete.</button>
                                        <small id="invoiceDialogHelp" class="form-text text-muted">Deleting Invoice Will Release The Attached Resources.</small>
                                        <this.Status />
                                    </div>
                                </div>
                                {/* <button type="button" class="btn btn-secondary m-2" data-dismiss="modal">Close</button> */}
                            </div>
                        </div>
                        <div class="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    Invoices = (e) => {

        var invoices_ = []

        for (var item in this.state.retrivedInvoices[0]) {



            var title = this.state.retrivedInvoices[0][item].customer;
            var note = null
            if (this.state.retrivedInvoices[0][item].note.length > 0) {
                note = <span lid={this.state.retrivedInvoices[0][item]._id.$oid}><br />{this.state.retrivedInvoices[0][item].note}</span>
            }

            if (this.state.retrivedInvoices[0][item].isav) {
                invoices_.push(

                    <a class="inquiry_item list-group-item d-flex justify-content-between align-items-center border" lid={this.state.retrivedInvoices[0][item]._id.$oid}

                        onClick={(e) => {
                            const lid_ = e.target.getAttribute("lid")
                            // this.setState({ invoiceID: lid_ }, this.fetchSingleInvoice)

                            fullDialogV2Dispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Edit Invoice",
                                message: <EditApprovalInvoice invoiceID={lid_} />,
                                dialogWidth: 3
                            })

                        }}>
                        <span lid={this.state.retrivedInvoices[0][item]._id.$oid}><b lid={this.state.retrivedInvoices[0][item]._id.$oid}>{title}</b>{note}<br />{this.state.retrivedInvoices[0][item].invoice_number}</span><span lid={this.state.retrivedInvoices[0][item]._id.$oid}><span lid={this.state.retrivedInvoices[0][item]._id.$oid} class="badge badge-secondary">Approval</span>&nbsp;&nbsp;{moment(this.state.retrivedInvoices[0][item]["_created"].$date).format('D MMM')}</span></a>

                )
            }
            else {
                invoices_.push(

                    <a class="inquiry_item list-group-item d-flex justify-content-between align-items-center border" lid={this.state.retrivedInvoices[0][item]._id.$oid}
                        data-toggle="modal" data-target="#viewPrintInvoice"
                        onClick={(e) => {
                            const lid_ = e.target.getAttribute("lid")
                            this.setState({ invoiceID: lid_ })
                        }}><span lid={this.state.retrivedInvoices[0][item]._id.$oid}><b lid={this.state.retrivedInvoices[0][item]._id.$oid}>{title}</b><br />{this.state.retrivedInvoices[0][item].invoice_number}</span><span lid={this.state.retrivedInvoices[0][item]._id.$oid}>{moment(this.state.retrivedInvoices[0][item]["_created"].$date).format('D MMM')}</span></a>

                )
            }
        }

        return (
            <div class="list-group">
                {invoices_}
            </div>
        )
    }

    handle_inventory_search = (query) => {

        this.setState({ asyncinventorysearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'inventory', {
            params: {
                page_number: 0,
                st: query,
                s: "a"
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncinventorysearch: { isLoading: false, options: response["data"]["payload"][0] } })
            })

    }



    Dialog_ = (props) => {
        return (
            <div class="modal fade" id={props.label} tabindex="-1" role="dialog" aria-labelledby={props.label} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{props.h_text}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{props.msg}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" name={props.b_name} class={`btn ` + props.c_text} onClick={props.handler} data-dismiss="modal">{props.b_label}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    ApprovalUnchecked = () => {
        if (!this.state.selectedInvoice.isav) {
            return (
                <span>Approval tag will be removed! Do this only when you need to finalize the invoice.</span>
            )
        }
        return ("")
    }

    EditInvoice = (e) => {


        var items = []
        var selectedInvoice_ = this.state.selectedInvoice


        for (var item in this.state.editInvoiceItems) {

            const item_ = this.state.editInvoiceItems[item]._id;
            var discount = 0
            if ((this.state.editInvoiceItems[item].discount_type == "d")) {
                discount = this.state.editInvoiceItems[item].count * this.state.editInvoiceItems[item].discount
            }
            if (this.state.editInvoiceItems[item].discount_type == "%") {
                discount = this.state.editInvoiceItems[item].count * (this.state.editInvoiceItems[item].discount / 100) * this.state.editInvoiceItems[item].price
            }
            this.state.editInvoiceItems[item].price_mult = ((this.state.editInvoiceItems[item].count * this.state.editInvoiceItems[item].price) - discount)

            items.push(
                <p>
                    <hr />
                    <span className="d-flex justify-content-between align-items-center">
                        <span>
                            <h5>{this.state.editInvoiceItems[item].name}</h5>
                        </span>
                        <span>
                            <i class="far fa-trash-alt" style={{ cursor: "pointer" }} lid={item} onClick={(e) => {
                                const item_ = e.target.getAttribute('lid')
                                var tmp_items = this.state.editInvoiceItems
                                delete this.state.editInvoiceItems[item_]
                                this.setState({ editInvoiceItems: tmp_items })
                            }}></i>
                        </span>
                    </span>

                    <span className="d-flex justify-content-between align-items-center">
                        <span>
                            SKU: {this.state.editInvoiceItems[item].sku} <br />
                        </span>
                        <span>
                            ₹&nbsp;{this.state.editInvoiceItems[item].price} <br />
                        </span>
                    </span>
                    <span className="d-flex justify-content-between align-items-center">
                        <span className="d-flex flex-inline  align-items-center">
                            {/* Quantity:&nbsp; */}
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">

                                    <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                        const item_ = e.target.getAttribute('lid')
                                        var discount = 0
                                        var tmp_items = this.state.editInvoiceItems
                                        if (parseInt(tmp_items[item_].count) < 2) {
                                            tmp_items[item_].count = 1
                                        }
                                        else {
                                            tmp_items[item_].count -= 1
                                        }
                                        if ((tmp_items[item_].discount_type == "d")) {
                                            discount = tmp_items[item_].count * tmp_items[item_].discount
                                        }
                                        if (tmp_items[item_].discount_type == "%") {
                                            discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].price
                                        }
                                        tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].price) - discount)
                                        this.setState({ editInvoiceItems: tmp_items })
                                    }}>-</button>
                                </div>
                                <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" value={this.state.editInvoiceItems[item].count} onChange={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.editInvoiceItems
                                    var discount = 0
                                    // if (tmp_items[item_].count > 1) {
                                    if (e.target.value === "") {
                                        tmp_items[item_].count = 0
                                    } else if (parseInt(e.target.value) < 1) {

                                        tmp_items[item_].count = 0
                                    }
                                    else {
                                        tmp_items[item_].count = parseInt(e.target.value)
                                    }
                                    // }
                                    if ((tmp_items[item_].discount_type == "d")) {
                                        discount = tmp_items[item_].count * tmp_items[item_].discount
                                    }
                                    if (tmp_items[item_].discount_type == "%") {
                                        discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].price
                                    }
                                    tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].price) - discount)
                                    this.setState({ editInvoiceItems: tmp_items })
                                }} />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" lid={item} type="button" id="button-addon1" onClick={(e) => {
                                        const item_ = e.target.getAttribute('lid')
                                        var discount = 0
                                        var tmp_items = this.state.editInvoiceItems
                                        tmp_items[item_].count += 1
                                        if ((tmp_items[item_].discount_type == "d")) {
                                            discount = tmp_items[item_].count * tmp_items[item_].discount
                                        }
                                        if (tmp_items[item_].discount_type == "%") {
                                            discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].price
                                        }
                                        tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].price) - discount)
                                        this.setState({ editInvoiceItems: tmp_items })
                                    }}>+</button>
                                </div>
                            </div>

                        </span>

                        <span>
                            <div class="input-group input-group-sm">
                                {/* <div class="input-group-prepend"> */}
                                <select class="custom-select" style={{ width: '50px' }} lid={item} defaultValue="%" value={this.state.editInvoiceItems[item].discount_type} onChange={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.editInvoiceItems
                                    tmp_items[item_].discount_type = e.target.value
                                    this.setState({ editInvoiceItems: tmp_items })
                                }}>
                                    <option value="%">%</option>
                                    <option value="d">d</option>
                                </select>
                                {/* </div> */}
                                <input type="text" lid={item} class="form-control" style={{ width: '80px', textAlign: 'center' }} placeholder="" aria-label="discount on single item" aria-describedby="button-discount" value={this.state.editInvoiceItems[item].discount} onChange={(e) => {
                                    const item_ = e.target.getAttribute('lid')
                                    var tmp_items = this.state.editInvoiceItems

                                    // if (tmp_items[item_].count > 1) {

                                    if (e.target.value === "") {
                                        tmp_items[item_].discount = 0
                                    } else if (parseInt(e.target.value) < 0) {

                                        tmp_items[item_].discount = 0
                                    }
                                    else if ((tmp_items[item_].discount_type == "d")) {
                                        if ((parseFloat(e.target.value) > tmp_items[item_].price * tmp_items[item_].count)) {
                                            // if ((tmp_items[item_].discount_type == "d")) {
                                            tmp_items[item_].discount = tmp_items[item_].price * tmp_items[item_].count
                                            // }
                                        } else {
                                            tmp_items[item_].discount = (e.target.value)
                                        }
                                    }
                                    else if (tmp_items[item_].discount_type == "%") {
                                        if ((parseFloat(e.target.value) > 100)) {
                                            tmp_items[item_].discount = 100
                                        }
                                        else {
                                            tmp_items[item_].discount = (e.target.value)
                                        }
                                    }
                                    else {
                                        tmp_items[item_].discount = 0
                                    }

                                    var discount = 0
                                    if ((tmp_items[item_].discount_type == "d")) {
                                        discount = tmp_items[item_].discount
                                    }
                                    if (tmp_items[item_].discount_type == "%") {
                                        discount = tmp_items[item_].count * (tmp_items[item_].discount / 100) * tmp_items[item_].price
                                    }
                                    // }
                                    tmp_items[item_].price_mult = ((tmp_items[item_].count * tmp_items[item_].price) - discount)
                                    this.setState({ editInvoiceItems: tmp_items })
                                }} />
                            </div>
                        </span>
                    </span>

                    <span className="d-flex justify-content-between align-items-center">
                        <span>

                        </span>
                        <span>
                            <h5 className="mt-2">
                                ₹&nbsp;{parseFloat(this.state.editInvoiceItems[item].price_mult).toFixed(2)}
                            </h5>
                        </span>
                    </span>

                </p >
            )
        }



        // total sum


        var total_sum_compo = ""
        var total_sum = 0
        for (var item in this.state.editInvoiceItems) {
            total_sum += parseFloat(this.state.editInvoiceItems[item].price_mult)
            // total_sum += parseInt(this.state.invoiceItems[item].price)

        }
        if (total_sum > 0) {
            total_sum_compo = [
                <hr />,
                <span className="d-flex justify-content-end justify-align-center">
                    <span>
                        {/* <label for="invoiceInvoice"><b>Total</b></label> */}
                        <span>Total: {parseFloat(total_sum).toFixed(2)}</span>
                    </span>
                </span>,
                <hr />
            ]
        }


        // selected customer 

        var selected_customer = ""
        if ("customer_id" in this.state.selectedInvoice && this.state.selectedInvoice.customer_id.length > 0) {
            const customer = this.state.selectedInvoice.customer
            selected_customer = <div>
                <div class="row mt-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="container w-100 p-0">
                            <div className="card border-primary" >
                                <div class="card-body">
                                    <div class="container m-0 p-0">
                                        <div class="row">
                                            <div class="card m-1 mt-0 border-0 p-0">
                                                <div class="card-body">
                                                    <h5 class="card-title"><b>{customer['firstname']} {customer['lastname']}</b></h5>
                                                    <label>Email:</label> {customer['email']}<br />
                                                    <label>Phone:</label> {customer['phone']}<br />
                                                    <label>Handler:</label>&nbsp;{customer['handler']}<br />
                                                    <label>Brand:</label>&nbsp;{customer['brand']}
                                                </div>
                                            </div>
                                            <div class="card m-1 border-0 p-0">
                                                <div class="card-body">
                                                    <h5 class="card-title">Shipping Address</h5>
                                                    {/* <h5 class="card-subtitle mb-2 text-muted">Shipping Address</h5> */}
                                                    <label></label>
                                                    <label>{customer['shippingAddressAddress1']}</label><br />
                                                    <label>{customer['shippingAddressAddress2']}</label><br />
                                                    <label>{customer['shippingAddressCity']}, {customer['shippingAddressState']}</label><br />
                                                    <label>{customer['shippingAddressPincode']}, {customer['shippingAddressCountry']}</label>
                                                </div>
                                            </div>
                                            <div class="card m-1 border-0 p-0">
                                                <div class="card-body">
                                                    <h5 class="card-title">Billing Address</h5>
                                                    {/* <h5 class="card-subtitle mb-2 text-muted">Billing Address</h5> */}
                                                    <label>{customer['billingAddressAddress1']}</label><br />
                                                    <label>{customer['billingAddressAddress2']}</label><br />
                                                    <label>{customer['billingAddressCity']}, {customer['billingAddressState']}</label><br />
                                                    <label>{customer['billingAddressPincode']}, {customer['billingAddressCountry']}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        }

        // approval unchecked

        var approval_unchecked = ""
        if (!this.state.selectedInvoice.isav) {
            approval_unchecked = <span>Approval Tag Will Be Removed! Do This Only When You Need To Finalize The Invoice.</span>
        }


        // status comp
        var status_comp = ""
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            status_comp = <div id="editInvoiceStatusDiv" class="alert alert-success mt-2" role="alert">
                {this.state.response["payload"]}
            </div>

        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            status_comp = <div id="editInvoiceStatusDiv" tabIndex="-1" class="alert alert-danger mt-2" role="alert">
                {ret}
            </div>
        }

        return (

            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {/* <this.SelectedCustomer /> */}
                        {selected_customer}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="editInvoiceCustomer"><b>Customer</b></label>
                        <AsyncTypeahead type="text" placeholder="Search" name="to" id="editInvoiceCustomer"
                            labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                            filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                            onSearch={this.handle_search}
                            {...this.state.asyncsearch}
                            maxResults="10"
                            ref={(typeahead) => this.edit_typeahead_customer = typeahead}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps} className="search_menu" id="search_menu">
                                    {results.map((result, index) => (
                                        <MenuItem option={result} position={index} className="search_item m-0">
                                            <div className="w-100 p-2 search_item" onClick={(e) => {
                                                //   $("#search_menu").hide();
                                                var tmp_selectedInovice = this.state.selectedInvoice
                                                tmp_selectedInovice.customer_id = result._id.$oid
                                                tmp_selectedInovice.customer = result
                                                this.setState({ selectedInvoice: tmp_selectedInovice, orderID: "", response: {}, handler: result.handler, handlerID: result.handlerId })
                                            }}>

                                                {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        />
                    </div>

                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="editInvoiceDate"><b>Invoice Date</b></label>
                        <DatePicker
                            id="editInvoiceDate"
                            selected={new Date(this.state.selectedInvoice.invoice_date)}

                            onChange={(date) => {
                                var tmp_selectedInovice = this.state.selectedInvoice
                                tmp_selectedInovice.invoice_date = date
                                this.setState({ selectedInvoice: tmp_selectedInovice })
                            }}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="invoiceInvoice"><b>Invoice Number</b></label>
                        <input type="text" id="inventoryInvoiceNumber" className="form-control border-1 small" name="invoice_number" value={this.state.selectedInvoice.invoice_number} onChange={(e) => {
                            var tmp_selectedInovice = this.state.selectedInvoice
                            tmp_selectedInovice.invoice_number = e.target.value
                            this.setState({ selectedInvoice: tmp_selectedInovice })
                        }} />


                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="editInvoiceNote"><b>Note</b></label>
                        <textarea type="text" id="editInvoiceNote" className="form-control border-1 small" name="invoiceNote" value={this.state.selectedInvoice.note} onChange={(e) => {
                            var tmp_selectedInovice = this.state.selectedInvoice
                            tmp_selectedInovice.note = e.target.value
                            this.setState({ selectedInvoice: tmp_selectedInovice })

                        }} />

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="invoiceItems"><b>Items</b></label>
                        <AsyncTypeahead type="text" placeholder="Search" name="items" id="invoiceItems"
                            labelKey={option => `${option.name} | ${option.sku}`}
                            filterBy={['name', 'sku']}
                            onSearch={this.handle_inventory_search}
                            {...this.state.asyncinventorysearch}
                            maxResults="10"
                            ref={(typeahead) => this.edit_typeahead_items_search = typeahead}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps} className="search_menu" id="search_menu">
                                    {results.map((result, index) => (
                                        <MenuItem option={result} position={index} className="search_item m-0">
                                            <div className="w-100 p-2 search_item" onClick={(e) => {

                                                var tmp_selectedInovice = this.state.editInvoiceItems

                                                tmp_selectedInovice[result._id.$oid] = {
                                                    "_id": result._id.$oid,
                                                    "name": result.name, "sku": result.sku, "count": 1, "price": result.price.sp,
                                                    "price_mult": result.price.sp, "discount": 0, "discount_type": "%", "curr_unit": result.price.unit
                                                } // 0 for percentage 1 for direct 
                                                this.setState({ editInvoiceItems: tmp_selectedInovice }, () => {
                                                    const ref_ = this.edit_typeahead_items_search.getInstance()
                                                    ref_.clear()
                                                })


                                            }}>
                                                {`${result.name} | ${result.sku}`}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {items}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {/* <this.TotalSum /> */}
                        {total_sum_compo}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 ">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="editApprovalVoucherCheckbox"
                                checked={this.state.selectedInvoice.isav} onChange={(e) => {
                                    var tmp_selectedInovice = this.state.selectedInvoice
                                    tmp_selectedInovice.isav = e.target.checked
                                    if (e.target.checked) {
                                        this.setState({ selectedInvoice: tmp_selectedInovice })

                                    }
                                    else {
                                        this.setState({ selectedInvoice: tmp_selectedInovice })
                                    }

                                }}
                            />
                            <label class="form-check-label" for="editApprovalVoucherCheckbox">Approval Voucher</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 text-danger ">
                        {/* <this.ApprovalUnchecked /> */}
                        {approval_unchecked}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 ">
                        <button type="button" id="editInvoiceUpdateButton" class="btn btn-primary mr-1" data-toggle="modal" data-target="#updateInvoiceDialog" >Update</button>
                        {/* <button type="button" class="btn btn-primary m-1">Print</button> */}
                        {/* <button type="button" class="btn btn-primary m-1" onClick={() => {
                            this.edit_typeahead_customer.getInstance().clear()
                            this.edit_typeahead_items_search.getInstance().clear()
                            this.setState({
                                // customer: "",
                                // customerID: "",
                                // invoiceNumber: "",
                                // orderID: "",
                                // handlerID: "",
                                // handler: "",
                                // invoiceItems: {},
                                // invoiceDate: new Date(),
                                // response: {}
                                selectedInvoice:{
                                    invoiceDate: new Date()
                                }
                            })
                        }}>Clear</button> */}
                        <button type="button" id="editViewInvoiceButton" class="btn btn-primary m-1" onClick={this.fetchInvoiceView}>View</button>
                        <button type="button" id="editPrintInvoiceButton" class="btn btn-primary m-1" onClick={this.fetchInvoicePrint}>Print</button>
                        <button type="button" id="editInvoiceDeleteButton" class="btn btn-danger m-1" data-toggle="modal" data-target="#deleteEditInvoiceDialog" >Delete</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2">
                        {/* <this.Status /> */}
                        {status_comp}
                    </div>
                </div>


                <div class="modal fade" id={`updateInvoiceDialog`} tabindex="-1" role="dialog" aria-labelledby={`updateInvoiceDialog`} aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">{`Update Invoice`}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>{`Update Invoice.`}</p>
                                <p>Are you sure?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                                <button type="button" name={`update`} class={`btn ` + `btn-primary`} onClick={this.handle_update} data-dismiss="modal">{`Update`}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="modal fade" id={`deleteEditInvoiceDialog`} tabindex="-1" role="dialog" aria-labelledby={`deleteEditInvoiceDialog`} aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">{`Delete Invoice`}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>{`Delete Invoice.`}</p>
                                <p>Are you sure?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                                <button type="button" name={`deleteinvoice`} class={`btn ` + `btn-danger`} onClick={this.handle_delete} data-dismiss="modal">{`Delete`}</button>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <this.Dialog_ name="updateinvoice" b_name="update" msg={`Update Invoice.`} h_text="Update Invoice" c_text="btn-primary" b_label="Update" handler={this.handle_update} label="updateInvoiceDialog" /> */}
                {/* <this.Dialog_ name="deleteinvoice" b_name="delete" msg={`Delete Invoice.`} h_text="Delete Invoice" c_text="btn-danger" b_label="Delete" handler={this.handle_delete} label="deleteEditInvoiceDialog" /> */}
            </div>
        )

    }

    render() {
        if (this.state.approvalInvoiceSelectedBool) {
            return (
                <div className="container p-2">
                    <div className="container p-4">
                        <div class="row p-0 m-0">
                            <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                                this.setState({ approvalInvoiceSelectedBool: false, response: {} }, () => {
                                    this.fetchAllInvoices();
                                    // this.initTimer()
                                })

                            }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                        </div>
                    </div>
                    <this.EditInvoice />
                </div>
            )
        }
        return (
            <div className="container p-2">

                <this.SearchBar />
                <this.Pagination />
                <this.Invoices />
                <div class="modal fade" id="viewPrintInvoice" tabindex="-1" role="dialog" aria-labelledby="viewPrintInvoice" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="viewPrintInvoiceModalLongTitle">View Or Print Invoice</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="">
                                    <div class="form-group form-check d-flex w-100 align-items-center justify-content-center p-0">
                                        <div>
                                            <input type="checkbox" class="form-check-input" id="panCheck" value={this.state.panCheck} onChange={(e) => {

                                                this.setState({ panCheck: e.target.checked })
                                            }} />
                                            <label class="form-check-label" for="panCheck">View/Print PAN</label>
                                        </div>
                                    </div>
                                    <div class="form-group row  w-100 d-flex align-items-center justify-content-center">
                                        <button type="button" id="viewInvoiceButton" class="btn btn-primary m-2" onClick={this.fetchInvoiceView}>View</button>
                                        <button type="button" id="printInvoiceButton" class="btn btn-primary m-2" onClick={this.fetchInvoicePrint}>Print</button>
                                        <button type="button" id="deleteInvoiceButton" class="btn btn-danger m-2" data-toggle="collapse" href="#invoiceDeleteConfirmCollapse" role="button" aria-expanded="false" aria-controls="invoiceDeleteConfirmCollapse">Delete</button>
                                    </div>
                                    <div class="form-group row  w-100 d-flex align-items-center justify-content-center">
                                        <div class="collapse" id="invoiceDeleteConfirmCollapse">
                                            <small id="invoiceDialogHelp2" class="form-text text-muted"><b>Are You Sure?</b></small>
                                            <button type="button" id="deleteInvoiceButton2" class="btn btn-danger" onClick={this.handle_delete}>Yes, Delete.</button>
                                            <small id="invoiceDialogHelp" class="form-text text-muted">Deleting Invoice Will Release The Attached Resources.</small>
                                            <this.Status />
                                        </div>
                                    </div>
                                    {/* <button type="button" class="btn btn-secondary m-2" data-dismiss="modal">Close</button> */}
                                </div>
                            </div>
                            <div class="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function ApprovalInvoice() {
    return (
        <div>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Approval Invoice</h1>
                {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
            </div>

            <div class="row">

                <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Generate Approval Invoice</h6>
                        </div>
                        <div class="m-0">
                            <CreateInvoice />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Search Approval Invoice</h6>
                        </div>
                        <div class="m-0">
                            <SearchInvoices />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApprovalInvoice;