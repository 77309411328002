import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Db from './db';
import Dashboard from  './Dashboard';
import Login from './Login';
import Cookies from 'js-cookie';
import {createStore} from 'redux';

function App() {

  
  var token = Cookies.get('ACCT');

  if (token != null){
    return (
      <div>
        <Dashboard />
      </div>
    )
  } else {
    return (
      <div>
        <Login />
      </div>
    )
  }
}

export default App;
