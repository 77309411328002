import React, { useState ,useEffect} from 'react';
import { thisExpression, throwStatement } from '@babel/types';
import axios from 'axios';
import URL from './URL';
import Cookies from 'js-cookie';
import { fail } from 'assert';
import $ from 'jquery';

var ACCT = Cookies.get('ACCT')

class CompanySettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "company": "", "currentpassword": "", "newpassword": "", "retypenewpassword": "", "postStatus": {} };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.postPasswordChange = this.postPasswordChange.bind(this);
        this.handleCompanyInfoChange = this.handleCompanyInfoChange.bind(this);
        this.postCompanyInfoChange = this.postCompanyInfoChange.bind(this);
        this.PostStatus = this.PostStatus.bind(this);
        const that = this;
        axios.post(URL + 'company/info', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "company": response['data']['payload'] });
            })
    }

    postCompanyInfoChange = (e) => {
        const that = this;
        axios.post(URL + 'company/info/update', this.state.company, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']['payload']);
                that.setState({ "postStatus": response['data'] });
            })
    }

    handleCompanyInfoChange = (e) => {
        const tmp_c = this.state.company;
        tmp_c[e.target.name] = e.target.value;
        this.setState({ "company": tmp_c })
    }

    handlePasswordChange = (e) => {
        const tmp_s = this.state;
        const tmp_v = e.target.value;
        tmp_s[e.target.name] = tmp_v;
        this.setState({ tmp_n: tmp_v });
    }
    PostStatus = (e) => {
        if (this.state.postStatus["_status"] === "fail") {
            var failItems = []
            for (var item in this.state.postStatus['payload']) {
                failItems.push(
                    <div class="row">
                        {item}: {this.state.postStatus['payload'][item]}
                    </div>
                );
            }
            return (
                <div id="postStatus1" class="alert alert-danger m-2" role="alert">
                    {failItems}
                </div>
            );
        }
        if (this.state.postStatus["_status"] === "success") {
            return (
                <div id="postStatus1" class="alert alert-success" role="alert">
                    {this.state.postStatus['payload']}
                </div>
            );
        }
        return (<span></span>);
    }

    postPasswordChange = (e) => {
        const that = this;
        axios.post(URL + 'company/password', {
            currentpassword: this.state.currentpassword,
            newpassword: this.state.newpassword,
            retypenewpassword: this.state.retypenewpassword
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']['payload']);
                that.setState({ "postStatus": response['data'] });
            })

    }

    CompanyLogoUpload = (e) => {
        return [

            <button id="companyLogoUpdateButton" type="button" class="btn btn-primary m-1" onClick={(e) => {
                document.getElementById('logoUploadInput').click();
            }}>Upload Logo</button>,
            <input type="file" class="form-control-file" id="logoUploadInput" onChange={(input) => {
                const that = this;

                for (var i = 0; i < input.target.files.length; i++) {
                    if (input.target.files[i]["type"] !== "image/jpeg" && input.target.files[i]["type"] !== "image/png") {
                        return;
                    }
                    var reader = new FileReader();
                    reader.onload = function (e) {

                        document.getElementById('logoUploadInput').value = "";


                        // prepare image

                        var image = ""

                        if (e.target.result.length > 0) {
                            image = e.target.result.split(",")[1]
                        }

                        ///////////

                        $("#companyLogoUpdateButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Upload Logo")
                        const params = { ba: "1" }
                        axios.post(URL + 'company/logo',
                            {
                                image: image
                            },
                            {
                                params: params,
                                headers: {
                                    Authorization: ACCT,
                                }
                            }).then(function (response) {

                                that.setState({ "postStatus": response['data'] });
                                $("#companyLogoUpdateButton").html("Upload Logo")

                            })
                    }
                    reader.readAsDataURL(input.target.files[i])
                }
            }} hidden />
        ]

    }
    ImageView = (e) => {

        useEffect(() => {
            // Update the document title using the browser API
            const that = this;
            var params = { "_id": 'c_logo' }
            axios.get(URL + "img", {
                responseType: "blob",
                headers: { Authorization: ACCT },
                params: params
            })
                .then(function (response) {
                    var reader = new window.FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function () {

                        var imageDataUrl = reader.result;
                        
                        document.getElementById("companyLogoImg").setAttribute("src", imageDataUrl);

                    }
                    
                })
        });

        // aquire images




        return (
            <img class="card-img-top" id="companyLogoImg" style={{maxHeight:'300px',width:'auto'}} onClick={(e) => {
                // this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                // document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                // $('#fullscreenimageviewdialog').removeAttr('hidden')
                // $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
            }} />
        )

    }

    render() {
        return (
            <div class="container">
                <div className="row">
                    <this.ImageView />
                </div>
                <form class="">
                    <div class="row">
                        <div class="col-sm m-1">
                            <div class="form-group row">
                                <label for="companyowner">Company Owner</label>
                                {/* <h4 id="companyowner">{this.state.company.owner}</h4> */}
                                <input type="text" name="owner" id="companyowner" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.owner} />
                            </div>
                            <div class="form-group row">
                                <label for="companyname">Company Name</label>
                                <input type="text" name="name" id="companyname" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.name} />
                            </div>
                            <div class="form-group row">
                                <label for="companyphone">Phone</label>
                                <input type="text" name="phone" id="companyphone" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.phone} />
                            </div>
                            <div class="form-group row">
                                <label for="companyemail">Email</label>
                                <input type="text" name="email" id="companyemail" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.email} />
                            </div>

                            <div class="form-group row">
                                <label for="companygst">GST</label>
                                <input type="text" name="gst" id="companygst" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.gst} />
                            </div>
                            <div class="form-group row">
                                <label for="companypan">PAN</label>
                                <input type="text" name="pan" id="companypan" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.pan} />
                            </div>
                        </div>
                        <div class="col-sm m-1">
                            <div class="form-group row" >
                                <label for="companyshippingAddressAddress1">Address 1</label>
                                <input type="text" name="shippingAddressAddress1" id="companyshippingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.shippingAddressAddress1} />
                            </div>
                            <div class="form-group row">
                                <label for="companyshippingAddressAddress2">Address 2</label>
                                <input type="text" name="shippingAddressAddress2" id="companyshippingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.shippingAddressAddress2} />
                            </div>

                            <div class="form-group row">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-sm">
                                            <label for="companyshippingAddressCity">City</label>
                                            <input type="text" name="shippingAddressCity" id="companyshippingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.shippingAddressCity} />
                                        </div>
                                        <div class="col-sm">
                                            <label for="companyshippingAddressState">State</label>
                                            <input type="text" name="shippingAddressState" id="companyshippingAddressState" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.shippingAddressState} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-sm">
                                            <label for="companyshippingAddressPincode">Pincode</label>
                                            <input type="text" name="shippingAddressPincode" id="companyshippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.shippingAddressPincode} />
                                        </div>
                                        <div class="col-sm">
                                            <label for="companyshippingAddressCountry">Country</label>
                                            <input type="text" name="shippingAddressCountry" id="companyshippingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handleCompanyInfoChange} value={this.state.company.shippingAddressCountry} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="companyLogoUploadDiv">Company Logo</label>
                                <div id="companyLogoUploadDiv">
                                    <this.CompanyLogoUpload />
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
                <div class="form-group row">
                    <button type="button" class="btn btn-primary" onClick={this.postCompanyInfoChange}>Save</button>
                </div>
                <div class="row p-0 mb-3">
                    <div class="col-sm">
                        <label for="currentpassword">Old Password</label>
                        <input type="password" name="currentpassword" id="currentpassword" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handlePasswordChange} value={this.state.currentpassword} />
                    </div>
                    <div class="col-sm">
                        <label for="newpassword">New Password</label>
                        <input type="password" name="newpassword" id="newpassword" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handlePasswordChange} value={this.state.newpassword} />
                    </div>
                    <div class="col-sm">
                        <label for="retypenewpassword">Retype New Password</label>
                        <input type="password" name="retypenewpassword" id="retypenewpassword" className="form-control border-1 small  form-control-lg" placeholder="" onChange={this.handlePasswordChange} value={this.state.retypenewpassword} />
                    </div>
                </div>
                <div class="form-group row">
                    <button type="button" class="btn btn-primary" onClick={this.postPasswordChange}>Change Password</button>
                </div>
                <this.PostStatus />
            </div>
        );
    }
}

class CompanyUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "existingUsers": {}, "newUser": { "username": "", "fullname": "", "password": "", "role": "", "phone": "" }, "postStatus": {}, "deleteUserLid": "", "updateUserPassword": "", "updateUserFullname": "", "updateUserPhone": "", "updateUserId": "" }

        this.postNewUser = this.postNewUser.bind(this);
        this.handleAddNewUserChange = this.handleAddNewUserChange.bind(this);
        this.ExistingUser = this.ExistingUser.bind(this);
        this.PostStatus = this.PostStatus.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.Dialog = this.Dialog.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.handleUserEditChange = this.handleUserEditChange.bind(this);
        this.EditUserDialog = this.EditUserDialog.bind(this);
        this.fetchExistingUsers = this.fetchExistingUsers.bind(this);

        this.fetchExistingUsers();
    }

    fetchExistingUsers = (e) => {
        const that = this;
        axios.get(URL + 'users', {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']['payload']);
                that.setState({ "existingUsers": response['data']['payload'] });
            })
    }

    resetFields = (e) => {
        if (this.state.postStatus["_status"] === "success") {
            this.setState({ "newUser": { "username": "", "fullname": "", "password": "", "role": "", "phone": "" } })
        }
    }

    PostStatus = (e) => {
        if (this.state.postStatus["_status"] === "fail") {
            var failItems = []
            for (var item in this.state.postStatus['payload']) {
                failItems.push(
                    <div class="row">
                        {item}: {this.state.postStatus['payload'][item]}
                    </div>
                );
            }
            return (
                <div class="alert alert-danger m-2" role="alert">
                    {failItems}
                </div>
            );
        }
        if (this.state.postStatus["_status"] === "success") {
            return (
                <div class="alert alert-success" role="alert">
                    dunzo... {this.state.postStatus['payload']}
                </div>
            );
        }
        return (<span></span>);
    }

    handleAddNewUserChange = (e) => {
        const tmpNewUser = this.state.newUser;
        tmpNewUser[e.target.name] = e.target.value;
        this.setState({ "newUser": tmpNewUser });
    }

    updateUser = (e) => {
        const that = this;
        axios.put(URL + 'users/update', {
            _id: this.state.updateUserId,
            password: this.state.updateUserPassword,
            fullname: this.state.updateUserFullname,
            phone: this.state.updateUserPhone
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.fetchExistingUsers();
                console.log(response['data']);
            })
    }
    handleUserEditChange = (e) => {
        const key = e.target.id.toString();
        const val = e.target.value;
        var tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log(this.state)
    }

    EditUserDialog = (e) => {
        return (
            <div class="modal fade" id="editUserDialog" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Edit User</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body m-2">
                            <form>
                                <div class="form-group row" key="0">
                                    <label for="editUserFullname" class="">Fullname</label>
                                    {/* <div class="col-sm-10" key="0"> */}
                                    <input autoComplete="new-password" autocomplete="off" type="text" name="fullname" id="updateUserFullname" className="form-control border-1 small  form-control-lg" onChange={this.handleUserEditChange} value={this.state.updateUserFullname} key="0" />
                                    {/* </div> */}
                                </div>
                                <div class="form-group row" key="0">
                                    <label for="editOrderPhone" class="">Phone</label>
                                    {/* <div class="col-sm-10" key="0"> */}
                                    <input autoComplete="new-password" autocomplete="off" type="text" name="phone" id="updateUserPhone" className="form-control border-1 small  form-control-lg" onChange={this.handleUserEditChange} value={this.state.updateUserPhone} key="0" />
                                    {/* </div> */}
                                </div>
                                <div class="form-group row" key="0">
                                    <label for="editOrderTracking" class="">Password</label>
                                    {/* <div class="col-sm-10" key="0"> */}
                                    <input autoComplete="new-password" autocomplete="off" type="password" name="password" id="updateUserPassword" className="form-control border-1 small  form-control-lg" onChange={this.handleUserEditChange} value={this.state.updateUserPassword} key="0" />
                                    {/* </div> */}
                                </div>
                            </form>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={this.updateUser}>Done</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    postNewUser = (e) => {
        //console.log(this.state.newUser)
        const that = this;
        axios.post(URL + 'users/add', this.state.newUser, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']);
                that.setState({ "postStatus": response['data'] });
                const that_ = that;
                axios.get(URL + 'users', {
                    headers: { Authorization: ACCT }
                })
                    .then(function (response) {
                        console.log(response['data']['payload']);
                        that_.setState({ "existingUsers": response['data']['payload'] }, that.resetFields);
                    })
            })
    }
    deleteUser = (e) => {
        const _id = this.state.deleteUserLid;
        const that = this;
        axios.post(URL + 'users/delete', {
            _id: _id
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']);
                that.setState({ "postStatus": response['data'] });
                const that_ = that;
                axios.get(URL + 'users', {
                    headers: { Authorization: ACCT }
                })
                    .then(function (response) {
                        console.log(response['data']['payload']);
                        that_.setState({ "existingUsers": response['data']['payload'] });
                    })
            })
    }
    ExistingUser = (e) => {
        var users = [];
        for (var item in this.state.existingUsers) {
            users.push(
                <tr>
                    <td>
                        <input autocomplete="off" type="text" class="form-control border-1 small" placeholder="Username" name="username" id="existingCustomerUsername" value={this.state.existingUsers[item]['username']} />
                    </td>
                    <td>
                        <input autocomplete="off" type="text" class="form-control border-1 small" placeholder="Full Name" name="fullname" id="existingCustomerFullname" value={this.state.existingUsers[item]['fullname']} />
                    </td>
                    <td>
                        <input autocomplete="off" type="text" class="form-control border-1 small" placeholder="Phone" name="phone" id="existingCustomerPhone" value={this.state.existingUsers[item]['phone']} />
                    </td>
                    {/* <td>
                        <input autocomplete="off" type="password" class="form-control border-1 small " placeholder="Password" name="password" id="existingCustomerPassword" value="123456" />
                        <input autocomplete="off" type="password" class="form-control border-1 small " placeholder="Password" name="password" id="existingCustomerPassword" value={this.state.existingUsers[item]['password']} />
                    </td> */}
                    <td>
                        <select class="custom-select " name="role" id="existingCustomerRole" value={this.state.existingUsers[item]['role']}>
                            <option selected value="selectRole">Select Role</option>
                            <option value="Handler">Handler</option>
                            <option value="Clerk">Clerk</option>
                            <option value="Sales">Sales</option>
                            <option value="Invoice">Invoice</option>
                            <option value="Inventory">Inventory</option>
                        </select>
                    </td>
                    <td class="align-middle">
                        <i class="fas fa-edit mr-3" title="Edit User" style={{ cursor: 'pointer' }} itemid={item} id={`editUser-` + item} onClick={(e) => {
                            const itemid = e.target.getAttribute("itemid");
                            var fullname = this.state.existingUsers[itemid]['fullname'];
                            var phone = this.state.existingUsers[itemid]['phone'];
                            var Id = this.state.existingUsers[itemid]._id["$oid"]
                            this.setState({ updateUserFullname: fullname, updateUserPhone: phone, updateUserPassword: "", updateUserId: Id })
                        }} lid={this.state.existingUsers[item]._id["$oid"]} data-toggle="modal" data-target="#editUserDialog" ></i>

                        <i class="fas fa-trash-alt" style={{ cursor: 'pointer' }} lid={this.state.existingUsers[item]._id["$oid"]} data-toggle="modal" data-target="#deleteDialog" onClick={(e) => {
                            this.setState({ deleteUserLid: e.target.getAttribute("lid") })
                        }}></i>
                    </td>

                </tr>
            );
        }
        return (
            <table class="table w-100 m-0">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Phone</th>
                        {/* <th scope="col">Password</th> */}
                        <th scope="col">Role</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {users}
                    <tr>
                        <td class="border-0">
                            <button type="button" class="btn btn-primary" data-toggle="collapse" data-target="#addNewUser" aria-expanded="false">Add New User</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
    Dialog(props) {
        const name = props.name;
        const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
        const handle = props.handle;

        return (
            <div class="modal fade" id={name + `Dialog`} tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Confirm Action</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Do you want to {name} the user?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={handle}>{nameCapitalized}</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Exit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <this.ExistingUser />
                <div class="collapse" id="addNewUser">
                    <table class="table w-100 m-0 ">
                        <tbody>
                            <tr>
                                <td>
                                    <input autocomplete="off" type="text" class="form-control border-1 small" placeholder="Username" name="username" id="customerUsername" onChange={this.handleAddNewUserChange} value={this.state.newUser.username} />
                                </td>
                                <td>
                                    <input autocomplete="off" type="text" class="form-control border-1 small" placeholder="Full Name" name="fullname" id="customerFullname" onChange={this.handleAddNewUserChange} value={this.state.newUser.fullname} />
                                </td>
                                <td>
                                    <input autocomplete="off" type="text" class="form-control border-1 small" placeholder="Phone" name="phone" id="customerPhone" onChange={this.handleAddNewUserChange} value={this.state.newUser.phone} />
                                </td>
                                <td>
                                    <input autocomplete="off" type="password" class="form-control border-1 small " placeholder="Password" name="password" id="customerPassword" onChange={this.handleAddNewUserChange} value={this.state.newUser.password} />
                                </td>
                                <td>
                                    <select class="custom-select" name="role" id="customerRole" onChange={this.handleAddNewUserChange}>
                                        <option selected value="selectRole">Select Role</option>
                                        <option value="Handler">Handler</option>
                                        <option value="Clerk">Clerk</option>
                                        <option value="Sales">Sales</option>
                                        <option value="Invoice">Invoice</option>
                                        <option value="Inventory">Inventory</option>
                                    </select>
                                </td>
                                <td class="align-middle">
                                    {/* <i class="fas fa-trash-alt" style={{ cursor: 'pointer' }}></i> */}
                                </td>

                            </tr>
                            <tr>
                                <td class="border-0">
                                    <button type="button" class="btn btn-primary" onClick={this.postNewUser}>Add</button>
                                    <this.PostStatus />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <this.Dialog name="delete" handle={this.deleteUser} />
                <this.EditUserDialog />
            </div>
        );
    }
}

class Handlers extends React.Component {
    constructor(props) {
        super(props);
        this.ExistingHandler = this.ExistingHandler.bind(this);
        this.handleAddNewHandlerChange = this.handleAddNewHandlerChange.bind(this);
        this.postNewHandlerData = this.postNewHandlerData.bind(this);
        this.PostStatus = this.PostStatus.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.state = { "handlers": "", "newHandler": { "firstname": "", "lastname": "", "phone": "" }, "postStatus": {} };
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']['payload']);
                that.setState({ "handlers": response['data']['payload'] });
            })
    }

    deleteHandler = (e) => {
        const lid = e.target.getAttribute('lid');
        const that = this;
        axios.post(URL + 'company/handlers/delete', {
            _id: lid
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                console.log(response['data']);
                that.setState({ "postStatus": response['data'] });
                const that_ = that;
                axios.post(URL + 'company/handlers', {
                }, {
                    headers: { Authorization: ACCT }
                })
                    .then(function (response) {
                        // console.log(response['data']['payload']);
                        that_.setState({ "handlers": response['data']['payload'] });
                    })
            })
    }

    ExistingHandler = (e) => {
        var handlers = []
        for (var item in this.state.handlers) {
            handlers.push(
                <tr>
                    <th scope="row">{parseInt(item) + 1}</th>
                    <td>{this.state.handlers[item]['firstname']}</td>
                    <td>{this.state.handlers[item]['lastname']}</td>
                    <td>{this.state.handlers[item]['phone']}</td>
                    <td>
                        <i class="fas fa-trash-alt" lid={this.state.handlers[item]['_id']['$oid']} style={{ cursor: 'pointer' }} onClick={this.deleteHandler}></i>
                    </td>
                </tr>
            );
        }
        return (
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Firstname</th>
                        <th scope="col">Lastname</th>
                        <th scope="col">Phone</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {handlers}
                </tbody>
            </table>
        );
    }

    handleAddNewHandlerChange = (e) => {
        var tmpNewHandler = this.state.newHandler;
        tmpNewHandler[e.target.name] = e.target.value;
        this.setState({ "newHandler": tmpNewHandler })
    }

    postNewHandlerData = (e) => {
        const that = this;
        axios.post(URL + 'company/handlers/add', this.state.newHandler, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                console.log(response['data']);
                that.setState({ "postStatus": response['data'] });
                if (response["data"]["_status"] === "success") {
                    that.resetFields();
                }
                const that_ = that;
                axios.post(URL + 'company/handlers', {
                }, {
                    headers: { Authorization: ACCT }
                })
                    .then(function (response) {
                        console.log(response['data']['payload']);
                        that_.setState({ "handlers": response['data']['payload'] });
                    })
            })
    }

    resetFields = (e) => {
        var tmpNewHandler = this.state.newHandler;
        tmpNewHandler["firstname"] = "";
        tmpNewHandler["lastname"] = "";
        tmpNewHandler["phone"] = "";
        this.setState({ "newHandler": tmpNewHandler })
    }

    PostStatus = (e) => {
        if (this.state.postStatus["_status"] === "fail") {
            var failItems = []
            for (var item in this.state.postStatus['payload']) {
                failItems.push(
                    <div class="row">
                        {item}: {this.state.postStatus['payload'][item]}
                    </div>
                );
            }
            return (
                <div class="alert alert-danger m-2" role="alert">
                    {failItems}
                </div>
            );
        }
        if (this.state.postStatus["_status"] === "success") {
            return (
                <div class="alert alert-success m-2" role="alert">
                    {this.state.postStatus['payload']}
                </div>
            );
        }
        return (<span></span>);
    }
    render() {
        return (
            <div class="">
                <this.ExistingHandler />
                <div class="m-2">
                    <button type="button" class="btn btn-primary" data-toggle="collapse" data-target="#addNewHandlerCollapse" aria-expanded="false">Add New Handler</button>
                </div>
                <div class="collapse" id="addNewHandlerCollapse">
                    <div class="card card-body m-2">
                        <form>
                            <div class="form-group">
                                {/* <label for="addNewHandlerFullname">Full Name</label> */}
                                <input autocomplete="off" type="text" class="form-control" id="addNewHandlerFirstname" name="firstname" aria-describedby="firstnameHelp" placeholder="Enter Firstname" value={this.state.newHandler.firstname} onChange={this.handleAddNewHandlerChange} />
                            </div>
                            <div class="form-group">
                                {/* <label for="addNewHandlerFullname">Full Name</label> */}
                                <input autocomplete="off" type="text" class="form-control" id="addNewHandlerLastname" name="lastname" aria-describedby="lastnameHelp" placeholder="Enter Lastname" value={this.state.newHandler.lastname} onChange={this.handleAddNewHandlerChange} />
                            </div>
                            <div class="form-group">
                                {/* <label for="addNewHandlerPhone">Phone</label> */}
                                <input autocomplete="off" type="text" class="form-control" id="addNewHandlerPhone" name="phone" aria-describedby="phoneHelp" placeholder="Enter Phone" value={this.state.newHandler.phone} onChange={this.handleAddNewHandlerChange} />
                            </div>
                            <button type="button" class="btn btn-primary" onClick={this.postNewHandlerData}>Add</button>
                        </form>
                        <this.PostStatus />
                    </div>
                </div>
            </div>
        )
    }
}

class DatabaseMetrics extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "metrics": "" };
        const that = this;
        axios.post(URL + 'company/database/metrics', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data']['payload']);
                that.setState({ "metrics": response['data']['payload'] });
            })
    }
    render() {
        return (
            <div class="container m-4">
                <h4>Total Entries:&nbsp;<b>{this.state['metrics']}</b></h4>
            </div>
        )
    }
}
const Company = (e) => {
    return (
        <div>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Company</h1>
                <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
            </div>

            <div class="row">

                <div class="col-xl-6 col-lg-7 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Company Settings</h6>
                        </div>
                        <div class="m-5">
                            <CompanySettings />
                        </div>
                    </div>
                </div>
                {/* 
                <div class="col-xl-6 col-lg-7 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Handlers</h6>
                        </div>
                        <div class="m-0">
                            <Handlers />
                        </div>
                    </div>
                </div> */}

                <div class="col-xl-6 col-lg-7 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Database Metrics</h6>
                        </div>
                        <div class="m-0">
                            <DatabaseMetrics />
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Company Users</h6>
                        </div>
                        <div class="m-0">
                            <CompanyUsers />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Company;