import React from 'react'

class NotFound extends React.Component {
    render() {
        return (
            <center>
                <h1>404</h1>
            </center>
        )
    }

}

export default NotFound


