import React from 'react';
import $ from 'jquery';
import axios from 'axios';

import Cookies from 'js-cookie';
import URL from './URL';
import './Inquiry.css';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DebounceInput } from 'react-debounce-input';
import Pagination from "react-js-pagination";
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import csc from 'country-state-city'
import { ICountry, IState, ICity } from 'country-state-city'

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')


class Handlers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "handlers": {} };
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "handlers": response['data']['payload'] });
            })

        this.selectedHandler = props.handler;
    }
    render() {
        var handlers = []
        for (var handler in this.state.handlers) {
            var name_ = this.state.handlers[handler].firstname + " " + this.state.handlers[handler].lastname;
            if (name_ == this.selectedHandler) {

                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_} selected>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
            else {
                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_}>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
        }
        return (

            handlers
        );
    }
}

class Data extends React.Component {
    constructor(props) {
        super(props);
        this.state = { defaultInfo: { ...defaultInfoValues }, submitButtonLoadingSpinner: false, statusStringBool: false, statusString: {}, selectedCustomers: {}, retrievedCustomerData: {}, billingSelectedCountryId: "", shippingSelectedCountryId: "", billingSelectedStateId: "", shippingSelectedStateId: "", billingSelectedCityId: "", shippingSelectedCityId: "", inputSuggestionBool: true, billingStates: csc.getStatesOfCountry("101"), shippingStates: csc.getStatesOfCountry("101"), billingCities: [], shippingCities: [], shippingAddressCitySelected: null, shippingAddressStateSelected: null, shippingAddressCountrySelected: null, shippingBillingAddressSameBool: false, interests_search_term: "", fetched_interests: [], added_interests: {} }

        this.handleDefaultInfoChange = this.handleDefaultInfoChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.postData = this.postData.bind(this);
        this.SubmitButtonLoadignSpinner = this.SubmitButtonLoadignSpinner.bind(this);
        this.StatusString = this.StatusString.bind(this);
        this.resetStatusAndField = this.resetStatusAndField.bind(this);
        this.closeStatusString = this.closeStatusString.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.addNewPhone = this.addNewPhone.bind(this);
        this.removeNewPhone = this.removeNewPhone.bind(this);
        this.handlePhonesChange = this.handlePhonesChange.bind(this);
        $('#firstname').focus();

        this.allCountries = csc.getAllCountries();
        this.billingStates = []
        this.shippingStates = []
        this.billingCities = []
        this.shippingCities = []

        this.suggestionInputRefs = {}

    }

    componentDidMount() {
        var tmp_countries = csc.getAllCountries();
        this.setState({ billingCountries: tmp_countries, shippingCountries: tmp_countries })
    }


    resetFields = (e) => {
        var shippingAddressAddress1 = document.getElementById("shippingAddressAddress1");
        var shippingAddressAddress2 = document.getElementById("shippingAddressAddress2");
        // var shippingAddressCity = document.getElementById("shippingAddressCity");
        // var shippingAddressState = document.getElementById("shippingAddressState");
        var shippingAddressPincode = document.getElementById("shippingAddressPincode");
        // var shippingAddressCountry = document.getElementById("shippingAddressCountry");

        var billingAddressAddress1 = document.getElementById("billingAddressAddress1");
        var billingAddressAddress2 = document.getElementById("billingAddressAddress2");
        // var billingAddressCity = document.getElementById("billingAddressCity");
        // var billingAddressState = document.getElementById("billingAddressState");
        var billingAddressPincode = document.getElementById("billingAddressPincode");
        // var billingAddressCountry = document.getElementById("billingAddressCountry");

        var firstname = document.getElementById("firstname");
        var lastname = document.getElementById("lastname");
        var brand = document.getElementById("brand");
        $("#interests").val("");
        $("#instagram").val("");
        $("#facebook").val("");
        $("#whatsapp").val("");
        $("#website").val("");
        var phone = document.getElementById("phone");
        var email = document.getElementById("email");
        var gstin = document.getElementById("gstin");
        var handler = document.getElementById("inputGroupSelect01");
        var customerSearchTextbox = document.getElementById("customerSearchTextbox");
        var coupon = document.getElementById("coupon");

        $("select#dataBillingAddressCountry").val("India")
        $("select#dataBillingAddressState").prop('selectedIndex', 0);
        $("select#dataBillingAddressCity").prop('selectedIndex', 0);

        $("select#dataShippingAddressCountry").val("India")
        $("select#dataShippingAddressState").prop('selectedIndex', 0);
        $("select#dataShippingAddressCity").prop('selectedIndex', 0);

        shippingAddressAddress1.value = "";
        shippingAddressAddress2.value = "";
        shippingAddressPincode.value = "";
        billingAddressAddress1.value = "";
        billingAddressAddress2.value = "";
        // billingAddressCity.value = "";
        // billingAddressState.value = "";
        billingAddressPincode.value = "";
        // billingAddressCountry.value = "";
        firstname.value = "";
        lastname.value = "";
        brand.value = "";
        phone.value = "";
        email.value = "";
        gstin.value = "";
        handler.selectedIndex = 0;
        $("select#dataEntryType").prop('selectedIndex', 0);
        $("select#dataEntryTag").prop('selectedIndex', 0);
        if (customerSearchTextbox != null) {
            customerSearchTextbox.value = "";
        }
        coupon.value = "";

        const tmpValues = {
            "firstname": "",
            "lastname": "",
            "brand": "",
            "interests": "",
            "phone": "",
            "phones": [],
            "email": "",
            "gstin": "",
            "instagram": "",
            "facebook": "",
            "whatsapp": "",
            "website": "",
            "type": "",
            "coupon": "",
            "tag": "",
            "billingAddressAddress1": "",
            "billingAddressAddress2": "",
            "billingAddressCity": "",
            "billingAddressState": "",
            "billingAddressPincode": "",
            "billingAddressCountry": "India",
            "shippingAddressAddress1": "",
            "shippingAddressAddress2": "",
            "shippingAddressCity": "",
            "shippingAddressState": "",
            "shippingAddressPincode": "",
            "shippingAddressCountry": "India",
            "handler": "",
            "handlerId": ""
        }
        this.setState({ defaultInfo: { ...tmpValues }, added_interests: {}, shippingBillingAddressSameBool: false, shippingAddressStateSelected: null }, () => { })
        this.setState({ state: this.state });

    }

    resetStatusAndField = (e) => {
        this.resetFields();
        this.setState({ statusStringBool: false });
    }
    closeStatusString = (e) => {
        this.setState({ statusStringBool: false });
    }
    StatusString = (e) => {
        if (this.state.statusStringBool == true) {
            if (this.state.statusString['_status'] == 'fail') {
                var issues = [];
                for (var index in this.state.statusString['payload']) {
                    if (index === "phones") {
                        for (var index_ in this.state.statusString['payload']['phones'][0]) {
                            issues.push(
                                <div class="row"><span>Phone #{parseInt(index_ + 1).toString()}: {this.state.statusString['payload']['phones'][0][index_]} </span></div>
                            )
                        }
                    } else {
                        issues.push(
                            <div class="row"><span>{index}: {this.state.statusString['payload'][index]} </span></div>
                        )
                    }

                }
                // issues.push(<JSONPretty id="json-pretty" data={this.state.statusString['payload']}></JSONPretty>);
                return (
                    <div class="input-group">
                        <div class="alert alert-danger m-0" role="alert">

                            {issues}
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" onClick={this.resetStatusAndField}>
                                <span>Reset</span>
                            </button>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" onClick={this.closeStatusString}>
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                )

            }
            if (this.state.statusString['_status'] == 'success') {
                return (
                    <div class="input-group">
                        <div class="alert alert-success m-0" role="alert">
                            <div class="row">
                                Data successfully entered with {this.state.statusString['payload']}
                            </div>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-success" onClick={this.resetStatusAndField}>
                                <span>Reset</span>
                            </button>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-success" onClick={this.closeStatusString}>
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div>

            </div>
        );
    }

    SubmitButtonLoadignSpinner = (e) => {
        if (this.state.submitButtonLoadingSpinner == false) {
            return (
                <span></span>
            );
        }
        if (this.state.submitButtonLoadingSpinner == true) {
            return (
                <i id="spin_submit_button" class="fa fa-circle-o-notch fa-spin mr-2"></i>
            );
        }
    }
    postData = () => {
        // setSubmitButtonLoadingSpinner(true);
        // console.log(this.state.defaultInfo);
        const that = this;
        this.setState({ submitButtonLoadingSpinner: true })
        var aggr_data = { ...this.state.defaultInfo };


        // generate interests
        // interest string
        var interests = ""
        for (var i in this.state.added_interests) {
            interests += i + " "
        }

        aggr_data["interests"] = interests


        // var i = 0;
        // for (i = 0; i < this.state.selectedCustomers.length; i++) {
        //     const tmp_pair = '{"' + dataState[i]['dataKey'] + '": "' + dataState[i]['dataValue'] + '"}';
        //     aggr_data = json_prop_concat(aggr_data, JSON.parse(tmp_pair));
        // }
        // console.log(JSON.stringify(aggr_data));
        axios.post(URL + "data", aggr_data, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data'])
                that.setState({ statusString: response['data'], submitButtonLoadingSpinner: false, statusStringBool: true });
                if (response['data']["_status"] == "success") {
                    that.resetFields();
                }
                //setStatusString(response['data']);
                //setStatusStringBool(true);
                //setSubmitButtonLoadingSpinner(false);
            })
            .catch(function (error) {
                // console.log(error)
            })
    }
    handleCheckBoxChange = (e) => {
        if (e.target.checked) {
            this.setState({ shippingBillingAddressSameBool: true, shippingStates: this.state.billingStates, shippingCities: this.state.billingCities }, () => {
                const updatedDefaultInfo = this.state.defaultInfo;
                var shippingAddressAddress1 = document.getElementById("shippingAddressAddress1");
                var shippingAddressAddress2 = document.getElementById("shippingAddressAddress2");
                var shippingAddressCity = document.getElementById("shippingAddressCity");
                var shippingAddressState = document.getElementById("shippingAddressState");
                var shippingAddressPincode = document.getElementById("shippingAddressPincode");
                var shippingAddressCountry = document.getElementById("shippingAddressCountry");

                if ("billingAddressAddress1" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressAddress1'] = updatedDefaultInfo["billingAddressAddress1"];
                    shippingAddressAddress1.value = updatedDefaultInfo["billingAddressAddress1"];

                }
                if ("billingAddressAddress2" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressAddress2'] = updatedDefaultInfo["billingAddressAddress2"];
                    shippingAddressAddress2.value = updatedDefaultInfo["billingAddressAddress2"];
                }
                //alert(updatedDefaultInfo['billingAddressCity'])
                if ("billingAddressCity" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressCity'] = updatedDefaultInfo["billingAddressCity"];
                    const idx = $("select#dataBillingAddressCity").prop('selectedIndex');
                    $("select#dataShippingAddressCity").prop('selectedIndex', idx);
                    // shippingAddressCity.value = updatedDefaultInfo["billingAddressCity"];
                    //  this.suggestionInputRefs['shippingAddressCity']._updateText("Great")
                }
                if ("billingAddressState" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressState'] = updatedDefaultInfo["billingAddressState"];
                    const idx = $("select#dataBillingAddressState").prop('selectedIndex');
                    $("select#dataShippingAddressState").prop('selectedIndex', idx);
                    // shippingAddressState.value = updatedDefaultInfo["billingAddressState"];
                }
                if ("billingAddressPincode" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressPincode'] = updatedDefaultInfo["billingAddressPincode"];
                    shippingAddressPincode.value = updatedDefaultInfo["billingAddressPincode"];
                }
                if ("billingAddressCountry" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressCountry'] = updatedDefaultInfo["billingAddressCountry"];
                    const idx = $("select#dataBillingAddressCountry").prop('selectedIndex');
                    $("select#dataShippingAddressCountry").prop('selectedIndex', idx);
                    // shippingAddressCountry.value = updatedDefaultInfo["billingAddressCountry"];
                }
                this.setState({ "defaultInfo": updatedDefaultInfo });
            })

        } else {
            this.setState({ shippingBillingAddressSameBool: false })
        }
    };
    handleDefaultInfoChange = (e) => {
        const updatedDefaultInfo = this.state.defaultInfo;
        updatedDefaultInfo[e.target.name] = e.target.value;
        if (e.target.name === "handler") {
            var index = e.target.selectedIndex;
            const h_lid = e.target[index].getAttribute("lid");
            updatedDefaultInfo["handlerId"] = h_lid;
        }
        var checkbox = document.getElementsByName("shippingBillingCheckBox");
        if (checkbox[0].checked) {
            var shippingAddressAddress1 = document.getElementById("shippingAddressAddress1");
            var shippingAddressAddress2 = document.getElementById("shippingAddressAddress2");
            var shippingAddressCity = document.getElementById("shippingAddressCity");
            var shippingAddressState = document.getElementById("shippingAddressState");
            var shippingAddressPincode = document.getElementById("shippingAddressPincode");
            var shippingAddressCountry = document.getElementById("shippingAddressCountry");

            if ("billingAddressAddress1" in updatedDefaultInfo) {
                updatedDefaultInfo['shippingAddressAddress1'] = updatedDefaultInfo["billingAddressAddress1"];
                shippingAddressAddress1.value = updatedDefaultInfo["billingAddressAddress1"];
            }
            if ("billingAddressAddress2" in updatedDefaultInfo) {
                updatedDefaultInfo['shippingAddressAddress2'] = updatedDefaultInfo["billingAddressAddress2"];
                shippingAddressAddress2.value = updatedDefaultInfo["billingAddressAddress2"];
            }
            // if ("billingAddressCity" in updatedDefaultInfo) {
            //     updatedDefaultInfo['shippingAddressCity'] = updatedDefaultInfo["billingAddressCity"];
            //     shippingAddressCity.value = updatedDefaultInfo["billingAddressCity"];
            // }
            // if ("billingAddressState" in updatedDefaultInfo) {
            //     updatedDefaultInfo['shippingAddressState'] = updatedDefaultInfo["billingAddressState"];
            //     shippingAddressState.value = updatedDefaultInfo["billingAddressState"];
            // }
            if ("billingAddressPincode" in updatedDefaultInfo) {
                updatedDefaultInfo['shippingAddressPincode'] = updatedDefaultInfo["billingAddressPincode"];
                shippingAddressPincode.value = updatedDefaultInfo["billingAddressPincode"];
            }
            // if ("billingAddressCountry" in updatedDefaultInfo) {
            //     updatedDefaultInfo['shippingAddressCountry'] = updatedDefaultInfo["billingAddressCountry"];
            //     shippingAddressCountry.value = updatedDefaultInfo["billingAddressCountry"];
            // }
        }
        this.setState({ "defaultInfo": updatedDefaultInfo });
    }
    handleKeyPress = (e) => {
        if (e.keyCode == 13) {
            if ($("#firstname").val() == "") {

                $("#firstname").focus();
                return;
            }
            // if ($("#lastname").val() == "") {
            //     $("#lastname").focus();
            //     return;
            // }
            if ($("#phone").val() == "") {
                $("#phone").focus();
                return;
            }
            if ($("#inputGroupSelect01").val() == "" || $("#inputGroupSelect01").val() == undefined) {
                $("#inputGroupSelect01").focus();
                return;
            }
            $("#postDataButton").focus();
        }
    }

    addNewPhone = (e) => {
        var tmpDefaultInfo = this.state.defaultInfo
        tmpDefaultInfo.phones.push("")
        this.setState({ defaultInfo: { ...tmpDefaultInfo } })
    }
    removeNewPhone = (e) => {
        var index = e.target.getAttribute("itemid");
        var tmpDefaultInfo = this.state.defaultInfo
        var a = tmpDefaultInfo.phones.splice(index, 1)
        this.setState({ defaultInfo: tmpDefaultInfo })
    }
    handlePhonesChange = (e) => {
        var index = e.target.getAttribute("itemid");
        var tmpDefaultInfo = this.state.defaultInfo
        tmpDefaultInfo.phones[index] = e.target.value
        this.setState({ defaultInfo: tmpDefaultInfo })
    }

    getInterests = (query) => {
        const that = this
        axios.get(URL + "data/interests",
            {
                headers: { Authorization: ACCT },
                params: {
                    s: query
                }
            })
            .then(function (response) {
                that.setState({ fetched_interests: response.data.payload })

            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    InterestSection = () => {

        // generate badge

        var badges = []


        for (var i in this.state.added_interests) {
            badges.push(
                <span class="badge badge-pill badge-primary m-2 p-2"><b>{i} &nbsp; <span className="btn-link text-light" style={{ cursor: 'pointer' }} lid={i} onClick={(e) => {
                    const lid = e.target.getAttribute('lid')
                    var tmp_added_interest = this.state.added_interests
                    delete tmp_added_interest[lid]
                    this.setState({ added_interests: tmp_added_interest }, () => {
                    })
                }}><b lid={i}>X</b></span></b></span>
            )
        }

        return (
            <div className="w-100">
                <div>
                    {badges}
                </div>
                <AsyncTypeahead type="text"
                    placeholder="Interests"
                    minLength={1}
                    inputProps={
                        {
                            className: "form-control border-1 small  form-control-lg w-100",
                        }
                    }
                    labelKey={option => `${option.interest}`}
                    filterBy={['interest']}
                    isLoading="false"
                    options={this.state.fetched_interests}
                    maxResults="5"
                    onSearch={(query) => { this.getInterests(query) }}

                    ref={(typeahead) => this.interestSuggestion = typeahead}
                    onKeyDown={(e) => {
                        if (e.keyCode == 13) {
                            var tmp_added_interest = this.state.added_interests
                            tmp_added_interest[e.target.value] = e.target.value
                            this.setState({ added_interests: tmp_added_interest }, () => {
                            })
                        }
                    }}
                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps} className="search_menu" id="interest_search_menu">
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} className="search_item m-0"
                                    onClick={(e) => {
                                        var tmp_added_interest = this.state.added_interests
                                        tmp_added_interest[result.interest] = result.interest
                                        this.setState({ added_interests: tmp_added_interest }, () => {
                                        })
                                    }}>
                                    <div className="w-100 p-2 search_item">
                                        {`${result.interest}`}
                                    </div>
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                />
            </div>
        )
    }

    SuggestionInput = (props) => {

        return (
            <AsyncTypeahead type="text" placeholder={props.placeholder} name={props.name}
                inputProps={
                    {

                        id: props.name,
                        className: "form-control border-1 small  form-control-lg w-100",
                    }
                }

                disabled={props.disabled}
                defaultInputValue={props.defaultInputValue}
                labelKey={option => `${option.name}`}
                filterBy={['name']}
                isLoading="false"
                options={props.data}
                maxResults="10"
                selected={props.selectedOption}
                onSearch={() => {
                    if (props.name === "billingAddressState") {
                        var tmp_states = csc.getStatesOfCountry(this.state.billingSelectedCountryId)
                        this.setState({ billingStates: tmp_states })
                    }
                    if (props.name === "shippingAddressState") {
                        var tmp_states = csc.getStatesOfCountry(this.state.shippingSelectedCountryId)
                        this.setState({ shippingStates: tmp_states })
                    }
                    if (props.name === "billingAddressCity") {
                        var tmp_cities = csc.getCitiesOfState(this.state.billingSelectedStateId)
                        this.setState({ billingCities: tmp_cities })
                    }
                    if (props.name === "shippingAddressCity") {
                        var tmp_cities = csc.getCitiesOfState(this.state.shippingSelectedStateId)
                        this.setState({ shippingCities: tmp_cities })
                    }


                }}
                ref={(typeahead) => this.suggestionInputRefs[props.name] = typeahead}

                onChange={(result) => {
                    // console.log(result)
                    if (result.length === 0) {
                        return
                    }
                    const result_ = result[0]
                    if (props.name === "billingAddressCountry") {
                        $("#billingAddressState").removeAttr('disabled')
                        this.setState({ billingSelectedCountryId: result_.id })
                    }
                    if (props.name === "shippingAddressCountry") {
                        $("#shippingAddressState").removeAttr('disabled')
                        this.setState({ shippingSelectedCountryId: result_.id, shippingAddressCountrySelected: [result_] })
                    }
                    if (props.name === "billingAddressState") {
                        $("#billingAddressCity").removeAttr('disabled')
                        this.setState({ billingSelectedStateId: result_.id })
                    }
                    if (props.name === "shippingAddressState") {
                        $("#shippingAddressCity").removeAttr('disabled')
                        this.setState({ shippingSelectedStateId: result_.id, shippingAddressStateSelected: [result_] })
                    }
                    if (props.name === "shippingAddressCity") {
                        this.setState({ shippingAddressCitySelected: [result_] })
                    }
                    var updatedDefaultInfo = this.state.defaultInfo;
                    updatedDefaultInfo[props.name] = result_.name;
                    this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                        // this.setState({ inputSuggestionBool: true });
                    });
                }}
                renderMenu={(results, menuProps) => (
                    <Menu {...menuProps} className="search_menu" id="search_menu">
                        {results.map((result, index) => (
                            <MenuItem option={result} position={index} name={props.name} className="search_item m-0"
                                onClick={(e) => {

                                }}>
                                <div className="w-100 p-2 search_item">
                                    {`${result.name}`}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            />
        )

    }

    SuggestionSelect = (props) => {
        return (
            <select class="form-control form-control-lg" key={props.id} name={props.name} id={props.id} defaultValue={props.defaultValue} onChange={(e) => {
                const iid = e.target.options[e.target.selectedIndex].getAttribute('iid')

                if (props.name === "billingAddressCountry") {
                    $("select#dataBillingAddressState").prop('selectedIndex', 0);
                    $("select#dataBillingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;

                    if (this.state.shippingBillingAddressSameBool) {

                        $("select#dataShippingAddressState").prop('selectedIndex', 0);
                        $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                        updatedDefaultInfo['shippingAddressCountry'] = e.target.value;

                        var tmp_states = csc.getStatesOfCountry(iid)
                        this.setState({ billingStates: tmp_states, shippingStates: tmp_states, shippingCities: [], billingCities: [], "defaultInfo": updatedDefaultInfo }, () => {
                            const idx = $("select#dataBillingAddressCountry").prop('selectedIndex');
                            $("select#dataShippingAddressCountry").prop('selectedIndex', idx);
                        })
                    } else {

                        updatedDefaultInfo["billingAddressState"] = "";
                        updatedDefaultInfo["billingAddressCity"] = "";
                        var tmp_states = csc.getStatesOfCountry(iid)
                        this.setState({ billingStates: tmp_states, billingCities: [], "defaultInfo": updatedDefaultInfo })
                    }



                }
                if (props.name === "shippingAddressCountry") {


                    $("select#dataShippingAddressState").prop('selectedIndex', 0);
                    $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;


                    updatedDefaultInfo["shippingAddressState"] = "";
                    updatedDefaultInfo["shippingAddressCity"] = "";


                    var tmp_states = csc.getStatesOfCountry(iid)
                    this.setState({ shippingStates: tmp_states, shippingCities: [], "defaultInfo": updatedDefaultInfo })
                }
                if (props.name === "billingAddressState") {

                    $("select#dataBillingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;

                    if (this.state.shippingBillingAddressSameBool) {

                        $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                        updatedDefaultInfo['shippingAddressState'] = e.target.value;

                        var tmp_cities = csc.getCitiesOfState(iid)
                        this.setState({ billingCities: tmp_cities, shippingCities: tmp_cities, "defaultInfo": updatedDefaultInfo }, () => {
                            const idx = $("select#dataBillingAddressState").prop('selectedIndex');
                            $("select#dataShippingAddressState").prop('selectedIndex', idx);
                        })
                    } else {
                        var tmp_cities = csc.getCitiesOfState(iid)
                        updatedDefaultInfo["billingAddressCity"] = "";
                        this.setState({ billingCities: tmp_cities, "defaultInfo": updatedDefaultInfo })
                    }

                }
                if (props.name === "shippingAddressState") {
                    $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;
                    updatedDefaultInfo["shippingAddressCity"] = "";

                    var tmp_cities = csc.getCitiesOfState(iid)
                    this.setState({ shippingCities: tmp_cities })
                }

                if (props.name === "billingAddressCity") {


                    var updatedDefaultInfo = this.state.defaultInfo;
                    if (this.state.shippingBillingAddressSameBool) {
                        updatedDefaultInfo['shippingAddressCity'] = e.target.value;
                        this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                            const idx = $("select#dataBillingAddressCity").prop('selectedIndex');
                            $("select#dataShippingAddressCity").prop('selectedIndex', idx);
                        })
                    }

                }

                var updatedDefaultInfo = this.state.defaultInfo;
                updatedDefaultInfo[props.name] = e.target.value;
                this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                    // this.setState({ inputSuggestionBool: true });
                });

            }}>
                <option selected value="">Select {props.what}</option>
                {
                    props.data.map((item, index) => (
                        <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                    ))
                }
            </select>
        )
    }

    render() {
        if (this.props.show_card === "noshow") {

            return (


                <form class="m-5">
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="firstname" id="firstname" className="firstname form-control border-1 small  form-control-lg" placeholder="Firstname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />

                            <input autoComplete="new-password" autocomplete="off" type="text" name="lastname" id="lastname" className="lastname form-control border-1 small  form-control-lg" placeholder="Lastname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="brand" id="brand" className="form-control border-1 small  form-control-lg" placeholder="Brand" maxlength="50" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="interests" id="interests" className="form-control border-1 small  form-control-lg" placeholder="Interests" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="phone" id="phone" className="form-control border-1 small  form-control-lg" placeholder="Phone" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={this.addNewPhone}>Add</button>
                            </div>
                        </div>
                    </div>

                    {this.state.defaultInfo.phones.map((phone, index) => (
                        <div class="form-group row">
                            <div class="input-group">
                                <input autoComplete="new-password" autocomplete="off" type="text" name={`phones-` + index} id={`phones-` + index} className="form-control border-1 small  form-control-lg" placeholder={`Phone #` + parseInt(index + 1).toString()} maxlength="16" itemid={index} onKeyDown={this.handleKeyPress} onChange={this.handlePhonesChange} value={this.state.defaultInfo.phones[index]} />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" itemid={index} onClick={this.removeNewPhone}>Remove</button>
                                </div>
                            </div>
                        </div>

                    ))}

                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="email" name="email" id="email" className="form-control border-1 small  form-control-lg" placeholder="Email" maxlength="254" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="gstin" id="gstin" className="form-control border-1 small  form-control-lg" placeholder="GSTIN" maxlength="15" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row border">
                        <span class="m-2"><span class="btn-link" data-toggle="collapse" data-target="#interestsData" aria-expanded="false" aria-controls="interestsData" style={{ cursor: 'pointer' }}>Social</span></span>
                        <div class="collapse w-100 p-2" id="interestsData">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="instagram" id="instagram" className="form-control border-1 small  form-control-lg" placeholder="Instagram" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="facebook" id="facebook" className="form-control border-1 small  form-control-lg mt-3" placeholder="Facebook" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="whatsapp" id="whatsapp" className="form-control border-1 small  form-control-lg mt-3" placeholder="WhatsApp" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="website" id="website" className="form-control border-1 small  form-control-lg mt-3" placeholder="Website" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>

                    <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Billing Address</span>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress1" id="billingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress2" id="billingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressCity" id="billingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressState" id="billingAddressState" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>

                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressPincode" id="billingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressCountry" id="billingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Shipping Address</span>
                        <div class="input-group custom-control custom-checkbox ml-2">
                            <input type="checkbox" name="shippingBillingCheckBox" class="custom-control-input" id="customControlAutosizing" onChange={this.handleCheckBoxChange} />
                            <label class="custom-control-label" for="customControlAutosizing">Same as billing address</label>
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress1" id="shippingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress2" id="shippingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressCity" id="shippingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressState" id="shippingAddressState" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>

                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressPincode" id="shippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressCountry" id="shippingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    {/* <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Add Customers</span>
                        <div class="input-group p-2">
                            <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.customerSearchChangeHandle} />
                            <div class="input-group-append">
                                <input type="button" class="btn btn-secondary btn-sm" value="Add" />
                            </div>
                        </div>
                        <div class="w-100 p-2">
                            <this.SelectedCustomers />
                        </div>
                        <this.CustomerSearchResults />
                    </div> */}
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="coupon" id="coupon" className="coupon form-control border-1 small  form-control-lg" placeholder="Coupon" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="dataEntryType">Type</label>
                            </div>
                            <select class="custom-select" name="type" id="dataEntryType" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Data Type</option>
                                <option value="Online Re-Seller">Online Re-Seller</option>
                                <option value="Wholeseller">Wholeseller</option>
                                <option value="Personal Use">Personal Use</option>
                                <option value="Retailer">Retailer</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="dataEntryTag">Tag</label>
                            </div>
                            <select class="custom-select" name="tag" id="dataEntryTag" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Data Tag</option>
                                <option value="Placeholder">Placeholder</option>
                                <option value="Doubt">Doubt</option>
                                <option value="Fake">Fake</option>
                                <option value="Fraud">Fraud</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Handler</label>
                            </div>
                            <select class="custom-select" name="handler" id="inputGroupSelect01" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Handler</option>
                                <Handlers />
                            </select>
                        </div>
                    </div>


                    <div class="form-group row">
                        <button type="button" class="btn btn-primary" id="postDataButton" onClick={this.postData}>
                            <this.SubmitButtonLoadignSpinner />
                            <span>Submit</span>
                        </button>
                    </div>
                    <div class="form-group row">
                        <this.StatusString />
                    </div>
                </form>



            );
        }
        else {
            return (

                // <div class="card shadow mb-4">

                <form class="m-5">
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="firstname" id="firstname" className="firstname form-control border-1 small  form-control-lg" placeholder="Firstname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />

                            <input autoComplete="new-password" autocomplete="off" type="text" name="lastname" id="lastname" className="lastname form-control border-1 small  form-control-lg" placeholder="Lastname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="brand" id="brand" className="form-control border-1 small  form-control-lg" placeholder="Brand" maxlength="50" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <this.InterestSection />
                        {/* <input autoComplete="new-password" autocomplete="off" type="text" name="interests" id="interests" className="form-control border-1 small  form-control-lg" placeholder="Interests" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} /> */}
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="phone" id="phone" className="form-control border-1 small  form-control-lg" placeholder="Phone" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={this.addNewPhone}>Add</button>
                            </div>
                        </div>
                    </div>

                    {this.state.defaultInfo.phones.map((phone, index) => (
                        <div class="form-group row">
                            <div class="input-group">
                                <input autoComplete="new-password" autocomplete="off" type="text" name={`phones-` + index} id={`phones-` + index} className="form-control border-1 small  form-control-lg" placeholder={`Phone #` + parseInt(index + 1).toString()} maxlength="16" itemid={index} onKeyDown={this.handleKeyPress} onChange={this.handlePhonesChange} value={this.state.defaultInfo.phones[index]} />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" itemid={index} onClick={this.removeNewPhone}>Remove</button>
                                </div>
                            </div>
                        </div>

                    ))}

                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="email" name="email" id="email" className="form-control border-1 small  form-control-lg" placeholder="Email" maxlength="254" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="gstin" id="gstin" className="form-control border-1 small  form-control-lg" placeholder="GSTIN" maxlength="15" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row border">
                        <span class="m-2"><span class="btn-link" data-toggle="collapse" data-target="#interestsData" aria-expanded="false" aria-controls="interestsData" style={{ cursor: 'pointer' }}>Social</span></span>
                        <div class="collapse w-100 p-2" id="interestsData">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="instagram" id="instagram" className="form-control border-1 small  form-control-lg" placeholder="Instagram" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="facebook" id="facebook" className="form-control border-1 small  form-control-lg mt-3" placeholder="Facebook" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="whatsapp" id="whatsapp" className="form-control border-1 small  form-control-lg mt-3" placeholder="WhatsApp" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="website" id="website" className="form-control border-1 small  form-control-lg mt-3" placeholder="Website" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>

                    <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Billing Address</span>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress1" id="billingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress2" id="billingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>

                        <div class="input-group p-2">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                    {/* <this.SuggestionInput data={this.state.billingCities} placeholder="City" id="billingAddressCity" name="billingAddressCity" disabled={true} /> */}
                                    <this.SuggestionSelect data={this.state.billingCities} name="billingAddressCity" id="dataBillingAddressCity" what="City" defaultValue="" />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                    {/* <this.SuggestionInput data={this.state.billingStates} placeholder="State" id name="billingAddressState" disabled={true} /> */}
                                    <this.SuggestionSelect data={this.state.billingStates} name="billingAddressState" id="dataBillingAddressState" what="State" defaultValue="" />
                                </div>
                            </div>

                        </div>

                        <div class="input-group p-2">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                    <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressPincode" id="billingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                    <this.SuggestionSelect data={this.allCountries} name="billingAddressCountry" id="dataBillingAddressCountry" what="Country" defaultValue="India" />
                                    {/* <this.SuggestionInput data={this.allCountries} placeholder="Country" name="billingAddressCountry" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Shipping Address</span>
                        <div class="input-group custom-control custom-checkbox ml-2">
                            <input type="checkbox" name="shippingBillingCheckBox" class="custom-control-input" id="customControlAutosizing" checked={this.state.shippingBillingAddressSameBool} onChange={this.handleCheckBoxChange} />
                            <label class="custom-control-label" for="customControlAutosizing">Same as billing address</label>
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress1" id="shippingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress2" id="shippingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                    {/* {
                                            this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" value={this.state.defaultInfo.billingAddressCity} disabled /> : <this.SuggestionInput data={this.state.shippingCities} placeholder="City" name="shippingAddressCity" disabled={true} selectedOption={this.state.shippingAddressCitySelected} />
                                        } */}
                                    <this.SuggestionSelect data={this.state.shippingCities} name="shippingAddressCity" id="dataShippingAddressCity" what="City" defaultValue="" />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                    {/* {
                                            this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" value={this.state.defaultInfo.billingAddressState} disabled /> : <this.SuggestionInput data={this.state.shippingStates} placeholder="State" name="shippingAddressState" disabled={true} selectedOption={this.state.shippingAddressStateSelected} />
                                        } */}
                                    <this.SuggestionSelect data={this.state.shippingStates} name="shippingAddressState" id="dataShippingAddressState" what="State" defaultValue="" />

                                </div>
                            </div>

                        </div>

                        <div class="input-group p-2">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                    <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressPincode" id="shippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                    {/* {
                                            this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" value={this.state.defaultInfo.billingAddressCountry} disabled /> : <this.SuggestionInput data={this.allCountries} placeholder="Country" name="shippingAddressCountry" selectedOption={this.state.shippingAddressCountrySelected} />
                                        } */}
                                    <this.SuggestionSelect data={this.allCountries} name="shippingAddressCountry" id="dataShippingAddressCountry" what="Country" defaultValue="India" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="coupon" id="coupon" className="coupon form-control border-1 small  form-control-lg" placeholder="Coupon" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="dataEntryType">Type</label>
                            </div>
                            <select class="custom-select" name="type" id="dataEntryType" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Data Type</option>
                                <option value="Online Re-Seller">Online Re-Seller</option>
                                <option value="Wholeseller">Wholeseller</option>
                                <option value="Personal Use">Personal Use</option>
                                <option value="Retailer">Retailer</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="dataEntryTag">Tag</label>
                            </div>
                            <select class="custom-select" name="tag" id="dataEntryTag" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Data Tag</option>
                                <option value="Placeholder">Placeholder</option>
                                <option value="Doubt">Doubt</option>
                                <option value="Fake">Fake</option>
                                <option value="Fraud">Fraud</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Handler</label>
                            </div>
                            <select class="custom-select" name="handler" id="inputGroupSelect01" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Handler</option>
                                <Handlers />
                            </select>
                        </div>
                    </div>


                    <div class="form-group row">
                        <button type="button" class="btn btn-primary m-1" id="postDataButton" onClick={this.postData}>
                            <this.SubmitButtonLoadignSpinner />
                            <span>Submit</span>
                        </button>
                        <button type="button" class="btn btn-primary m-1" id="clearDataButton" onClick={this.resetFields}>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div class="form-group row">
                        <this.StatusString />
                    </div>
                </form>
                // </div>


            );
        }
    }
}

const defaultInfoValues = {
    "firstname": "",
    "lastname": "",
    "brand": "",
    "interests": "",
    "phone": "",
    "phones": [],
    "email": "",
    "gstin": "",
    "instagram": "",
    "facebook": "",
    "whatsapp": "",
    "website": "",
    "type": "",
    "tag": "",
    "billingAddressAddress1": "",
    "billingAddressAddress2": "",
    "billingAddressCity": "",
    "billingAddressState": "",
    "billingAddressPincode": "",
    "billingAddressCountry": "India",
    "shippingAddressAddress1": "",
    "shippingAddressAddress2": "",
    "shippingAddressCity": "",
    "shippingAddressState": "",
    "shippingAddressPincode": "",
    "shippingAddressCountry": "India",
    "handler": "",
    "handlerId": "",
}



class AllInquires extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            inquires: {},
            search_term: "",
            handler_id: "",
            initiator_id: "",
            initiator: "",
            page_number: 1,
            show: "",
            inquiry_selected_bool: false,
            selected_inquiry: {},
            selected_inquiry_id: "",
            fullscreenimageviewdialogimagerotationangle: 0,
            inquiry_push_order_bool: false,
            inquiry_edit_bool: false,

            editinquiry_title: "",
            editinquiry_description: "",
            editinquiry_images: [],
            editinquiry_initiator_id: "",
            editinquiry_initiator: "",
            editinquiry_images_extra: [],
            close_order_remarks: "",

            response: {},
            response_under_buttons: {},

            pushorder_inititator: "",
            pushorder_inititator_id: "",
            pushorder_handler: "",
            pushorder_orderid: 0,
            pushorder_amount: "0",
            pushorder_date: new Date(),
            pushorder_description: "",
            pushorder_to_id: "",
            pushorder_to: "",
            pushorder_from_id: "",
            pushorder_from: "",
            pushorder_shipfromownercheckbox: false,
            handlerId: { "name": "Select Handler", "lid": "Select Handler" }

        }
        this.pushorder_to_ref = React.createRef()
        this.typeahead_from = null;
        this.typeahead_to = null;
        this.typeahead_editorder = null;
        this.get_all_inquiries();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.get_all_inquiries(), 5000);
    }
    componentWillUnmount() {
        clearInterval(this.timer)
        this.timer = null; // here...
    }

    get_all_inquiries = () => {

        const that = this;
        var params = { page_number: this.state.page_number - 1, s: this.state.show }

        if (this.state.show.length > 0) {
            params["s"] = this.state.show;
        } else {
            delete params["s"]
        }

        if (this.state.search_term.length > 0) {
            params["st"] = this.state.search_term
        } else {
            delete params["st"];
        }

        if (this.state.initiator_id.length > 0) {
            params["ii"] = this.state.initiator_id
        } else {
            delete params["ii"];
        }

        if (this.state.handler_id.length > 0) {
            params["hi"] = this.state.handler_id
        } else {
            delete params["hi"];
        }


        axios.get(URL + "inquiry", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ "inquires": response["data"]["payload"] })
            })

    }
    get_single_inquiry = () => {

        const that = this;

        var params = { "_id": this.state.selected_inquiry_id }
        axios.get(URL + "inquiry", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ "selected_inquiry": response["data"]["payload"], "inquiry_selected_bool": true })
            })

    }


    Inquiries = () => {
        var inquires_ = []

        for (var i in this.state.inquires[0]) {
            inquires_.push(
                <a class="inquiry_item list-group-item d-flex justify-content-between align-items-center" lid={this.state.inquires[0][i]._id.$oid} onClick={(e) => {
                    const lid_ = e.target.getAttribute("lid")
                    this.setState({ selected_inquiry: true, selected_inquiry_id: lid_ }, this.get_single_inquiry)
                }}><span lid={this.state.inquires[0][i]._id.$oid}><b lid={this.state.inquires[0][i]._id.$oid}>{this.state.inquires[0][i].initiator}</b><br />{this.state.inquires[0][i].title}</span><span lid={this.state.inquires[0][i]._id.$oid}>{moment(this.state.inquires[0][i]["_created"].$date).format('D MMM')}</span></a>
            )
        }

        return (
            <div class="list-group">
                {/* <this.SectionHeader /> */}
                {/* <this.SectionFooter /> */}
                {inquires_}
            </div>
        )
    }

    rotate_image = () => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialogimage').style.transform = angle;
        })
    }

    FullScreenImageView() {
        return (
            <div id="fullscreenimageviewdialog" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog") {
                        $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog').attr('hidden', 'hidden') }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialogimage" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }
    ImageView = (e) => {

        if (this.state.selected_inquiry.images.length > 0) {
            var ret = []

            // add image tags
            for (var i in this.state.selected_inquiry.images) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.selected_inquiry.images[i]["image"]} class="card-img-top" id={`img-` + this.state.selected_inquiry.images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                    </div>
                )
            }

            // acquire images
            for (var i in this.state.selected_inquiry.images) {
                const that = this;

                const _id = this.state.selected_inquiry.images[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);

                        }
                        console.log("image added")
                    })
            }


            return (
                <div class="card" >
                    <div class="card-body">
                        <h5 class="card-title">Images</h5>
                        <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
                        <div class="d-flex flex-wrap align-items-center">
                            {/* {ret} */}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <span></span>
        )

    }

    ImageViewEdit = () => {
        var ret = []
        if ((this.state.editinquiry_images.length + this.state.editinquiry_images_extra.length) > 0) {

            // add image tags
            for (var i in this.state.editinquiry_images) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.editinquiry_images[i]["image"]} class="card-img-top" id={`img-` + this.state.editinquiry_images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid_ = e.target.getAttribute("iid")
                            var tmp_editinquiry_images = this.state.editinquiry_images;
                            tmp_editinquiry_images.splice(parseInt(iid_), 1);
                            this.setState({ editinquiry_images: tmp_editinquiry_images });
                        }}> Delete</i>
                    </div>
                )
            }

            // aquire images
            for (var i in this.state.editinquiry_images) {
                const that = this;

                const _id = this.state.editinquiry_images[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);

                        }
                        console.log("image added")
                    })
            }


            for (var i in this.state.editinquiry_images_extra) {
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img iid={i} src={this.state.editinquiry_images_extra[i]} class="card-img-top" onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.editinquiry_images_extra;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}> Delete</i>
                    </div>
                )
            }

        }

        var help_text = ""
        if (this.state.editinquiry_images.length + this.state.editinquiry_images_extra.length > 0) {

            help_text = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Edit Images</h5>
                    {help_text}
                    <div class="d-flex flex-wrap align-items-center">
                        {ret}
                    </div>
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile2').click();
                    }}>Add Image(s)</button>
                    {/* {clear_all_button} */}
                    <input type="file" class="form-control-file" id="exampleFormControlFile2" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.editinquiry_images_extra;

                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ editinquiry_images_extra: tmp_images })
                                document.getElementById('exampleFormControlFile2').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )


    }

    Dialog(props) {
        return (
            <div class="modal fade" id={props.label} tabindex="-1" role="dialog" aria-labelledby={props.label} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{props.h_text}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{props.msg}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" class={`btn ` + props.c_text} onClick={props.handler} data-dismiss="modal">{props.b_label}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    handle_close = () => {
        const that = this;

        var params = { ut: "c" }

        axios.patch(URL + "inquiry", {
            _id: this.state.selected_inquiry_id,
            remarks: this.state.close_order_remarks
        }, {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response_under_buttons: response["data"] })
            })
    }


    handle_delete = () => {
        const that = this;

        var params = { _id: this.state.selected_inquiry_id }
        axios.delete(URL + "inquiry", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response_under_buttons: response["data"] })
            })
    }

    SelectedInquiry = () => {

        var delete_button = <h2 class="m-2">
            <i class="far fa-trash-alt inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Delete Inquiry" data-toggle="modal" data-target="#deleteInquiryDialog"></i>
        </h2>
        if (TP !== "owner") {
            delete_button = ""
        }



        var status_under_buttons = ""
        if ("_status" in this.state.response_under_buttons && this.state.response_under_buttons["_status"] === "success") {

            status_under_buttons = <div class="alert alert-success mt-2" role="alert">
                {this.state.response_under_buttons["payload"]}
            </div>

        }
        else if ("_status" in this.state.response_under_buttons && this.state.response_under_buttons["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response_under_buttons["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response_under_buttons.payload[0][i]}<br /></span>
                );
            }

            status_under_buttons = <div class="alert alert-danger mt-2" role="alert">
                {/* Some Error Has Occurred. */}
                {ret}
            </div>

        }
        // prepare image view
        var image_view = []

        if (this.state.selected_inquiry.images.length > 0) {
            var ret = []

            // add image tags
            for (var i in this.state.selected_inquiry.images) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.selected_inquiry.images[i]["image"]} class="card-img-top" id={`img-` + this.state.selected_inquiry.images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                    </div>
                )
            }

            // acquire images
            for (var i in this.state.selected_inquiry.images) {
                const that = this;

                const _id = this.state.selected_inquiry.images[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);

                        }
                        console.log("image added")
                    })
            }


            image_view = <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Images</h5>
                    <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
                    <div class="d-flex flex-wrap align-items-center">
                        {ret}
                    </div>
                </div>
            </div>

        } else {
            image_view = <span></span>
        }


        return [
            <div className="container pr-4 pl-4 pb-2 pt-2">
                <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {
                    if (this.state.inquiry_selected_bool) {
                        this.setState({ inquiry_selected_bool: false, response_under_buttons: {} }, this.get_all_inquiries)
                    }
                    else {
                        this.setState({ inquiry_selected_bool: true, response_under_buttons: {} }, this.get_all_inquiries)
                    }
                }}><i class="fas fa-arrow-left"></i> Back</span></span>
            </div>,
            <div class="container pr-4 pl-4">
                <div class="form-group row">
                    <h2 class="m-2">
                        <i class="fas fa-arrow-circle-up inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Push To Orders" onClick={() => {
                            const desc_ = this.state.selected_inquiry.description;
                            const init_ = this.state.selected_inquiry.initiator;
                            const handler_ = this.state.selected_inquiry.handler;
                            this.reset_push_order();
                            this.setState({ pushorder_description: desc_, inquiry_push_order_bool: true, inquiry_selected_bool: false, pushorder_inititator: init_, pushorder_handler: handler_ })
                        }}></i>
                    </h2>
                    <h2 class="m-2">
                        <i class="fas fa-edit inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Edit Inquiry" onClick={() => {
                            const desc_ = this.state.selected_inquiry.description;
                            const title_ = this.state.selected_inquiry.title;
                            const init_ = this.state.selected_inquiry.initiator;
                            const init_id = this.state.selected_inquiry.initiator_id;
                            const images_ = this.state.selected_inquiry.images;
                            this.setState({ editinquiry_images_extra: [], editinquiry_images: images_, editinquiry_initiator_id: init_id, editinquiry_initiator: init_, editinquiry_description: desc_, editinquiry_title: title_, inquiry_edit_bool: true, inquiry_selected_bool: false })
                        }}></i>
                    </h2>
                    <h2 class="m-2">
                        <i class="far fa-times-circle inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" data-toggle="modal" data-target="#closeInquiryDialog" title="Close Inquiry"></i>
                    </h2>
                    {delete_button}
                </div>
                <div class="form-group">
                    {status_under_buttons}
                </div>
                <div class="form-group">
                    {/* <label for="selectedIquiryTitle"><b>Title</b></label> */}
                    <h3 id="selectedIquiryTitle">{this.state.selected_inquiry.title}</h3>
                </div>
                <div class="form-group">
                    <label for="selectedIquiryInitiator"><b>Initiator</b></label>
                    <p style={{ whiteSpace: "pre-wrap" }}>{this.state.selected_inquiry.initiator}</p>
                </div>
                <div class="form-group">
                    <label for="selectedIquiryDescription"><b>Created On</b></label>
                    <p>{moment(this.state.selected_inquiry._created.$date).format('Do MMMM YYYY, h:mm:ss A')}</p>
                </div>
                <div class="form-group">

                    {
                        this.state.selected_inquiry.description.length > 0 ? [<label for="selectedIquiryDescription"><b>Description</b></label>, <p style={{ whiteSpace: "pre-wrap" }}>{this.state.selected_inquiry.description}</p>] : ""
                    }

                </div>
                <div class="form-group">
                    {image_view}
                </div>
            </div>,
            <this.FullScreenImageView />,
            <this.Dialog name="close" msg="Close Inquiry." h_text="Close Inquiry" c_text="btn-primary" b_label="Close" handler={this.handle_close} label="closeInquiryDialog" />,
            <this.Dialog name="delete" msg="Delete Inquiry." h_text="Delete Inquiry" c_text="btn-danger" b_label="Delete" handler={this.handle_delete} label="deleteInquiryDialog" />
        ]
    }

    handle_order_push = (e) => {
        $("#pushorderbutton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Add Order")
        // if (this.state.selectedCustomerID === "" && this.state.shipFromOwnerCheckbox) {
        //     to_id = transactDialogLid
        // } else {
        //     to_id = this.state.selectedCustomerID
        // }
        const that = this;
        axios.post(URL + "orders", {
            amount: this.state.pushorder_amount,
            date: this.state.pushorder_date,
            to_id: this.state.pushorder_to_id,
            to: "",
            amountstate: "",
            init_id: this.state.selected_inquiry.initiator_id,
            description: this.state.pushorder_description,
            from_id: this.state.pushorder_from_id,
            from: "",
            handler: "",
            orderid: this.state.pushorder_orderid.toString(),
            handlerId: this.state.handlerId["lid"],
            orderstate: "",
            tracking: "",
            shipFromOwnerCheckbox: this.state.pushorder_shipfromownercheckbox

        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                $("#pushorderbutton").html("Add Order")
                that.setState({ response: response["data"] })
            })

    }

    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }

    Selected_to() {
        if (this.state.pushorder_to.length > 0) {
            return (
                <div class="form-group p-1">
                    <b>Selected To: </b>{this.state.pushorder_to} <this.Selected_to_reset />
                </div>
            )
        }
        else {
            return (
                ""
            )
        }
    }
    Selected_to_reset(e) {
        if (this.state.pushorder_to_id === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ pushorder_to_id: "", pushorder_to: "" });
                    this.typeahead_to.getInstance().clear()
                }}></i>
            )
        }

    }
    Selected_from() {
        if (this.state.pushorder_from.length > 0) {
            return (
                <div class="form-group p-1">
                    <b>Selected From: </b>{this.state.pushorder_from} <this.Selected_from_reset />
                </div>
            )
        }
        else {
            return (
                ""
            )
        }
    }
    Selected_from_reset (e) {
        if (this.state.pushorder_from_id === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ pushorder_from_id: "", pushorder_from: "" });
                    this.typeahead_from.getInstance().clear()
                }}></i>
            )
        }

    }

    reset_push_order = (e) => {
        this.setState({
            // pushorder_inititator: "",
            // pushorder_inititator_id: "",
            // pushorder_handler: "",
            pushorder_orderid: 0,
            pushorder_amount: "0",
            pushorder_date: new Date(),
            pushorder_description: "",
            pushorder_to_id: "",
            pushorder_to: "",
            pushorder_from_id: "",
            pushorder_from: "",
            pushorder_shipfromownercheckbox: false,
            handlerId: { "name": "Select Handler", "lid": "Select Handler" }
        })
        if (this.typeahead_to !== null && this.typeahead_from !== null) {
            this.typeahead_from.getInstance().clear()
            this.typeahead_to.getInstance().clear()
        }

    }

    Status(e) {
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            return (
                <div class="alert alert-success mt-2" role="alert">
                    Data Added. {this.state.response["payload"]}
                </div>
            );
        }
        else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div class="alert alert-danger mt-2" role="alert">
                    Some Error Has Occurred.
                    {/* {ret} */}
                </div>
            );
        }
        return ("");
    }

    Status_under_buttons(e) {
        if ("_status" in this.state.response_under_buttons && this.state.response_under_buttons["_status"] === "success") {
            return (
                <div class="alert alert-success mt-2" role="alert">
                    {this.state.response_under_buttons["payload"]}
                </div>
            );
        }
        else if ("_status" in this.state.response_under_buttons && this.state.response_under_buttons["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response_under_buttons["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response_under_buttons.payload[0][i]}<br /></span>
                );
            }
            return (
                <div class="alert alert-danger mt-2" role="alert">
                    {/* Some Error Has Occurred. */}
                    {ret}
                </div>
            );
        }
        return ("");
    }

    PushOrder = (e) => {

        var handler_div = []
        if (TP === "owner") {
            handler_div.push(
                <div class="form-group p-1">
                    <label for="pushorder_handler"><b>Handler</b></label>
                    <select class="custom-select" id="pushorder_handler" defaultValue={this.state.handlerId["name"]} value={this.state.handlerId["name"]} onChange={(e) => {
                        var index = e.target.selectedIndex;
                        const h_lid = e.target[index].getAttribute("lid");
                        const tmp_d = { "name": e.target.value, "lid": h_lid }
                        this.setState({ handlerId: tmp_d }, () => {
                            console.log(this.state.handlerId)
                        })
                    }}>
                        <option lid="Select Handler" value="Select Handler">Select Handler</option>
                        <Handlers handler={this.state.handlerId["name"]} />
                    </select>
                </div>
            )
        }

        var selected_to = ""
        var selected_to_reset = ""
        if (this.state.pushorder_to_id === "") {
            selected_to_reset = ""
        }
        else {
            selected_to_reset = <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ pushorder_to_id: "", pushorder_to: "" });
                    this.typeahead_to.getInstance().clear()
                }}></i>
        }

        if (this.state.pushorder_to.length > 0) {
            selected_to = <div class="form-group p-1">
                <b>Selected To: </b>{this.state.pushorder_to} {selected_to_reset}
            </div>
        }

        var selected_from = ""
        var selected_from_reset = ""
        if (this.state.pushorder_from_id === "") {
            selected_from_reset =  ""
        }
        else {
            selected_from_reset = <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ pushorder_from_id: "", pushorder_from: "" });
                    this.typeahead_from.getInstance().clear()
                }}></i>
        }

        if (this.state.pushorder_from.length > 0) {
            selected_from = <div class="form-group p-1">
                    <b>Selected From: </b>{this.state.pushorder_from} {selected_from_reset}
                </div>
            
        }

        var status = []

        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            status = <div class="alert alert-success mt-2" role="alert">
                    Data Added. {this.state.response["payload"]}
                </div>
        }
        else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            // var ret = []
            // for (var i in this.state.response["payload"][0]) {
            //     // console.log(this.state.response.payload[0][i])
            //     ret.push(
            //         <span>{i}: {this.state.response.payload[0][i]}<br /></span>
            //     );
            // }
            status = <div class="alert alert-danger mt-2" role="alert">
                    Some Error Has Occurred.
                    {/* {ret} */}
                </div>
        }


        return [
            <div className="container pr-4 pl-4 pb-2 pt-2">
                <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                    this.setState({ inquiry_selected_bool: true, inquiry_push_order_bool: false })


                }}><i class="fas fa-arrow-left"></i> Back</span></span>
            </div>,
            <div class="container pr-4 pl-4">
                <div class="d-flex flex-row">
                    <div class="form-group p-1">
                        <label for="pushorder_initiator"><b>Initiator</b></label><br />
                        <span class="pushorder_initiator">{this.state.pushorder_inititator}</span>
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <div class="form-group p-1">
                        <label for="pushorder_handler"><b>Handler</b></label><br />
                        <span class="pushorder_handler">{this.state.pushorder_handler}</span>
                    </div>
                </div>
                <div class="d-flex flex-row">

                    <div class="form-group p-1">
                        <label for="pushorder_orderid"><b>Order ID</b></label>
                        <input type="number" class="form-control" id="pushorder_orderid" name="orderid" value={this.state.pushorder_orderid} onChange={(e) => {
                            this.setState({ pushorder_orderid: e.target.value })
                        }} />
                    </div>
                    {handler_div}
                </div>


                <div class="d-flex flex-row">
                    <div class="form-group p-1">
                        <label for="pushorder_amount"><b>Amount</b></label>
                        <input type="number" class="form-control" id="pushorder_amount" name="amount" value={this.state.pushorder_amount} onChange={(e) => {
                            this.setState({ pushorder_amount: e.target.value })
                        }} />
                    </div>
                    <div class="form-group p-1">
                        <label for="pushorder_date"><b>Date</b></label><br />
                        <DatePicker
                            id="pushorder_date"
                            selected={this.state.pushorder_date}
                            onChange={(date) => {
                                this.setState({ pushorder_date: date });
                            }}
                            dateFormat="dd/MM/yyyy"
                            className="form-control w-100"
                        />
                    </div>
                </div>
                <div class="form-group p-1">
                    <label for="pushorder_description"><b>Description</b></label>
                    <textarea rows="3" class="form-control" id="pushorder_description" name="description" value={this.state.pushorder_description} onChange={(e) => {
                        this.setState({ pushorder_description: e.target.value })
                    }} />
                </div>
                <div class="form-group p-1">
                    <label for="pushorder_shipto"><b>Ship To</b></label>
                    <AsyncTypeahead type="text" placeholder="Search" name="to" id="pushorder_shipto"
                        labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                        filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                        onSearch={this.handle_search}
                        {...this.state.asyncsearch}
                        maxResults="10"
                        ref={(typeahead) => this.typeahead_to = typeahead}
                        renderMenu={(results, menuProps) => (
                            <Menu {...menuProps} className="search_menu" id="search_menu">
                                {results.map((result, index) => (
                                    <MenuItem option={result} position={index} className="search_item m-0">
                                        <div className="w-100 p-2 search_item" onClick={(e) => {
                                            //   $("#search_menu").hide();
                                            this.setState({ pushorder_to_id: result._id.$oid, pushorder_to: `${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}` })
                                        }}>

                                            {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                        </div>
                                    </MenuItem>
                                ))}
                            </Menu>
                        )}
                    />
                </div>
                {/* <this.Selected_to /> */}
                {selected_to}
                <div class="form-group p-1">
                    <label for="pushorder_shipfrom"><b>Ship From</b></label>
                    <AsyncTypeahead type="text" placeholder="Search" name="to" id="pushorder_shipfrom"
                        labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                        filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                        onSearch={this.handle_search}
                        {...this.state.asyncsearch}
                        maxResults="10"
                        ref={(typeahead) => this.typeahead_from = typeahead}
                        renderMenu={(results, menuProps) => (
                            <Menu {...menuProps} className="search_menu" id="search_menu">
                                {results.map((result, index) => (
                                    <MenuItem option={result} position={index} className="search_item m-0">
                                        <div className="w-100 p-2 search_item" onClick={(e) => {
                                            //   $("#search_menu").hide();
                                            this.setState({ pushorder_from_id: result._id.$oid, pushorder_from: `${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}` })
                                        }}>

                                            {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                        </div>
                                    </MenuItem>
                                ))}
                            </Menu>
                        )}
                    />
                </div>
                {/* <this.Selected_from /> */}
                {selected_from}
                <div class="form-group m-0 p-1">
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" defaultChecked={this.state.pushorder_shipfromownercheckbox} id="pushorder_shipfromownercheckbox" onChange={(e) => {
                            if (e.target.checked) {
                                this.setState({ pushorder_shipfromownercheckbox: true })
                            } else {
                                this.setState({ pushorder_shipfromownercheckbox: false })
                            }
                        }} />
                        <label class="" for="pushorder_shipfromownercheckbox">Ship From Owner</label>
                    </div>
                </div>
                <div class="form-group">
                    <button type="button" id="pushorderbutton" class="btn btn-primary m-2" onClick={this.handle_post_inquiry} data-toggle="modal" data-target="#pushOrderInquiryDialog">Add Order</button>
                    <button type="button" id="resetpushorderbutton" class="btn btn-primary m-2" onClick={this.reset_push_order}>Reset Order</button>

                </div>
                <div class="form-group">
                    {/* <this.Status /> */}
                    {status}
                </div>
            </div>,
            <this.Dialog name="push" msg="Push Inquiry Into Order." h_text="Add Order" c_text="btn-primary" b_label="Add Order" handler={this.handle_order_push} label="pushOrderInquiryDialog" />
        ]
    }

    Selected_initiator() {
        if (this.state.editinquiry_initiator_id.length > 0) {
            return (
                <div class="form-group">
                    <b>Selected Initiator: </b>{this.state.editinquiry_initiator} <this.Selected_initiator_reset />
                </div>
            )
        }
        else {
            return (
                ""
            )
        }
    }


    Selected_initiator_reset(e) {
        if (this.state.editinquiry_initiator_id === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ editinquiry_initiator_id: "", editinquiry_initiator: "" });
                    this.typeahead_editorder.getInstance().clear()
                }}></i>
            )
        }

    }

    Selected_initiator_reset_ = (e) => {
        if (this.state.initiator_id === "") {
            return (
                ""
            )
        }
        else {
            return (
                <div className="p-2">
                    {this.state.initiator} <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                        this.setState({ selected_inquiry_id: "", initiator_id: "" }, this.get_all_inquiries);
                        this.typeahead.getInstance().clear()
                    }}></i>
                </div>
            )
        }

    }


    handle_edit = (e) => {

        const that = this;

        var tmp_images = []

        if (this.state.editinquiry_images_extra.length > 0) {
            for (var i in this.state.editinquiry_images_extra) {
                tmp_images.push(
                    {
                        "image": this.state.editinquiry_images_extra[i].split(",")[1],
                        "alt": ""
                    }
                )
            }
        }

        var params = { ut: "u" }

        axios.patch(URL + 'inquiry', {
            _id: this.state.selected_inquiry_id,
            initiator_id: this.state.editinquiry_initiator_id,
            title: this.state.editinquiry_title,
            description: this.state.editinquiry_description,
            extra_images: tmp_images,
            images: this.state.editinquiry_images
        }, {
            params: params,
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ response_under_buttons: response["data"] })
            })
    }

    EditInquiry = (e) => {

        var status_under_buttons = ""
        if ("_status" in this.state.response_under_buttons && this.state.response_under_buttons["_status"] === "success") {
            status_under_buttons = <div class="alert alert-success mt-2" role="alert">
                    {this.state.response_under_buttons["payload"]}
                </div>
        }
        else if ("_status" in this.state.response_under_buttons && this.state.response_under_buttons["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response_under_buttons["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response_under_buttons.payload[0][i]}<br /></span>
                );
            }
            status_under_buttons = <div class="alert alert-danger mt-2" role="alert">
                    {/* Some Error Has Occurred. */}
                    {ret}
                </div>
        }

        var selected_initiator = ""
        var seleceted_initiator_reset = ""
        if (this.state.editinquiry_initiator_id === "") {
            seleceted_initiator_reset = ""
        }
        else {
            seleceted_initiator_reset = <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ editinquiry_initiator_id: "", editinquiry_initiator: "" });
                    this.typeahead_editorder.getInstance().clear()
                }}></i>
        }

        if (this.state.editinquiry_initiator_id.length > 0) {
            selected_initiator = <div class="form-group">
                    <b>Selected Initiator: </b>{this.state.editinquiry_initiator} {seleceted_initiator_reset}
                </div>
            
        }
        

        var image_edit = []

        var ret = []
        if ((this.state.editinquiry_images.length + this.state.editinquiry_images_extra.length) > 0) {

            // add image tags
            for (var i in this.state.editinquiry_images) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.editinquiry_images[i]["image"]} class="card-img-top" id={`img-` + this.state.editinquiry_images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid_ = e.target.getAttribute("iid")
                            var tmp_editinquiry_images = this.state.editinquiry_images;
                            tmp_editinquiry_images.splice(parseInt(iid_), 1);
                            this.setState({ editinquiry_images: tmp_editinquiry_images });
                        }}> Delete</i>
                    </div>
                )
            }

            // aquire images
            for (var i in this.state.editinquiry_images) {
                const that = this;

                const _id = this.state.editinquiry_images[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);

                        }
                        console.log("image added")
                    })
            }


            for (var i in this.state.editinquiry_images_extra) {
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img iid={i} src={this.state.editinquiry_images_extra[i]} class="card-img-top" onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.editinquiry_images_extra;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}> Delete</i>
                    </div>
                )
            }

        }

        var help_text = ""
        if (this.state.editinquiry_images.length + this.state.editinquiry_images_extra.length > 0) {

            help_text = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
        }

        image_edit = <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Edit Images</h5>
                    {help_text}
                    <div class="d-flex flex-wrap align-items-center">
                        {ret}
                    </div>
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile2').click();
                    }}>Add Image(s)</button>
                    {/* {clear_all_button} */}
                    <input type="file" class="form-control-file" id="exampleFormControlFile2" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.editinquiry_images_extra;

                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ editinquiry_images_extra: tmp_images })
                                document.getElementById('exampleFormControlFile2').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        

        return [    
            <div className="container pr-4 pl-4 pb-2 pt-2">
                <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                    this.setState({ inquiry_selected_bool: true, inquiry_edit_bool: false, response_under_buttons: {} })


                }}><i class="fas fa-arrow-left"></i> Back</span></span>
            </div>,
            <div class="container p-2">
                {/* <this.Status_under_buttons /> */}
                {status_under_buttons}
                <form>
                    <div class="form-group ">
                        <label for="addinquiry_initiator"><b>Initiator (If Exists)</b></label>
                        <AsyncTypeahead type="text" placeholder="Search" name="to"

                            labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                            filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                            onSearch={this.handle_search}
                            {...this.state.asyncsearch}
                            maxResults="10"
                            ref={(typeahead) => this.typeahead_editorder = typeahead}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps} className="search_menu" id="search_menu">
                                    {results.map((result, index) => (
                                        <MenuItem option={result} position={index} className="search_item m-0">
                                            <div className="w-100 p-2 search_item" onClick={(e) => {
                                                //   $("#search_menu").hide();
                                                this.setState({ editinquiry_initiator_id: result._id.$oid, editinquiry_initiator: `${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}` })
                                            }}>

                                                {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        />


                    </div>
                    {/* <this.Selected_initiator /> */}
                    {selected_initiator}

                    <div class="form-group">
                        <label for="addinquiry_title"><b>Title</b></label>
                        <input type="text" class="form-control" id="addinquiry_title" name="title" value={this.state.editinquiry_title} onChange={(e) => {
                            this.setState({ editinquiry_title: e.target.value })
                        }} />
                    </div>
                    <div class="form-group">
                        <label for="addinquiry_description"><b>Description</b></label>
                        <textarea class="form-control" id="addinquiry_description" rows="3" value={this.state.editinquiry_description} onChange={(e) => { this.setState({ editinquiry_description: e.target.value }) }}></textarea>
                    </div>
                    <div class="form-group">
                        <this.ImageUploader />
                        <div class="form-group">
                            {/* <this.ImageViewEdit /> */}
                            {image_edit}
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" class="btn btn-primary m-2" data-toggle="modal" data-target="#editInquiryDialog">Edit Inquiry</button>
                        {/* <this.Clear_all_button /> */}
                    </div>
                    <div class="form-group">
                        {/* <this.Status /> */}
                    </div>
                </form>

            </div>,
            <this.FullScreenImageView />,
            <this.Dialog name="edit" msg="Edit Inquiry." h_text="Edit Inquiry" c_text="btn-primary" b_label="Edit" handler={this.handle_edit} label="editInquiryDialog" />
        ]
    }

    SectionHeaderSearch = (e) => {
        return (
            <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search inquires" placeholder="Search Inquiries" value={this.state.search_term} onChange={(e) => {
                this.setState({ search_term: e.target.value }, () => {
                    this.setState({ page_number: 1 }, this.get_all_inquiries)
                })
            }} />
        )
    }

    Section_header_show = (e) => {
        return (
            <select id="sectionHeaderShowSelect" class="custom-select w-100" defaultValue={this.state.show} onChange={(e) => {
                this.setState({ "show": e.target.value, "page_number": 1 }, this.get_all_inquiries)
            }}>
                <option value="r" selected>Running</option>
                <option value="a">All</option>
                <option value="c">Closed</option>
            </select>
        )
    }

    Section_header_search_reset = (e) => {
        if (this.state.search_term === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ search_term: "" }, this.get_all_inquiries);
                }}></i>
            )
        }

    }

    SectionHeader = (e) => {
        var section_header_search_reset = <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
            this.setState({ search_term: "" }, this.get_all_inquiries);
        }}></i>
        if (this.state.search_term === "") {
            section_header_search_reset = ""
        }

        var selected_initiator_reset_ = <div className="p-2">
            {this.state.initiator} <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                this.setState({ selected_inquiry_id: "", initiator_id: "" }, this.get_all_inquiries);
                this.typeahead.getInstance().clear()
            }}></i>
        </div>

        if (this.state.initiator_id === "") {
            selected_initiator_reset_ = ""
        }


        var handler_span = []
        var search_span = <span class="d-inline-flex col-sm-12 col-xl-12 col-lg-12 p-1">
            <span class="d-inline-flex align-items-center w-100">
                {/* <label class="my-1 ml-2 mr-2" for="inlineFormCustomSelectPref">Search:</label> */}
                {/* <this.SectionHeaderSearch /> */}
                <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search inquires" placeholder="Search Inquiries" value={this.state.search_term} onChange={(e) => {
                    this.setState({ search_term: e.target.value }, () => {
                        this.setState({ page_number: 1 }, this.get_all_inquiries)
                    })
                }} />
                {section_header_search_reset}
            </span>
        </span>
        if (TP === "owner") {
            handler_span.push(
                <span class="d-inline-flex col-sm-12 col-xl-6 col-lg-6 p-1">
                    <span class="d-inline-flex align-items-center w-100">
                        {/* <label class="my-1 mr-2" for="inputGroupSelectHandlerSectionHeader">Handler:</label> */}
                        <select class="custom-select w-100" name="handler" id="inputGroupSelectHandlerSectionHeader" onChange={(e) => {
                            // const handler_ = e.target.value
                            var index = e.target.selectedIndex;
                            const h_lid = e.target[index].getAttribute("lid");
                            this.setState({ handler_id: h_lid }, this.get_all_inquiries)
                        }}>
                            <option selected lid="" value="">All</option>
                            <Handlers />
                        </select>
                    </span>
                </span>
            )
            search_span = <span class="d-inline-flex col-sm-12 col-xl-6 col-lg-6 p-1">
                <span class="d-inline-flex align-items-center w-100">
                    {/* <label class="my-1 ml-2 mr-2" for="inlineFormCustomSelectPref">Search:</label> */}
                    {/* <this.SectionHeaderSearch /> */}
                    <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search inquires" placeholder="Search Inquiries" value={this.state.search_term} onChange={(e) => {
                        this.setState({ search_term: e.target.value }, () => {
                            this.setState({ page_number: 1 }, this.get_all_inquiries)
                        })
                    }} />
                    {/* <this.Section_header_search_reset /> */}
                    {section_header_search_reset}
                </span>
            </span>
        }

        return (
            <div>
                <div className="col-sm-12 col-xl-12 col-lg-12 p-0">
                    <div className="row">
                        <div className="col-sm-12 col-xl-6 col-lg-6 p-1">
                            <div className="">
                                <AsyncTypeahead type="text" placeholder="Search Initiator" name="to"
                                    labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                                    filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                                    onSearch={this.handle_search}
                                    {...this.state.asyncsearch}
                                    maxResults="10"

                                    ref={(typeahead) => this.typeahead = typeahead}
                                    renderMenu={(results, menuProps) => (
                                        <Menu {...menuProps} className="search_menu" id="search_menu">
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} className="search_item m-0">
                                                    <div className="w-100 p-2 search_item" onClick={(e) => {
                                                        //   $("#search_menu").hide();
                                                        this.setState({ initiator_id: result._id.$oid, initiator: `${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}` }, () => {
                                                            this.get_all_inquiries();
                                                            this.typeahead.getInstance().clear();
                                                        })
                                                    }}>

                                                        {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    )}
                                />
                                {/* <this.Selected_initiator_reset_ /> */}
                                {selected_initiator_reset_}
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-6 col-lg-6 p-1">
                            {/* <this.Section_header_show /> */}
                            <select id="sectionHeaderShowSelect" class="custom-select w-100" defaultValue={this.state.show} onChange={(e) => {
                                this.setState({ "show": e.target.value, "page_number": 1 }, this.get_all_inquiries)
                            }}>
                                <option value="r" selected>Running</option>
                                <option value="a">All</option>
                                <option value="c">Closed</option>
                            </select>
                        </div>

                    </div>
                </div >
                <div className="row">
                    {handler_span}
                    {search_span}
                </div>
            </div >

        );

    }

    handle_pagination_click = (e) => {
        var page_number = e.target.getAttribute("name")
        this.setState({ "page_number": parseInt(page_number) }, this.get_all_inquiries)

    }

    SectionFooter = (e) => {

        const total_pages = this.state.inquires[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.page_number}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ "page_number": parseInt(pageNumber) }, this.get_all_inquiries)
                        }}
                    />
                </div>
            </div>

        )

    }

    render() {

        var ret = ""
        if (this.state.inquiry_selected_bool) {
            return (
                <this.SelectedInquiry />
            )
        }
        else if (this.state.inquiry_push_order_bool) {
            return (
                <this.PushOrder />
            )
        }
        else if (this.state.inquiry_edit_bool) {
            return (
                <this.EditInquiry />
            )
        }
        else {
            return (
                <div class="container p-4">
                    <this.SectionHeader />
                    <this.SectionFooter />
                    <this.Inquiries />
                </div>
            )
        }
    }
}

class AddInquiry extends React.Component {

    constructor(props) {
        super(props)
        this.state = ({
            images: [],
            initiator: "",
            initiator_id: "",
            description: "",
            title: "",
            add_database_entry_bool: false,
            asyncsearch: {},
            response: {}
        })
    }

    FullScreenImageView = (e) => {
        return (
            <div id="fullscreenimageviewdialog1" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog1") {
                        $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog1').attr('hidden', 'hidden') }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialog1image" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }

    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialog1image').style.transform = angle;
        })
    }

    ImageUploader = (e) => {

        var img_ = []
        for (var i in this.state.images) {
            console.log(i)
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} src={this.state.images[i]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <i class="fas fa-times" iid={i} onClick={(e) => {
                        var iid = e.target.getAttribute('iid');
                        var tmp_images = this.state.images;
                        tmp_images.splice(parseInt(iid), 1)
                        this.setState({ images: tmp_images })
                    }}> Delete</i>
                </div>
            )
        }


        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Add Images</h5>
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>

                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    handle_post_inquiry = (e) => {
        const that = this;

        var tmp_images = []

        if (this.state.images.length > 0) {
            for (var i in this.state.images) {
                tmp_images.push(
                    {
                        "image": this.state.images[i].split(",")[1],
                        "alt": ""
                    }
                )
            }
        }
        $("#add_inquiry_button").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Add Inquiry")
        axios.post(URL + 'inquiry', {
            initiator_id: this.state.initiator_id,
            title: this.state.title,
            description: this.state.description,
            images: tmp_images
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                $("#add_inquiry_button").html("Add Inquiry")
                that.setState({ response: response["data"] })
            })
    }

    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }

    Selected_initiator = () => {
        if (this.state.initiator_id.length > 0) {
            return (
                <div class="form-group">
                    <b>Selected Initiator: </b>{this.state.initiator} <this.Selected_initiator_reset />
                </div>
            )
        }
        else {
            return (
                ""
            )
        }
    }


    Selected_initiator_reset = (e) => {
        if (this.state.initiator_id === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ initiator_id: "", initiator: "" });
                    this.typeahead.getInstance().clear()
                }}></i>
            )
        }

    }

    Status = (e) => {
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }

    handle_clear_all = (e) => {
        this.setState({
            images: [],
            initiator: "",
            initiator_id: "",
            description: "",
            title: "",
            add_database_entry_bool: false,
            asyncsearch: {},
            response: {}
        })
        this.typeahead.getInstance().clear()
    }

    Clear_all_button = (e) => {
        if ("_status" in this.state.response) {
            return (
                <button type="button" class="btn btn-primary m-2" onClick={this.handle_clear_all}>Clear All</button>
            )
        }
        return ("")
    }

    render() {
        if (this.state.add_database_entry_bool) {
            return [
                <div className="container p-4">
                    <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {
                        if (this.state.add_database_entry_bool) {
                            this.setState({ add_database_entry_bool: false })
                        }
                        else {
                            this.setState({ add_database_entry_bool: true })
                        }
                    }}><i class="fas fa-arrow-left"></i> Back</span></span>
                </div>,
                <Data />
            ]
        }
        else {


            return [
                <div class="container p-4">
                    <form>
                        <div class="form-group ">
                            <label for="addinquiry_initiator"><b>Initiator (If Exists)</b></label>
                            <AsyncTypeahead type="text" placeholder="Search" name="to"

                                labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                                filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                                onSearch={this.handle_search}
                                {...this.state.asyncsearch}
                                maxResults="10"
                                ref={(typeahead) => this.typeahead = typeahead}
                                renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps} className="search_menu" id="search_menu">
                                        {results.map((result, index) => (
                                            <MenuItem option={result} position={index} className="search_item m-0">
                                                <div className="w-100 p-2 search_item" onClick={(e) => {
                                                    //   $("#search_menu").hide();
                                                    this.setState({ initiator_id: result._id.$oid, initiator: `${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}` })
                                                }}>

                                                    {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                )}
                            />


                        </div>
                        <this.Selected_initiator />

                        <div class="form-group">
                            <span class="m-2"><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {
                                if (this.state.add_database_entry_bool) {
                                    this.setState({ add_database_entry_bool: false })
                                }
                                else {
                                    this.setState({ add_database_entry_bool: true })
                                }
                            }}>Add Entry</span></span>

                        </div>
                        <div class="form-group">
                            <label for="addinquiry_title"><b>Title</b></label>
                            <input type="text" class="form-control" id="addinquiry_title" name="title" value={this.state.title} onChange={(e) => {
                                this.setState({ title: e.target.value })
                            }} />
                        </div>
                        <div class="form-group">
                            <label for="addinquiry_description"><b>Description</b></label>
                            <textarea class="form-control" id="addinquiry_description" rows="3" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }}></textarea>
                        </div>
                        <div class="form-group">
                            <this.ImageUploader />
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-primary m-2" id="add_inquiry_button" onClick={this.handle_post_inquiry}>Add Inquiry</button>
                            <this.Clear_all_button />
                        </div>
                        <div class="form-group">
                            <this.Status />
                        </div>
                    </form>

                </div>
                ,
                <this.FullScreenImageView />
            ]
        }
    }
}


function Inquiry() {
    return (
        <div>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Inquiry</h1>
                {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
            </div>

            <div class="row">

                <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Add Inquiry</h6>
                        </div>
                        <div class="m-0">
                            <AddInquiry />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">All Inquiries</h6>
                        </div>
                        <div class="m-0">
                            <AllInquires />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const styles = {
    inventory_item: {
        backgroudColor: "red"
    }
}
export default Inquiry;