import React, { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Cookies from 'js-cookie';
import URL from './URL';
import moment from 'moment-timezone';
import { Line, Bar } from 'react-chartjs-2';
import { DebounceInput } from 'react-debounce-input';
import '@simonwep/pickr/dist/themes/classic.min.css';   // 'classic' theme
import Pickr from '@simonwep/pickr';
import printJS from 'print-js'
import { renderToString } from 'react-dom/server'


import { fullDialogDispatcher } from './FullDialog';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { JWURLFRONT } from './JoypurwalaURL';

var country_json_1 = require("../node_modules/country-state-city/lib/country.json");
const XLSX = require('xlsx');
const Dispatcher = require('flux').Dispatcher;

var inventorySearchDispatcher = new Dispatcher()


var QRCode = require('qrcode')


var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')


function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}

function getTagHtml(qrcode, name, sku, mrp, size, weight, qrcodeBool, nameBool, skuBool, mrpBool, sizeBool, weightBool) {


    var qrcodeVal = ""
    if (qrcode !== undefined && qrcode !== "" && qrcode !== null && qrcodeBool) {
        qrcodeVal = `<img src="${qrcode}" style="width:13mm;height:13mm;margin-left:0.5em" />`
    }
    var nameVal = ""
    if (name !== undefined && name !== "" && name !== null && nameBool) {
        nameVal = name
    }
    var skuVal = ""
    if (sku !== undefined && sku !== "" && sku !== null && skuBool) {
        skuVal = sku
    }

    var mrpVal = ""
    if (mrp !== undefined && mrp !== "" && mrp !== null && mrpBool) {
        mrpVal = `₹ ${parseFloat(mrp).toLocaleString('en-IN')}`
    }

    var sizeVal = ""
    if (size !== undefined && size !== "" && size !== null && sizeBool) {
        sizeVal = `w&times;h&times;d: ${size.width}&times;${size.height}&times;${size.depth}&nbsp;${size.unit}`
    }

    var weightVal = ""
    if (weight !== undefined && weight !== "" && weight !== null && weightBool) {
        weightVal = weight + " (apx)"
    }


    return (`
        <html>
        <head>
        <style>
        body {margin:0;padding:0;max-height:'14mm',font-size:3mm;font-weight: bold;font-family: Arial, Helvetica, sans-serif;overflow-y:hidden;}
        tr {margin:0;padding:0;}
        td {margin:0;padding:0;}
        </style>
        </head>
        <body>
        <div style="height:14mm;max-height:14mm;overflow:hidden">
        <table style="height:15mm">
        <tr>
        <td>
        ${qrcodeVal}
        </td>
        <td style="max-width:18mm;font-size:3mm;">
        ${nameVal}
        <br />
        ${skuVal}
        
        </td>
        <td style="max-width:32mm;font-size:3mm;margin-left:2mm;">
        <div style="margin-left:2mm;">
        ${mrpVal}
        <br />
        ${sizeVal}
        <br />
        ${weightVal}
        </div>
        </td>
        </tr>
        </table>
        </div>
        </body>
        </html>
    `)
}

class TagHTML extends React.Component {
    constructor(props) {
        super()
        this.tagQRCode = props.tagQRCode
        this.tagSKU = props.tagSKU
        this.tagName = props.tagName
        this.tagMRP = props.tagMRP
        this.tagSize = props.tagSize
        this.tagWeight = props.tagWeight
        this.rows = parseInt(props.rows)
        this.columns = parseInt(props.columns)
    }

    render() {

        var TRs = []
        // get TRs


        for (var i = 0; i < this.rows; i++) {
            TRs.push(
                <tr key={`trkey` + i} style={{
                    pageBreakInside: 'avoid',
                    pageBreakAfter: 'auto'
                }}>
                    <td style={{
                        pageBreakInside: 'avoid',
                        pageBreakAfter: 'auto',
                        width: '14mm',
                        maxHeight: '14mm'
                    }}>
                        {this.tagQRCode}
                    </td>
                    <td style={{
                        pageBreakInside: 'avoid',
                        pageBreakAfter: 'auto',
                        width: '18mm',
                        maxHeight: '14mm',

                    }}>
                        {this.tagName}
                        {this.tagSKU}

                    </td>
                    <td style={{
                        pageBreakInside: 'avoid',
                        pageBreakAfter: 'auto',
                        width: '18mm',
                        maxHeight: '14mm'
                    }}>
                        {this.tagMRP}
                        {this.tagSize}
                        {this.tagWeight}

                    </td>
                </tr>
            )
        }

        return (
            <html>
                <body style={{
                    margin: '0',
                    maxHeight: '5mm'
                }}>
                    <table id="tagPreview" className="" style={{
                        pageBreakAfter: 'auto',
                        width: '65mm',
                        height: '14mm',
                        maxHeight: '14mm',
                        maxWidth: '65mm',
                        margin: '0'
                    }}>
                        {TRs}
                    </table>
                </body>
            </html>
        )
    }
}

class PrintTag extends React.Component {
    constructor(props) {
        super()

        this.data = props.data

        this.state = {
            copies: 1,

            qrCode: true,
            name: true,
            sku: true,
            mrp: true,
            size: true,
            weight: true,

            nameText: this.data.name
        }

    }

    startPrint = () => {

    }

    render() {

        if (this.data.sku === undefined || this.data.sku === "" || this.data.sku === null) {
            return (
                <div className="p-2">
                    <span className="text-danger">
                        No SKU present for the product.
                    </span>
                </div>
            )
        }

        // qrcode test
        var qrcodeImage = null

        // QR code with sku 
        var qrcodeData = `${this.data.sku}`
        var opts = {
            margin: 1,
        }
        var qrURL = null
        QRCode.toDataURL(qrcodeData, opts, function (err, url) {
            qrcodeImage = <img style={{
                // height:'14mm',
                // width:'14m'
                marginLeft: '0.5em'
            }} src={url} />
            qrURL = url
        })

        var tagQRCode = null
        if (this.state.qrCode) {
            tagQRCode = qrcodeImage
        }
        var tagName = null
        if (this.state.name) {
            // if (tagSKU === null) {
            tagName = <span style={{
                // fontSize:'3mm'
            }}><b>{this.state.nameText}</b></span>
            // } else {
            //     tagName = [<br />, <span>{this.data.name}</span>]
            // }
        }
        var tagSKU = null
        if (this.state.sku) {
            // tagSKU = this.data.sku
            if (tagName === null) {
                tagSKU = <span><b>{this.data.sku}</b></span>
            } else {
                tagSKU = [<br />, <span><b>{this.data.sku}</b></span>]
            }
        }


        var tagMRP = null
        if (this.state.mrp) {

            // MRP or SP???

            // tagMRP = [<span><b>₹ {parseFloat(this.data.price.sp).toLocaleString('en-IN')}</b></span>]
            tagMRP = [<span><b>₹ {parseFloat(this.data.price.mrp).toLocaleString('en-IN')}</b></span>]
            // tagMRP = [<br />, <span>₹ {parseFloat(this.data.price.sp).toLocaleString('en-IN')}</span>]
        }

        var tagSize = null
        if (this.state.size) {
            var sizeLabelW = null
            var sizeLabelH = null
            var sizeLabelD = null

            var width = null
            if (this.data.dimensions.width !== "" && this.data.dimensions.width !== undefined && this.data.dimensions.width !== null && parseFloat(this.data.dimensions.width) !== 0) {
                width = [<span><b>{this.data.dimensions.width}</b></span>]
                sizeLabelW = <span><b>w</b></span>
            }
            var height = null
            if (this.data.dimensions.height !== "" && this.data.dimensions.height !== undefined && this.data.dimensions.height !== null && parseFloat(this.data.dimensions.height) !== 0) {
                if (width !== null) {
                    height = [<span><b>&times;</b></span>, <span><b>{this.data.dimensions.height}</b></span>]
                    sizeLabelH = <b><span>&times;h</span></b>
                } else {
                    height = [<span><b>{this.data.dimensions.height}</b></span>]
                    sizeLabelH = <span><b>h</b></span>
                }
            }
            var depth = null
            if (this.data.dimensions.depth !== "" && this.data.dimensions.depth !== undefined && this.data.dimensions.depth !== null && parseFloat(this.data.dimensions.depth) !== 0) {
                if (width !== null || height !== null) {
                    depth = [<span><b>&times;</b></span>, <span><b>{this.data.dimensions.depth}</b></span>]
                    sizeLabelD = <b><span>&times;d</span></b>
                } else {
                    depth = [<span><b>{this.data.dimensions.depth}</b></span>]
                    sizeLabelD = <span><b>d</b></span>
                }
            }
            if (width === null && height === null && depth === null) {
                tagSize = null
            } else {
                if (tagMRP === null) {

                    tagSize = [<span><b>{sizeLabelW}{sizeLabelH}{sizeLabelD}:&nbsp;{width}{height}{depth}&nbsp;{this.data.dimensions.unit}</b></span>]
                } else {
                    tagSize = [<br />, <span><b>{sizeLabelW}{sizeLabelH}{sizeLabelD}:&nbsp;{width}{height}{depth}&nbsp;{this.data.dimensions.unit}</b></span>]
                }
            }
        }
        var tagWeight = null
        if (this.state.weight) {
            if (this.data.weight !== undefined && this.data.weight.value != undefined && parseFloat(this.data.weight.value) !== 0) {
                if (tagSize === null && tagMRP === null) {
                    tagWeight = [<span><b>{this.data.weight.value}&nbsp;{this.data.weight.unit} (apx)</b></span>]
                } else {
                    tagWeight = [<br />, <span><b>{this.data.weight.value}&nbsp;{this.data.weight.unit} (apx)</b></span>]
                }
            }
        }
        var tag = <table id="tagPreview" className="text-dark border p-1 rounded">
            <tr style={{
                // fontSize:'3mm'
            }}>
                <td >
                    {tagQRCode}
                </td>
                <td>
                    {tagName}
                    {tagSKU}
                </td>
                <td className="pl-2 pr-2">
                    {tagMRP}
                    {tagSize}
                    {tagWeight}
                </td>
            </tr>
        </table>



        return (
            <div className="p-2">

                <b>
                    {this.data.name}
                </b>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={this.state.qrCode} id="checkboxQRCode" onChange={(e) => {
                        this.setState({ qrCode: e.target.checked })
                    }} />
                    <label className="form-check-label" htmlFor="checkboxQRCode">
                        QR Code
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={this.state.name} id="checkboxName" onChange={(e) => {
                        this.setState({ name: e.target.checked })
                    }} />
                    <label className="form-check-label" htmlFor="checkboxName">
                        Name
                    </label>
                    <input type="text" hidden={!this.state.name} className="form-control" id="tagNameEdit" value={this.state.nameText} onChange={(e) => {
                        this.setState({ nameText: e.target.value })
                    }} />
                    <small hidden={!this.state.name}><span className="btn-link" style={{
                        cursor: "pointer"
                    }} onClick={() => {
                        this.setState({ nameText: this.data.name })
                    }}>Reset</span></small>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={this.state.sku} id="checkboxSKU" onChange={(e) => {
                        this.setState({ sku: e.target.checked })
                    }} />
                    <label className="form-check-label" htmlFor="checkboxSKU">
                        SKU
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={this.state.mrp} id="checkboxMRP" onChange={(e) => {
                        this.setState({ mrp: e.target.checked })
                    }} />
                    <label className="form-check-label" htmlFor="checkboxMRP">
                        MRP
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={this.state.size} id="checkboxSize" onChange={(e) => {
                        this.setState({ size: e.target.checked })
                    }} />
                    <label className="form-check-label" htmlFor="checkboxSize">
                        Size
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={this.state.weight} id="checkboxWeight" onChange={(e) => {
                        this.setState({ weight: e.target.checked })
                    }} />
                    <label className="form-check-label" htmlFor="checkboxWeight">
                        Weight
                    </label>
                </div>
                {/* <label htmlFor="printTagCopies">Copies</label>
                <input id="printTagCopies" type="number" min="1" max="1000" value={this.state.copies} className="form-control" onChange={(e) => {
                    if (e.target.value === "") {
                        this.setState({ copies: 1 })
                        return
                    }
                    if (e.target.value === 0 || e.target.value === "0" || parseInt(e.target.value) === 0) {
                        this.setState({ copies: 1 })
                        return
                    }
                    if (parseInt(e.target.value) > 1000) {
                        this.setState({ copies: 1000 })
                        return
                    }
                    this.setState({ copies: parseInt(e.target.value).toString() })
                }} /> */}
                <div className="mt-2">
                    Preview:
                    {tag}
                    {/* <TagHTML tagQRCode={tagQRCode} tagSKU={tagSKU} tagName={tagName} tagMRP={tagMRP} tagSize={tagSize} tagWeight={tagWeight} /> */}
                </div>
                <input type="button" value="Print" className="btn btn-primary mt-2" onClick={(e) => {
                    // printJS('printableDiv', 'raw-html')
                    // const html = renderToString(<TagHTML tagQRCode={tagQRCode} tagSKU={tagSKU} tagName={tagName} tagMRP={tagMRP} tagSize={tagSize} tagWeight={tagWeight} rows={1} columns={1} />)

                    const html = getTagHtml(qrURL, this.state.nameText, this.data.sku, this.data.price.mrp, this.data.dimensions, this.data.weight.value + " " + this.data.weight.unit, this.state.qrCode, this.state.name, this.state.sku, this.state.mrp, this.state.size, this.state.weight)

                    printJS(
                        {
                            type: "raw-html",
                            printable: html,

                        }
                    )

                }} />

            </div>
        )
    }
}

class SampleExcelDownload extends React.Component {
    constructor(props) {
        super()
        this.state = {
            count: 1,

            isError: false,
            isLoading: false,
            isSuccess: false,

            SKUs: []
        }
    }

    processExcelWithSKUs = () => {
        const sheetData = []
        // // prepare data
        for (var i in this.state.SKUs) {
            sheetData.push(
                {
                    "SKUs": this.state.SKUs[i]
                }
            )
        }

        var wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(sheetData)
        wb.SheetNames.push("Bulk SKUs")
        wb.Sheets["Bulk SKUs"] = sheet

        const d = new Date()
        const filename = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}-bulk-SKUs.xlsx`

        XLSX.writeFile(wb, filename)


    }

    handleFetchSKUs = (response) => {

        if (!("_status" in response) || !("payload" in response)) {
            alert("Unable to connect to server. Please check your internet connection and try again later.")
            return
        }

        if (response["_status"] !== "success") {
            alert("Some server side error has occurred. Please try again later.")
            return
        }

        this.setState({ SKUs: response.payload }, this.processExcelWithSKUs)
    }

    fetchSKUs = () => {
        const that = this
        axios.get(URL + "inventory", {
            params: {
                sx: "y",
                sxc: parseInt(this.state.count)
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.handleFetchSKUs(response.data)
            })
            .catch(function (error) {
                that.handleFetchSKUs({})
            })
    }

    render() {
        return (

            <div className="p-2">
                <div className="">
                    <label htmlFor="bulkSKUsCount">Number of SKUs</label>
                    <input type="number" id="bulkSKUsCount" className="form-control" value={this.state.count} onChange={(e) => {
                        this.setState({ count: e.target.value })
                    }} min="1" />
                    <button id="bulkAddButtonSKUs" type="button" class="btn btn-primary mt-1 text-white" onClick={this.fetchSKUs}>
                        Download Bulk SKUs
                    </button>
                </div>
                <hr />
                <div className="">
                    <a id="bulkAddButtonNoSKUs" type="button" class="btn btn-primary m-1 text-white" href="/proone-sample.xlsx" >
                        Download Excel With Fields
                    </a>

                    <a id="bulkAddButtonNoSKUs" type="button" class="btn btn-primary m-1 text-white" href="/proone_bulk_stock_update_sample.xlsx" >
                        Download Excel For Bulk Stock Update
                    </a>

                    
                </div>
            </div>

        )
    }
}

class AddToInventory extends React.Component {
    constructor(props) {
        super(props)
        this.resetAllFields = this.resetAllFields.bind(this)
        this.postItem = this.postItem.bind(this);
        this.state = {
            name: "",
            description: "",
            ecomDescription: "",
            ecom: true,
            keywords: "",
            color: {
                color: "",
                colorn: ""
            },
            sku: "",
            hsn: "",
            type: "Goods",
            unit: "pcs",
            quantity: "0",
            dimension: {
                height: "0",
                width: "0",
                depth: "0",
                unit: "mm"
            },
            weight: {
                value: "0",
                unit: 'gms'
            },
            info: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            price: {
                cp: "0",
                sp: "0",
                mrp: "0"
                // unit: "inr"
            },
            tax: {
                p: "0",
                // c: "igst",
                t: "inc"
            },
            coupon: "",

            response: {},
            images: [],

            article: "",
            material: "",
            gender: "",
            shape: "",
            occasion: "",
            purity: "",
            plating: "",
            countryoforigin: "",
            maxperecomorder:0
        }
    }

    componentDidMount() {
        const pickr = Pickr.create({
            el: '.color-picker',
            theme: 'classic', // or 'monolith', or 'nano'

            swatches: [
                'rgba(244, 67, 54, 1)',
                'rgba(233, 30, 99, 0.95)',
                'rgba(156, 39, 176, 0.9)',
                'rgba(103, 58, 183, 0.85)',
                'rgba(63, 81, 181, 0.8)',
                'rgba(33, 150, 243, 0.75)',
                'rgba(3, 169, 244, 0.7)',
                'rgba(0, 188, 212, 0.7)',
                'rgba(0, 150, 136, 0.75)',
                'rgba(76, 175, 80, 0.8)',
                'rgba(139, 195, 74, 0.85)',
                'rgba(205, 220, 57, 0.9)',
                'rgba(255, 235, 59, 0.95)',
                'rgba(255, 193, 7, 1)'
            ],

            components: {

                // Main components
                preview: true,
                opacity: true,
                hue: true,

                // Input / output Options
                interaction: {
                    hex: true,
                    rgba: true,
                    hsla: true,
                    hsva: true,
                    cmyk: true,
                    input: true,
                    clear: true,
                    save: true
                }
            }
        });
        pickr.on('save', (color, instance) => {
            var tmp_color = this.state.color
            tmp_color['color'] = color.toHEXA().toString()
            this.setState({ color: tmp_color })

        })
    }

    resetAllFields() {
        this.setState({
            name: "",
            description: "",
            ecomDescription: "",
            ecom: true,
            keywords: "",
            color: {
                color: "",
                colorn: ""
            },
            sku: "",
            hsn: "",
            type: "Goods",
            unit: "pcs",
            quantity: "0",
            dimension: {
                height: "0",
                width: "0",
                depth: "0",
                unit: "mm"
            },
            weight: {
                value: "0",
                unit: 'gms'
            },
            info: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            price: {
                cp: "0",
                sp: "0",
                mrp: "0"
                // unit: "inr"
            },
            tax: {
                p: "0",
                // c: "igst",
                t: "inc"
            },
            coupon: "",

            response: {},
            images: [],

            article: "",
            material: "",
            gender: "",
            shape: "",
            occasion: "",
            purity: "",
            plating: "",
            countryoforigin: "",
            maxperecomorder:0
        })
    }

    FullScreenImageView = () => {
        return (
            <div id="fullscreenimageviewdialog1" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabIndex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog1") {
                        $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog1').attr('hidden', 'hidden') }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialog1image" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }

    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialog1image').style.transform = angle;
        })
    }

    ImageUploader = () => {
        var img_ = []

        for (var i in this.state.images) {
            const idx = i
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} src={this.state.images[i]["image"]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <div className="inquiry_selected_buttons">
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}></i>&nbsp;<span iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}>Delete</span>
                    </div>
                    <div className="">
                        QR Position
                        <select class="custom-select" name="type" id={`addIventoryImageQRPos` + i} defaultValue={this.state.images[i]["qp"]} iid={i} onChange={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images[iid]["qp"] = e.target.value;
                            this.setState({ images: tmp_images })
                        }}>
                            <option selected value="br">Bottom Right</option>
                            <option value="tl">Top Left</option>
                            <option value="tr">Top Right</option>
                            <option value="bl">Bottom Left</option>
                        </select>
                    </div>
                    <div className="">
                        QR Size
                        <select class="custom-select" name="type" id={`addIventoryImageQRSize` + i} defaultValue={this.state.images[i]["qs"]} iid={i} onChange={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images[iid]["qs"] = e.target.value;
                            this.setState({ images: tmp_images })
                        }}>
                            <option selected value="100">100 px</option>
                            <option value="200">200 px</option>
                            <option value="300">300 px</option>
                        </select>
                    </div>
                    <div className="">
                        <div class="form-check">
                            <input class="form-check-input" iid={idx} type="radio" name="primaryImageRadio" id={`primaryImageRadio` + i} checked={this.state.images[i]["ip"]} onChange={(e) => {

                                var iid_ = e.target.getAttribute("iid")
                                var tmpImages = this.state.images


                                // all false
                                for (var ii in tmpImages) {
                                    tmpImages[ii]["ip"] = false
                                }


                                tmpImages[iid_]["ip"] = e.target.checked
                                this.setState({ images: tmpImages })

                            }} />
                            <label class="form-check-label" for={`primaryImageRadio` + i}>
                                Is Primary
                            </label>
                        </div>
                    </div>
                </div>
            )
        }

        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    {/* <h5 class="card-title">Add Images</h5> */}
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>
                    {/* <this.Display_images /> */}
                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;

                                tmp_images.push(
                                    {
                                        "image": e.target.result,
                                        "qp": "br",
                                        "qs": "100",
                                        "ip": false
                                    }

                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    postItem() {
        $("#postItemButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Add")
        const that = this
        var tmp_images = []

        if (this.state.images.length > 0) {
            for (var i in this.state.images) {
                tmp_images.push(
                    {
                        "image": this.state.images[i]["image"].split(",")[1],
                        "qs": this.state.images[i]["qs"],
                        "qp": this.state.images[i]["qp"],
                        "ip": this.state.images[i]["ip"]
                    }
                )
            }
        }
        axios.post(URL + "inventory", {
            name: this.state.name,
            description: this.state.description,
            ecomDescription: this.state.ecomDescription,
            ecom: this.state.ecom,
            keywords: this.state.keywords,
            color: this.state.color,
            type: this.state.type,
            sku: this.state.sku,
            hsn: this.state.hsn,
            quantity: parseInt(this.state.quantity),
            unit: this.state.unit,
            dimensions: this.state.dimension,
            weight: this.state.weight,
            info: this.state.info,
            price: this.state.price,
            tax: this.state.tax,
            coupon: this.state.coupon,
            images: tmp_images,
            article: this.state.article,
            material: this.state.material,
            gender: this.state.gender,
            shape: this.state.shape,
            occasion: this.state.occasion,
            purity: this.state.purity,
            plating: this.state.plating,
            countryoforigin: this.state.countryoforigin,
            maxperecomorder: parseInt(this.state.maxperecomorder)
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                if (("_status" in response.data)) {
                    if (response.data._status === "success") {
                        that.resetAllFields()
                        inventorySearchDispatcher.dispatch({
                            actionType: "reload-inventory"
                        })
                    }
                }

                that.setState({ response: response["data"] })


                $("#postItemButton").html("Add")

            })
    }

    printStuff(key, value) {
        var ret = []
        if (value[0].constructor === {}.constructor) {
            for (var i in value[0]) {
                ret.push(
                    this.printStuff(i, value[0][i])
                )
            }
            return ret
        }

        ret.push(
            <span><span>{key}: {value}</span><br /></span>
        )

        return ret

    }

    Status = () => {

        useEffect(() => {
            // Update the document title using the browser API
            if ("_status" in this.state.response) {
                document.getElementById('addInventoryStatusDiv').scrollIntoView()
            }
        });

        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="addInventoryStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []

            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                // alert(i)
                ret.push(
                    this.printStuff(i, this.state.response.payload[0][i])
                    //     // <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="addInventoryStatusDiv" style={{
                    whiteSpace: 'pre-wrap'
                }} className="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return "";
    }

    BulkAddition = () => {
        return [

            <button id="bulkExcelDownload" type="button" className="btn btn-primary m-1" onClick={(e) => {
                fullDialogDispatcher.dispatch({
                    actionType:"show-dialog",
                    title:"Download Sample Excel",
                    message:<SampleExcelDownload />
                })
            }}>Sample Excel</button>,

            <button id="bulkAddButton" type="button" className="btn btn-primary m-1" onClick={(e) => {
                document.getElementById('bulkAddInput').click();
            }}>Bulk Add</button>,
            <input type="file" class="form-control-file" id="bulkAddInput" onChange={(input) => {
                const that = this;

                for (var i = 0; i < input.target.files.length; i++) {
                    if (input.target.files[i]["type"] !== "application/vnd.ms-excel" && input.target.files[i]["type"] !== "application/vnd.oasis.opendocument.spreadsheet" && input.target.files[i]["type"] !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                        var file_type = "Unknown"

                        if (input.target.files[i]["type"].length > 0) {
                            var file_type = input.target.files[i]["type"]
                        }

                        var error = {
                            "_status": "",
                            "payload": []
                        }
                        var tmpPayload = {}
                        var errorSet = false

                        if (!errorSet) {
                            error["_status"] = "fail"
                            errorSet = true
                        }
                        tmpPayload["Bulk Addition"] = "Invalid File. Only Microsoft Excel (.xlsx) Is Allowed. The File Uploaded Is Of Type " + file_type + "."

                        if (errorSet) {
                            error["payload"] = [tmpPayload]
                            this.setState({ response: error })
                            return
                        }

                    }
                    var reader = new FileReader();
                    reader.onload = function (e) {

                        document.getElementById('bulkAddInput').value = "";
                        $("#postItemButton").attr('disabled', 'disabled')
                        $("#bulkAddButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Bulk Add")
                        const params = { ba: "1" }
                        axios.post(URL + 'inventory',
                            {
                                file: e.target.result.split(",")[1]
                            },
                            {
                                params: params,
                                headers: {
                                    Authorization: ACCT,
                                }
                            }).then(function (response) {

                                that.setState({ response: response["data"] })
                                $("#postItemButton").removeAttr('disabled')
                                $("#bulkAddButton").html("Bulk Add")

                            })
                    }
                    reader.readAsDataURL(input.target.files[i])
                }
            }} hidden />

            ,
            
            <button id="bulkStockAddButton" type="button" className="btn btn-primary m-1" onClick={(e) => {
                document.getElementById('bulkStockAddInput').click();
            }}>Bulk Stock Add</button>,
            <input type="file" class="form-control-file" id="bulkStockAddInput" onChange={(input) => {
                const that = this;
                for (var i = 0; i < input.target.files.length; i++) {
                    if (input.target.files[i]["type"] !== "application/vnd.ms-excel" && input.target.files[i]["type"] !== "application/vnd.oasis.opendocument.spreadsheet" && input.target.files[i]["type"] !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                        var file_type = "Unknown"

                        if (input.target.files[i]["type"].length > 0) {
                            var file_type = input.target.files[i]["type"]
                        }

                        var error = {
                            "_status": "",
                            "payload": []
                        }
                        var tmpPayload = {}
                        var errorSet = false

                        if (!errorSet) {
                            error["_status"] = "fail"
                            errorSet = true
                        }
                        tmpPayload["Bulk Addition"] = "Invalid File. Only Microsoft Excel (.xlsx) Is Allowed. The File Uploaded Is Of Type " + file_type + "."

                        if (errorSet) {
                            error["payload"] = [tmpPayload]
                            this.setState({ response: error })
                            return
                        }

                    }
                    var reader = new FileReader();
                    reader.onload = function (e) {

                        document.getElementById('bulkAddInput').value = "";
                        $("#postItemButton").attr('disabled', 'disabled')
                        $("#bulkStockAddButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Bulk Stock Add")
                        const params = { bsa: "1" }
                        axios.post(URL + 'inventory',
                            {
                                file: e.target.result.split(",")[1]
                            },
                            {
                                params: params,
                                headers: {
                                    Authorization: ACCT,
                                }
                            }).then(function (response) {

                                that.setState({ response: response["data"] })
                                $("#postItemButton").removeAttr('disabled')
                                $("#bulkStockAddButton").html("Bulk Stock Add")

                            })
                    }
                    reader.readAsDataURL(input.target.files[i])
                }
            }} hidden />

            ,

            <button id="bulkPhotoAddButton" type="button" className="btn btn-primary m-1" onClick={(e) => {
                document.getElementById('bulkPhotosAddInput').click();


            }}>Add Bulk Photos</button>,
            <input type="file" className="form-control-file" id="bulkPhotosAddInput" onChange={(input) => {

                this.setState({ response: {} })

                const that = this;

                for (var i = 0; i < input.target.files.length; i++) {


                    if (input.target.files[i]["type"] !== "application/zip" && input.target.files[i]["type"] !== "application/x-zip-compressed") {
                        var file_type = "Unknown"

                        if (input.target.files[i]["type"].length > 0) {
                            var file_type = input.target.files[i]["type"]
                        }

                        var error = {
                            "_status": "",
                            "payload": []
                        }
                        var tmpPayload = {}
                        var errorSet = false

                        if (!errorSet) {
                            error["_status"] = "fail"
                            errorSet = true
                        }
                        tmpPayload["Bulk Addition"] = "Invalid File. Only Zipfile Is Allowed. The File Uploaded Is Of Type " + file_type + "."

                        if (errorSet) {
                            error["payload"] = [tmpPayload]
                            this.setState({ response: error })
                            return
                        }

                    }

                    const ff = input.target.files[i]


                    var reader = new FileReader();
                    reader.onload = function (e) {
                        document.getElementById('bulkPhotosAddInput').value = "";
                        $("#postItemButton").attr('disabled', 'disabled')
                        $("#bulkAddButton").attr('disabled', 'disabled')
                        $("#bulkStockAddButton").attr('disabled', 'disabled')
                        $("#bulkPhotoAddButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Bulk Add")


                        // upload image
                        var bodyFormData = new FormData();
                        const file = dataURItoBlob(e.target.result)
                        // const file = new Blob([e.target.result], {type: ff.type });
                        bodyFormData.append('zip', file, ff.name);

                        // wg.Add()
                        const params = { bpa: "1" }
                        axios.post(URL + 'inventory',
                            bodyFormData,
                            {
                                params: params,
                                headers: {
                                    Authorization: ACCT
                                }
                            }

                        )
                            .then(function (response_) {

                                that.setState({ response: response_["data"] })
                                $("#postItemButton").removeAttr('disabled')
                                $("#bulkAddButton").removeAttr('disabled')
                                $("#bulkStockAddButton").removeAttr('disabled')
                                
                                $("#bulkPhotoAddButton").html("Add Bulk Photos")
                            })
                            .catch(function (response_) {
                                that.setState({ response: { "_status": "fail", "payload": "Some unknown error has occurred." } })
                                $("#postItemButton").removeAttr('disabled')
                                $("#bulkAddButton").removeAttr('disabled')
                                $("#bulkStockAddButton").removeAttr('disabled')
                                $("#bulkPhotoAddButton").html("Add Bulk Photos")
                            });

                        // upload ends


                    }


                    reader.readAsDataURL(input.target.files[i])
                }
            }} hidden />

            ,
        ]

    }

    render() {
        return [
            <div className="container p-4">

                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <label htmlFor="inventoryName"><b>Name</b></label>
                        <input type="text" id="inventoryName" className="form-control border-1 small" name="name" value={this.state.name} onChange={(e) => {
                            this.setState({ name: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryType"><b>Type</b></label>
                        <select class="custom-select" name="type" id="inventoryType" value={this.state.type} onChange={(e) => {
                            this.setState({ type: e.target.value })
                        }}>
                            <option value="Goods">Goods</option>
                            <option value="Service">Service</option>
                        </select>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <label htmlFor="inventoryDescription"><b>Description</b></label>
                        <input type="text" id="inventoryDescription" className="form-control border-1 small" name="name" value={this.state.description} onChange={(e) => {
                            this.setState({ description: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryColor"><b>Color</b></label>
                        <div className="d-flex d-flex-inline align-items-center">
                            <span className="color-picker"></span>
                            <input type="text" id="inventoryColor" className="form-control border-1 small ml-2" name="colorn" value={this.state.color.colorn} onChange={(e) => {
                                var tmp_color = this.state.color
                                tmp_color[e.target.name] = e.target.value
                                this.setState({ color: tmp_color })
                            }} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label htmlFor="inventoryEcomDescription"><b>E-commmerce Description</b></label>
                        {/* <textarea rows="5" type="text" id="inventoryEcomDescription" className="form-control border-1 small" name="name" value={this.state.ecomDescription} onChange={(e) => {
                            this.setState({ ecomDescription: e.target.value })
                        }} /> */}
                        <ReactQuill theme="snow" value={this.state.ecomDescription} onChange={(data) => {
                            this.setState({ ecomDescription: data })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label htmlFor="inventoryEcom"><b>E-commerce Visibility</b></label>
                        <br />
                        <small>Should the item appear on E-commerce platform?</small>
                        <br />
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="inventoryEcom" defaultChecked={this.state.ecom} onChange={(e) => {
                                this.setState({ ecom: e.target.checked })
                            }} checked={this.state.ecom} />
                            <label className="form-check-label" htmlFor="inventoryEcom">
                                Show on e-commerce platform
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label htmlFor="inventoryKeywords"><b>Keywords</b></label>
                        <input type="text" id="inventoryKeywords" className="form-control border-1 small" name="keywords" value={this.state.keywords} onChange={(e) => {
                            this.setState({ keywords: e.target.value })
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <label htmlFor="inventorySku"><b>SKU</b></label>
                        <input type="text" id="inventorySku" className="form-control border-1 small" name="name" value={this.state.sku} onChange={(e) => {
                            this.setState({ sku: e.target.value })
                        }} />
                        <small id="invoiceNumberHelp" class="form-text text-muted">Use Only When Custom SKU Is Needed.</small>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryHsn"><b>HSN</b></label>
                        <input type="text" id="inventoryHsn" className="form-control border-1 small" name="name" value={this.state.hsn} onChange={(e) => {
                            this.setState({ hsn: e.target.value })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <label htmlFor="inventoryQuantity"><b>Quantity</b></label>
                        <input type="number" id="inventoryQuantity" className="form-control border-1 small" name="name" value={this.state.quantity} onChange={(e) => {
                            this.setState({ quantity: e.target.value })
                        }} /></div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">

                        <label htmlFor="inventoryUnit"><b>Unit</b></label>
                        <select class="custom-select" name="type" id="inventoryUnit" value={this.state.unit} onChange={(e) => {
                            this.setState({ unit: e.target.value })
                        }}>
                            <option value="box">Box</option>
                            <option value="pcs">Pcs</option>
                            <option value="tn">tn</option>
                            <option value="kg">kg</option>
                            <option value="lbs">lbs</option>
                            <option value="gm">gm</option>
                            <option value="mg">mg</option>
                            <option value="km">km</option>
                            <option value="m">m</option>
                            <option value="ft">ft</option>
                            <option value="in">in</option>
                            <option value="cm">cm</option>
                            <option value="mm">mm</option>

                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Dimension</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label htmlFor="inventoryDimensionHeight">Height</label>
                        <input type="number" id="inventoryDimensionHeight" className="form-control border-1 small" name="height" value={this.state.dimension.height} onChange={(e) => {
                            var tmp_dimension = this.state.dimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ dimension: tmp_dimension })

                        }} />
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <label htmlFor="inventoryDimensionWidth">Width</label>
                        <input type="number" id="inventoryDimensionWidth" className="form-control border-1 small" name="width" value={this.state.dimension.width} onChange={(e) => {
                            var tmp_dimension = this.state.dimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ dimension: tmp_dimension })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <label htmlFor="inventoryDimensionDepth">Depth</label>
                        <input type="number" id="inventoryDimensionDepth" className="form-control border-1 small" name="depth" value={this.state.dimension.depth} onChange={(e) => {
                            var tmp_dimension = this.state.dimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ dimension: tmp_dimension })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <label htmlFor="inventoryDimensionUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryDimensionUnit" value={this.state.dimension.unit} onChange={(e) => {
                            var tmp_dimension = this.state.dimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ dimension: tmp_dimension })
                        }}>
                            <option value="km">km</option>
                            <option value="m">m</option>
                            <option value="ft">ft</option>
                            <option value="in">in</option>
                            <option value="cm">cm</option>
                            <option value="mm">mm</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Weight</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">

                        <label htmlFor="inventoryWeightValue">Value</label>
                        <input type="number" id="inventoryWeightValue" className="form-control border-1 small" name="value" value={this.state.weight.value} onChange={(e) => {
                            var tmp_weight = this.state.weight
                            tmp_weight[e.target.name] = e.target.value
                            this.setState({ weight: tmp_weight })

                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryWeightUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryWeightUnit" value={this.state.weight.unit} onChange={(e) => {
                            var tmp_weight = this.state.weight
                            tmp_weight[e.target.name] = e.target.value
                            this.setState({ weight: tmp_weight })
                        }}>
                            <option value="tn">tn</option>
                            <option value="kg">kg</option>
                            <option value="lbs">lbs</option>
                            <option value="gms">gms</option>
                            <option value="mg">mg</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Info</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label htmlFor="inventoryInfoManufacturer">Manufacturer</label>
                        <input type="text" id="inventoryInfoManufacturer" className="form-control border-1 small" name="manufacturer" value={this.state.info.manufacturer} onChange={(e) => {
                            var tmp_info = this.state.info
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ info: tmp_info })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label htmlFor="inventoryInfoBrand">Brand</label>
                        <input type="text" id="inventoryInfoBrand" className="form-control border-1 small" name="brand" value={this.state.info.brand} onChange={(e) => {
                            var tmp_info = this.state.info
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ info: tmp_info })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label htmlFor="inventoryInfoUpc">UPC</label>
                        <input type="text" id="inventoryInfoUpc" className="form-control border-1 small" name="upc" value={this.state.info.upc} onChange={(e) => {
                            var tmp_info = this.state.info
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ info: tmp_info })
                        }} />

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label htmlFor="inventoryInfoEan">EAN</label>
                        <input type="text" id="inventoryInfoEan" className="form-control border-1 small" name="ean" value={this.state.info.ean} onChange={(e) => {
                            var tmp_info = this.state.info
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ info: tmp_info })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label htmlFor="inventoryInfoMpn">MPN</label>
                        <input type="text" id="inventoryInfoMpn" className="form-control border-1 small" name="mpn" value={this.state.info.mpn} onChange={(e) => {
                            var tmp_info = this.state.info
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ info: tmp_info })
                        }} />

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label htmlFor="inventoryInfoIsbn">ISBN</label>
                        <input type="text" id="inventoryInfoIsbn" className="form-control border-1 small" name="isbn" value={this.state.info.isbn} onChange={(e) => {
                            var tmp_info = this.state.info
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ info: tmp_info })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Price</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryPriceCp">Cost Price</label>
                        <input type="number" id="inventoryPriceCp" className="form-control border-1 small" name="cp" value={this.state.price.cp} onChange={(e) => {
                            var tmp_price = this.state.price
                            tmp_price[e.target.name] = e.target.value
                            this.setState({ price: tmp_price })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryPriceSp">Selling Price</label>
                        <input type="number" id="inventoryPriceSp" className="form-control border-1 small" name="sp" value={this.state.price.sp} onChange={(e) => {
                            var tmp_price = this.state.price
                            tmp_price[e.target.name] = e.target.value
                            this.setState({ price: tmp_price })
                        }} />

                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryPriceMrp">MRP</label>
                        <input type="number" id="inventoryPriceMrp" className="form-control border-1 small" name="mrp" value={this.state.price.mrp} onChange={(e) => {
                            var tmp_price = this.state.price
                            tmp_price[e.target.name] = e.target.value
                            this.setState({ price: tmp_price })
                        }} />

                    </div>
                    {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryPriceUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryPriceUnit" defaultValue={this.state.price.unit} value={this.state.price.unit} onChange={(e) => {
                            var tmp_price = this.state.price
                            tmp_price[e.target.name] = e.target.value
                            this.setState({ price: tmp_price })
                        }}>
                            <option value="inr">INR</option>
                            <option value="usd">USD</option>
                        </select>
                    </div> */}
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Tax Info</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryTaxP">Tax Bracket (%)</label>
                        <input type="number" id="inventoryTaxP" className="form-control border-1 small" name="p" value={this.state.tax.p} onChange={(e) => {
                            var tmp_tax = this.state.tax
                            var val = e.target.value
                            if (parseInt(val) < 0) {
                                val = 0
                            }
                            if (parseInt(val) > 100) {
                                val = 100
                            }
                            tmp_tax[e.target.name] = val
                            this.setState({ tax: tmp_tax })
                        }} />
                    </div>
                    {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryTaxCategory">Tax Category</label>
                        <select class="custom-select" name="c" id="inventoryTaxCategory" defaultValue={this.state.tax.c} value={this.state.tax.c} onChange={(e) => {
                            var tmp_tax = this.state.tax
                            tmp_tax[e.target.name] = e.target.value
                            this.setState({ tax: tmp_tax })
                        }}>
                            <option value="cgst">CGST</option>
                            <option value="utgst">UTGST</option>
                            <option value="sgst">SGST</option>
                            <option value="igst">IGST</option>
                        </select>
                    </div> */}
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryTaxType">Tax Type</label>
                        <select class="custom-select" name="t" id="inventoryTaxType" value={this.state.tax.t} onChange={(e) => {
                            var tmp_tax = this.state.tax
                            tmp_tax[e.target.name] = e.target.value
                            this.setState({ tax: tmp_tax })
                        }}>
                            <option value="inc">Inclusive</option>
                            <option value="exc">Exclusive</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryCoupon"><b>Coupon</b></label>
                        <input maxLength="12" type="text" id="inventoryCoupon" className="form-control border-1 small" name="coupon" value={this.state.coupon} onChange={(e) => {
                            this.setState({ coupon: e.target.value })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryArticle"><b>Article</b></label>
                        <input maxLength="100" type="text" id="inventoryArticle" className="form-control border-1 small" name="article" value={this.state.article} onChange={(e) => {
                            this.setState({ article: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryMaterial"><b>Material</b></label>
                        <input maxLength="100" type="text" id="inventoryMaterial" className="form-control border-1 small" name="material" value={this.state.material} onChange={(e) => {
                            this.setState({ material: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryGender"><b>Gender</b></label>
                        <input maxLength="100" type="text" id="inventoryGender" className="form-control border-1 small" name="gender" value={this.state.gender} onChange={(e) => {
                            this.setState({ gender: e.target.value })
                        }} />
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryShape"><b>Shape</b></label>
                        <input maxLength="100" type="text" id="inventoryShape" className="form-control border-1 small" name="shape" value={this.state.shape} onChange={(e) => {
                            this.setState({ shape: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryOccasion"><b>Occasion</b></label>
                        <input maxLength="100" type="text" id="inventoryOccasion" className="form-control border-1 small" name="occasion" value={this.state.occasion} onChange={(e) => {
                            this.setState({ occasion: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryPurity"><b>Purity</b></label>
                        <input maxLength="100" type="text" id="inventoryPurity" className="form-control border-1 small" name="purity" value={this.state.purity} onChange={(e) => {
                            this.setState({ purity: e.target.value })
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryPlating"><b>Plating</b></label>
                        <input maxLength="100" type="text" id="inventoryPlating" className="form-control border-1 small" name="plating" value={this.state.plating} onChange={(e) => {
                            this.setState({ plating: e.target.value })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryCountryOfOrigin"><b>Country Of Origin</b></label>
                        
                        
                        <select className='form-control' value={this.state.countryoforigin} onChange={(e)=>{
                            this.setState({ countryoforigin: e.target.value })
                        }}>
                            <option value="">Select Country of Origin</option>
                            {
                                country_json_1.map((item, index) => (
                                    <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryMaxPerEcomOrder"><b>Max Per Ecom Order</b></label>
                         <input type="number" min={0} id="inventoryMaxPerEcomOrder" className="form-control border-1 small" name="maxperecomorder" value={this.state.maxperecomorder} onChange={(e) => {
                            this.setState({ maxperecomorder: e.target.value })
                        }} />
                    </div>

                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                    <label htmlFor=""><b>Images</b></label>
                    <this.ImageUploader />
                </div>
                <div className="row mt-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <button className="btn btn-primary" id="postItemButton" onClick={this.postItem}>Add</button>
                        <this.BulkAddition />
                        <button className="btn btn-primary" onClick={this.resetAllFields}>Clear</button>
                        <this.Status />
                    </div>
                </div>
            </div>,
            <this.FullScreenImageView />
        ]
    }
}

class SearchInventory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search_term: "",
            page_number: 1,
            response: {},
            retrivedInventory: {},
            selectedInventory: {},
            selected_inventory_id: "",
            selectedInventoryBool: false,
            transactionBool: false,
            analyticsBool: false,
            historyBool: false,
            editColorChangeSetBool: false,

            editItemBool: false,

            editItemName: "",
            editItemDescription: "",
            editItemEcomDescription: "",
            editItemEcom: true,
            editItemKeywords: "",
            editItemColor: {
                color: "",
                colorn: ""
            },
            editItemSku: "",
            editItemHsn: "",
            editItemType: "Goods",
            editItemUnit: "box",
            editItemQuantity: "0",
            editItemDimension: {
                height: "0",
                width: "0",
                depth: "0",
                unit: "cm"
            },
            editItemWeight: {
                value: "0",
                unit: 'kg'
            },
            editItemInfo: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            editItemPrice: {
                cp: "0",
                sp: "0",
                // unit: "inr"
            },
            editItemTax: {
                p: "0",
                // c: "igst",
                t: "inc"
            },
            editItemResponse: {},
            editItemImages: [],
            editItemExtraImages: [],

            editItemChangeStock: 0,
            editItemCoupon: "",

            inventoryHistory: {},
            historyPageNumber: 1,


            inventoryAnalytics: {},
            analyticsRange: "1",
            analyticsRangeUnit: "Day",

            inventoryTransactions: {},
            transactionsPageNumber: 1,


            editItemArticle: "",
            editItemMaterial: "",
            editItemGender: "",
            editItemShape: "",
            editItemOccasion: "",
            editItemPurity: "",
            editItemPlating: "",
            editItemCountryOfOrigin: "",
            editItemMaxPerEcomOrder:0

        }
        this.get_all_inventory = this.get_all_inventory.bind(this);

        this.get_all_inventory();
    }

    componentDidMount() {
        // this.initTimer();
        const that = this
        inventorySearchDispatcher.register(function (payload) {
            if (payload.actionType === "reload-inventory") {
                that.get_all_inventory()
            }
        })
    }

    componentWillUnmount() {
        // this.deInitTimer()
    }

    initTimer = () => {
        this.timer = setInterval(() => this.get_all_inventory(), 10000);
    }

    deInitTimer = () => {
        clearInterval(this.timer)
        this.timer = null; // here...
    }

    get_all_inventory = () => {
        const that = this;
        var params = { page_number: this.state.page_number - 1, s: this.state.show }

        // if (this.state.show.length > 0) {
        //     params["s"] = this.state.show;
        // } else {
        //     delete params["s"]
        // }

        if (this.state.search_term.length > 0) {
            params["st"] = this.state.search_term
        } else {
            delete params["st"];
        }

        // if (this.state.initiator_id.length > 0) {
        //     params["ii"] = this.state.initiator_id
        // } else {
        //     delete params["ii"];
        // }

        // if (this.state.handler_id.length > 0) {
        //     params["hi"] = this.state.handler_id
        // } else {
        //     delete params["hi"];
        // }


        axios.get(URL + "inventory", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                // alert("wait")
                that.setState({ retrivedInventory: response["data"]["payload"] })
                // alert(response["data"]["payload"])
                // console.log(response["data"]["_status"])
            })
    }

    get_single_inventory = () => {

        const that = this;

        var params = { "_id": this.state.selected_inventory_id }
        axios.get(URL + "inventory", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {

                that.setState({ selectedInventory: response["data"]["payload"], editItemQuantity: response["data"]["payload"].quantity, selectedInventoryBool: true }, that.deInitTimer)
            })

    }

    get_all_history = () => {
        const that = this;
        var params = { page_number: this.state.historyPageNumber - 1, h: "y", _id: this.state.selected_inventory_id }

        axios.get(URL + "inventory", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ inventoryHistory: response["data"]["payload"] })
            })
    }

    get_all_analytics = () => {
        const that = this;
        var params = { ana: "y", r: this.state.analyticsRange, _id: this.state.selected_inventory_id }

        axios.get(URL + "inventory", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ inventoryAnalytics: response["data"]["payload"] })
            })
    }

    get_all_transactions = () => {
        const that = this;
        var params = { tr: "y", page_number: this.state.transactionsPageNumber - 1, _id: this.state.selected_inventory_id }

        axios.get(URL + "inventory", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ inventoryTransactions: response["data"]["payload"] })
            })
    }

    SectionPagination = (e) => {

        if (this.state.retrivedInventory === undefined) {
            return ""
        }

        const total_pages = this.state.retrivedInventory[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.page_number}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ page_number: parseInt(pageNumber) }, this.get_all_inventory)
                        }}
                    />
                </div>
            </div>

        )

    }

    HistoryPagination = (e) => {

        const total_pages = this.state.inventoryHistory[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.historyPageNumber}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ historyPageNumber: parseInt(pageNumber) }, this.get_all_history)
                        }}
                    />
                </div>
            </div>

        )

    }

    TransactionPagination = (e) => {
        const total_pages = this.state.inventoryTransactions[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.transactionsPageNumber}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ transactionsPageNumber: parseInt(pageNumber) }, this.get_all_transactions)
                        }}
                    />
                </div>
            </div>

        )
    }

    handleDelete = (e) => {
        const that = this;

        var params = { _id: this.state.selected_inventory_id }
        axios.delete(URL + "inventory", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response_under_buttons: response["data"] })
            })
    }

    Dialog = (props) => {
        return (
            <div class="modal fade" id={props.label} tabindex="-1" role="dialog" aria-labelledby={props.label} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{props.h_text}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{props.msg}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" name={props.b_name} class={`btn ` + props.c_text} onClick={props.handler} data-dismiss="modal">{props.b_label}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    SelectedInventoryNavigationButton = (e) => {
        return (
            <div>
                <div className="form-group row">
                    <h2 className="mr-2 ml-2 mt-2">
                        <i class="fas fa-chevron-left inquiry_selected_buttons" data-toggle="tooltip" data-placement="top" title="Previous Item"></i>
                    </h2>
                    <h2 className="mr-2 ml-2 mt-2">
                        <i class="fas fa-chevron-right inquiry_selected_buttons" data-toggle="tooltip" data-placement="top" title="Next Item"></i>
                    </h2>
                </div>
            </div>
        )
    }

    SelectedInventoryButton = (e) => {
        var delete_button = <h2 class="m-2">
            <i class="far fa-trash-alt inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Delete Item" data-toggle="modal" data-target="#deleteItemDialog"></i>
        </h2>
        if (TP !== "owner") {
            delete_button = ""
        }
        return (
            <div>
                <div class="form-group row">
                    <h2 class="m-2">
                        <i class="fas fa-edit inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Edit Item" onClick={() => {
                            const selectedInventory = this.state.selectedInventory
                            this.setState({
                                transactionBool: false,
                                analyticsBool: false,
                                historyBool: false,
                                editItemBool: true,
                                editItemName: selectedInventory.name,
                                editItemDescription: selectedInventory.description,
                                editItemEcom: selectedInventory.ecom,
                                editItemKeywords: selectedInventory.keywords,
                                editItemColor: { ...selectedInventory.color },
                                editItemType: selectedInventory.type,
                                editItemSku: selectedInventory.sku,
                                editItemHsn: selectedInventory.hsn,
                                editItemQuantity: selectedInventory.quantity,
                                editItemUnit: selectedInventory.unit,
                                editItemDimension: { ...selectedInventory.dimensions },
                                editItemWeight: { ...selectedInventory.weight },
                                editItemInfo: { ...selectedInventory.info },
                                editItemPrice: { ...selectedInventory.price },
                                editItemTax: { ...selectedInventory.tax },
                                editItemImages: [...selectedInventory.images],
                                editItemCoupon: selectedInventory.coupon,
                                editItemExtraImages: [],
                                editColorChangeSetBool: false
                            })
                        }}></i>
                    </h2>
                    <h2 class="m-2">
                        <i class="fas fa-chart-bar inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Analytics" onClick={() => {
                            this.get_all_analytics();
                            this.setState({
                                transactionBool: false,
                                analyticsBool: true,
                                historyBool: false,
                                editItemBool: false
                            })
                        }}></i>
                    </h2>
                    <h2 class="m-2">
                        <i class="fas fa-rupee-sign inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" data-toggle="modal" title="Transactions" onClick={() => {
                            this.get_all_transactions();
                            this.setState({
                                transactionBool: true,
                                analyticsBool: false,
                                historyBool: false,
                                editItemBool: false
                            })
                        }}></i>
                    </h2>
                    <h2 class="m-2">
                        <i class="fas fa-history inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="History" onClick={() => {
                            this.get_all_history();
                            this.setState({
                                transactionBool: false,
                                analyticsBool: false,
                                historyBool: true,
                                editItemBool: false
                            })
                        }}></i>
                    </h2>
                    {delete_button}
                </div>
            </div>
        )
    }

    Transaction = (e) => {
        var transactions_ = []
        const total_pages = this.state.inventoryTransactions[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        for (var item in this.state.inventoryTransactions[0]) {
            var order_customer_stuff = []
            var quantity = []
            order_customer_stuff.push(
                <b>Customer: </b>
            )
            order_customer_stuff.push(
                this.state.inventoryTransactions[0][item].customer
            )
            if ("order_id" in this.state.inventoryTransactions[0][item] && this.state.inventoryTransactions[0][item].order_id.length > 0) {
                order_customer_stuff.push(
                    [
                        <br />,
                        <b>Order ID: </b>,
                        this.state.inventoryTransactions[0][item].order_id
                    ]
                )


            }
            transactions_.push(
                <li class="list-group-item border"><p><b>Invoice Number: </b>{this.state.inventoryTransactions[0][item].invoice_number}<br />{order_customer_stuff}<br /><b>Quantity: </b>{this.state.inventoryTransactions[0][item].items[0].count} <br /><b>Created On: </b>{moment(this.state.inventoryTransactions[0][item]._created.$date).format('Do MMMM YYYY, h:mm:ss A')}</p></li>
            )
        }
        return (
            <div>
                {/* <this.TransactionPagination /> */}
                <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                    <div class="d-inline-flex  justify-content-center align-items-center w-100">
                        <Pagination
                            innerClass="pagination"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.transactionsPageNumber}
                            itemsCountPerPage={15}
                            totalItemsCount={total_pages}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber) => {
                                this.setState({ transactionsPageNumber: parseInt(pageNumber) }, this.get_all_transactions)
                            }}
                        />
                    </div>
                </div>
                <ul class="list-group p-2">
                    {transactions_}
                </ul>
            </div>
        )
    }

    History = (e) => {

        var history_ = []

        for (var item in this.state.inventoryHistory[0]) {
            history_.push(
                <li class="list-group-item border"><p>{this.state.inventoryHistory[0][item].a} | {this.state.inventoryHistory[0][item].b} | {this.state.inventoryHistory[0][item].ad}</p> <p>{this.state.inventoryHistory[0][item].c.toString()}</p><p> {moment.utc(this.state.inventoryHistory[0][item].t.$date).format('Do MMMM YYYY, h:mm:ss A')}</p></li>
            )
        }

        const total_pages = this.state.inventoryHistory[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        return [
            // <this.HistoryPagination />,
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.historyPageNumber}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ historyPageNumber: parseInt(pageNumber) }, this.get_all_history)
                        }}
                    />
                </div>
            </div>,
            <ul class="list-group p-2">
                {history_}
            </ul>
        ]
    }

    Analytics = (e) => {

        var stockData = []
        var dateLabel = []
        var stockChangeData = []
        var salesCountData = []
        var salesData = []
        var totalSaleInRupees = this.state.inventoryAnalytics.tsl
        var totalUnitsSold = this.state.inventoryAnalytics.tsc
        var totalInvoices = this.state.inventoryAnalytics.tic
        var currentStock = this.state.inventoryAnalytics.ts

        for (var item in this.state.inventoryAnalytics["stock"]) {
            dateLabel.push(moment(new Date(parseInt(item) * 1000)).format('D MMM'))
            stockData.push(this.state.inventoryAnalytics["stock"][item])
            stockChangeData.push(this.state.inventoryAnalytics["sc"][item])
        }

        for (var item in this.state.inventoryAnalytics.sales) {
            salesCountData.push(this.state.inventoryAnalytics.sales[item].tc)
            salesData.push(this.state.inventoryAnalytics.sales[item].ts)
        }

        return (
            <div className="">
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <div className="d-flex flex-row w-100 p-2">
                        <div style={{ whiteSpace: 'nowrap' }} className="mr-4">
                            <label for="inventoryAnalyticsTotalSales">Total Sales</label>
                            <h3>₹ {totalSaleInRupees}</h3>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }} className="mr-4">
                            <label for="inventoryAnalyticsTotalUnitSales">Total Unit(s) Sold</label>
                            <h3>{totalUnitsSold}</h3>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }} className="mr-4">
                            <label for="inventoryAnalyticsTotalInvoices">Total Invoices</label>
                            <h3>{totalInvoices}</h3>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }} className="">
                            <label for="inventoryAnalyticsCurrentStock">Current Stock</label>
                            <h3>{currentStock}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                    <label for="inventoryAnalyticsRange">Range</label>
                    <select class="custom-select" name="range" id="inventoryAnalyticsRange" defaultValue={this.state.analyticsRange} value={this.state.analyticsRange} onChange={(e) => {
                        const unit = e.target.options[e.target.selectedIndex].getAttribute('unit')
                        this.setState({ analyticsRange: e.target.value, analyticsRangeUnit: unit }, this.get_all_analytics)
                    }}>
                        <option unit="Day" value="1">1 Day</option>
                        <option unit="Days" value="5">5 Days</option>
                        <option unit="Days" value="15">15 Days</option>
                        <option unit="Days" value="30">30 Days</option>
                        <option unit="Days" selected value="180">6 Months</option>
                        <option unit="Days" value="365">1 Year</option>
                        <option unit="" value="-1">All Time</option>
                    </select>
                </div>
                <div className="container">
                    <Line
                        data={
                            {
                                labels: dateLabel,
                                datasets: [{
                                    label: "Stock in last " + this.state.analyticsRange + " " + this.state.analyticsRangeUnit,
                                    data: stockData,
                                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                    borderColor: 'rgba(255, 99, 132, 1)',
                                    borderWidth: 1
                                }]

                            }
                        }

                        options={{ maintainAspectRatio: true }}

                    />
                </div>
                <div className="container">
                    <Bar
                        data={
                            {
                                labels: dateLabel,
                                datasets: [{
                                    label: "Stock Changes in last " + this.state.analyticsRange + " " + this.state.analyticsRangeUnit,
                                    data: stockChangeData,
                                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                    borderColor: 'rgba(54, 162, 235, 1)',
                                    borderWidth: 1
                                }]

                            }
                        }
                        options={{ maintainAspectRatio: true }}
                    />
                </div>
                <div className="container">
                    <Bar
                        data={
                            {
                                labels: dateLabel,
                                datasets: [{
                                    label: "Sales Count in last " + this.state.analyticsRange + " " + this.state.analyticsRangeUnit,
                                    data: salesCountData,
                                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                    borderColor: 'rgba(54, 162, 235, 1)',
                                    borderWidth: 1
                                }]

                            }
                        }
                        options={{ maintainAspectRatio: true }}
                    />
                </div>
                <div className="container">
                    <Line
                        data={
                            {
                                labels: dateLabel,
                                datasets: [{
                                    label: "Stock in last " + this.state.analyticsRange + " " + this.state.analyticsRangeUnit,
                                    data: stockData,
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.2)',

                                    ],
                                    borderColor: [
                                        'rgba(255, 99, 132, 1)',

                                    ],
                                    borderWidth: 1
                                },
                                {
                                    label: "Sales in last " + this.state.analyticsRange + " " + this.state.analyticsRangeUnit,
                                    data: salesCountData,
                                    backgroundColor: [
                                        'rgba(75, 192, 192, 0.2)',
                                    ],
                                    borderColor: [

                                        'rgba(75, 192, 192, 1)',

                                    ],
                                    borderWidth: 1
                                }]

                            }
                        }
                        options={{ maintainAspectRatio: true }}

                    />
                </div>
                <div className="container">
                    <Line
                        data={
                            {
                                labels: dateLabel,
                                datasets: [{
                                    label: "Sales in last " + this.state.analyticsRange + " " + this.state.analyticsRangeUnit,
                                    data: salesData,
                                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                    borderColor: 'rgba(54, 162, 235, 1)',
                                    borderWidth: 1
                                }]

                            }
                        }
                        options={{ maintainAspectRatio: true }}
                    />
                </div>
            </div>
        )
    }

    handleStockUpdate = (e) => {
        const that = this;

        var params = { ut: "s" }

        axios.patch(URL + 'inventory', {
            _id: this.state.selected_inventory_id,
            cs: this.state.editItemChangeStock,
            cst: e.target.name
        }, {
            params: params,
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.get_single_inventory()
                that.setState({ response: response["data"] })
            })
    }

    Status = (e) => {
        useEffect(() => {
            // Update the document title using the browser API
            if ("_status" in this.state.response) {
                document.getElementById('searchInventoryStatusDiv').scrollIntoView()
            }
        });
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div id="searchInventoryStatusDiv" class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div id="searchInventoryStatusDiv" class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }

    handleEdit = (e) => {

        const that = this;

        var tmp_images = []

        if (this.state.editItemExtraImages.length > 0) {
            for (var i in this.state.editItemExtraImages) {
                tmp_images.push(
                    {
                        "image": this.state.editItemExtraImages[i]["image"].split(",")[1],
                        "qs": this.state.editItemExtraImages[i]["qs"],
                        "qp": this.state.editItemExtraImages[i]["qp"],
                        "ip": this.state.editItemExtraImages[i]["ip"]
                    }
                )
            }
        }

        // prepare price
        var tmpPrice = this.state.editItemPrice
        tmpPrice["cp"] = parseFloat(tmpPrice["cp"]).toString()
        tmpPrice["sp"] = parseFloat(tmpPrice["sp"]).toString()

        this.setState({ response: {} })

        var params = { ut: "u" }

        axios.patch(URL + 'inventory', {
            _id: this.state.selected_inventory_id,
            name: this.state.editItemName,
            description: this.state.editItemDescription,
            ecomDescription: this.state.editItemEcomDescription,
            ecom: this.state.editItemEcom,
            keywords: this.state.editItemKeywords,
            color: this.state.editItemColor,
            type: this.state.editItemType,
            sku: this.state.editItemSku,
            hsn: this.state.editItemHsn,
            // quantity: this.state.editItemQuantity,
            // unit: this.state.editItemUnit,
            dimensions: this.state.editItemDimension,
            weight: this.state.editItemWeight,
            info: this.state.editItemInfo,
            price: tmpPrice,
            tax: this.state.editItemTax,
            images: this.state.editItemImages,
            coupon: this.state.editItemCoupon,
            extra_images: tmp_images,

            article: this.state.editItemArticle,
            material: this.state.editItemMaterial,
            gender: this.state.editItemGender,
            shape: this.state.editItemShape,
            occasion: this.state.editItemOccasion,
            purity: this.state.editItemPurity,
            plating: this.state.editItemPlating,
            countryoforigin: this.state.editItemCountryOfOrigin,
            maxperecomorder: parseInt(this.state.editItemMaxPerEcomOrder)

        }, {
            params: params,
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ response: response["data"] })

                if ("_status" in response.data) {
                    if (response.data._status === "success") {

                        that.setState({ editItemBool: false, response_under_buttons: {}, response: {} }, () => {
                            that.get_single_inventory();
                        })
                    }
                }
            })
    }

    SelectedInventory = (e) => {
        var dimension_string = ""
        if (this.state.selectedInventory.dimensions.height.length > 0) {
            dimension_string += this.state.selectedInventory.dimensions.height
        }
        else {
            dimension_string += "0"
        }
        if (this.state.selectedInventory.dimensions.width.length > 0) {
            dimension_string += " x " + this.state.selectedInventory.dimensions.width
        }
        else {
            dimension_string += " x 0"
        }
        if (this.state.selectedInventory.dimensions.depth.length > 0) {
            dimension_string += " x " + this.state.selectedInventory.dimensions.depth
        }
        else {
            dimension_string += " x 0"
        }
        dimension_string += " " + this.state.selectedInventory.dimensions.unit

        var weight_string = ""
        if (this.state.selectedInventory.weight.value.length > 0) {
            weight_string += this.state.selectedInventory.weight.value + " " + this.state.selectedInventory.weight.unit
        }
        else {
            weight_string += ""
        }


        // selected inventory button
        var selected_inventory_button = ""

        var delete_button = <h2 class="m-2">
            <i class="far fa-trash-alt inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Delete Item" data-toggle="modal" data-target="#deleteItemDialog"></i>
        </h2>
        if (TP !== "owner") {
            delete_button = ""
        }
        selected_inventory_button = <div>
            <div class="form-group row">
                <h2 class="m-2">
                    <i class="fas fa-edit inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Edit Item" onClick={() => {
                        const selectedInventory = this.state.selectedInventory
                        this.setState({
                            transactionBool: false,
                            analyticsBool: false,
                            historyBool: false,
                            editItemBool: true,
                            editItemName: selectedInventory.name,
                            editItemDescription: selectedInventory.description,
                            editItemEcom: selectedInventory.ecom,
                            editItemEcomDescription: selectedInventory.ecomDescription,
                            editItemKeywords: selectedInventory.keywords,
                            editItemColor: { ...selectedInventory.color },
                            editItemType: selectedInventory.type,
                            editItemSku: selectedInventory.sku,
                            editItemHsn: selectedInventory.hsn,
                            editItemQuantity: selectedInventory.quantity,
                            editItemUnit: selectedInventory.unit,
                            editItemDimension: { ...selectedInventory.dimensions },
                            editItemWeight: { ...selectedInventory.weight },
                            editItemInfo: { ...selectedInventory.info },
                            editItemPrice: { ...selectedInventory.price },
                            editItemTax: { ...selectedInventory.tax },
                            editItemImages: [...selectedInventory.images],
                            editItemCoupon: selectedInventory.coupon,
                            editItemExtraImages: [],
                            editColorChangeSetBool: false,

                            editItemArticle: selectedInventory.article,
                            editItemMaterial: selectedInventory.material,
                            editItemGender: selectedInventory.gender,
                            editItemShape: selectedInventory.shape,
                            editItemOccasion: selectedInventory.occasion,
                            editItemPurity: selectedInventory.purity,
                            editItemPlating: selectedInventory.plating,
                            editItemCountryOfOrigin: selectedInventory.countryoforigin,
                            editItemMaxPerEcomOrder:selectedInventory.maxperecomorder
                        })
                    }}></i>
                </h2>
                <h2 class="m-2">
                    <i class="fas fa-chart-bar inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="Analytics" onClick={() => {
                        this.get_all_analytics();
                        this.setState({
                            transactionBool: false,
                            analyticsBool: true,
                            historyBool: false,
                            editItemBool: false
                        })
                    }}></i>
                </h2>
                <h2 class="m-2">
                    <i class="fas fa-rupee-sign inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" data-toggle="modal" title="Transactions" onClick={() => {
                        this.get_all_transactions();
                        this.setState({
                            transactionBool: true,
                            analyticsBool: false,
                            historyBool: false,
                            editItemBool: false
                        })
                    }}></i>
                </h2>
                <h2 class="m-2">
                    <i class="fas fa-history inquiry_selected_buttons" data-toggle="tooltip" data-placement="bottom" title="History" onClick={() => {
                        this.get_all_history();
                        this.setState({
                            transactionBool: false,
                            analyticsBool: false,
                            historyBool: true,
                            editItemBool: false
                        })
                    }}></i>
                </h2>
                {delete_button}
            </div>
        </div>

        // image viewer
        var image_view = ""
        if (this.state.selectedInventory.images.length > 0) {
            var ret = []

            // add image tags
            for (var i in this.state.selectedInventory.images) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <a id={`imgex-` + this.state.selectedInventory.images[i]["image"]} href="" download="">
                            <i style={{ position: 'absolute', right: '0.5em', top: '0.5em', color: 'black', background: 'white' }} className="fas fa-download text-dark p-2" onClick={() => {

                            }}></i>
                        </a>
                        <img lid={this.state.selectedInventory.images[i]["image"]} class="card-img-top" id={`img-` + this.state.selectedInventory.images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                    </div>
                )
            }

            // aquire images
            for (var i in this.state.selectedInventory.images) {
                const that = this;

                const _id = this.state.selectedInventory.images[i]["image"]

                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            var ex_img_id = "imgex-" + _id
                            // alert(img_id)
                            try {
                                document.getElementById(img_id).setAttribute("src", imageDataUrl);
                                document.getElementById(ex_img_id).setAttribute("href", imageDataUrl);
                                document.getElementById(ex_img_id).setAttribute("download", that.state.selectedInventory.sku + "-" + _id);
                            } catch (err) {

                            }
                        }
                        console.log("image added")
                    })
            }


            image_view = <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Images</h5>
                    <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
                    <div class="d-flex flex-wrap align-items-center">
                        {ret}
                    </div>
                </div>
            </div>
        }

        // visibility
        var ecomVisibility = "Visible"

        if (!this.state.selectedInventory.ecom) {
            ecomVisibility = "Not Visible"
        }
        return (
            <div>
                {/* <this.SelectedInventoryNavigationButton /> */}
                {/* <this.SelectedInventoryButton /> */}
                {selected_inventory_button}
                {/* <div className="row m-0 mb-2 p-2" style={{backgroundColor:this.state.selectedInventory.color.color}}>

                </div> */}
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Name
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.name}</p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Description
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p dangerouslySetInnerHTML={{
                            __html: this.state.selectedInventory.description
                        }}>

                        </p>
                        {/* <p style={{
                            whiteSpace: "pre-wrap"
                        }}>{this.state.selectedInventory.description}</p> */}
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        E-commerce Platform Visibility
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <b>{ecomVisibility}</b>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Keywords
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.keywords}</p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        SKU
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.sku}</p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        HSN
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.hsn}</p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Quantity
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.quantity} {this.state.selectedInventory.unit}</p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Dimensions
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{dimension_string} </p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Weight
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{weight_string} </p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Coupon
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.coupon}</p>
                    </div>
                </div>
                <div className="row p-1 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <b>Info</b>
                    </div>
                </div>
                <div className="row p-2 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Manufacturer
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.info.manufacturer}</p>
                    </div>
                </div>
                <div className="row p-2 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        Brand
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.info.brand}</p>
                    </div>
                </div>
                <div className="row p-2 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        UPC
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.info.upc}</p>
                    </div>
                </div>
                <div className="row p-2 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        EAN
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.info.ean}</p>
                    </div>
                </div>
                <div className="row p-2 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        MPN
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.info.mpn}</p>
                    </div>
                </div>
                <div className="row p-2 m-0">
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        ISBN
                    </div>
                    <div className="col-xl-6 col-sm-12 col-lg-6 col-6 p-0">
                        <p>{this.state.selectedInventory.info.isbn}</p>
                    </div>
                </div>
                {image_view}
                {/* <this.Dialog name="delete" msg="Delete Item." h_text="Delete Item" c_text="btn-danger" b_label="Delete" handler={this.handleDelete} label="deleteItemDialog" /> */}

                <div class="modal fade" id={`deleteItemDialog`} tabindex="-1" role="dialog" aria-labelledby={`deleteItemDialog`} aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">{`Delete Item`}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>Delete Item.</p>
                                <p>Are you sure?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                                <button type="button" class={`btn ` + `btn-danger`} onClick={this.handleDelete} data-dismiss="modal">{`Delete`}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialogimage').style.transform = angle;
        })
    }

    FullScreenImageView = (e) => {
        return (
            <div id="fullscreenimageviewdialog" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog") {
                        $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog').attr('hidden', 'hidden') }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialogimage" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }

    ImageView = (e) => {

        if (this.state.selectedInventory.images.length > 0) {
            var ret = []

            // add image tags
            for (var i in this.state.selectedInventory.images) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <a id={`imgex-` + this.state.selectedInventory.images[i]["image"]} href="" download="">
                            <i className="text-dark" style={{ position: 'absolute', right: '0.5em', top: '0.5em', color: 'black' }} class="fas fa-external-link-alt" onClick={() => {

                            }}></i>
                        </a>
                        <img lid={this.state.selectedInventory.images[i]["image"]} class="card-img-top" id={`img-` + this.state.selectedInventory.images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                    </div>
                )
            }

            // aquire images
            for (var i in this.state.selectedInventory.images) {
                const that = this;

                const _id = this.state.selectedInventory.images[i]["image"]

                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            var ex_img_id = "imgex-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);
                            document.getElementById(ex_img_id).setAttribute("href", imageDataUrl);
                            document.getElementById(ex_img_id).setAttribute("download", that.state.selectedInventory.sku + "-" + _id);
                        }
                        console.log("image added")
                    })
            }


            return (
                <div class="card" >
                    <div class="card-body">
                        <h5 class="card-title">Images</h5>
                        <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
                        <div class="d-flex flex-wrap align-items-center">
                            {ret}
                        </div>
                    </div>
                </div>
            )
        }
        return ("")
    }

    ImageViewEdit = (e) => {
        var ret = []
        if ((this.state.editItemImages.length + this.state.editItemExtraImages.length) > 0) {

            // add image tags
            for (var i in this.state.editItemImages) {
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.editItemImages[i]["image"]} class="card-img-top" id={`img-` + this.state.editItemImages[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid_ = e.target.getAttribute("iid")
                            var tmp_edititem_images = this.state.editItemImages;
                            tmp_edititem_images.splice(parseInt(iid_), 1);
                            this.setState({ editItemImages: tmp_edititem_images });
                        }}> Delete</i>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="editPrimaryImageRadio" id={`editPrimaryImageRadio` + i} value={this.state.editItemImages[i]["ip"]} onChange={(e) => {
                                var tmpImages = this.state.editItemImages
                                tmpImages[i]["ip"] = e.target.checked
                                this.setState({ editItemImages: tmpImages })
                            }} />
                            <label class="form-check-label" for={`editPrimaryImageRadio` + i}>
                                Is Primary
                            </label>
                        </div>
                    </div>
                )
            }

            // aquire images
            for (var i in this.state.editItemImages) {
                const that = this;

                const _id = this.state.editItemImages[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);

                        }
                        console.log("image added")
                    })
            }


            for (var i in this.state.editItemExtraImages) {
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img iid={i} src={this.state.editItemExtraImages[i]["image"]} class="card-img-top" onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />

                        <div className="inquiry_selected_buttons">
                            <i class="fas fa-times" iid={i} onClick={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images.splice(parseInt(iid), 1)
                                this.setState({ images: tmp_images })
                            }}></i>&nbsp;<span iid={i} onClick={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images.splice(parseInt(iid), 1)
                                this.setState({ images: tmp_images })
                            }}>Delete</span>
                        </div>
                        <div className="">
                            QR Position
                            <select class="custom-select" name="type" id={`editAddIventoryImageQRPos` + i} defaultValue={this.state.editItemExtraImages[i]["qp"]} iid={i} onChange={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images[i]["qp"] = e.target.value;
                                this.setState({ images: tmp_images })
                            }}>
                                <option selected value="br">Bottom Right</option>
                                <option value="tl">Top Left</option>
                                <option value="tr">Top Right</option>
                                <option value="bl">Bottom Left</option>
                            </select>
                        </div>
                        <div className="">
                            QR Size
                            <select class="custom-select" name="type" id={`editAddIventoryImageQRSize` + i} defaultValue={this.state.editItemExtraImages[i]["qs"]} iid={i} onChange={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images[i]["qs"] = e.target.value;
                                this.setState({ images: tmp_images })
                            }}>
                                <option selected value="100">100 px</option>
                                <option value="200">200 px</option>
                                <option value="300">300 px</option>
                            </select>
                        </div>
                        <div className="">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="editPrimaryImageRadio" id={`editPrimaryImageRadio` + i} value={this.state.editItemExtraImages[i]["ip"]} onChange={(e) => {
                                    var tmpImages = this.state.editItemExtraImages
                                    tmpImages[i]["ip"] = e.target.checked
                                    this.setState({ images: tmpImages })
                                }} />
                                <label class="form-check-label" for={`editPrimaryImageRadio` + i}>
                                    Is Primary
                                </label>
                            </div>
                        </div>

                    </div>
                )
            }

        }

        var help_text = ""
        if (this.state.editItemImages.length + this.state.editItemExtraImages.length > 0) {

            help_text = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Edit Images</h5>
                    {help_text}
                    <div class="d-flex flex-wrap align-items-center">
                        {ret}
                    </div>
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile2').click();
                    }}>Add Image(s)</button>
                    {/* {clear_all_button} */}
                    <input type="file" class="form-control-file" id="exampleFormControlFile2" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.editItemExtraImages;

                                tmp_images.push(
                                    {
                                        "image": e.target.result,
                                        "qp": "br",
                                        "qs": "100",
                                        "ip": false
                                    }
                                )
                                that.setState({ editItemExtraImages: tmp_images })
                                document.getElementById('exampleFormControlFile2').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )


    }

    EditColorChange = () => {
        const handleColorChange = useCallback((color, instance) => {
            var tmp_color = this.state.editItemColor
            tmp_color['color'] = color.toHEXA().toString()
            this.setState({ editItemColor: tmp_color })
        }, []);
        useEffect(() => {
            if (!this.state.editColorChangeSetBool) {
                const pickr = Pickr.create({
                    el: '.color-picker-edit',
                    theme: 'classic', // or 'monolith', or 'nano'

                    swatches: [
                        'rgba(244, 67, 54, 1)',
                        'rgba(233, 30, 99, 0.95)',
                        'rgba(156, 39, 176, 0.9)',
                        'rgba(103, 58, 183, 0.85)',
                        'rgba(63, 81, 181, 0.8)',
                        'rgba(33, 150, 243, 0.75)',
                        'rgba(3, 169, 244, 0.7)',
                        'rgba(0, 188, 212, 0.7)',
                        'rgba(0, 150, 136, 0.75)',
                        'rgba(76, 175, 80, 0.8)',
                        'rgba(139, 195, 74, 0.85)',
                        'rgba(205, 220, 57, 0.9)',
                        'rgba(255, 235, 59, 0.95)',
                        'rgba(255, 193, 7, 1)'
                    ],
                    default: this.state.editItemColor.color,
                    defaultRepresentation: 'HEX',
                    components: {

                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: false,
                            save: true
                        }
                    }
                });
                pickr.on('save', handleColorChange)
                this.setState({ editColorChangeSetBool: true })
            }
        })

        return (
            ""
        )
    }

    resetAllFields = () => {
        this.setState({
            response: {},
            editItemName: "",
            editItemDescription: "",
            editItemEcomDescription: "",
            editItemKeywords: "",
            editItemColor: {
                color: "",
                colorn: ""
            },
            editItemSku: "",
            editItemHsn: "",
            editItemType: "Goods",
            editItemUnit: "box",
            editItemQuantity: "0",
            editItemDimension: {
                height: "0",
                width: "0",
                depth: "0",
                unit: "cm"
            },
            editItemWeight: {
                value: "0",
                unit: 'kg'
            },
            editItemInfo: {
                manufacturer: "",
                brand: "",
                upc: "",
                ean: "",
                mpn: "",
                isbn: ""
            },
            editItemPrice: {
                cp: "0",
                sp: "0",
                // unit: "inr"
            },
            editItemTax: {
                p: "0",
                // c: "igst",
                t: "inc"
            },
            editItemResponse: {},
            editItemImages: [],
            editItemExtraImages: [],

            editItemChangeStock: 0,
            editItemCoupon: "",

            editItemArticle: "",
            editItemMaterial: "",
            editItemGender: "",
            editItemShape: "",
            editItemOccasion: "",
            editItemPurity: "",
            editItemPlating: "",
            editItemCountryOfOrigin: "",
            editItemMaxPerEcomOrder:0
        })
    }

    EditItem = (e) => {
        const handleColorChange = useCallback((color, instance) => {
            var tmp_color = this.state.editItemColor
            tmp_color['color'] = color.toHEXA().toString()
            this.setState({ editItemColor: tmp_color })
        }, []);

        useEffect(() => {
            if (!this.state.editColorChangeSetBool) {
                const pickr = Pickr.create({
                    el: '.color-picker-edit',
                    theme: 'classic', // or 'monolith', or 'nano'

                    swatches: [
                        'rgba(244, 67, 54, 1)',
                        'rgba(233, 30, 99, 0.95)',
                        'rgba(156, 39, 176, 0.9)',
                        'rgba(103, 58, 183, 0.85)',
                        'rgba(63, 81, 181, 0.8)',
                        'rgba(33, 150, 243, 0.75)',
                        'rgba(3, 169, 244, 0.7)',
                        'rgba(0, 188, 212, 0.7)',
                        'rgba(0, 150, 136, 0.75)',
                        'rgba(76, 175, 80, 0.8)',
                        'rgba(139, 195, 74, 0.85)',
                        'rgba(205, 220, 57, 0.9)',
                        'rgba(255, 235, 59, 0.95)',
                        'rgba(255, 193, 7, 1)'
                    ],
                    default: this.state.editItemColor.color,
                    defaultRepresentation: 'HEX',
                    components: {

                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: false,
                            save: true
                        }
                    }
                });
                pickr.on('save', handleColorChange)
                this.setState({ editColorChangeSetBool: true })
            }
        })

        var image_view_edit = ""
        var ret = []
        if ((this.state.editItemImages.length + this.state.editItemExtraImages.length) > 0) {

            // add image tags
            for (var i in this.state.editItemImages) {
                const idx = i
                const that = this;
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.editItemImages[i]["image"]} class="card-img-top" id={`img-` + this.state.editItemImages[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i class="fas fa-times" iid={i} onClick={(e) => {
                            var iid_ = e.target.getAttribute("iid")
                            var tmp_edititem_images = this.state.editItemImages;
                            tmp_edititem_images.splice(parseInt(iid_), 1);
                            this.setState({ editItemImages: tmp_edititem_images });
                        }}> Delete</i>
                        <div class="form-check">
                            <input class="form-check-input" iid={idx} type="radio" name="editPrimaryImageRadio" id={`editPrimaryImageRadio` + i}
                                checked={this.state.editItemImages[idx]["ip"]} defaultChecked={this.state.editItemImages[i]["ip"]} onChange={(e) => {
                                    var iid_ = e.target.getAttribute("iid")
                                    var tmpImages = this.state.editItemImages
                                    var tmpExtraEditImages = this.state.editItemExtraImages

                                    // all false
                                    for (var ii in tmpImages) {
                                        tmpImages[ii]["ip"] = false
                                    }
                                    for (var ii in tmpExtraEditImages) {
                                        tmpExtraEditImages[ii]["ip"] = false
                                    }

                                    tmpImages[iid_]["ip"] = e.target.checked
                                    this.setState({ editItemImages: tmpImages, editItemExtraImages: tmpExtraEditImages })

                                }} />
                            <label class="form-check-label" for={`editPrimaryImageRadio` + i}>
                                Is Primary
                            </label>
                        </div>

                    </div>
                )
            }

            // aquire images
            for (var i in this.state.editItemImages) {
                const that = this;

                const _id = this.state.editItemImages[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {
                            try {
                                var imageDataUrl = reader.result;
                                var img_id = "img-" + _id
                                document.getElementById(img_id).setAttribute("src", imageDataUrl);
                            }
                            catch (err) {

                            }

                        }
                        console.log("image added")
                    })
            }


            for (var i in this.state.editItemExtraImages) {
                const ii = i
                ret.push(
                    <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img iid={i} src={this.state.editItemExtraImages[i]["image"]} class="card-img-top" onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />

                        <div className="inquiry_selected_buttons">
                            <i class="fas fa-times" iid={i} onClick={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images.splice(parseInt(iid), 1)
                                this.setState({ images: tmp_images })
                            }}></i>&nbsp;<span iid={i} onClick={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images.splice(parseInt(iid), 1)
                                this.setState({ images: tmp_images })
                            }}>Delete</span>
                        </div>
                        <div className="">
                            QR Position
                            <select class="custom-select" name="type" id={`editAddIventoryImageQRPos` + i} defaultValue={this.state.editItemExtraImages[i]["qp"]} iid={ii} onChange={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images[ii]["qp"] = e.target.value;
                                // alert(e.target.value)
                                this.setState({ images: tmp_images })
                            }}>
                                <option selected value="br">Bottom Right</option>
                                <option value="tl">Top Left</option>
                                <option value="tr">Top Right</option>
                                <option value="bl">Bottom Left</option>
                            </select>
                        </div>
                        <div className="">
                            QR Size
                            <select class="custom-select" name="type" id={`editAddIventoryImageQRSize` + i} defaultValue={this.state.editItemExtraImages[i]["qs"]} iid={i} onChange={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.editItemExtraImages;
                                tmp_images[i]["qs"] = e.target.value;
                                this.setState({ images: tmp_images })
                            }}>
                                <option selected value="100">100 px</option>
                                <option value="200">200 px</option>
                                <option value="300">300 px</option>
                            </select>
                        </div>
                        <div className="">
                            <div class="form-check">
                                <input class="form-check-input" iid={i} type="radio" name="editPrimaryImageRadio" id={`editEditPrimaryImageRadio` + i}
                                    value={this.state.editItemExtraImages[i]["ip"]} onChange={(e) => {

                                        var iid_ = e.target.getAttribute("iid")
                                        var tmpImages = this.state.editItemImages
                                        var tmpExtraEditImages = this.state.editItemExtraImages

                                        // all false
                                        for (var ii in tmpImages) {
                                            tmpImages[ii]["ip"] = false
                                        }
                                        for (var ii in tmpExtraEditImages) {
                                            tmpExtraEditImages[ii]["ip"] = false
                                        }

                                        tmpExtraEditImages[iid_]["ip"] = e.target.checked
                                        this.setState({ editItemImages: tmpImages, editItemExtraImages: tmpExtraEditImages })


                                    }} />
                                <label class="form-check-label" for={`editEditPrimaryImageRadio` + i}>
                                    Is Primary
                                </label>
                            </div>
                        </div>

                    </div>
                )
            }

        }

        var help_text = ""
        if (this.state.editItemImages.length + this.state.editItemExtraImages.length > 0) {

            help_text = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
        }

        image_view_edit = <div class="card" >
            <div class="card-body">
                <h5 class="card-title">Edit Images</h5>
                {help_text}
                <div class="d-flex flex-wrap align-items-center">
                    {ret}
                </div>
                <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                    document.getElementById('exampleFormControlFile2').click();
                }}>Add Image(s)</button>
                {/* {clear_all_button} */}
                <input type="file" class="form-control-file" id="exampleFormControlFile2" onChange={(input) => {
                    const that = this;

                    for (var i = 0; i < input.target.files.length; i++) {
                        if (input.target.files[i]["type"] !== "image/jpeg") {
                            continue;
                        }
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            var tmp_images = that.state.editItemExtraImages;

                            tmp_images.push(
                                {
                                    "image": e.target.result,
                                    "qp": "br",
                                    "qs": "100"
                                }
                            )
                            that.setState({ editItemExtraImages: tmp_images })
                            document.getElementById('exampleFormControlFile2').value = "";

                        }
                        reader.readAsDataURL(input.target.files[i])
                    }
                }} multiple hidden />
            </div>
        </div>

        return [
            <div className="container p-4">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryStock"><b>Current Stock</b></label>
                        <p>
                            <b>{this.state.editItemQuantity}</b>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryName"><b>Change Stock</b></label>
                        <div className="input-group">
                            <input type="number" id="inventoryName" className="form-control" name="stockquantity" value={this.state.editItemChangeStock} onChange={(e) => {
                                this.setState({ editItemChangeStock: e.target.value })
                            }} />
                            <div class="input-group-append">

                                <button className="btn btn-success" name="add" data-toggle="tooltip" data-placement="bottom" title="Change Item Stock" data-toggle="modal" data-target="#updateStockAddItemDialog">Add</button>
                                <button className="btn btn-danger" name="remove" data-toggle="tooltip" data-placement="bottom" title="Change Item Stock" data-toggle="modal" data-target="#updateStockRemoveItemDialog">Remove</button>
                            </div>
                        </div>
                    </div>

                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <label for="inventoryName"><b>Name</b></label>
                        <input type="text" id="inventoryName" className="form-control border-1 small" name="name" value={this.state.editItemName} onChange={(e) => {
                            this.setState({ editItemName: e.target.value })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryType"><b>Type</b></label>
                        <select class="custom-select" name="type" id="inventoryType" value={this.state.editItemType} defaultValue={this.state.editItemType} onChange={(e) => {
                            this.setState({ editItemType: e.target.value })
                        }}>
                            <option value="Goods">Goods</option>
                            <option value="Service">Service</option>
                        </select>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-12">
                        <label for="inventoryDescription"><b>Description</b></label>
                        <textarea rows="6" type="text" id="inventoryDescription" className="form-control border-1 small" name="name" value={this.state.editItemDescription} onChange={(e) => {
                            this.setState({ editItemDescription: e.target.value })
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryColor"><b>Color</b></label>
                        <div className="d-flex d-flex-inline align-items-center">
                            {/* <this.EditColorChange /> */}
                            <span className="color-picker-edit"></span>
                            <input type="text" id="inventoryColor" className="form-control border-1 small ml-2" name="colorn" value={this.state.editItemColor.colorn} onChange={(e) => {
                                var tmp_color = this.state.editItemColor
                                tmp_color[e.target.name] = e.target.value
                                this.setState({ editItemColor: tmp_color })
                            }} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="inventoryEditEcomDescription"><b>E-commerce Description</b></label>
                        <ReactQuill id="inventoryEditEcomDescription" theme="snow" value={this.state.editItemEcomDescription || ''} onChange={(data) => {
                            this.setState({ editItemEcomDescription: data })
                        }} />
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label htmlFor="inventoryEditEcom"><b>E-commerce Visibility</b></label>
                        <br />
                        <small>Should the item appear on E-commerce platform?</small>
                        <br />
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="inventoryEditEcom" defaultChecked={this.state.editItemEcom} onChange={(e) => {
                                this.setState({ editItemEcom: e.target.checked })
                            }} checked={this.state.editItemEcom} />
                            <label className="form-check-label" htmlFor="inventoryEditEcom">
                                Show on e-commerce platform
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="editInventoryKeywords"><b>Keywords</b></label>
                        <input type="text" id="editInventoryKeywords" className="form-control border-1 small" name="keywords" value={this.state.editItemKeywords} onChange={(e) => {
                            this.setState({ editItemKeywords: e.target.value })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventorySku"><b>SKU</b></label>
                        <input type="text" id="inventorySku" className="form-control border-1 small" name="name" value={this.state.editItemSku} onChange={(e) => {
                            this.setState({ editItemSku: e.target.value })
                        }} disabled />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryHsn"><b>HSN</b></label>
                        <input type="text" id="inventoryHsn" className="form-control border-1 small" name="name" value={this.state.editItemHsn} onChange={(e) => {
                            this.setState({ editItemHsn: e.target.value })
                        }} />
                    </div>
                </div>

                < div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Dimension</b>
                    </div>
                </div >

                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label for="inventoryDimensionHeight">Height</label>
                        <input type="number" id="inventoryDimensionHeight" className="form-control border-1 small" name="height" value={this.state.editItemDimension.height} onChange={(e) => {
                            var tmp_dimension = this.state.editItemDimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ editItemDimension: tmp_dimension })

                        }} />
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <label for="inventoryDimensionWidth">Width</label>
                        <input type="number" id="inventoryDimensionWidth" className="form-control border-1 small" name="width" value={this.state.editItemDimension.width} onChange={(e) => {
                            var tmp_dimension = this.state.editItemDimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ editItemDimension: tmp_dimension })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <label for="inventoryDimensionDepth">Depth</label>
                        <input type="number" id="inventoryDimensionDepth" className="form-control border-1 small" name="depth" value={this.state.editItemDimension.depth} onChange={(e) => {
                            var tmp_dimension = this.state.editItemDimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ editItemDimension: tmp_dimension })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <label for="inventoryDimensionUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryDimensionUnit" defaultValue={this.state.editItemDimension.unit} value={this.state.editItemDimension.unit} onChange={(e) => {
                            var tmp_dimension = this.state.editItemDimension
                            tmp_dimension[e.target.name] = e.target.value
                            this.setState({ editItemDimension: tmp_dimension })
                        }}>
                            <option value="km">km</option>
                            <option value="m">m</option>
                            <option value="ft">ft</option>
                            <option value="in">in</option>
                            <option selected value="cm">cm</option>
                            <option value="mm">mm</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Weight</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">

                        <label for="inventoryWeightValue">Value</label>
                        <input type="number" id="inventoryWeightValue" className="form-control border-1 small" name="value" value={this.state.editItemWeight.value} onChange={(e) => {
                            var tmp_weight = this.state.editItemWeight
                            tmp_weight[e.target.name] = e.target.value
                            this.setState({ editItemWeight: tmp_weight })

                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryWeightUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryWeightUnit" defaultValue={this.state.editItemWeight.unit} value={this.state.editItemWeight.unit} onChange={(e) => {
                            var tmp_weight = this.state.editItemWeight
                            tmp_weight[e.target.name] = e.target.value
                            this.setState({ editItemWeight: tmp_weight })
                        }}>
                            <option value="tn">tn</option>
                            <option value="kg">kg</option>
                            <option value="lbs">lbs</option>
                            <option value="gms">gms</option>
                            <option value="mg">mg</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Info</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryInfoManufacturer">Manufacturer</label>
                        <input type="text" id="inventoryInfoManufacturer" className="form-control border-1 small" name="manufacturer" value={this.state.editItemInfo.manufacturer} onChange={(e) => {
                            var tmp_info = this.state.editItemInfo
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ editItemInfo: tmp_info })
                        }} />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryInfoBrand">Brand</label>
                        <input type="text" id="inventoryInfoBrand" className="form-control border-1 small" name="brand" value={this.state.editItemInfo.brand} onChange={(e) => {
                            var tmp_info = this.state.editItemInfo
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ editItemInfo: tmp_info })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryInfoUpc">UPC</label>
                        <input type="text" id="inventoryInfoUpc" className="form-control border-1 small" name="upc" value={this.state.editItemInfo.upc} onChange={(e) => {
                            var tmp_info = this.state.editItemInfo
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ editItemInfo: tmp_info })
                        }} />

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryInfoEan">EAN</label>
                        <input type="text" id="inventoryInfoEan" className="form-control border-1 small" name="ean" value={this.state.editItemInfo.ean} onChange={(e) => {
                            var tmp_info = this.state.editItemInfo
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ editItemInfo: tmp_info })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryInfoMpn">MPN</label>
                        <input type="text" id="inventoryInfoMpn" className="form-control border-1 small" name="mpn" value={this.state.editItemInfo.mpn} onChange={(e) => {
                            var tmp_info = this.state.editItemInfo
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ editItemInfo: tmp_info })
                        }} />

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label for="inventoryInfoIsbn">ISBN</label>
                        <input type="text" id="inventoryInfoIsbn" className="form-control border-1 small" name="isbn" value={this.state.editItemInfo.isbn} onChange={(e) => {
                            var tmp_info = this.state.editItemInfo
                            tmp_info[e.target.name] = e.target.value
                            this.setState({ editItemInfo: tmp_info })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <b>Price</b>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryPriceCp">Cost Price</label>
                        <input type="number" id="inventoryPriceCp" className="form-control border-1 small" name="cp" value={this.state.editItemPrice.cp} onChange={(e) => {
                            var tmp_price = this.state.editItemPrice
                            tmp_price[e.target.name] = parseFloat(e.target.value).toString()
                            this.setState({ editItemPrice: tmp_price })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryPriceSp">Selling Price</label>
                        <input type="number" id="inventoryPriceSp" className="form-control border-1 small" name="sp" value={this.state.editItemPrice.sp} onChange={(e) => {
                            var tmp_price = this.state.editItemPrice
                            tmp_price[e.target.name] = parseFloat(e.target.value).toString()
                            this.setState({ editItemPrice: tmp_price })
                        }} />

                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryPriceMrp">MRP</label>
                        <input type="number" id="inventoryPriceMrp" className="form-control border-1 small" name="mrp" value={this.state.editItemPrice.mrp} onChange={(e) => {
                            var tmp_price = this.state.editItemPrice
                            tmp_price[e.target.name] = parseFloat(e.target.value).toString()
                            this.setState({ editItemPrice: tmp_price })
                        }} />

                    </div>
                    {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryPriceUnit">Unit</label>
                        <select class="custom-select" name="unit" id="inventoryPriceUnit" defaultValue={this.state.editItemPrice.unit} value={this.state.editItemPrice.unit} onChange={(e) => {
                            var tmp_price = this.state.editItemPrice
                            tmp_price[e.target.name] = e.target.value
                            this.setState({ editItemPrice: tmp_price })
                        }}>
                            <option value="inr">INR</option>
                            <option value="usd">USD</option>
                        </select>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryTaxP">Tax Bracket (%)</label>
                        <input type="number" id="inventoryTaxP" className="form-control border-1 small" name="p" value={this.state.editItemTax.p} onChange={(e) => {
                            var tmp_tax = this.state.editItemTax
                            var val = e.target.value
                            if (parseInt(val) < 0) {
                                val = 0
                            }
                            if (parseInt(val) > 100) {
                                val = 100
                            }
                            tmp_tax[e.target.name] = val
                            this.setState({ editItemTax: tmp_tax })
                        }} />
                    </div>
                    {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryTaxCategory">Tax Category</label>
                        <select class="custom-select" name="c" id="inventoryTaxCategory" defaultValue={this.state.editItemTax.c} value={this.state.editItemTax.c} onChange={(e) => {
                            var tmp_tax = this.state.editItemTax
                            tmp_tax[e.target.name] = e.target.value
                            this.setState({ editItemTax: tmp_tax })
                        }}>
                            <option value="cgst">CGST</option>
                            <option value="utgst">UTGST</option>
                            <option value="sgst">SGST</option>
                            <option value="igst">IGST</option>
                        </select>
                    </div> */}
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryTaxType">Tax Type</label>
                        <select class="custom-select" name="t" id="inventoryTaxType" defaultValue={this.state.editItemTax.t} value={this.state.editItemTax.t} onChange={(e) => {
                            var tmp_tax = this.state.editItemTax
                            tmp_tax[e.target.name] = e.target.value
                            this.setState({ editItemTax: tmp_tax })
                        }}>
                            <option value="inc">Inclusive</option>
                            <option value="exc">Exclusive</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label for="inventoryEditCoupon"><b>Coupon</b></label>
                        <input maxLength="12" type="text" id="inventoryEditCoupon" className="form-control border-1 small" name="coupon" value={this.state.editItemCoupon} onChange={(e) => {
                            this.setState({ editItemCoupon: e.target.value })
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditArticle"><b>Article</b></label>
                        <input maxLength="100" type="text" id="inventoryEditArticle" className="form-control border-1 small" name="article" value={this.state.editItemArticle} onChange={(e) => {
                            this.setState({ editItemArticle: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditMaterial"><b>Material</b></label>
                        <input maxLength="100" type="text" id="inventoryEditMaterial" className="form-control border-1 small" name="material" value={this.state.editItemMaterial} onChange={(e) => {
                            this.setState({ editItemMaterial: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditGender"><b>Gender</b></label>
                        <input maxLength="100" type="text" id="inventoryEditGender" className="form-control border-1 small" name="gender" value={this.state.editItemGender} onChange={(e) => {
                            this.setState({ editItemGender: e.target.value })
                        }} />
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditShape"><b>Shape</b></label>
                        <input maxLength="100" type="text" id="inventoryEditShape" className="form-control border-1 small" name="shape" value={this.state.editItemShape} onChange={(e) => {
                            this.setState({ editItemShape: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditOccasion"><b>Occasion</b></label>
                        <input maxLength="100" type="text" id="inventoryEditOccasion" className="form-control border-1 small" name="occasion" value={this.state.editItemOccasion} onChange={(e) => {
                            this.setState({ editItemOccasion: e.target.value })
                        }} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditPurity"><b>Purity</b></label>
                        <input maxLength="100" type="text" id="inventoryEditPurity" className="form-control border-1 small" name="purity" value={this.state.editItemPurity} onChange={(e) => {
                            this.setState({ editItemPurity: e.target.value })
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditPlating"><b>Plating</b></label>
                        <input maxLength="100" type="text" id="inventoryEditPlating" className="form-control border-1 small" name="plating" value={this.state.editItemPlating} onChange={(e) => {
                            this.setState({ editItemPlating: e.target.value })
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditCountryOfOrigin"><b>Country Of Origin</b></label>
                        {/* <input maxLength="100" type="text" id="inventoryEditCountryOfOrigin" className="form-control border-1 small" name="countryoforigin" value={this.state.editItemCountryOfOrigin} onChange={(e) => {
                            this.setState({ editItemCountryOfOrigin: e.target.value })
                        }} /> */}
                        <select className='form-control' value={this.state.editItemCountryOfOrigin} onChange={(e)=>{
                            this.setState({ editItemCountryOfOrigin: e.target.value })
                        }}>
                            <option value="">Select Country of Origin</option>
                            {
                                country_json_1.map((item, index) => (
                                    <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label htmlFor="inventoryEditMaxPerEcomOrder"><b>Max Item Per Ecom Order</b></label>
                        <input min="0" type="number" id="inventoryEditMaxPerEcomOrder" className="form-control border-1 small" name="maxperecomorder" value={this.state.editItemMaxPerEcomOrder} onChange={(e) => {
                            this.setState({ editItemMaxPerEcomOrder: e.target.value })
                        }} />
                    </div>

                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
                    <label for=""><b>Images</b></label>

                    {/* <this.ImageViewEdit /> */}
                    {image_view_edit}
                </div>
                <div className="row mt-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <button className="btn btn-primary" id="updateItemButton" data-toggle="tooltip" data-placement="bottom" title="Delete Item" data-toggle="modal" data-target="#updateItemDialog">Update</button>
                        <button className="btn btn-primary m-2" onClick={this.resetAllFields}>Clear</button>
                        {/* <this.Status /> */}
                    </div>
                </div>
            </div >,

            <div class="modal fade" id={`updateItemDialog`} tabindex="-1" role="dialog" aria-labelledby={`updateItemDialog`} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{`Update Item`}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{`Update Item.`}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" class={`btn ` + `btn-danger`} onClick={this.handleEdit} data-dismiss="modal">{`Update`}</button>
                        </div>
                    </div>
                </div>
            </div>,
            <div class="modal fade" id={`updateStockAddItemDialog`} tabindex="-1" role="dialog" aria-labelledby={`updateStockAddItemDialog`} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{`Update Stock`}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{`Add ` + this.state.editItemChangeStock + ` To Stock.`}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" name="add" class={`btn ` + `btn-success`} onClick={this.handleStockUpdate} data-dismiss="modal">{`Add`}</button>
                        </div>
                    </div>
                </div>
            </div>,
            <div class="modal fade" id={`updateStockRemoveItemDialog`} tabindex="-1" role="dialog" aria-labelledby={`updateStockRemoveItemDialog`} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{`Update Stock`}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{`Remove ` + this.state.editItemChangeStock + ` From Stock.`}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" name="remove" class={`btn ` + `btn-danger`} onClick={this.handleStockUpdate} data-dismiss="modal">{`Remove`}</button>
                        </div>
                    </div>
                </div>
            </div>


            // <this.Dialog name="update" msg="Update Item." h_text="Update Item" c_text="btn-danger" b_label="Update" handler={this.handleEdit} label="updateItemDialog" />,
            // <this.Dialog name="updatestock" b_name="add" msg={`Add ` + this.state.editItemChangeStock + ` To Stock.`} h_text="Update Stock" c_text="btn-success" b_label="Add" handler={this.handleStockUpdate} label="updateStockAddItemDialog" />,
            // <this.Dialog name="updatestock" b_name="remove" msg={`Remove ` + this.state.editItemChangeStock + ` From Stock.`} h_text="Update Stock" c_text="btn-danger" b_label="Remove" handler={this.handleStockUpdate} label="updateStockRemoveItemDialog" />


        ]
    }

    Inventory = (e) => {

        // if (this.state.retrivedInventory === undefined) {
        //     return ""
        // }

        var inventory_ = []

        console.log(this.state.retrivedInventory)

        for (var item in this.state.retrivedInventory[0]) {
            const idx = item
            var attachment_badge = []

            if ("images" in this.state.retrivedInventory[0][item]) {
                var count = Object.keys(this.state.retrivedInventory[0][item]["images"]).length;
                if (count > 0) {
                    attachment_badge = <i lid={this.state.retrivedInventory[0][item]["_id"]["$oid"]} className="fas fa-paperclip mr-2"></i>
                }
            }


            // check if price exist

            var price = null
            if (parseFloat(this.state.retrivedInventory[0][item].price.sp) > 0) {
                price = [<br />, <span lid={this.state.retrivedInventory[0][item]._id.$oid}>
                    ₹ {parseFloat(this.state.retrivedInventory[0][item].price.sp).toLocaleString("en-IN")} INR
                </span>]
            }

            inventory_.push(

                <a key={`retrievedInventory` + item} class="inquiry_item list-group-item d-flex justify-content-between align-items-center border" lid={this.state.retrivedInventory[0][item]._id.$oid} onClick={(e) => {
                    const lid_ = e.target.getAttribute("lid")
                    this.setState({ selected_inventory_id: lid_ }, this.get_single_inventory)
                }}><span lid={this.state.retrivedInventory[0][item]._id.$oid}><b lid={this.state.retrivedInventory[0][item]._id.$oid}>{this.state.retrivedInventory[0][item].name}</b><br />
                        {this.state.retrivedInventory[0][item].sku}
                        {price}
                    </span><span lid={this.state.retrivedInventory[0][item]._id.$oid}>
                        <i lid={this.state.retrivedInventory[0][item]["_id"]["$oid"]} className="fas fa-tag mr-2" onClick={(e) => {
                            e.stopPropagation()
                            fullDialogDispatcher.dispatch({
                                actionType: "show-dialog",
                                title: "Print Tag",
                                message: <PrintTag data={this.state.retrivedInventory[0][idx]} />
                            })
                        }}></i>{attachment_badge}{moment(this.state.retrivedInventory[0][item]["_created"].$date).format('D MMM')}</span></a>

            )
        }

        return (
            <div class="list-group">
                {inventory_}
            </div>
        )
    }

    Section_header_search_reset = (e) => {
        if (this.state.search_term === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ search_term: "" }, this.get_all_inventory);
                }}></i>
            )
        }
    }

    render() {
        if (this.state.transactionBool) {
            return [
                <div className="container p-4">
                    <div class="row p-0 m-0">
                        <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                            this.setState({ transactionBool: false, response_under_buttons: {} }, () => {
                                // this.get_all_inventory();
                                // this.initTimer()
                            })

                        }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                    </div>
                </div>,
                <this.Transaction />
            ]

        }
        if (this.state.historyBool) {
            return [
                <div className="container p-4">
                    <div class="row p-0 m-0">
                        <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                            this.setState({ historyBool: false, response_under_buttons: {} }, () => {
                                // this.get_all_inventory();
                                // this.initTimer()
                            })

                        }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                    </div>
                </div>,
                <this.History />
            ]
        }
        if (this.state.analyticsBool) {
            return [
                <div className="container p-4">
                    <div class="row p-0 m-0">
                        <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                            this.setState({ analyticsBool: false, response_under_buttons: {} }, () => {
                                // this.get_all_inventory();
                                // this.initTimer()
                            })

                        }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                    </div>
                </div>,
                <this.Analytics />
            ]
        }
        if (this.state.editItemBool) {
            return [
                <div className="container p-4">
                    <div class="row p-0 m-0">
                        <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                            this.setState({ editItemBool: false, response_under_buttons: {} }, () => {
                                this.get_single_inventory();
                                // this.initTimer()
                            })

                        }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                    </div>
                </div>,
                <this.EditItem />,
                <div className="container pl-4 pr-4">
                    <this.Status />
                </div>
            ]
        }
        if (this.state.selectedInventoryBool) {
            return (
                <div className="container p-4">
                    <div class="row p-0 m-0">
                        <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {
                            if (this.state.selectedInventoryBool) {
                                this.setState({ selectedInventoryBool: false, response_under_buttons: {} }, () => {
                                    this.get_all_inventory();
                                    this.initTimer()
                                })
                            }
                            else {
                                this.setState({ selectedInventoryBool: true, response_under_buttons: {} }, () => {
                                    this.get_all_inventory();
                                    this.initTimer()
                                })
                            }
                        }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                    </div>
                    <this.SelectedInventory />
                    <this.FullScreenImageView />
                </div>
            )
        }
        return (
            <div className="contrainer p-4">
                <div className="d-inline-flex align-items-center w-100">
                    <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search Inventory" placeholder="Search Inventory" value={this.state.search_term} onChange={(e) => {
                        this.setState({ search_term: e.target.value }, () => {
                            this.setState({ page_number: 1 }, this.get_all_inventory)
                        })
                    }} />
                    <this.Section_header_search_reset />
                </div>
                <this.SectionPagination />
                <this.Inventory />

            </div>
        )
    }
}

class Inventory extends React.Component {
    constructor(props) {
        super()

        this.state = {
            showAddInventoryBool: true,
            showSearchInventoryBool: false,

            columnedBool: false,
            columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between"
        }
    }
    render() {



        var searchInventorySection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpBool = this.state.showSearchInventoryBool
            this.setState({ showSearchInventoryBool: !tmpBool })
        }}>Show</span>

        var addInventorySection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpBool = this.state.showAddInventoryBool
            this.setState({ showAddInventoryBool: !tmpBool })
        }}>Show</span>

        if (this.state.showSearchInventoryBool) {
            searchInventorySection = <SearchInventory />
        }
        if (this.state.showAddInventoryBool) {
            addInventorySection = <AddToInventory />
        }

        var addInventoryToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpBool = this.state.showAddInventoryBool
            this.setState({ showAddInventoryBool: !tmpBool })
        }}></i>
        if (this.state.showAddInventoryBool) {
            var addInventoryToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpBool = this.state.showAddInventoryBool
                this.setState({ showAddInventoryBool: !tmpBool })
            }}></i>
        }

        var searchInventoryToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpBool = this.state.showSearchInventoryBool
            this.setState({ showSearchInventoryBool: !tmpBool })
        }}></i>
        if (this.state.showSearchInventoryBool) {
            var searchInventoryToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpBool = this.state.showSearchInventoryBool
                this.setState({ showSearchInventoryBool: !tmpBool })
            }}></i>
        }

        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Inventory &nbsp;&nbsp;<i class="fas fa-columns" style={{ cursor: 'pointer' }} title="Toggle Split" onClick={() => {
                        if (this.state.columnedBool) {
                            this.setState({ columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between", columnedBool: false })
                        } else {
                            this.setState({ columnClass: "col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between", columnedBool: true, showSearchInventoryBool: true, showAddInventoryBool: true })
                        }
                    }}></i></h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                        $("#addressName").focus()
                    }}></i>Add New Inquiry</span> */}
                </div>

                <div className="row">

                    <div className={this.state.columnClass}>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Add To Inventory {addInventoryToggle} </h6>
                            </div>
                            <div class="m-0">
                                {/* <AddToInventory /> */}
                                {addInventorySection}
                            </div>
                        </div>
                    </div>
                    <div className={this.state.columnClass}>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Search Inventory {searchInventoryToggle} </h6>
                            </div>
                            <div class="m-0">
                                {/* <SearchInventory /> */}
                                {searchInventorySection}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Inventory;