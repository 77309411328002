import React from 'react';
import GoogleMapReact from 'google-map-react';
// import { fitBounds } from 'google-map-react/utils';
import URL from './URL';
import axios from 'axios';
import Cookies from 'js-cookie';
import Pagination from "react-js-pagination";
import moment from 'moment-timezone';

import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015


var ACCT = Cookies.get('ACCT')

class LocationServices extends React.Component {
    constructor(props) {
        super(props)

        this.mapSettings = {
            center: {
                lat: 26.8953885,
                lng: 75.8025419
            },
            zoom: 9
        };

        this.markerStyle = {
            position: 'absolute',
            width: 30,
            height: 30,
            left: -30 / 2,
            top: -30 / 2,

            border: '8px solid #007bff',
            borderRadius: 30,
            backgroundColor: 'white',
            textAlign: 'center',
            // color: '#3f51b5',
            fontSize: 16,
            fontWeight: 'bold',
            padding: 4
        }

        this.state = {
            pageNumber: 1,
            fetchedLocations: [],
            total_data_count: 0,
            isLoading: true,
            status: "",
            mapLat: undefined,
            mapLng: undefined,
            mapMarker: "",
            mapCenter: {
                lat: 26.8953885,
                lng: 75.8025419
            },
            u_id: ""
        }

    }

    componentDidMount() {
        // this.fetchLocations()
    }

    Marker = (lat, lng, text) => {
        return (
            <div

                style={this.markerStyle}
                lat={lat}
                lng={lng}
            >
                {text}
            </div>
        )
    }

    handleFetchLocationResponse = (response) => {
        if (!("_status" in response)) {
            this.setState({ isLoading: false, status: "Failed To Fetch Sensor Node Data." })
            return;
        }
        if (response["_status"] === "fail" || response["_status"] !== "success") {
            this.setState({ isLoading: false, status: "Failed To Fetch Sensor Node Data." })
            return;
        }

        this.setState({
            total_data_count: response.payload.tc,
            fetchedLocations: response.payload.d,
            isLoading: false
        })
    }

    fetchLocations = () => {
        const that = this;
        var params = { pn: this.state.pageNumber, _id: this.state.u_id }

        axios.get(URL + "location", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.handleFetchLocationResponse(response["data"])
            })
    }

    AllLocations = () => {

        var location_list = []

        for (var i in this.state.fetchedLocations) {
            location_list.push(
                <span style={{ cursor: 'pointer' }} class="list-group-item list-group-item-action" lat={this.state.fetchedLocations[i].lat} lng={this.state.fetchedLocations[i].long} onClick={(e) => {
                    const lat = e.target.getAttribute('lat')
                    const lng = e.target.getAttribute('lng')

                    var Marker = <div
                        style={this.markerStyle}
                        lat={lat}
                        lng={lng}
                    >
                        {/* {text} */}
                    </div>

                    this.setState({
                        mapMarker: Marker, mapCenter: {
                            lat: parseFloat(lat),
                            lng: parseFloat(lng)
                        }
                    }, () => {
                        // console.log(this.state.mapCenter)
                    })


                }}>
                    {moment(this.state.fetchedLocations[i]._created.$date).format('Do MMMM YYYY, h:mm:ss A')}
                </span>
            )
        }

        return (
            <div className="m-2">
                <div className="d-flex justify-content-center">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={15}
                        totalItemsCount={this.state.total_data_count}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ "pageNumber": parseInt(pageNumber) }, this.fetchLocations)
                        }}
                    />
                </div>
                <div class="list-group">
                    {location_list}
                </div>
            </div>
        )
    }

    // handleApiLoaded = (map, maps) => {
    //     // use map and maps objects
    //     var latlngbounds = new maps.LatLngBounds();
    //     for (var i = 0; i < latlng.length; i++) {
    //         latlngbounds.extend(latlng[i]);
    //     }
    //     map.fitBounds(latlngbounds);
    // };
    handle_search = (query) => {
        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'users', {
            params: {
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"] } })

            })
    }

    render() {

        var fetchedLocation = ""

        if (this.state.isLoading) {
            fetchedLocation = <div className="m-2">Please select a user by searching using the text box above.</div>
        } else {
            fetchedLocation = <this.AllLocations />
        }

        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Location Services</h1>
                </div>

                <div class="row">

                    <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">View Users Location</h6>
                            </div>
                            <div className="row">
                                <div class="col-md-4 col-sm-12 col-xl-4 col-lg-4 align-items-center justify-content-between collapse show">
                                    <div className="m-2">
                                        <AsyncTypeahead type="text" placeholder="Search" class="my-1" name="to"
                                            isLoading={false}
                                            id={`searchUser`}
                                            labelKey={option => `${option.firstname} ${option.lastname} | ${option.username} | ${option.phone}`}
                                            filterBy={['firstname', 'lastname', 'username']}
                                            onSearch={this.handle_search}
                                            {...this.state.asyncsearch}
                                            maxResults={10}
                                            ref={(typeahead) => this.typeahead = typeahead}
                                            renderMenu={(results, menuProps) => (
                                                <Menu {...menuProps} className="search_menu" id="search_menu">
                                                    {results.map((result, index) => (
                                                        <MenuItem option={result} position={index} className="search_item m-0">
                                                            <div className="w-100 p-2 search_item" onClick={(e) => {
                                                                this.setState({ u_id: result._id.$oid }, this.fetchLocations)
                                                            }}>{`${result.firstname} ${result.lastname} | ${result.username} | ${result.phone}`}</div>
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            )}
                                        />
                                    </div>
                                    {fetchedLocation}
                                </div>
                                <div class="col-md-8 col-sm-12 col-xl-8 col-lg-8 align-items-center justify-content-between collapse show">

                                    <div style={{ minHeight: '300px', height: '100%', width: '100%', background: 'pink' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: "AIzaSyBMcS98N35rFPYiJprsphbxt2h5Jb_FuNM" }}
                                            defaultCenter={this.mapSettings.center}
                                            // defaultCenter={this.state.mapCenter}
                                            defaultZoom={this.mapSettings.zoom}
                                            // ref={(map) => { this.map = map }}
                                            center={this.state.mapCenter}
                                        >
                                            {this.state.mapMarker}
                                        </GoogleMapReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default LocationServices