import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { fullDialogDispatcher } from './FullDialog';
import { ItemSearch } from './ItemSearch';


class EmailMarketing extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-4">
                <div className="form-group">
                    <span className="btn-link" style={{
                        cursor: 'pointer'
                    }}><FontAwesomeIcon icon={faPlus} />&nbsp;New Campaign</span>
                </div>
                <div>
                    <ItemSearch nameTitleKey="firstname" subNameTitleKey="lastname" subHeadingKeys={["phone", "email"]} onClickCallback={(resultData) => {
                        // const id = this.state.slider[iid]["_id"]
                        // this.addItem(resultData)

                        fullDialogDispatcher.dispatch({
                            actionType: 'show-dialog',
                            title: 'Approve Registration?',
                            
                        })
                    }} resultClickable={true} isResultLink={false} timeKey="time" sortCriteria="up" searchAllowed={false} gType="get" urlEndPoint="intermediatory" />
                </div>
            </div>
        )
    }
}


class Marketing extends React.Component {
    constructor(props) {
        super()
        this.state = {
            columnedBool: false,
            columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between"
        }
    }

    render() {
        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Marketing &nbsp;&nbsp;<i class="fas fa-columns" style={{ cursor: 'pointer' }} title="Toggle Split" onClick={() => {
                        if (this.state.columnedBool) {
                            this.setState({ columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between", columnedBool: false })
                        } else {
                            this.setState({ columnClass: "col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between", columnedBool: true })
                        }
                    }}></i></h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div className="row">

                    <div className={this.state.columnClass}>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Email Marketing</h6>
                            </div>
                            <div class="m-0">
                                <EmailMarketing />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default Marketing