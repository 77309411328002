import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import Cookies from 'js-cookie';
import URL from './URL';
import './Inquiry.css';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DebounceInput } from 'react-debounce-input';
import Pagination from "react-js-pagination";
import Quill from 'quill';

import 'quill/themes/snow';
import 'quill/'

var Delta = Quill.import('delta');
var randomBytes = require('randombytes');
var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')

function toHex(x, n) {
    var res = ""
    for (var i = 0; i < n; i++) {
        res += parseInt(x[i]).toString(16).padStart(2, "0")
    }
    return res.toString()
}

class Notebook extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search_term: "",
            page_number: 1,
            retrivedNotebooks: {},

            someNotebookSelected: false,

            currentNotebookName: "",
            currentNotebookID: "",
            currentNotebook: {},
            currentNotebookContent: {},
            currentNotebookNameChanged: false,

            notebookSelectedClass: {}
        }
        this.editor = null;
        this.startNewNotebook = this.startNewNotebook.bind(this);
    }

    handleQuillImage = () => {
        var range = this.editor.getSelection();
        var value = prompt('please copy paste the image url here.');
        if (value) {
            this.editor.insertEmbed(range.index, 'image', value, Quill.sources.USER);
        }
    }

    componentDidMount() {
        var toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image', 'video', 'formula'],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];

        var DirectionAttribute = Quill.import('attributors/attribute/direction');
        Quill.register(DirectionAttribute, true);
        var AlignClass = Quill.import('attributors/class/align');
        Quill.register(AlignClass, true);
        var BackgroundClass = Quill.import('attributors/class/background');
        Quill.register(BackgroundClass, true);
        var ColorClass = Quill.import('attributors/class/color');
        Quill.register(ColorClass, true);
        var DirectionClass = Quill.import('attributors/class/direction');
        Quill.register(DirectionClass, true);
        var FontClass = Quill.import('attributors/class/font');
        Quill.register(FontClass, true);
        var SizeClass = Quill.import('attributors/class/size');
        Quill.register(SizeClass, true);
        var AlignStyle = Quill.import('attributors/style/align');
        Quill.register(AlignStyle, true);
        var BackgroundStyle = Quill.import('attributors/style/background');
        Quill.register(BackgroundStyle, true);
        var ColorStyle = Quill.import('attributors/style/color');
        Quill.register(ColorStyle, true);
        var DirectionStyle = Quill.import('attributors/style/direction');
        Quill.register(DirectionStyle, true);
        var FontStyle = Quill.import('attributors/style/font');
        Quill.register(FontStyle, true);
        var SizeStyle = Quill.import('attributors/style/size');
        Quill.register(SizeStyle, true);

        this.editor = new Quill("#notebookeditor", {
            modules: {
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        // image: this.handleQuillImage
                    }
                }

                // 'image-tooltip': true,
            },
            theme: 'snow'
        });

    

        var change = new Delta();
        this.editor.on('text-change', (delta) => {
            var contents = this.editor.getContents();
            change = change.compose(delta)
            this.setState({ currentNotebookContent: contents })
            // console.log('contents', contents.ops[0]);
        })
        const that = this;
        setInterval(function () {
            if (that.state.currentNotebookNameChanged) {
                if (that.state.currentNotebookID.length > 0) {
                    that.setState({ currentNotebookNameChanged: false }, () => {
                        that.updateNotebook()
                    })
                }
                else {
                    that.setState({ currentNotebookNameChanged: false }, () => {
                        that.saveNotebook()
                    })

                }
            }
            if (change.length() > 0) {
                if (that.state.currentNotebookID.length > 0) {
                    that.updateNotebook()
                }
                else {
                    that.saveNotebook()
                }
                change = new Delta();
            }
        }, 2 * 1000);

        this.getAllNotebooks()
    }

    hideEditorContent = () => {
        $('#maineditor').attr('hidden', 'hidden');
    }
    loadEditorContent = () => {
        $('#maineditor').removeAttr('hidden');
        
        this.editor.setContents(this.state.currentNotebookContent)
        this.editor.focus()
    }

    getAllNotebooks = () => {
        const that = this;
        var params = { page_number: this.state.page_number - 1 }

        if (this.state.search_term.length > 0) {
            params["st"] = this.state.search_term
        } else {
            delete params["st"];
        }

        axios.get(URL + "notebook", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedNotebooks: response["data"]["payload"] })
            })
    }

    Pagination = () => {
        const total_pages = this.state.retrivedNotebooks[1]
        if (total_pages < 1) {
            return ""
        }
        if (this.state.someNotebookSelected) {
            return ""
        }
        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.page_number}
                        itemsCountPerPage={10}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ page_number: parseInt(pageNumber) }, this.getAllNotebooks)
                        }}
                    />
                </div>
            </div>
        )
    }

    getSingleNoteboook = () => {
        const that = this;

        var params = { "_id": this.state.currentNotebookID }
        axios.get(URL + "notebook", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ currentNotebook: response.data.payload, currentNotebookID: response.data.payload._id.$oid, currentNotebookContent: response.data.payload.data, currentNotebookName: response.data.payload.name }, () => {
                    that.loadEditorContent()
                    
                })
            })

    }

    AllNotebooks = () => {
        var notebooks_ = []

        for (var item in this.state.retrivedNotebooks[0]) {
            notebooks_.push(

                <a class="inquiry_item list-group-item d-flex justify-content-between align-items-center border" lid={this.state.retrivedNotebooks[0][item]._id.$oid} onClick={(e) => {

                    const lid_ = e.target.getAttribute("lid")

                    this.setState({ currentNotebookID: lid_, someNotebookSelected: true }, this.getSingleNoteboook)
                }}><span lid={this.state.retrivedNotebooks[0][item]._id.$oid}><b lid={this.state.retrivedNotebooks[0][item]._id.$oid}>{this.state.retrivedNotebooks[0][item].name}</b></span><span lid={this.state.retrivedNotebooks[0][item]._id.$oid}>{moment(this.state.retrivedNotebooks[0][item]["_created"].$date).format('D MMM YY HH:mm:ss')}</span></a>

            )
        }
        if (this.state.someNotebookSelected === true) {
            return null
        }
        else {
            return (

                <div class="list-group" id="allNotebooks">
                    {notebooks_}
                </div>
            )
        }
    }


    establishBack = () => {
        this.hideEditorContent();
        this.setState({
            someNotebookSelected: false,
            currentNotebookID: "",
            currentNotebookName: "",
            currentNotebookID: "",
            currentNotebook: {},
            currentNotebookContent: {},
            currentNotebookNameChanged: false,
        }, this.getAllNotebooks);
    }

    BackButton = () => {
        if (this.state.someNotebookSelected) {
            return (
                <div className="mt-2 ml-1">
                    <div class="btn-link" style={{ cursor: 'pointer' }} onClick={(e) => {
                        this.establishBack();
                    }}>
                        <h5 className="m-0">
                            <i className="fas fa-arrow-left mr-2 "></i>
                            <span>Back</span>
                        </h5>
                    </div>
                </div>
            )
        }
        return ""
    }


    startNewNotebook = () => {
        const that = this
        randomBytes(16, function (err, resp) {
            if (err != null) {
                return
            }
            var tmpFileName = "nb-" + toHex(resp, 8)
            that.setState({ someNotebookSelected: true, currentNotebookName: tmpFileName, currentNotebookID: "" }, () => {
                $('#maineditor').removeAttr('hidden');
                that.editor.setContents({})
                that.editor.focus()
            })
        });
    }

    updateNotebook = () => {
        if (this.state.currentNotebookName < 1) {
            return
        }

        $("#saveLoading").html("saving...")
        // $("#saveLoading").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this
        var params = { "ut": "u" }
        axios.patch(URL + "notebook", {
            _id: this.state.currentNotebookID,
            name: this.state.currentNotebookName,
            data: this.state.currentNotebookContent
        }, {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] }, () => {
                    that.getAllNotebooks();
                })

                $("#saveLoading").html("")

            })
    }
    deleteNoteBook = () => {
        if (this.state.currentNotebookID.length < 1) {
            return
        }
        const that = this;
        var params = { _id: this.state.currentNotebookID }
        axios.delete(URL + "notebook", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({
                    response: response["data"],
                    currentNotebookName: "",
                    currentNotebookID: "",
                    currentNotebook: {},
                    currentNotebookContent: {}
                }, () => {
                    that.establishBack();
                    that.getAllNotebooks();
                })
            })
    }

    saveNotebook = () => {

        if (this.state.currentNotebookName < 1) {
            return
        }

        $("#saveLoading").html("saving...")
        // $("#saveLoading").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this

        axios.post(URL + "notebook", {
            name: this.state.currentNotebookName,
            data: this.state.currentNotebookContent
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                if ("_id" in response.data.payload) {
                    that.setState({ response: response["data"], currentNotebookID: response.data.payload._id }, () => {
                        that.getAllNotebooks();
                    })
                }
                else {
                    that.setState({ response: response["data"] })
                }

                $("#saveLoading").html("")

            })
    }

    DeleteButton = () => {
        if (this.state.currentNotebookName.length > 0) {
            return (
                <button className="btn btn-danger m-1" data-toggle="tooltip" data-placement="bottom" title="Delete Notebook" data-toggle="modal" data-target="#deleteNotebookDialog">Delete</button>
            )
        }
        return (
            <button className="btn btn-danger m-1" disabled>Delete</button>
        )
    }

    Dialog = (props) => {
        return (
            <div class="modal fade" id={props.label} tabindex="-1" role="dialog" aria-labelledby={props.label} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{props.h_text}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{props.msg}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" name={props.b_name} class={`btn ` + props.c_text} onClick={props.handler} data-dismiss="modal">{props.b_label}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


    SelectedNotebook = () => {

    }

    render() {
        return [
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Notebooks</h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div class="row">

                    <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary" onClick={this.saveNotebook}>Notebooks</h6>
                            </div>
                            <div className="row">
                                <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between collapse show">
                                    <div className="m-2 mb-0">
                                        <button className="btn btn-primary m-1" onClick={this.startNewNotebook} disabled={this.state.someNotebookSelected}>New</button>
                                        <button className="btn btn-primary m-1" onClick={()=>{
                                            var myWindow = window.open("", "", );
                                            myWindow.document.write(this.editor.root.innerHTML);
                                        }} disabled={!this.state.someNotebookSelected}>Print</button> 
                                        <this.DeleteButton />
                                        <span id="saveLoading"></span>
                                        <this.BackButton />
                                        <this.Pagination />
                                        <this.AllNotebooks />
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between">

                                    <div class="m-0 p-2" id="maineditor" hidden>
                                        <div id="">
                                            <h3 className="m-1"><input style={{ width: "100%", border: 0 }} id="notebookname" className=" " type="text" value={this.state.currentNotebookName} onChange={(e) => {
                                                this.setState({ currentNotebookName: e.target.value, currentNotebookNameChanged: true })
                                            }} /></h3>
                                        </div>
                                        <div id="toolbar">
                                        </div>
                                        <div id="notebookeditor">
                                            {/* <div id="notebookeditor" style={{ height: "600px", maxHeight: "600px" }}> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <this.Dialog name="delete" msg="Delete Notebook." h_text="Delete Notebook" c_text="btn-danger" b_label="Delete" handler={this.deleteNoteBook} label="deleteNotebookDialog" />
            </div>

        ]
    }
}

export default Notebook
