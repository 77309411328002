import React from 'react'
import $ from 'jquery';

import axios from 'axios';
import Cookies from 'js-cookie';
import { JWURL } from './JoypurwalaURL'
import { DebounceInput } from 'react-debounce-input';
import { TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import { testModeAPI } from 'react-ga';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')


function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}


class FullScreenImageView extends React.Component {

    constructor(props) {
        super()

        this.state = {
            fullscreenimageviewdialogimagerotationangle: 0
        }
    }

    rotate_image = () => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialogimage').style.transform = angle;
        })
    }

    render() {
        return (
            <div id="fullscreenimageviewdialog" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog") {
                        $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog').attr('hidden', 'hidden') }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialogimage" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }
}

class HomePageSlider extends React.Component {
    constructor() {
        super()
        this.state = {
            images: {},
            images_extra: {},
            slider: {},
            currentSlider: {}
        }
    }

    componentDidMount() {
        this.fetchCurrentSlider()
    }

    ImageUploader = (e) => {

        var img_ = []
        for (var i in this.state.images) {
            console.log(i)
            img_.push(
                <div class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                    <img iid={i} src={this.state.images[i]} class="card-img-top" onClick={(e) => {
                        this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                        document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                        $('#fullscreenimageviewdialog1').removeAttr('hidden')
                        $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                    }} />
                    <i class="fas fa-times" iid={i} onClick={(e) => {
                        var iid = e.target.getAttribute('iid');
                        var tmp_images = this.state.images;
                        tmp_images.splice(parseInt(iid), 1)
                        this.setState({ images: tmp_images })
                    }}> Delete</i>
                </div>
            )
        }


        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Add Images</h5>
                    <div class="d-flex flex-wrap align-items-center">
                        {img_}
                    </div>

                    {image_delete_help}
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" class="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    fetchCurrentImages = () => {
        const that = this
        axios.get(JWURL + "image", {
            headers: { Authorization: ACCT },

        })
            .then(function (response) {
                that.setState({ retrivedInventory: response["data"]["payload"] })
            })
    }

    fetchCurrentSlider = () => {
        const that = this
        axios.get(JWURL + "admin", {
            params: {
                g: "crs",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch current slider information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch current slider information")
                    return
                }

                // prepare slider info
                var preparedData = {}

                for (var i in response.payload) {
                    preparedData[response.payload[i]["_id"]] = response.payload[i]
                }

                that.setState({ currentSlider: preparedData })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchInventory = () => {

        if (this.state.search_term === "") {
            return
        }

        const that = this
        axios.get(JWURL + "inventory", {
            params: {
                g: "inv",
                st: this.state.search_term
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch inventory")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch inventory")
                    return
                }

                that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    saveSlider = () => {
        // if (Object.keys(this.state.currentSlider).length === 0) {
        //     return
        // } 

        var IDs = []
        // prepare data 
        for (var i in this.state.currentSlider) {
            IDs.push(i)
        }

        const that = this
        axios.post(JWURL + "admin", {
            IDs: IDs
        }, {
            params: {
                g: "scs",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to save slider information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to save slider information")
                    return
                }

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })

    }
    render() {


        var sliderContent = []
        for (var i in this.state.slider) {
            const idx = i
            sliderContent.push(
                <div>
                    <span iid={idx} id={this.state.slider[idx]["_id"]} style={{
                        cursor: 'pointer',
                    }}
                        onClick={(e) => {
                            const iid = e.target.getAttribute("iid")
                            if (this.state.slider[iid] === undefined) {
                                return
                            }
                            const id = this.state.slider[iid]["_id"]
                            var tmpCurrentSliders = this.state.currentSlider
                            const content = this.state.slider[iid]
                            tmpCurrentSliders[id] = content
                            this.setState({ currentSlider: tmpCurrentSliders })

                        }}><b iid={idx}>{this.state.slider[i]["name"]}</b> | <span iid={idx}>{this.state.slider[i]["sku"]}</span></span>
                </div>
            )
        }

        var currentSlider = []
        for (var i in this.state.currentSlider) {
            currentSlider.push(
                <div>
                    <span><b>{this.state.currentSlider[i]["name"]}</b> | {this.state.currentSlider[i]["sku"]}<i style={{
                        cursor: 'pointer'
                    }} id={this.state.currentSlider[i]["_id"]} className="fas fa-times-circle ml-2" onClick={(e) => {
                        const id = e.target.getAttribute("id")
                        var tmpCurrentSliders = this.state.currentSlider
                        delete tmpCurrentSliders[id]
                        this.setState({ currentSlider: tmpCurrentSliders })
                    }}></i></span>
                </div>
            )
        }

        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                    <div className="form-group">
                        <label>Search inventory</label>
                        <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search inquires" placeholder="Search Inventory" value={this.state.search_term} onChange={(e) => {
                            this.setState({ search_term: e.target.value }, () => {
                                this.setState({ page_number: 1 }, this.fetchInventory)
                            })
                        }} />
                    </div>


                    <div className="form-group">
                        <label>Search Results</label>
                        {sliderContent}
                    </div>

                    <div className="form-group">
                        <label>Current Slider</label>
                        {currentSlider}
                    </div>

                    <div className="form-group">
                        <input type="button" className="btn btn-primary" value="Save" onClick={this.saveSlider} />
                    </div>
                </div>
                {/* {image_edit} */}


            </div>
        )
    }
}

class HomePageFeatured extends React.Component {
    constructor() {
        super()
        this.state = {
            images: {},
            images_extra: {},
            slider: {},
            currentSlider: {}
        }
    }

    componentDidMount() {
        this.fetchCurrentHomepageFeatured()
    }



    fetchCurrentHomepageFeatured = () => {
        const that = this
        axios.get(JWURL + "admin", {
            params: {
                g: "chp",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch current slider information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch current slider information")
                    return
                }

                // prepare slider info
                var preparedData = {}

                for (var i in response.payload) {
                    preparedData[response.payload[i]["_id"]] = response.payload[i]
                }

                that.setState({ currentSlider: preparedData })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchInventory = () => {

        if (this.state.search_term === "") {
            return
        }

        const that = this
        axios.get(JWURL + "inventory", {
            params: {
                g: "inv",
                st: this.state.search_term
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch inventory")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch inventory")
                    return
                }

                that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    saveHomepageFeatured = () => {
        // if (Object.keys(this.state.currentSlider).length === 0) {
        //     return
        // } 

        var IDs = []
        // prepare data 
        for (var i in this.state.currentSlider) {
            IDs.push(i)
        }

        const that = this
        axios.post(JWURL + "admin", {
            IDs: IDs
        }, {
            params: {
                g: "shp",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to save slider information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to save slider information")
                    return
                }

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })

    }

    render() {


        var sliderContent = []
        for (var i in this.state.slider) {
            sliderContent.push(
                <div>
                    <span iid={i} id={this.state.slider[i]["_id"]} style={{
                        cursor: 'pointer',
                    }}
                        onClick={(e) => {
                            const iid = e.target.getAttribute("iid")
                            const id = this.state.slider[iid]["_id"]
                            var tmpCurrentSliders = this.state.currentSlider
                            tmpCurrentSliders[id] = this.state.slider[iid]
                            this.setState({ currentSlider: tmpCurrentSliders })

                        }}><b iid={i}>{this.state.slider[i]["name"]}</b> | {this.state.slider[i]["sku"]}</span>
                </div>
            )
        }

        var currentSlider = []
        for (var i in this.state.currentSlider) {
            currentSlider.push(
                <div>
                    <span><b>{this.state.currentSlider[i]["name"]}</b> | {this.state.currentSlider[i]["sku"]}<i style={{
                        cursor: 'pointer'
                    }} id={this.state.currentSlider[i]["_id"]} className="fas fa-times-circle ml-2" onClick={(e) => {
                        const id = e.target.getAttribute("id")
                        var tmpCurrentSliders = this.state.currentSlider
                        delete tmpCurrentSliders[id]
                        this.setState({ currentSlider: tmpCurrentSliders })
                    }}></i></span>
                </div>
            )
        }

        return (
            <div className="m-2">
                <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12">

                    <div className="form-group">
                        <label>Search inventory</label>
                        <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search inquires" placeholder="Search Inventory" value={this.state.search_term} onChange={(e) => {
                            this.setState({ search_term: e.target.value }, () => {
                                this.setState({ page_number: 1 }, this.fetchInventory)
                            })
                        }} />
                    </div>


                    <div className="form-group">
                        <label>Search Results</label>
                        {sliderContent}
                    </div>

                    <div className="form-group">
                        <label>Current Home Page Featured</label>
                        {currentSlider}
                    </div>

                    <div className="form-group">
                        <input type="button" className="btn btn-primary" value="Save" onClick={this.saveHomepageFeatured} />
                    </div>
                </div>
                {/* {image_edit} */}


            </div>
        )
    }
}

class AboutPage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            aboutPageContent: ""
        }
    }

    componentDidMount = () => {
        this.fetchAboutPage()
    }

    saveAboutPage = () => {

        const that = this
        axios.post(JWURL + "admin", {
            content: this.state.aboutPageContent,
            shortPara: this.state.aboutPageShortParagragh,
            title: this.state.aboutPageTitle
        }, {
            params: {
                g: "sab",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to save about page information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to save about page information")
                    return
                }

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchAboutPage = () => {
        const that = this
        axios.get(JWURL + "admin", {
            params: {
                g: "abt",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch about page information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch about page information")
                    return
                }


                that.setState({ aboutPageContent: response["payload"]["content"], aboutPageShortParagragh: response["payload"]["shortPara"], aboutPageTitle: response["payload"]["title"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    render() {
        return (
            <div className="m-2">
                <div className="form-group">
                    <label>About Page Title</label>
                    <input type="text" className="form-control" value={this.state.aboutPageTitle} onChange={(e) => {
                        this.setState({ aboutPageTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>About Page Short Paragragh</label>
                    <textarea rows="3" className="form-control" value={this.state.aboutPageShortParagragh} onChange={(e) => {
                        this.setState({ aboutPageShortParagragh: e.target.value })
                    }}></textarea>
                </div>
                <div className="form-group">
                    <label>About Page Content</label>
                    <ReactQuill id="inventoryEditEcomDescription" theme="snow" value={this.state.aboutPageContent} onChange={(data) => {
                            this.setState({ aboutPageContent: data })
                        }} />
                    {/* <textarea rows="5" className="form-control" value={this.state.aboutPageContent} onChange={(e) => {
                        this.setState({ aboutPageContent: e.target.value })
                    }}></textarea> */}
                </div>
                <div className="form-group">
                    <input type="button" value="Save" className="btn btn-primary" onClick={this.saveAboutPage} />
                </div>
            </div>
        )
    }
}

class ContactPage extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    componentDidMount() {
        this.fetchContactPage()
    }

    fetchContactPage = () => {
        const that = this
        axios.get(JWURL + "admin", {
            params: {
                g: "cnt",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch contact page information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch contact page information")
                    return
                }


                that.setState({ contactPageTitle: response["payload"]["title"], contactPageUnderTitlePara: response["payload"]["underTitlePara"], contactPageRightSectionTitle: response["payload"]["rightSectionTitle"], contactPageRightSectionUnderTitlePara: response["payload"]["rightSectionUnderTitlePara"], contactPageRightSectionAddress: response["payload"]["rightSectionAddress"], contactPageRightSectionPhone: response["payload"]["rightSectionPhone"], contactPageRightSectionEmail: response["payload"]["rightSectionEmail"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    saveContactPage = () => {
        const that = this
        axios.post(JWURL + "admin", {
            title: this.state.contactPageTitle,
            underTitlePara: this.state.contactPageUnderTitlePara,
            rightSectionTitle: this.state.contactPageRightSectionTitle,
            rightSectionUnderTitlePara: this.state.contactPageRightSectionUnderTitlePara,
            rightSectionAddress: this.state.contactPageRightSectionAddress,
            rightSectionPhone: this.state.contactPageRightSectionPhone,
            rightSectionEmail: this.state.contactPageRightSectionEmail
        }, {
            params: {
                g: "scn",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to save about page information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to save about page information")
                    return
                }

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    render() {
        return (
            <div className="m-2">
                <div className="form-group">
                    <label>Contact Page Title</label>
                    <input type="text" className="form-control" value={this.state.contactPageTitle} onChange={(e) => {
                        this.setState({ contactPageTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Page Under Title Paragrapgh</label>
                    <input type="text" className="form-control" value={this.state.contactPageUnderTitlePara} onChange={(e) => {
                        this.setState({ contactPageUnderTitlePara: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Page Right Section Title</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionTitle} onChange={(e) => {
                        this.setState({ contactPageRightSectionTitle: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Right Section Under Title Paragrapgh</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionUnderTitlePara} onChange={(e) => {
                        this.setState({ contactPageRightSectionUnderTitlePara: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>Contact Right Section Address</label>
                    <textarea type="text" rows="4" className="form-control" value={this.state.contactPageRightSectionAddress} onChange={(e) => {
                        this.setState({ contactPageRightSectionAddress: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <label>Contact Right Section Phone</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionPhone} onChange={(e) => {
                        this.setState({ contactPageRightSectionPhone: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <label>Contact Right Section Email</label>
                    <input type="text" className="form-control" value={this.state.contactPageRightSectionEmail} onChange={(e) => {
                        this.setState({ contactPageRightSectionEmail: e.target.value })
                    }} />
                </div>

                <div className="form-group">
                    <input type="button" className="btn btn-primary" value="Save" onClick={this.saveContactPage} />
                </div>

            </div>
        )
    }
}

class SubMenuItemColumnMenuItem extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
        this.columnID = props.columnID
        this.columnMenuItemID = props.columnMenuItemID
    }

    render() {
        const id = this.id
        const columnID = this.columnID
        const columnMenuItemID = this.columnMenuItemID

        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }
        if (this.state.menuItems[id].columns[columnID] === null || this.state.menuItems[id].columns[columnID] === undefined) {
            return null
        }
        if (this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID] === null || this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID] === undefined) {
            return null
        }
        return (
            <div className="form-group border rounded p-2   ">
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <i style={{
                        cursor: 'pointer'
                    }} id={id} className="fas fa-times-circle ml-2" onClick={(e) => {
                        const id = e.target.getAttribute("id")
                        var tmpMenuItems = this.state.menuItems
                        // console.log(id)
                        // console.log(tmpMenuItems[id])
                        tmpMenuItems[id].columns[columnID].menuitems.splice(parseInt(columnMenuItemID), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }}></i>
                </label>
                <input type="text" value={this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].columns[columnID].menuitems[columnMenuItemID].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
                <label>Link</label>
                <input type="text" value={this.state.menuItems[id].columns[columnID].menuitems[columnMenuItemID].link} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].columns[columnID].menuitems[columnMenuItemID].link = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
            </div>
        )
    }
}

class SubMenutItemColumn extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
        this.columnID = props.columnID
    }


    render() {
        const columnID = this.columnID
        const id = this.id
        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }
        if (this.state.menuItems[id].columns[columnID] === null || this.state.menuItems[id].columns[columnID] === undefined) {
            return null
        }

        var menuItems = []
        for (var i in this.state.menuItems[id].columns[columnID].menuitems) {
            menuItems.push(
                <SubMenuItemColumnMenuItem key={`SubMenuItemColumnMenuItem` + i} id={id} columnID={columnID} columnMenuItemID={i} state={this.state} />
            )
        }

        return (
            <div className="form-group border rounded p-2   ">
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <i style={{
                        cursor: 'pointer'
                    }} id={id} className="fas fa-times-circle ml-2" onClick={(e) => {
                        const id = e.target.getAttribute("id")
                        var tmpMenuItems = this.state.menuItems
                        // // console.log(id)
                        // // console.log(tmpMenuItems[id])
                        tmpMenuItems[id].columns.splice(parseInt(columnID), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }}></i>
                </label>
                <input type="text" value={this.state.menuItems[id].columns[columnID].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].columns[columnID].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />

                <div className="form-group">
                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns[columnID].menuitems.push(
                            {
                                "title": "",
                                "link": "",
                                "type": "menuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Menu Item</span>
                    {menuItems}
                </div>

            </div>
        )
    }
}

class SubMenuItem extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
    }

    render() {
        const id = this.id
        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }

        var currentColumns = []

        for (var i in this.state.menuItems[id].columns) {
            const iid = i
            currentColumns.push(
                <SubMenutItemColumn key={`SubMenutItemColumn` + i} id={id} columnID={iid} state={this.state} />
            )
        }
        return (
            <div className="form-group border rounded p-2   ">
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <i style={{
                        cursor: 'pointer'
                    }} id={id} className="fas fa-times-circle ml-2" onClick={(e) => {
                        const id = e.target.getAttribute("id")
                        var tmpMenuItems = this.state.menuItems
                        // console.log(id)
                        // console.log(tmpMenuItems[id])
                        tmpMenuItems.splice(parseInt(id), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }}></i>
                </label>
                <input type="text" value={this.state.menuItems[id].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />

                <label>Columns</label>

                <div className="form-group">
                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems[id].columns.push(
                            {
                                "title": "",
                                "menuitems": [],
                                "type": "columnmenuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add New Columns</span>
                </div>

                {currentColumns}


            </div>
        )
    }
}

class MenuItem extends React.Component {
    constructor(props) {
        super()
        this.state = props.state
        this.id = props.id
    }

    render() {
        const id = this.id
        if (this.state.menuItems[id] === null || this.state.menuItems[id] === undefined) {
            return null
        }
        return (
            <div className="form-group border rounded p-2   ">
                <label className="d-flex justify-content-between">
                    <span>Title</span>
                    <i style={{
                        cursor: 'pointer'
                    }} id={id} className="fas fa-times-circle ml-2" onClick={(e) => {
                        const id = e.target.getAttribute("id")
                        var tmpMenuItems = this.state.menuItems
                        // console.log(id)
                        // console.log(tmpMenuItems[id])
                        tmpMenuItems.splice(parseInt(id), 1)
                        this.setState({ menuItems: tmpMenuItems })
                    }}></i>
                </label>
                <input type="text" value={this.state.menuItems[id].title} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].title = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
                <label>Link</label>
                <input type="text" value={this.state.menuItems[id].link} className="form-control" onChange={(e) => {
                    const tmpMenuItems = this.state.menuItems
                    tmpMenuItems[id].link = e.target.value
                    this.setState({ menuItems: tmpMenuItems })
                }} />
            </div>
        )
    }
}

class NavBar extends React.Component {
    constructor() {
        super()
        this.state = {
            menuItems: []
        }
    }

    componentDidMount() {
        this.fetchNavBar()
    }

    saveNavBar = () => {
        const that = this
        axios.post(JWURL + "admin", {
            menuItems: this.state.menuItems
        }, {
            params: {
                g: "snb",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to save nav bar information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to save nav bar information")
                    return
                }

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchNavBar = () => {
        const that = this
        axios.get(JWURL + "admin", {
            params: {
                g: "nvb",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch navbar information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch navbar information")
                    return
                }


                that.setState({ menuItems: response.payload.menuItems })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }
    render() {
        var currentMenuItems = []

        for (var i in this.state.menuItems) {
            const id = i
            if (this.state.menuItems[id].type === "menuitem") {
                currentMenuItems.push(
                    <MenuItem key={`MenuItem` + id} id={id} state={this.state} />
                )
            }

            if (this.state.menuItems[id].type === "submenuitem") {
                currentMenuItems.push(
                    <SubMenuItem key={`SubMenuItem` + id} id={id} state={this.state} />
                )
            }
        }

        return (
            <div className="m-2">
                <div className="form-group">
                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.push(
                            {
                                "title": "",
                                "link": "",
                                "type": "menuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Menu Item</span>

                    <span className="btn btn-link" onClick={(e) => {
                        const tmpMenuItems = this.state.menuItems
                        tmpMenuItems.push(
                            {
                                "title": "",
                                "columns": [],
                                "type": "submenuitem"
                            }
                        )
                        this.setState({ menuItems: tmpMenuItems })
                    }}>Add Sub Menu Item</span>
                </div>

                <div className="form-group">
                    <label>Current Menu Items</label>
                    {currentMenuItems}
                </div>

                <div className="form-group">
                    <input type="button" className="btn btn-primary" value="Save" onClick={this.saveNavBar} />
                </div>
            </div>
        )
    }
}

class Footer extends React.Component {
    constructor() {
        super()
        this.state = {
            social: {}
        }
    }

    componentDidMount() {
        this.fetchFooter()
    }

    saveFooter = () => {
        const that = this
        axios.post(JWURL + "admin", this.state, {
            params: {
                g: "sft",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to save footer information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to save footer information")
                    return
                }

                // that.setState({ slider: response["payload"] })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }

    fetchFooter = () => {
        const that = this
        axios.get(JWURL + "admin", {
            params: {
                g: "fot",
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                if (!("status" in response) || !("payload" in response)) {
                    alert("Unable to fetch footer information")
                    return
                }
                if (response["status"] != "success") {
                    alert("Unable to fetch footer information")
                    return
                }


                that.setState({ copyrightMessage: response.payload.copyrightMessage, social: response.payload.social })
            })
            .catch(function (error) {
                alert("Some error has occurred")
            })
    }
    render() {
        return (
            <div className="m-2">
                <div className="form-group">
                    <label>
                        Copyright Message
                    </label>
                    <input type="text" value={this.state.copyrightMessage} className="form-control" onChange={(e) => {
                        this.setState({ copyrightMessage: e.target.value })
                    }} />
                </div>
                <div className="form-group">
                    <label>
                        Social
                    </label>
                    <div className="form-group border rounded p-2">
                        <label>Facebook</label>
                        <input type="text" value={this.state.social.facebook} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.facebook = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>Instagram</label>
                        <input type="text" value={this.state.social.instagram} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.instagram = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>Twitter</label>
                        <input type="text" value={this.state.social.twitter} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.twitter = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>WhatsApp</label>
                        <input type="text" value={this.state.social.whatsapp} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.whatsapp = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                        <label>YouTube</label>
                        <input type="text" value={this.state.social.youtube} className="form-control" onChange={(e) => {
                            var tmpSocial = this.state.social
                            tmpSocial.youtube = e.target.value
                            this.setState({ social: tmpSocial })
                        }} />
                    </div>
                </div>
                <div className="form-group">
                    <input type="button" value="Save" className="btn btn-primary" onClick={this.saveFooter} />
                </div>
            </div>
        )
    }
}

class MainMessage extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div className="m-2">
                <span>Coming Soon...</span>
            </div>
        )
    }
}

class Ecommerce extends React.Component {
    constructor() {
        super()

        this.state = {
            showBools: {
                "homepageSlider": true,
                "homepageFeatured": false,
                "navbar": false,
                "contact":false,
                "about":false,
                "footer":false
            }
        }



    }

    render() {
        var homepageSliderToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['homepageSlider'] = !tmpShowBools['homepageSlider']
            this.setState({ showBools: tmpShowBools })
        }}></i>
        if (this.state.showBools['homepageSlider']) {
            var homepageSliderToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpShowBools = this.state.showBools
                tmpShowBools['homepageSlider'] = !tmpShowBools['homepageSlider']
                this.setState({ showBools: tmpShowBools })
            }}></i>
        }


        var navbarToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['navbar'] = !tmpShowBools['navbar']
            this.setState({ showBools: tmpShowBools })
        }}></i>
        if (this.state.showBools['navbar']) {
            var navbarToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpShowBools = this.state.showBools
                tmpShowBools['navbar'] = !tmpShowBools['navbar']
                this.setState({ showBools: tmpShowBools })
            }}></i>
        }

        var homepageFeaturedToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['homepageFeatured'] = !tmpShowBools['homepageFeatured']
            this.setState({ showBools: tmpShowBools })
        }}></i>
        if (this.state.showBools['homepageFeatured']) {
            var homepageFeaturedToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpShowBools = this.state.showBools
                tmpShowBools['homepageFeatured'] = !tmpShowBools['homepageFeatured']
                this.setState({ showBools: tmpShowBools })
            }}></i>
        }


        var contactToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['contact'] = !tmpShowBools['contact']
            this.setState({ showBools: tmpShowBools })
        }}></i>
        if (this.state.showBools['contact']) {
            var contactToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpShowBools = this.state.showBools
                tmpShowBools['contact'] = !tmpShowBools['contact']
                this.setState({ showBools: tmpShowBools })
            }}></i>
        }


        var aboutToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['about'] = !tmpShowBools['about']
            this.setState({ showBools: tmpShowBools })
        }}></i>
        if (this.state.showBools['about']) {
            var aboutToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpShowBools = this.state.showBools
                tmpShowBools['about'] = !tmpShowBools['about']
                this.setState({ showBools: tmpShowBools })
            }}></i>
        }


        var footerToggle = <i class="fas fa-chevron-circle-down" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['footer'] = !tmpShowBools['footer']
            this.setState({ showBools: tmpShowBools })
        }}></i>
        if (this.state.showBools['footer']) {
            var footerToggle = <i class="fas fa-chevron-circle-up" style={{ cursor: 'pointer' }} onClick={() => {
                const tmpShowBools = this.state.showBools
                tmpShowBools['footer'] = !tmpShowBools['footer']
                this.setState({ showBools: tmpShowBools })
            }}></i>
        }



        /////////////////////

        var homepageSliderSection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['homepageSlider'] = !tmpShowBools['homepageSlider']
            this.setState({ showBools: tmpShowBools })
        }}>Show</span>

        if (this.state.showBools['homepageSlider']) {
            homepageSliderSection = <HomePageSlider />
        }

        var navbarSection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['navbar'] = !tmpShowBools['navbar']
            this.setState({ showBools: tmpShowBools })
        }}>Show</span>

        if (this.state.showBools['navbar']) {
            navbarSection = <NavBar />
        }


        var homepageFeaturedSection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['homepageFeatured'] = !tmpShowBools['homepageFeatured']
            this.setState({ showBools: tmpShowBools })
        }}>Show</span>

        if (this.state.showBools['homepageFeatured']) {
            homepageFeaturedSection = <HomePageFeatured />
        }

        var contactSection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['contact'] = !tmpShowBools['contact']
            this.setState({ showBools: tmpShowBools })
        }}>Show</span>

        if (this.state.showBools['contact']) {
            contactSection = <ContactPage />
        }

        var aboutSection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['about'] = !tmpShowBools['about']
            this.setState({ showBools: tmpShowBools })
        }}>Show</span>

        if (this.state.showBools['about']) {
            aboutSection = <AboutPage />
        }


        var footerSection = <span className="btn-link m-3" style={{ cursor: 'pointer' }} onClick={() => {
            const tmpShowBools = this.state.showBools
            tmpShowBools['footer'] = !tmpShowBools['footer']
            this.setState({ showBools: tmpShowBools })
        }}>Show</span>

        if (this.state.showBools['footer']) {
            footerSection = <Footer />
        }


        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">E-commerce
                {/* <i class="fas fa-columns" style={{cursor:'pointer'}} title="Toggle Split" 
                onClick={()=>{
                    if (this.state.columnedBool) {
                        this.setState({columnClass:"col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between",columnedBool:false})
                    } else {
                        this.setState({columnClass:"col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between",columnedBool:true,showSearchInventoryBool:true,showAddInventoryBool:true})
                    }
                }}
                ></i> */}
                    </h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div className="row">

                    <div className='col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between'>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Homepage Slider {homepageSliderToggle}</h6>
                            </div>
                            <div class="m-0">
                                {homepageSliderSection}
                                {/* <HomePageSlider /> */}
                                {/* <AddToInventory /> */}
                                {/* {addInventorySection} */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Home Page Featured {homepageFeaturedToggle}</h6>
                            </div>
                            <div class="m-0">
                                {homepageFeaturedSection}
                                {/* <HomePageFeatured /> */}
                                {/* <SearchInventory /> */}
                                {/* {searchInventorySection} */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Contact {contactToggle}</h6>
                            </div>
                            <div class="m-0">
                                {contactSection}
                                {/* <ContactPage /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Main Message</h6>
                            </div>
                            <div class="m-0">
                                <MainMessage />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Nav Bar {navbarToggle}</h6>
                            </div>
                            <div class="m-0">
                                {/* <NavBar /> */}
                                {navbarSection}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Footer {footerToggle}</h6>
                            </div>
                            <div class="m-0">
                                {footerSection}
                                {/* <Footer /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">About {aboutToggle}</h6>
                            </div>
                            <div class="m-0">
                                {/* <AboutPage /> */}
                                {aboutSection}
                                {/* <HomePageFeatured /> */}
                                {/* <SearchInventory /> */}
                                {/* {searchInventorySection} */}
                            </div>
                        </div>
                    </div>
                </div>

                <FullScreenImageView />
            </div>

        )
    }
}


export default Ecommerce