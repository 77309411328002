import React, { useState } from 'react';
import axios from 'axios';
import './index.css';
import Cookies from 'js-cookie';
import URL from './URL'


const Login = e => {
  const [userState, setUserState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [serverReply, setServerReply] = useState("");
  const [serverReplyBool, setServerReplyBool] = useState(false);

  const handleUserChange = (e) => {
    setUserState(e.target.value);
  }
  const handlePasswordChange = (e) => {
    setPasswordState(e.target.value);
  }

  const ServerAlert = (e) => {
    if (serverReplyBool === true) {

      if (serverReply['_status'] === "fail") {
        return (
          <div class="alert alert-danger m-2" role="alert">
            {serverReply['_status']}
          </div>
        );
      }
      if (serverReply['_status'] === "success") {
        Cookies.set('ACCT', serverReply['payload'])
        Cookies.set('TP', serverReply['account_type'])
        Cookies.set('U', serverReply['username'])
        window.location.reload();
        return (
          <div class="alert alert-success m-2" role="alert">
            {serverReply['_status']}
          </div>
        );

      }


    }
    return (
      <div></div>
    );
  }

  const handle_login = (e) => {
    setServerReplyBool(true);
  }

  const login_function = (e) => {
    setServerReplyBool(false);
    axios.post(URL + "login", {
      username: userState,
      password: passwordState,
    })
      .then(function (response) {
        setServerReply(response['data'])
        handle_login();
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  return (

    <div class="container">
      <div class="row justify-content-center">

        <div class="col-xl-6 col-lg-6 col-md-9 col-sm-12">

          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <div class="row">
                {/* <div class="col-lg-6 d-none d-lg-block bg-login-img" style={{background:'url(https://images.pexels.com/photos/709552/pexels-photo-709552.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)'}}></div> */}
                <div class="w-100 d-flex justify-content-center">
                  {/* <div class="col-lg-6 d-none d-lg-block bg-login-img"> */}
                    <img src="6efc5eeb-2509-4d73-af45-d34b1c132509_200x200.png"></img>
                  {/* </div> */}

                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="p-5">
                    {/* <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">Login to ProOne</h1>
                    </div> */}
                    <form class="user">
                      <div class="form-group">
                        <input type="text" class="form-control form-control-user" id="InputUsername" aria-describedby="UsernameHelp" placeholder="Username" onChange={handleUserChange} />
                      </div>
                      <div class="form-group">
                        <input type="password" class="form-control form-control-user" id="InputPassword" placeholder="Password" onChange={handlePasswordChange} />
                      </div>
                      {/* <div class="form-group">
                            <div class="custom-control custom-checkbox small">
                              <input type="checkbox" class="custom-control-input" id="customCheck" />
                              <label class="custom-control-label" for="customCheck">Remember Me</label>
                            </div>
                          </div> */}
                      <input type="button" class="btn btn-primary btn-user btn-block" value="Login" onClick={login_function} />
                      {/* <input type="button" class="btn btn-info btn-user btn-block" value="Create Account" /> */}
                      {/* Login
                        </input> */}
                      <ServerAlert />
                      {/* <hr />
                      <a href="index.html" class="btn btn-google btn-user btn-block">
                        <i class="fab fa-google fa-fw"></i> Login with Google
                        </a>
                      <a href="index.html" class="btn btn-facebook btn-user btn-block">
                        <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                        </a> */}
                    </form>
                    {/* <hr /> */}
                    {/* <div class="text-center">
                      <a class="small" href="forgot-password.html">Forgot Password?</a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
}

export default Login;