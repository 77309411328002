import React from 'react'
import { faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';

import Pagination from "react-js-pagination";

import 'react-quill/dist/quill.snow.css';

import URL from './URL'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';


var _ = require('lodash');


const { DateTime, } = require("luxon");

var ACCT = Cookies.get('ACCT')


class ItemSearch extends React.Component {
    constructor(props) {
        super()

        this.urlEndPoint = props.urlEndPoint
        this.onClickCallback = props.onClickCallback
        this.gType = props.gType
        this.searchLimit = props.searchLimit
        this.urlParams = props.urlParams
        this.apiEndpoint = props.apiEndpoint

        this.timeKey = props.timeKey
        this.updatedKey = props.updatedKey
        this.nameTitleKey = props.nameTitleKey
        this.subNameTitleKey = props.subNameTitleKey
        this.searchAllowed = props.searchAllowed
        this.resultClickable = props.resultClickable
        this.noTime = props.noTime
        this.sortCriteria = props.sortCriteria

        this.subHeadingKeys = props.subHeadingKeys

        this.resultView = props.resultView

        this.isResultLink = props.isResultLink
        this.resultLink = props.resultLink

        this.resultExtraClassname = ""
        this.resultStyle = {}

        this.searchTypeTimer = null

        if (this.apiEndpoint === undefined || this.apiEndpoint === null) {
            this.apiEndpoint = URL
        } 

        

        if (this.timeKey === undefined || this.timeKey === null) {
            this.timeKey = "t"
        }
        if (this.updatedKey === undefined || this.updatedKey === null) {
            this.updatedKey = "u" // u is user????
        }

        if (this.searchAllowed === undefined || this.searchAllowed === null) {
            this.searchAllowed = true // u is user????
        }

        if (this.isResultLink === undefined || this.isResultLink === null) {
            this.isResultLink = false
        }

        if (this.resultLink === undefined || this.resultLink === null) {
            this.resultLink = ""
        }

        if (this.noTime === undefined || this.noTime === null) {
            this.noTime = false
        } else {
            this.noTime = props.noTime
        }

        if (this.sortCriteria === undefined || this.sortCriteria === null) {
            this.sortCriteria = "t"
        }

        if (this.resultClickable !== undefined || this.resultClickable !== null) {
            if (this.resultClickable === true) {
                this.resultExtraClassname = " list-group-item-action"
                this.resultStyle = {
                    cursor: 'pointer'
                }
            }
        }


        if (this.searchLimit !== undefined || this.searchLimit !== null) {
            if (parseInt(this.searchLimit)) {
                this.searchLimit = parseInt(this.searchLimit)
            } else {
                this.searchLimit = 10
            }
        }

        this.state = {
            isLoading: true,
            isSuccess: false,
            isError: false,

            searchTerm: "",

            pageNumber: 1,

            totalDocCount: 0,
            data: []
        }

    }

    componentDidMount() {
        this.getData()
    }

    handleGetData = (response) => {
        if (!("_status" in response) || !("payload" in response)) {
            this.setState({ isLoading: false, isError: true, isSuccess: false })
            return
        }

        if (response["_status"] !== "success") {
            this.setState({ isLoading: false, isError: true, isSuccess: false })
            return
        }

        this.setState({ isLoading: false, isSuccess: true, isError: false, data: response.payload.data, totalDocCount: response.payload.tc })
    }

    getData = () => {
        const that = this

        axios.get(this.apiEndpoint + this.urlEndPoint, {
            params: {
                g: this.gType,
                pn: this.state.pageNumber,
                sc: this.sortCriteria,
                st: this.state.searchTerm,
                sl: this.searchLimit,
                ...this.urlParams
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                that.handleGetData(response)
            })
            .catch(function (error) {
                that.handleGetData({})
            })
    }



    render() {

        if (this.state.isError) {
            return (
                <div>
                    <span className="text-danger">
                        Unable to fetch search results.
                    </span>
                </div>
            )
        }
        if (this.state.isLoading) {
            return (
                <div>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        var data = []
        for (var i in this.state.data) {
            const idx = i

            var timeDiv = null
            if (!this.noTime && this.timeKey in this.state.data[i]) {
                timeDiv = <small title={DateTime.fromMillis(this.state.data[i][this.timeKey].$date).toRelative().toString()}>{DateTime.fromMillis(this.state.data[i][this.timeKey].$date).toLocaleString(DateTime.DATETIME_MED)}</small>
            }

            // subheadings 

            var subheadings = []
            if (this.subHeadingKeys !== undefined && this.subHeadingKeys !== null && this.subHeadingKeys.length > 0) {
                for (var shk in this.subHeadingKeys) {
                    // alert(_.get(this.state.data[i], this.subHeadingKeys[shk]))
                    var val = _.get(this.state.data[i], this.subHeadingKeys[shk])
                    if (val === undefined || val === null) {

                    } else {
                        subheadings.push([
                            <br />,
                            <small>{_.get(this.state.data[i], this.subHeadingKeys[shk])}</small>
                        ])
                    }
                    
                }
            }

            if (this.isResultLink === false) {
                if (this.subNameTitleKey !== undefined && this.subNameTitleKey !== null && this.subNameTitleKey.length > 0) {
                    data.push(
                        <li className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} onClick={() => {
                            this.onClickCallback(this.state.data[idx])
                        }
                        }>
                            <div className="d-flex w-100 justify-content-between">
                                <span>
                                    <h5 className="mb-1">{this.state.data[i][this.nameTitleKey]}</h5>
                                    {this.state.data[i][this.subNameTitleKey]}
                                    {subheadings}
                                </span>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </li>
                    )
                } else {
                    data.push(
                        <li className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} onClick={() => {
                            this.onClickCallback(this.state.data[idx])
                        }
                        }>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">{this.state.data[i][this.nameTitleKey]}</h5>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </li>
                    )
                }
            } else {
                if (this.subNameTitleKey !== undefined && this.subNameTitleKey !== null && this.subNameTitleKey.length > 0) {
                    data.push(
                        <Link to={this.resultLink + this.state.data[idx]._id} className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} >
                            <div className="d-flex w-100 justify-content-between">
                                <span>
                                    <h5 className="mb-1">{this.state.data[i][this.nameTitleKey]}</h5>
                                    {this.state.data[i][this.subNameTitleKey]}
                                    {subheadings}
                                </span>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </Link>
                    )
                } else {
                    data.push(
                        <Link to={this.resultLink + this.state.data[idx]._id} className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} >
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">{this.state.data[i][this.nameTitleKey]}</h5>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </Link>
                    )
                }
            }

        }

        // set search stuff

        return (
            <div className="m-2">

                <div className="d-flex flex-row align-items-center">
                    <input className="form-control" value={this.state.searchTerm} disabled={!this.searchAllowed} placeholder="Search" onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {

                            if (this.searchTypeTimer === null) {
                                this.searchTypeTimer = window.setTimeout(() => {
                                    this.setState({pageNumber:1},()=>{
                                        this.getData()
                                    })
                                }, 500)
                            } else {
                                clearTimeout(this.searchTypeTimer)
                                this.searchTypeTimer = window.setTimeout(() => {
                                    this.setState({pageNumber:1},()=>{
                                        this.getData()
                                    })
                                }, 500)
                            }
                        })
                    }} />
                    <span hidden={this.state.searchTerm === ""}>
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.setState({ searchTerm: "" }, this.getData)
                        }} />
                    </span>
                </div>

                <div className="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                    <div className="d-inline-flex  justify-content-center align-items-center w-100">
                        <Pagination
                            innerClass="pagination"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={this.searchLimit}
                            totalItemsCount={this.state.totalDocCount}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber) => {
                                this.setState({ pageNumber: parseInt(pageNumber) }, this.getData)
                            }}
                        />
                    </div>
                </div>
                <div className="m-2">
                    <small>Total Records: {this.state.totalDocCount}. Showing {((this.state.pageNumber - 1) * this.searchLimit) + 1} - {((this.state.pageNumber - 1) * this.searchLimit) + data.length}.</small>&nbsp;
                </div>
                <ul class="list-group">
                    {data}
                </ul>
            </div>
        )
    }
}


export {
    ItemSearch
}