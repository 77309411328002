import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Dashboard from './Dashboard';
import db from './db';
// import Login from './Login';
import Register from './Register';
import notfound from  "./404";
// import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
// import jQuery from 'jquery'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

// import 'gijgo/css/gijgo.min.css';
// import 'gijgo/js/gijgo';

import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css';

ReactGA.initialize('UA-140229250-3');
ReactGA.pageview(window.location.pathname + window.location.search);

const routing = (
    <Router>
        <div>
            <Switch>
            {/* <Route exact path="/" component={App} /> */}
            <Route exact path="/" component={App} />
            {/* <Route path="/dashboard" component={Dashboard} />
            <Route path="/database" component={db} />
            <Route path="/app" component={App} />  
            <Route path="/register" component={Register} />    */}
            <Route component={notfound} />
            </Switch>
        </div>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
