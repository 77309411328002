import React, { useState } from 'react';
import axios from 'axios';
import URL from './URL';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { createStore } from 'redux'
import { thisExpression, blockStatement } from '@babel/types';
import moment from 'moment-timezone';
import { parse } from 'url';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Rating from 'react-rating'
import { instanceOf } from 'prop-types';
import Pagination from "react-js-pagination";
import html2canvas from 'html2canvas';
import { fullDialogDispatcher } from './FullDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


const { jsPDF } = require("jspdf");


var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')

if (userInfo != null) {
    userInfo = JSON.parse(userInfo);
    account_type = userInfo['account_type'];

}

function setSelectedCustomer(state = "", action) {
    state = action.type;
    return state;
}

let selectedCustomersStore = createStore(setSelectedCustomer)


const searchFilterCheckboxValues = {
    // "ID": true,
    "Firstname": true,
    "Lastname": true,
    "Brand": true,
    "Interests": true,
    "Phone": true,
    "Email": true,
    "GSTIN": true,
    "Handler": true,
    "City": true,
    "State": true,
    "Pincode": true,
    "Country": true
};

const searchGetAllFieldsCheckboxValues = {
    // "ID": true,
    "Firstname": false,
    "Lastname": false,
    "Brand": false,
    "Interests": false,
    "Phone": false,
    "Email": false,
    "GSTIN": false,
    "Handler": false,
    "City": false,
    "State": false,
    "Pincode": false,
    "Country": false
};

class Handlers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "handlers": {} };
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "handlers": response['data']['payload'] });
            })

        this.selectedHandler = props.handler;
    }
    render() {
        var handlers = []
        for (var handler in this.state.handlers) {
            var name_ = this.state.handlers[handler].firstname + " " + this.state.handlers[handler].lastname;
            if (name_ == this.selectedHandler) {

                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_} selected>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
            else {
                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_}>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
        }
        return (

            handlers
        );
    }
}


class SelectedOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "selectedOrders": {}, "printOrderColumns": "2", "printOrderTimes": "1",isPDFLoading:false };

        this.AllSelectedOrders = this.AllSelectedOrders.bind(this);
        this.addOrder = this.addOrder.bind(this);
        this.deleteSelectedOrder = this.deleteSelectedOrder.bind(this);
        this.PrintButton = this.PrintButton.bind(this);
        this.ClearAllButton = this.ClearAllButton.bind(this);
        this.clearAllSelectedOrder = this.clearAllSelectedOrder.bind(this);
        this.Dialog = this.Dialog.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handlePrintChange = this.handlePrintChange.bind(this);

        selectedCustomersStore.subscribe(this.addOrder);
    }

    handlePrint = (e) => {

        var allOrderIds = []
        for (var id in this.state.selectedOrders) {
            allOrderIds.push(id);
        }
        const that = this;
        axios.post(URL + 'orders/print', {
            allOrderIds: allOrderIds,
            times: this.state.printOrderTimes,
            columns: this.state.printOrderColumns
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                if (response["data"]["_status"] === "success") {
                    var WinPrint = window.open('', 'proone', 'width=800px,height=500px,toolbar=0,scrollbars=1,status=0,');
                    WinPrint.document.write(response["data"]["payload"]);
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }
                // that.setState({ "retrievedOrders": response['data']['payload'] });
                // console.log(that.state.retrievedOrders)

            })
    }

    handlePDFPrint = (e) => {
        
        var fileName = `${this.state.printOrderTimes}-${this.state.printOrderColumns}`
        var allOrderIds = []
        for (var id in this.state.selectedOrders) {
            allOrderIds.push(id);
            fileName += `-${this.state.selectedOrders[id].orderid}`
            // console.log(this.state.selectedOrders[id])
        }
        this.setState({isPDFLoading:true})
        const that = this;
        axios.post(URL + 'orders/printpdf', {
            allOrderIds: allOrderIds,
            times: this.state.printOrderTimes,
            columns: this.state.printOrderColumns
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                if (response["data"]["_status"] === "success") {
                    const doc = new jsPDF(
                        {
                            orientation:'portrait',
                            unit:'px',
                            format:[1122.51,794]
                        }
                    );

                    doc.html(response["data"]["payload"])
                        .then(() => {
                            doc.save(`${fileName}.pdf`)
                            that.setState({isPDFLoading:false})
                        });

                }

            })

            .catch(function (error) {
                alert("Failed to generate PDF")
                console.log(error)
            })
    }

    deleteSelectedOrder = (e) => {
        const lid = e.target.getAttribute("lid")
        var tmp = this.state.selectedOrders
        delete tmp[lid];
        this.setState({ selectedOrders: tmp })
    }

    addOrder = (e) => {
        console.log("adding");
        const incoming = selectedCustomersStore.getState();
        var tmp = this.state.selectedOrders;
        tmp[incoming["_id"]["$oid"]] = incoming;
        this.setState({ selectedOrders: tmp })
        console.log(this.state.selectedOrders)
    }

    AllSelectedOrders = (e) => {
        var row = [];
        for (var item in this.state.selectedOrders) {
            var amountStateClass = "";
            var orderStateClass = "";
            if (this.state.selectedOrders[item].orderstate == "Processing") {
                orderStateClass = "bg-warning text-dark"
            }
            if (this.state.selectedOrders[item].orderstate == "Cancelled") {
                orderStateClass = "bg-danger text-white"
            }
            if (this.state.selectedOrders[item].orderstate == "Ready") {
                orderStateClass = "bg-primary text-white"
            }
            if (this.state.selectedOrders[item].orderstate == "Packed") {
                orderStateClass = "bg-primary text-white"
            }
            if (this.state.selectedOrders[item].orderstate == "Partly Sent") {
                orderStateClass = "bg-info text-white"
            }
            if (this.state.selectedOrders[item].orderstate == "Sent") {
                orderStateClass = "bg-success text-white"
            }
            if (this.state.selectedOrders[item].orderstate == "Returned") {
                orderStateClass = "bg-dark text-white"
            }
            if (this.state.selectedOrders[item].amountstate == "Pending") {
                amountStateClass = "bg-danger text-white"
            }
            if (this.state.selectedOrders[item].amountstate == "Pooling") {
                amountStateClass = "bg-primary text-white"
            }
            if (this.state.selectedOrders[item].amountstate == "Partly Received") {
                amountStateClass = "bg-warning text-dark"
            }
            if (this.state.selectedOrders[item].amountstate == "Received") {
                amountStateClass = "bg-success text-white"
            }
            if (this.state.selectedOrders[item].amountstate == "COD Received") {
                amountStateClass = "bg-success text-white"
            }
            if (this.state.selectedOrders[item].amountstate == "Refund") {
                amountStateClass = "bg-dark text-white"
            }

            var _id = "";
            row.push(
                <tr>
                    <td>
                        {this.state.selectedOrders[item].orderid}
                    </td>
                    <td>
                        {/* {moment(this.state.selectedOrders[item].date).format('dddd, Do MMM YYYY')} */}
                        {/* {this.state.selectedOrders[item].date} */}
                        {moment.unix(parseInt(this.state.selectedOrders[item].date.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY')}
                    </td>
                    <td>
                        {this.state.selectedOrders[item].to}
                    </td>
                    <td>
                        {this.state.selectedOrders[item].from}
                    </td>
                    <td>
                        {this.state.selectedOrders[item].amount}
                    </td>
                    <td id={`orderSelectedDescription-` + item}>
                        {this.state.selectedOrders[item].description}
                    </td>
                    <td>
                        <select style={{ minWidth: '150px' }} class={`custom-select ` + orderStateClass} name="orderstate" defaultValue={this.state.selectedOrders[item].orderstate} id={`orderSelectedState-` + item} itemid={item} lid={this.state.selectedOrders[item]._id["$oid"]} disabled>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-warning text-dark" value="Processing">Processing</option>
                            <option class="bg-danger text-white" value="Cancelled">Cancelled</option>
                            <option class="bg-primary text-white" value="Ready">Ready</option>
                            <option class="bg-primary text-white" value="Packed">Packed</option>
                            <option class="bg-info text-white" value="Partly Sent">Partly Sent</option>
                            <option class="bg-success text-white" value="Sent">Sent</option>
                        </select>
                    </td>
                    <td>
                        <select style={{ minWidth: '150px' }} class={`custom-select ` + amountStateClass} name="amountstate" defaultValue={this.state.selectedOrders[item].amountstate} id={`amountSelectedState-` + item} itemid={item} lid={this.state.selectedOrders[item]._id["$oid"]} disabled>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-danger text-white" value="Pending">Pending</option>
                            <option class="bg-primary text-white" value="Pooling">Pooling</option>
                            <option class="bg-warning text-dark" value="Partly Received">Partly Received</option>
                            <option class="bg-success text-white" value="Received">Received</option>
                            <option class="bg-success text-white" value="COD Received">COD Received</option>
                        </select>
                    </td>
                    <td id={`orderTracking-` + item}>
                        {this.state.selectedOrders[item].tracking}
                    </td>
                    <td>
                        <i class="fas fa-trash-alt mr-3" title="Delete Order From Selection" style={{ cursor: 'pointer' }} id={`deleteSelectedOrder-` + item} lid={this.state.selectedOrders[item]._id["$oid"]} onClick={this.deleteSelectedOrder} ></i>

                    </td>
                </tr>
            )
        }
        return (
            row
        );
    }

    clearAllSelectedOrder = (e) => {
        this.setState({ selectedOrders: {} })
    }

    PrintButton = (e) => {
        var count = Object.keys(this.state.selectedOrders).length;
        if (count > 0) {
            return (
                <button type="button" class="btn btn-primary mt-4 ml-4 mb-4 mr-2" data-toggle="modal" data-target="#printDialog">Print</button>
            );
        }
        else {
            return ("");
        }
    }

    ClearAllButton = (e) => {
        var count = Object.keys(this.state.selectedOrders).length;
        if (count > 0) {
            return (
                <button type="button" class="btn btn-primary mt-4 mb-4 ml-2" onClick={this.clearAllSelectedOrder}>Clear All</button>
            );
        }
        else {
            return ("");
        }
    }

    handlePrintChange = (e) => {
        const key = e.target.id.toString();
        const val = e.target.value;
        var tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log(this.state)
    }

    Dialog(props) {
        const name = props.name;
        const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
        const handle = props.handle;
        if (name === "print") {
            return (
                <div class="modal fade" id={name + `Dialog`} tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Print Order(s)</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body m-2">
                                <form>
                                    <div class="form-group row" key="0">
                                        <label for="printOrderTimes" class="">Times</label>
                                        {/* <div class="col-sm-10" key="0"> */}
                                        <input autoComplete="new-password" autocomplete="off" type="number" name="printOrderTimes" id="printOrderTimes" className="form-control border-1 small  form-control-lg" onChange={this.handlePrintChange} value={this.state.printOrderTimes} key="0" />
                                        {/* </div> */}
                                    </div>
                                    <div class="form-group row" key="0">
                                        <label for="editOrderColumns" class="">Columns</label>
                                        {/* <div class="col-sm-10" key="0"> */}
                                        <input autoComplete="new-password" autocomplete="off" type="number" name="printOrderColumns" id="printOrderColumns" className="form-control border-1 small  form-control-lg" onChange={this.handlePrintChange} value={this.state.printOrderColumns} key="0" />
                                        {/* </div> */}
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={handle}>Print</button>
                                <button type="button" class="btn btn-primary" disabled={this.state.isPDFLoading} onClick={() => {
                                    this.handlePDFPrint()
                                }}><span hidden={!this.state.isPDFLoading}><FontAwesomeIcon spin icon={faCircleNotch} /></span> PDF</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div class="modal fade" id={name + `Dialog`} tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Confirm Action</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Do you want to {name} the order?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={handle}>{nameCapitalized}</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Exit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <div class="">
                <div class="col-xl-4 col-lg-4 align-items-center justify-content-between">
                    <div class="input-group">
                        <this.PrintButton />
                        <this.ClearAllButton />
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover  w-100 m-0">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Order Id</th>
                                <th scope="col">Order Date</th>
                                <th scope="col">To</th>
                                <th scope="col">From</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Description</th>
                                <th scope="col">Order State</th>
                                <th scope="col">Amount State</th>
                                <th scope="col">Tracking</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <this.AllSelectedOrders />
                        </tbody>
                    </table>
                </div>
                <this.Dialog name="print" handle={this.handlePrint} />
            </div>

        );
    }

}

class OpenOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "aa": "" };
        this.handleRowClick = this.handleRowClick.bind(this);
        this.AllOrderRows = this.AllOrderRows.bind(this);

        // const that = this;
        // axios.get(URL + 'orders', {
        //     headers: { Authorization: ACCT }
        // })
        //     .then(function (response) {
        //         // console.log(response['data']['payload']);
        //         that.setState({ "existingUsers": response['data']['payload'] });
        //     })
    }
}

class AllOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = { handler: "", isopen: "true", retrievedOrders: {}, actionSelectedId: "", editOrderTracking: "", editOrderDescription: "", editOrderAmount: "", editOrderTo: "", editOrderToId: "", editOrderFromId: "", "handlers": {}, ordersPageNumber: 1, totalOrdersCount: 0, moreInformationId: "", retrievedMoreInformationOrder: {}, shipFromOwnerCheckbox: false, editOrderAmountToPrint: "", editOrderDate: "", editOrderRatings: {}, editOrder: {}, newSubOrderOrderID: "", newSubOrderToFromSet: false, newSubOrderAmount: "", newSubOrderDate: new Date(), newSubOrderDescription: "", newSubOrderShipFrom: "", newSubOrderShipTo: "", newSubOrderShipFromID: "", newSubOrderShipToID: "", newSubOrderShipFromOwnerCheckbox: false, newSubOrderSelectedToSet: false, newSubOrderSelectedFromSet: false, newSubOrderStatusText: {} };

        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.AllOrderRows = this.AllOrderRows.bind(this);
        this.fetchOrders = this.fetchOrders.bind(this);
        this.Dialog = this.Dialog.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleHandlerChange = this.handleHandlerChange.bind(this);
        this.Handlers = this.Handlers.bind(this);
        this.fetchHandlers = this.fetchHandlers.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.Pagination = this.Pagination.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.TotalOrderString = this.TotalOrderString.bind(this);
        this.customerSearchChangeHandle = this.customerSearchChangeHandle.bind(this);
        this.CustomerSearchResults = this.CustomerSearchResults.bind(this);
        this.SelectedCustomer = this.SelectedCustomer.bind(this);
        this.populateSelectedCustomer = this.populateSelectedCustomer.bind(this);
        this.resetState = this.resetState.bind(this);
        this.MoreInformationDialog = this.MoreInformationDialog.bind(this);
        this.fetchSingleOrder = this.fetchSingleOrder.bind(this);
        this.SelectedFrom = this.SelectedFrom.bind(this);
        this.FromSearchResults = this.FromSearchResults.bind(this);
        this.fromSearchChangeHandle = this.fromSearchChangeHandle.bind(this);
        this.removeSelectedCustomer = this.removeSelectedCustomer.bind(this);
        this.removeSelectedFrom = this.removeSelectedFrom.bind(this);
        this.populateSelectedFrom = this.populateSelectedFrom.bind(this);
        this.handleShipFromOwnerCheckbox = this.handleShipFromOwnerCheckbox.bind(this);
        this.NewSubOrderDialog = this.NewSubOrderDialog.bind(this);
        this.newSubOrderToSearchChangeHandle = this.newSubOrderToSearchChangeHandle.bind(this);
        this.NewSubOrderToSearchResults = this.NewSubOrderToSearchResults.bind(this);
        this.populateNewSubOrderSelectedTo = this.populateNewSubOrderSelectedTo.bind(this);
        this.NewSubOrderSelectedTo = this.NewSubOrderSelectedTo.bind(this)

        this.newSubOrderFromSearchChangeHandle = this.newSubOrderFromSearchChangeHandle.bind(this);
        this.NewSubOrderFromSearchResults = this.NewSubOrderFromSearchResults.bind(this);
        this.populateNewSubOrderSelectedFrom = this.populateNewSubOrderSelectedFrom.bind(this);
        this.NewSubOrderSelectedFrom = this.NewSubOrderSelectedFrom.bind(this);

        this.NewSubOrderStatusText = this.NewSubOrderStatusText.bind(this);
        this.resetNewSubOrderAll = this.resetNewSubOrderAll.bind(this);
        this.AllSubOrdersDialog = this.AllSubOrdersDialog.bind(this);
        this.fetchSubOrders = this.fetchSubOrders.bind(this);
        this.handleSubOrderSelect = this.handleSubOrderSelect.bind(this);

        this.fetchData();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchData(), 5000);
    }
    componentWillUnmount() {
        clearInterval(this.timer)
        this.timer = null; // here...
    }

    resetState = (e) => {
        $("#editOrderTo").val("")
        $("#editOrderFrom").val("")
        this.setState({ selectedCustomerSet: false, retrievedCustomerData: {}, selectedFromSet: false, retrievedFromData: {}, editOrderToId: "", editOrderFromId: "", shipFromOwnerCheckbox: false })
        $("#shipFromOwnerCheckbox").prop('checked', false)
    }
    fetchData() {
        this.fetchOrders();
        this.fetchHandlers();
    }
    Handlers = (e) => {
        var handlers = []
        for (var handler in this.state.handlers) {
            var name_ = this.state.handlers[handler].firstname + " " + this.state.handlers[handler].lastname;
            handlers.push(
                <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_}>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
            )
        }

        return (
            handlers
        );

    }
    fetchHandlers = (e) => {
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "handlers": response['data']['payload'] });
            })
    }
    fetchOrders = (e) => {
        const that = this;
        axios.get(URL + 'orders?handler=' + this.state.handler + '&isopen=' + this.state.isopen + '&ordersPageNumber=' + (this.state.ordersPageNumber - 1).toString(), {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "retrievedOrders": response['data']['payload']["orders"], "totalOrdersCount": response['data']['payload']["totalCount"] });
            })
    }

    fetchSingleOrder = (e) => {
        const that = this;
        axios.get(URL + 'orders?handler=' + this.state.handler + '&_id=' + this.state.moreInformationId, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ retrievedMoreInformationOrder: response["data"]["payload"] })
            })
    }

    handleEdit(e) {
        const lid = this.state.actionSelectedId;
        const that = this;
        axios.put(URL + 'orders', {
            _id: lid,
            description: this.state.editOrder.description,
            tracking: this.state.editOrder.tracking,
            amount: this.state.editOrder.amount,
            to_id: this.state.editOrderToId,
            from_id: this.state.editOrderFromId,
            shipFromOwnerCheckbox: this.state.shipFromOwnerCheckbox,
            amountToPrint: this.state.editOrder.amountToPrint,
            ratings: this.state.editOrderRatings,
            date: this.state.editOrderDate
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                console.log(response["data"])
                that.fetchOrders();
                that.resetState();
                that.fetchSubOrders();
                // that.setState({ "retrievedOrders": response['data']['payload'] });
                // console.log(that.state.retrievedOrders)
            })
    }

    handleClose(e) {
        const lid = this.state.actionSelectedId;
        const that = this;
        axios.patch(URL + 'orders', {
            _id: lid
        }
            , {
                headers: { Authorization: ACCT }
            })
            .then(function (response) {
                console.log(response["data"])
                that.fetchOrders();
                that.fetchSubOrders();
                // that.setState({ "retrievedOrders": response['data']['payload'] });
                // console.log(that.state.retrievedOrders)
            })
    }
    handleDelete(e) {
        const lid = this.state.actionSelectedId;
        const that = this;
        axios.delete(URL + 'orders?id=' + lid, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                console.log(response["data"])
                that.fetchOrders();
                that.fetchSubOrders();
                // that.setState({ "retrievedOrders": response['data']['payload'] });
                // console.log(that.state.retrievedOrders)
            })
    }

    handleSelect(e) {
        const itemid = e.target.getAttribute("itemid");
        selectedCustomersStore.dispatch({ type: this.state.retrievedOrders[itemid] })
    }

    handleStateChange(e) {
        var id = e.target.id;
        var itemid = e.target.getAttribute("itemid");
        var tmp = {}
        const lid = e.target.getAttribute("lid");

        tmp["_id"] = lid;
        tmp[e.target.name] = e.target.value;

        const that = this;

        $("#orderState-" + itemid).prop("disabled", true);
        $("#amountState-" + itemid).prop("disabled", true);

        axios.put(URL + 'orders', tmp, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                $("#orderState-" + itemid).prop("disabled", false);
                $("#amountState-" + itemid).prop("disabled", false);
                console.log(response["data"])
                that.fetchOrders();
                that.fetchSubOrders();
                // that.setState({ "retrievedOrders": response['data']['payload'] });
                // console.log(that.state.retrievedOrders)
            })
    }

    handlePagination = (e) => {
        var pagenumber = e.target.getAttribute('name');
        this.setState({ ordersPageNumber: pagenumber }, this.fetchData)

    }
    Pagination = (e) => {
        const navItems = [];
        var tmpTotalOrdersCount = parseInt(this.state.totalOrdersCount)
        // console.log(tmpTotalOrdersCount)
        const pageCount = Math.ceil(tmpTotalOrdersCount / 10);

        if (pageCount > 6) {
            var start_i = parseInt(this.state.ordersPageNumber) - 3;
            var end_i = parseInt(this.state.ordersPageNumber);
            if (start_i < 0) {
                start_i = 0;
            }
            for (var i = start_i; i < end_i; i++) {
                navItems.push(
                    <li class="page-item" id={`paginationItem-` + i}><a class="page-link" style={{ cursor: 'pointer' }} onClick={this.handlePagination} name={i}>{i + 1}</a></li>
                );
            }

            start_i = parseInt(this.state.ordersPageNumber);
            end_i = parseInt(this.state.ordersPageNumber) + 3;
            if (end_i > pageCount - 1) {
                end_i = pageCount - 1;
            }
            for (var i = start_i; i < end_i; i++) {
                navItems.push(
                    <li class="page-item" id={`paginationItem-` + i}><a class="page-link" style={{ cursor: 'pointer' }} onClick={this.handlePagination} name={i}>{i + 1}</a></li>
                );
            }
            if (parseInt(this.state.ordersPageNumber) < pageCount - 4) {


                navItems.push(
                    <li class="page-item"><span class="page-link ">&#183;</span></li>
                );
                navItems.push(
                    <li class="page-item"><span class="page-link border-left-0">&#183;</span></li>
                );
                navItems.push(
                    <li class="page-item"><span class="page-link border-left-0 border-right-0">&#183;</span></li>
                );
                navItems.push(
                    <li class="page-item" id={`paginationItem-` + pageCount}><a class="page-link" style={{ cursor: 'pointer' }} onClick={this.handlePagination} name={pageCount - 1}>{pageCount}</a></li>
                );


            }
            if (parseInt(this.state.ordersPageNumber) >= pageCount - 4) {

                navItems.push(
                    <li class="page-item" id={`paginationItem-` + (pageCount - 1)}><a class="page-link" style={{ cursor: 'pointer' }} onClick={this.handlePagination} name={pageCount - 1}>{pageCount}</a></li>
                );
            }

        }
        else {
            for (var i = 0; i < pageCount; i++) {

                navItems.push(
                    <li class="page-item" id={`paginationItem-` + i}><a class="page-link" style={{ cursor: 'pointer' }} onClick={this.handlePagination} name={i}>{i + 1}</a></li>
                );

            }
        }

        return (
            <Pagination
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.ordersPageNumber}
                itemsCountPerPage={10}
                totalItemsCount={tmpTotalOrdersCount}
                pageRangeDisplayed={3}
                onChange={(pageNumber) => {
                    this.setState({ "ordersPageNumber": parseInt(pageNumber) }, this.fetchData)
                }}
            />
        )
        // return (
        //     <ul class="m-0 pagination">
        //         {navItems}
        //     </ul>
        // );
    }

    TotalOrderString = (e) => {
        if (this.state.totalOrdersCount === 0) {
            return ("")
        }
        return (
            <span class="align-middle">
                Total Order(s): {this.state.totalOrdersCount}
            </span>
        )
    }

    AllOrderRows = (e) => {
        var tmpTotalOrdersCount = parseInt(this.state.totalOrdersCount)
        const ordersCount = Math.ceil(tmpTotalOrdersCount / 10);
        for (var i = 0; i < ordersCount; i++) {
            $("#paginationItem-" + i.toString()).removeClass("active")
        }
        $("#paginationItem-" + this.state.ordersPageNumber).addClass("active")
        var row = [];
        for (var item in this.state.retrievedOrders) {
            var amountStateClass = "";
            var orderStateClass = "";
            if (this.state.retrievedOrders[item].orderstate == "Processing") {
                orderStateClass = "bg-warning text-dark"
            }
            if (this.state.retrievedOrders[item].orderstate == "Cancelled") {
                orderStateClass = "bg-danger text-white"
            }
            if (this.state.retrievedOrders[item].orderstate == "Ready") {
                orderStateClass = "bg-primary text-white"
            }
            if (this.state.retrievedOrders[item].orderstate == "Packed") {
                orderStateClass = "bg-primary text-white"
            }
            if (this.state.retrievedOrders[item].orderstate == "Partly Sent") {
                orderStateClass = "bg-info text-white"
            }
            if (this.state.retrievedOrders[item].orderstate == "Sent") {
                orderStateClass = "bg-success text-white"
            }
            if (this.state.retrievedOrders[item].orderstate == "Returned") {
                orderStateClass = "bg-dark text-white"
            }
            if (this.state.retrievedOrders[item].amountstate == "Pending") {
                amountStateClass = "bg-danger text-white"
            }
            if (this.state.retrievedOrders[item].amountstate == "Pooling") {
                amountStateClass = "bg-primary text-white"
            }
            if (this.state.retrievedOrders[item].amountstate == "Partly Received") {
                amountStateClass = "bg-warning text-dark"
            }
            if (this.state.retrievedOrders[item].amountstate == "Received") {
                amountStateClass = "bg-success text-white"
            }
            if (this.state.retrievedOrders[item].amountstate == "COD Received") {
                amountStateClass = "bg-success text-white"
            }
            if (this.state.retrievedOrders[item].amountstate == "Refund") {
                amountStateClass = "bg-dark text-white"
            }

            var deleteTd = "";
            if (TP === "owner") {
                deleteTd = <i class="fas fa-trash-alt mr-3" title="Delete Order" style={{ cursor: 'pointer' }} id={`deleteOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={this.state.retrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#deleteDialog"></i>
            }
            var has_subordersString = ""
            if ("has_suborders" in this.state.retrievedOrders[item] && this.state.retrievedOrders[item]["has_suborders"] > 0) {
                has_subordersString = "*"
            }
            row.push(
                <tr>
                    <td>
                        {this.state.retrievedOrders[item].orderid}{has_subordersString}
                    </td>
                    <td>
                        {/* {moment(this.state.retrievedOrders[item].date).format('dddd, Do MMM YYYY')} */}
                        {/* {this.state.retrievedOrders[item].date} */}
                        {moment.unix(parseInt(this.state.retrievedOrders[item].date.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY')}
                    </td>
                    <td id={`orderTo-` + item} data-toggle="tooltip" data-placement="top" title={this.state.retrievedOrders[item].init}>
                        {this.state.retrievedOrders[item].to}
                    </td>
                    <td id={`orderFrom-` + item} data-toggle="tooltip" data-placement="top" title={this.state.retrievedOrders[item].init}>
                        {this.state.retrievedOrders[item].from}
                    </td>
                    <td id={`orderAmount-` + item}>
                        {this.state.retrievedOrders[item].amount}
                    </td>
                    <td id={`amountToPrint-` + item} hidden>
                        {this.state.retrievedOrders[item].amountToPrint}
                    </td>
                    <td id={`orderDescription-` + item}>
                        {this.state.retrievedOrders[item].description}
                    </td>
                    <td>
                        <select style={{ minWidth: '150px' }} class={`custom-select ` + orderStateClass} name="orderstate" onChange={this.handleStateChange} value={this.state.retrievedOrders[item].orderstate} id={`orderState-` + item} itemid={item} lid={this.state.retrievedOrders[item]._id["$oid"]}>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-light text-dark" value="Processing">Processing</option>
                            <option class="bg-light text-dark" value="Cancelled">Cancelled</option>
                            <option class="bg-light text-dark" value="Ready">Ready</option>
                            <option class="bg-light text-dark" value="Packed">Packed</option>
                            <option class="bg-light text-dark" value="Partly Sent">Partly Sent</option>
                            <option class="bg-light text-dark" value="Sent">Sent</option>
                            <option class="bg-light text-dark" value="Returned">Returned</option>
                        </select>
                    </td>
                    <td>
                        <select style={{ minWidth: '150px' }} class={`custom-select ` + amountStateClass} name="amountstate" onChange={this.handleStateChange} value={this.state.retrievedOrders[item].amountstate} id={`amountState-` + item} itemid={item} lid={this.state.retrievedOrders[item]._id["$oid"]}>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-light text-dark" value="Pending">Pending</option>
                            <option class="bg-light text-dark" value="Pooling">Pooling</option>
                            <option class="bg-light text-dark" value="Partly Received">Partly Received</option>
                            <option class="bg-light text-dark" value="Received">Received</option>
                            <option class="bg-light text-dark" value="COD Received">COD Received</option>
                            <option class="bg-light text-dark" value="Refund">Refund</option>
                        </select>
                    </td>
                    <td id={`orderTracking-` + item}>
                        {this.state.retrievedOrders[item].tracking}
                    </td>
                    <td class="d-flex flex-row align align-items-center">
                        <i class="fas fa-hand-pointer mr-3" title="Select Order" style={{ cursor: 'pointer' }} id={`selectOrder-` + item} itemid={item} onClick={this.handleSelect} lid={this.state.retrievedOrders[item]._id["$oid"]} ></i>

                        <i class="fas fa-edit mr-3" title="Edit Order" style={{ cursor: 'pointer' }} itemid={item} id={`editOrder-` + item} onClick={(e) => {
                            const lid = e.target.getAttribute('lid')
                            const that = this;
                            axios.get(URL + 'orders?handler=' + this.state.handler + '&_id=' + lid, {
                                headers: { Authorization: ACCT }
                            })
                                .then(function (response) {
                                    that.setState({ actionSelectedId: lid, editOrder: response.data.payload, editOrderTracking: response.data.payload.tracking, editOrderDescription: response.data.payload.description, editOrderAmount: response.data.payload.amount, editOrderTo: response.data.payload.to, editOrderFrom: response.data.payload.from, editOrderAmountToPrint: response.data.payload.amountToPrint, editOrderRatings: response.data.payload.ratings, editOrderDate: new Date(response.data.payload.date.$date) })
                                })

                        }} lid={this.state.retrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#editDialog" ></i>

                        {deleteTd}

                        <i class="fas fa-exclamation-circle mr-3" title="More Information" style={{ cursor: 'pointer' }} itemid={item} id={`closeOrder-` + item} data-toggle="modal" data-target="#moreInformationDialog" lid={this.state.retrievedOrders[item]._id["$oid"]} onClick={(e) => {
                            const itemid = e.target.getAttribute("lid");
                            this.setState({ moreInformationId: itemid }, this.fetchSingleOrder);
                        }}></i>



                        <i class="fas fa-check-circle mr-3" title="Close Order" style={{ cursor: 'pointer' }} id={`closeOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={this.state.retrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#closeDialog" ></i>

                        <i class="fas fa-arrow-down mr-3" title="Sub Order" style={{ cursor: 'pointer' }} id={`subOrder-` + item} lid={this.state.retrievedOrders[item]._id["$oid"]} data-toggle="collapse" href={`#suborderitem-` + item} role="button" aria-expanded="false" aria-controls={`suborderitem-` + item}></i>

                    </td>
                </tr>
            )

            const orderid_ = this.state.retrievedOrders[item].orderid

            row.push(
                <tr class="collapse" id={`suborderitem-` + item}>
                    <td colSpan="2">
                        <span class="btn btn-link" style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newSubOrderDialog" onClick={() => {
                            this.setState({ newSubOrderOrderID: orderid_ })
                        }
                        }>New Sub Order</span>
                    </td>
                    <td colSpan="2">
                        <span class="btn btn-link" style={{ cursor: 'pointer' }} onClick={() => {
                            this.setState({ newSubOrderOrderID: orderid_ }, this.fetchSubOrders)
                            $('#allSubOrderDialog').removeAttr('hidden')
                            $('#allSubOrderDialog').css("background", "rgba(0,0,0,0.5)")

                        }
                        }>View All Sub Order(s)</span>
                    </td>
                    <td></td><td></td><td></td><td></td><td></td><td></td>
                </tr>
            )
        }
        return (
            row
        );
    }

    postSubOrder = (e) => {
        $("#newSubOrderPostOrderButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Order")

        console.log(this.state.newSubOrderOrderID)
        console.log(this.state.newSubOrderDate)
        const that = this;
        axios.post(URL + "orders", {
            amount: this.state.newSubOrderAmount,
            date: this.state.newSubOrderDate,
            to_id: this.state.newSubOrderShipToID,
            to: "",
            amountstate: "",
            init_id: "  ",
            description: this.state.newSubOrderDescription,
            from_id: this.state.newSubOrderShipFromID,
            from: "",
            handler: "",
            orderid: this.state.newSubOrderOrderID.toString(),
            orderstate: "",
            tracking: "",
            shipFromOwnerCheckbox: this.state.newSubOrderShipFromOwnerCheckbox,
            is_suborder: true,

        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                $("#newSubOrderPostOrderButton").html("Order")
                that.setState({ newSubOrderStatusText: response["data"] })
                console.log(response["data"])
            })
    }

    newSubOrderToSearchChangeHandle = (e) => {
        $("#newSubOrderToSearchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error
                that.setState({ newSubOrderRetrievedToData: response['data']['payload']['data'] });
                $("#newSubOrderToSearchLoadingSpinner1").html("")
            })
    }
    populateNewSubOrderSelectedTo = (e) => {
        const _id = e.target.getAttribute("lid");
        console.log(_id);
        const that = this;
        axios.post(URL + "search", {
            searchTerm: "",
            searchFilters: {
                _id: _id
            },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ newSubOrderShipTo: response.data.payload.data, newSubOrderShipToID: response.data.payload.data._id.$oid, newSubOrderSelectedToSet: true });
            })
    }
    NewSubOrderToSearchResults = (e) => {
        var customerData_ = []
        for (var item in this.state.newSubOrderRetrievedToData) {
            var name = this.state.newSubOrderRetrievedToData[item]['firstname'] + " " + this.state.newSubOrderRetrievedToData[item]['lastname'];
            var lid = this.state.newSubOrderRetrievedToData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateNewSubOrderSelectedTo} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.newSubOrderRetrievedToData[item]['firstname']}&nbsp;{this.state.newSubOrderRetrievedToData[item]['lastname']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedToData[item]['phone']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedToData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            return (
                <span></span>
            );
        } else {
            return (
                <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {customerData_}
                </div>
            );
        }
    }
    NewSubOrderSelectedTo = (e) => {
        if (this.state.newSubOrderSelectedToSet) {
            const selectedCustomer = this.state.newSubOrderShipTo;
            return (<table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected To</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ newSubOrderShipTo: {}, newSubOrderShipToID: "", newSubOrderSelectedToSet: false })

                                }
                                }></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>);
        }
        return (<span></span>);


    }

    newSubOrderFromSearchChangeHandle = (e) => {
        $("#newSubOrderFromSearchLoadingSpinner2").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error
                that.setState({ newSubOrderRetrievedFromData: response['data']['payload']['data'] });
                $("#newSubOrderFromSearchLoadingSpinner2").html("")
            })
    }
    NewSubOrderFromSearchResults = (e) => {
        var customerData_ = []
        for (var item in this.state.newSubOrderRetrievedFromData) {
            var name = this.state.newSubOrderRetrievedFromData[item]['firstname'] + " " + this.state.newSubOrderRetrievedFromData[item]['lastname'];
            var lid = this.state.newSubOrderRetrievedFromData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateNewSubOrderSelectedFrom} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.newSubOrderRetrievedFromData[item]['firstname']}&nbsp;{this.state.newSubOrderRetrievedFromData[item]['lastname']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedFromData[item]['phone']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedFromData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            return (
                <span></span>
            );
        } else {
            return (
                <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {customerData_}
                </div>
            );
        }
    }
    NewSubOrderSelectedFrom = (e) => {
        if (this.state.newSubOrderSelectedFromSet) {
            const selectedCustomer = this.state.newSubOrderShipFrom;
            return (<table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected To</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ newSubOrderShipFrom: {}, newSubOrderShipFromID: "", newSubOrderSelectedFromSet: false })

                                }
                                }></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>);
        }
        return (<span></span>);


    }
    populateNewSubOrderSelectedFrom = (e) => {
        const _id = e.target.getAttribute("lid");
        console.log(_id);
        const that = this;
        axios.post(URL + "search", {
            searchTerm: "",
            searchFilters: {
                _id: _id
            },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ newSubOrderShipFrom: response.data.payload.data, newSubOrderShipFromID: response.data.payload.data._id.$oid, newSubOrderSelectedFromSet: true });
            })
    }

    NewSubOrderStatusText = (e) => {
        if (this.state.newSubOrderStatusText["_status"] === "success") {
            $('#newSubOrderPostOrderButton').attr('disabled', 'disabled');
            $('#newSubOrderBackButton').attr('disabled', 'disabled');
            $("#newSubOrderCloseButton").removeAttr('hidden');
            return (
                <span class="ml-2 text-success">
                    Order Placed
                </span>
            )
        }
        if (this.state.newSubOrderStatusText["_status"] === "fail") {
            var errors = []
            if (typeof (this.state.newSubOrderStatusText["payload"][0]) != "string") {
                for (var i in this.state.newSubOrderStatusText["payload"][0]) {
                    errors.push(<p>{i}: {this.state.newSubOrderStatusText["payload"][0][i]}</p>)
                }
            } else {
                errors.push(this.state.newSubOrderStatusText["payload"][0])
            }

            return (
                <span class="ml-2 text-danger">
                    <p>Some Error Occurred!</p>
                    {
                        errors
                    }
                </span>
            )
        }
        return ("")
    }
    resetNewSubOrderAll = (e) => {
        this.setState({ newSubOrderToFromSet: false, newSubOrderDate: new Date(), newSubOrderAmount: "0", newSubOrderShipFromOwnerCheckbox: false, newSubOrderDescription: "", newSubOrderRetrievedToData: {}, newSubOrderRetrievedFromData: {}, newSubOrderShipTo: {}, newSubOrderSelectedToSet: false, newSubOrderShipToID: "", newSubOrderSelectedFromSet: false, newSubOrderShipFromID: "", newSubOrderShipFrom: {}, newSubOrderShipFromOwnerCheckbox: false, newSubOrderStatusText: {} })
        $('#newSubOrderPostOrderButton').removeAttr('disabled');
        $('#newSubOrderBackButton').removeAttr('disabled');
        $('#newSubOrderCloseButton').attr('hidden', 'hidden')
    }

    NewSubOrderDialog = (e) => {


        // new suborder to search results

        var toSearchResults = ""
        var customerData_ = []
        for (var item in this.state.newSubOrderRetrievedToData) {
            var name = this.state.newSubOrderRetrievedToData[item]['firstname'] + " " + this.state.newSubOrderRetrievedToData[item]['lastname'];
            var lid = this.state.newSubOrderRetrievedToData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateNewSubOrderSelectedTo} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.newSubOrderRetrievedToData[item]['firstname']}&nbsp;{this.state.newSubOrderRetrievedToData[item]['lastname']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedToData[item]['phone']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedToData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            toSearchResults = <span></span>
        } else {
            toSearchResults = <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                {customerData_}
            </div>
        }

        // new suborder selected to
        var NewSubOrderSelectedTo = <span></span>
        if (this.state.newSubOrderSelectedToSet) {
            const selectedCustomer = this.state.newSubOrderShipTo;
            NewSubOrderSelectedTo = <table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected To</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ newSubOrderShipTo: {}, newSubOrderShipToID: "", newSubOrderSelectedToSet: false })

                                }
                                }></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>
        }

        // new suborder from results
        var NewSubOrderFromSearchResults = <span></span>
        var customerData_ = []
        for (var item in this.state.newSubOrderRetrievedFromData) {
            var name = this.state.newSubOrderRetrievedFromData[item]['firstname'] + " " + this.state.newSubOrderRetrievedFromData[item]['lastname'];
            var lid = this.state.newSubOrderRetrievedFromData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateNewSubOrderSelectedFrom} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.newSubOrderRetrievedFromData[item]['firstname']}&nbsp;{this.state.newSubOrderRetrievedFromData[item]['lastname']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedFromData[item]['phone']}&nbsp;|&nbsp;{this.state.newSubOrderRetrievedFromData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            NewSubOrderFromSearchResults = <span></span>
        } else {
            NewSubOrderFromSearchResults = <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                {customerData_}
            </div>
        }


        // new suborder
        var NewSubOrderSelectedFrom = <span></span>
        if (this.state.newSubOrderSelectedFromSet) {
            const selectedCustomer = this.state.newSubOrderShipFrom;
            NewSubOrderSelectedFrom = <table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected To</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ newSubOrderShipFrom: {}, newSubOrderShipFromID: "", newSubOrderSelectedFromSet: false })

                                }
                                }></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>
        }

        // new suborder text
        var NewSubOrderStatusText = ""
        if (this.state.newSubOrderStatusText["_status"] === "success") {
            $('#newSubOrderPostOrderButton').attr('disabled', 'disabled');
            $('#newSubOrderBackButton').attr('disabled', 'disabled');
            $("#newSubOrderCloseButton").removeAttr('hidden');
            NewSubOrderStatusText = <span class="ml-2 text-success">
                Order Placed
            </span>

        }
        if (this.state.newSubOrderStatusText["_status"] === "fail") {
            var errors = []
            if (typeof (this.state.newSubOrderStatusText["payload"][0]) != "string") {
                for (var i in this.state.newSubOrderStatusText["payload"][0]) {
                    errors.push(<p>{i}: {this.state.newSubOrderStatusText["payload"][0][i]}</p>)
                }
            } else {
                errors.push(this.state.newSubOrderStatusText["payload"][0])
            }

            NewSubOrderStatusText =
                <span class="ml-2 text-danger">
                    <p>Some Error Occurred!</p>
                    {
                        errors
                    }
                </span>
        }

        var body = ""
        var footer = ""
        if (!this.state.newSubOrderToFromSet) {
            body = <div class="modal-body p-4">

                <div class="d-flex justify-content-center">
                    <div class="container">
                        <div class="row">
                            <table class="m-2 w-100">
                                <tr>
                                    <td>
                                        Amount
    </td>
                                    <td>
                                        Date
    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input autocomplete="off" type="number" class="form-control" value={this.state.newSubOrderAmount} name="newSubOrderAmount" id="newSubOrderAmount" onChange={(e) => {
                                            this.setState({ newSubOrderAmount: e.target.value })
                                        }} />
                                    </td>
                                    <td>
                                        <DatePicker
                                            selected={this.state.newSubOrderDate}
                                            onChange={(date) => {
                                                this.setState({ newSubOrderDate: date });
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control w-100"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Description
</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <input autocomplete="off" type="text" class="form-control" value={this.state.newSubOrderDescription} name="newSubOrderDescription" id="newSubOrderDescription" onChange={(e) => {
                                            this.setState({ newSubOrderDescription: e.target.value });
                                        }} />
                                    </td>
                                </tr>
                            </table>
                            <form class="w-100">
                                <div class="form-group row border m-2">
                                    <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                        <span>
                                            <span>Ship To</span>
                                        </span>
                                        <span id="newSubOrderToSearchLoadingSpinner1">

                                        </span>
                                    </span>
                                    <div class="input-group p-2">
                                        <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="newSubOrderCustomerSearch" id="newSubOrderCustomerSearch" onChange={this.newSubOrderToSearchChangeHandle} />
                                    </div>
                                    {/* <this.NewSubOrderToSearchResults /> */}
                                    {toSearchResults}
                                    <div class="w-100">
                                        {/* <this.NewSubOrderSelectedTo /> */}
                                        {NewSubOrderSelectedTo}
                                    </div>
                                </div>
                                <div class="form-group row border m-2">
                                    <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                        <span>
                                            <span>Ship From</span>
                                        </span>
                                        <span id="newSubOrderFromSearchLoadingSpinner2">

                                        </span>
                                    </span>
                                    <div class="input-group p-2">
                                        <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="newSubOrderCustomerSearch" id="newSubOrderCustomerSearchTextbox" onChange={this.newSubOrderFromSearchChangeHandle} />
                                    </div>
                                    {/* <this.NewSubOrderFromSearchResults /> */}
                                    {NewSubOrderFromSearchResults}
                                    <div class="w-100">
                                        {/* <this.NewSubOrderSelectedFrom /> */}
                                        {NewSubOrderSelectedFrom}
                                    </div>
                                    <div class="form-group form-check m-2">
                                        <input type="checkbox" class="form-check-input" defaultChecked={this.state.newSubOrderShipFromOwnerCheckbox} id="newSubOrderShipFromOwnerCheckbox" onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ newSubOrderShipFromOwnerCheckbox: true })
                                            } else {
                                                this.setState({ newSubOrderShipFromOwnerCheckbox: false })
                                            }
                                        }} />
                                        <label class="form-check-label" for="newSubOrderShipFromOwnerCheckbox">Ship From Owner</label>
                                        {/* <button type="button" class="btn btn-warning ml-2">Confirm Transaction</button> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            footer = <div class="modal-footer">
                <button type="button" id="newSubOrderFirstCloseButton" class="btn btn-danger" data-dismiss="modal" aria-label="Close" onClick={this.resetNewSubOrderAll}>Close</button>
                <button type="button" id="newSubOrderNextButton" class="btn btn-primary" onClick={() => {
                    this.setState({ newSubOrderToFromSet: true })
                }
                }>Next</button>
            </div>
        }
        if (this.state.newSubOrderToFromSet) {

            var to = []
            var from = []
            var description = []
            var shipFromOwnerCheckbox = []
            if (this.state.newSubOrderShipToID.length > 0) {
                to.push(
                    <tr>
                        <td>
                            To:
                            </td>
                        <td>
                            {this.state.newSubOrderShipTo["firstname"]}&nbsp;{this.state.newSubOrderShipTo["lastname"]}&nbsp;|&nbsp;{this.state.newSubOrderShipTo["phone"]}&nbsp;|&nbsp;{this.state.newSubOrderShipTo["shippingAddressCity"]}
                        </td>
                    </tr>
                )
            }
            if (this.state.newSubOrderShipFromID.length > 0) {
                var newSubOrderShipFromOwnerCheckbox = ""
                if (this.state.newSubOrderShipFromOwnerCheckbox) {
                    newSubOrderShipFromOwnerCheckbox = <p class="text-danger"><br />Ship From Owner is Set</p>
                }
                from.push(
                    <tr>
                        <td>
                            From:
                            </td>
                        <td>
                            {this.state.newSubOrderShipFrom["firstname"]}&nbsp;{this.state.newSubOrderShipFrom["lastname"]}&nbsp;|&nbsp;{this.state.newSubOrderShipFrom["phone"]}&nbsp;|&nbsp;{this.state.newSubOrderShipFrom["shippingAddressCity"]}{newSubOrderShipFromOwnerCheckbox}
                        </td>
                    </tr>
                );
            }
            if (this.state.newSubOrderShipFromOwnerCheckbox) {
                shipFromOwnerCheckbox.push(
                    <tr>
                        <td>
                            Ship From Owner:
                            </td>
                        <td>
                            True
                        </td>
                    </tr>
                );
            }
            if (this.state.newSubOrderDescription.length > 0) {
                description.push(
                    <tr>
                        <td>
                            Description:
                        </td>
                        <td>
                            {this.state.newSubOrderDescription}
                        </td>
                    </tr>
                );
            }
            body = <div class="modal-body p-4">
                <div class="d-flex justify-content-center">
                    <div class="table-responsive">
                        <table class="table">
                            {to}
                            {from}
                            {shipFromOwnerCheckbox}
                            <tr>
                                <td>
                                    Amount:
                            </td>
                                <td>
                                    {this.state.newSubOrderAmount}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date:
                            </td>
                                <td>
                                    {moment(this.state.newSubOrderDate).format('dddd, Do MMM YYYY')}

                                </td>
                            </tr>
                            {description}
                        </table>
                    </div>
                </div>
                {/* <this.NewSubOrderStatusText /> */}
                {NewSubOrderStatusText}
            </div>

            footer = <div class="modal-footer">
                <button type="button" id="newSubOrderBackButton" class="btn btn-secondary" onClick={(e) => {
                    this.setState({ newSubOrderToFromSet: false })
                }}>Back</button>
                <button type="button" id="newSubOrderCloseButton" class="btn btn-danger " data-dismiss="modal" aria-label="Close" onClick={this.resetNewSubOrderAll} hidden>Close</button>
                <button type="button" id="newSubOrderPostOrderButton" class="btn btn-primary " onClick={this.postSubOrder}>Order</button>
            </div>
        }
        return (
            <div class="modal fade" id="newSubOrderDialog" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered ">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">New Sub Order for Order ID: {this.state.newSubOrderOrderID} </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.resetNewSubOrderAll}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {body}
                        {footer}

                    </div>
                </div>
            </div>
        );
    }

    handleEditChange = (e) => {
        const key = e.target.name.toString();
        const val = e.target.value;
        var tmp = this.state.editOrder;
        tmp[key] = val;
        this.setState({ editOrder: tmp });
        console.log(this.state)
    }

    populateSelectedCustomer = (e) => {
        const _id = e.target.getAttribute("lid");
        console.log(_id);
        const that = this;
        axios.post(URL + "search", {
            searchTerm: "",
            searchFilters: {
                _id: _id
            },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "selectedCustomer": response.data.payload.data, "selectedCustomerID": response.data.payload.data._id.$oid, editOrderToId: response.data.payload.data._id.$oid, "selectedCustomerSet": true }

                );
            })
    }

    customerSearchChangeHandle = (e) => {
        $("#searchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error

                that.setState({ retrievedCustomerData: response['data']['payload']['data'] });
                $("#searchLoadingSpinner1").html("")
            })
    }

    CustomerSearchResults = (e) => {
        var customerData_ = []
        for (var item in this.state.retrievedCustomerData) {
            var name = this.state.retrievedCustomerData[item]['firstname'] + " " + this.state.retrievedCustomerData[item]['lastname'];
            var lid = this.state.retrievedCustomerData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedCustomer} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedCustomerData[item]['firstname']}&nbsp;{this.state.retrievedCustomerData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedCustomerData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedCustomerData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            return (
                <span></span>
            );
        } else {
            return (
                <div class="list-group w-100 mt-2">
                    {customerData_}
                </div>
            );
        }
    }

    SelectedCustomer = (e) => {
        if (this.state.selectedCustomerSet) {
            const selectedCustomer = this.state.selectedCustomer;
            return (<table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected Customer</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={this.removeSelectedCustomer}></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>);
        }
        return (<span></span>);


    }

    fromSearchChangeHandle = (e) => {
        $("#fromSearchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error

                that.setState({ retrievedFromData: response['data']['payload']['data'] });
                $("#fromSearchLoadingSpinner1").html("")
            })
    }
    populateSelectedFrom = (e) => {
        const _id = e.target.getAttribute("lid");
        console.log(_id);
        const that = this;
        axios.post(URL + "search", {
            searchTerm: "",
            searchFilters: {
                _id: _id
            },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ selectedFrom: response.data.payload.data, selectedFromId: response.data.payload.data._id.$oid, editOrderFromId: response.data.payload.data._id.$oid, selectedFromSet: true }

                );
            })
    }

    FromSearchResults = (e) => {
        var customerData_ = []
        for (var item in this.state.retrievedFromData) {
            var name = this.state.retrievedFromData[item]['firstname'] + " " + this.state.retrievedFromData[item]['lastname'];
            var lid = this.state.retrievedFromData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedFrom} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedFromData[item]['firstname']}&nbsp;{this.state.retrievedFromData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedFromData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedFromData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            return (
                <span></span>
            );
        } else {
            return (
                <div class="list-group w-100 mt-2">
                    {customerData_}
                </div>
            );
        }
    }

    removeSelectedCustomer = (e) => {
        this.setState({ "selctedCustomer": {}, "selectedCustomerID": "", selectedCustomerSet: false })
    }

    removeSelectedFrom = (e) => {
        this.setState({ selectedFrom: {}, selectedFromId: "", selectedFromSet: false })
    }

    SelectedFrom = (e) => {
        if (this.state.selectedFromSet) {
            const selectedFrom = this.state.selectedFrom;
            return (<table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected Customer</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={this.removeSelectedFrom}></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedFrom['firstname']}</td>
                        <td>{selectedFrom['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedFrom['brand']}</td>
                        <td>{selectedFrom['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedFrom['email']}</td>
                        <td>{selectedFrom['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedFrom['shippingAddressAddress1']}</td>
                        <td>{selectedFrom['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedFrom['shippingAddressCity']}</td>
                        <td>{selectedFrom['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedFrom['shippingAddressPincode']}</td>
                        <td>{selectedFrom['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>);
        }
        return (<span></span>);


    }

    handleShipFromOwnerCheckbox = (e) => {
        if (e.target.checked) {
            this.setState({ shipFromOwnerCheckbox: true })
        } else {
            this.setState({ shipFromOwnerCheckbox: false })
        }
    }

    handleEditRatingChange = (value) => {
        // console.log(value)
        //  this.setState({ aa: 1 })
    }
    Dialog(props) {
        const name = props.name;
        const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
        const handle = props.handle;
        var tracking_rows = []

        var init_rating = 0;
        if (this.state.editOrderRatings != undefined && "init" in this.state.editOrderRatings) {
            init_rating = this.state.editOrderRatings["init"]
        }

        tracking_rows.push(
            <tr>
                <td>
                    {this.state.editOrder["init"]}&nbsp;(Initiator)
                </td>
                <td>
                    <Rating
                        //id="editEntryRating"
                        // name={item}
                        fractions={2}
                        initialRating={init_rating}
                        emptySymbol="fa fa-star-o fa-lg"
                        fullSymbol="fa fa-star fa-lg"
                        onClick={
                            (value) => {
                                // this.handleEditRatingChange(value)
                                var tmp_ = this.state.editOrderRatings
                                tmp_["init"] = value
                                // console.log(tmp_)
                                this.setState({ editOrderRatings: tmp_ }, () => {
                                    // console.log(this.state.editOrderRatings)
                                    // console.log(name)
                                })

                            }
                        }
                    />

                </td>
            </tr>
        );

        if (tracking_rows.length > 0) {
            tracking_rows.push(
                <tr>
                    <td>
                        <hr />
                    </td>
                    <td>
                        <hr />
                    </td>
                </tr>
            );
        }

        if (name === "edit") {
            var date_picker = []
            if (this.state.editOrderDate != "") {
                date_picker.push(
                    <DatePicker
                        selected={this.state.editOrderDate}
                        onChange={(date) => {
                            this.setState({ editOrderDate: date });
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control border-1 small  form-control-lg"
                    />
                );
            }
            return (
                <div class="modal fade" id={name + `Dialog`} tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Edit Order</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="p-2">
                                    <div class="form-group row" key="0">
                                        <table class="">
                                            {tracking_rows}
                                            <tr>
                                                <td>
                                                    Date
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {date_picker}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Amount
                                            </td>
                                                <td>
                                                    Amount To Print
                                            </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {/* <div class="col-sm-10" key="0"> */}
                                                    <input autoComplete="new-password" autocomplete="off" type="number" name="amount" id="editOrderAmount" className="form-control border-1 small  form-control-lg" onChange={this.handleEditChange} value={this.state.editOrder.amount} key="0" />
                                                    {/* </div> */}

                                                </td>
                                                <td>
                                                    <input autoComplete="new-password" autocomplete="off" type="number" name="amountToPrint" id="editOrderAmountToPrint" className="form-control border-1 small  form-control-lg" onChange={this.handleEditChange} value={this.state.editOrder.amountToPrint} key="0" />
                                                </td>
                                            </tr>
                                        </table>


                                    </div>
                                    <div class="form-group row" key="0">
                                        <label for="editOrderDescription" class="">Description</label>
                                        {/* <div class="col-sm-10" key="0"> */}
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="description" id="editOrderDescription" className="form-control border-1 small  form-control-lg" onChange={this.handleEditChange} value={this.state.editOrder.description} key="0" />
                                        {/* </div> */}
                                    </div>
                                    <div class="form-group row" key="0">
                                        <label for="editOrderTracking" class="">Tracking</label>
                                        {/* <div class="col-sm-10" key="0"> */}
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="tracking" id="editOrderTracking" className="form-control border-1 small  form-control-lg" onChange={this.handleEditChange} value={this.state.editOrder.tracking} key="0" />
                                        {/* </div> */}
                                    </div>
                                    <div class="form-group row">
                                        <span class="d-flex justify-content-between w-100">
                                            <span>
                                                <label for="editOrderTo" class="">Ship To</label>
                                            </span>
                                            <span id="searchLoadingSpinner1">

                                            </span>
                                        </span>
                                        <div class="input-group p-2">
                                            <b>{this.state.editOrder.to}</b>
                                        </div>
                                        <div class="input-group">
                                            <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="editOrderTo" onChange={this.customerSearchChangeHandle} />
                                        </div>
                                        <this.CustomerSearchResults />
                                        <div class="w-100">
                                            <this.SelectedCustomer />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <span class="d-flex justify-content-between w-100">
                                            <span>
                                                <label for="editOrderFrom" class="">Ship From</label>
                                            </span>
                                            <span id="fromSearchLoadingSpinner1">

                                            </span>
                                        </span>
                                        <div class="input-group p-2">
                                            <b>{this.state.editOrder.from}</b>
                                        </div>
                                        <div class="input-group">
                                            <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="editOrderFrom" onChange={this.fromSearchChangeHandle} />
                                        </div>
                                        <this.FromSearchResults />
                                        <div class="w-100">
                                            <this.SelectedFrom />
                                        </div>
                                        <div class="form-group form-check m-2">
                                            <input type="checkbox" class="form-check-input" defaultChecked={this.state.editOrder.shipFromOwnerCheckbox} id="shipFromOwnerCheckbox" onChange={this.handleShipFromOwnerCheckbox} />
                                            <label class="form-check-label" for="shipFromOwnerCheckbox">Ship From Owner</label>
                                            {/* <button type="button" class="btn btn-warning ml-2">Confirm Transaction</button> */}
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={handle}>Done</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={this.resetState}>Exit</button>
                            </div>
                        </div>
                    </div>
                </div >
            );
        }

        return (
            <div class="modal fade" id={name + `Dialog`} tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Confirm Action</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Do you want to {name} the order?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={handle}>{nameCapitalized}</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Exit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    MoreInformationDialog(props) {
        var orderitems = []
        const retrievedMoreInformationOrder = this.state.retrievedMoreInformationOrder;

        if ("_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td class="border-0">
                        Order UID
                    </td>
                    <td class="border-0">
                        {retrievedMoreInformationOrder["_id"]["$oid"]}
                    </td>
                </tr>
            )
        }

        if ("init_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Initiator ID
                    </td>
                    <td>
                        {retrievedMoreInformationOrder["init_id"]}
                    </td>
                </tr>
            )
            orderitems.push(
                <tr>
                    <td>
                        Initiator
                </td>
                    <td>
                        {retrievedMoreInformationOrder["init"]}
                    </td>
                </tr>
            )
        }

        if ("to_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        To ID
                    </td>
                    <td>
                        {retrievedMoreInformationOrder["to_id"]}
                    </td>
                </tr>
            )
            orderitems.push(
                <tr>
                    <td>
                        To
                </td>
                    <td>
                        {retrievedMoreInformationOrder["to"]}
                    </td>
                </tr>
            )
        }

        if ("from_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        From ID
                    </td>
                    <td>
                        {retrievedMoreInformationOrder["from_id"]}
                    </td>
                </tr>
            )
            orderitems.push(
                <tr>
                    <td>
                        From
                </td>
                    <td>
                        {retrievedMoreInformationOrder["from"]}
                    </td>
                </tr>
            )
        }

        if ("date" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Order Date
                    </td>
                    <td>
                        {moment.unix(parseInt(retrievedMoreInformationOrder.date.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY')}
                    </td>
                </tr>
            )

        }

        if ("_created" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Order Created
                    </td>
                    <td>
                        {moment.unix(parseInt(retrievedMoreInformationOrder._created.$date) / 1000).utcOffset("+0000").format('dddd, Do MMM YYYY h:mm:ss a')}
                    </td>
                </tr>
            )
        }
        if ("_updated" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Order Updated
                    </td>
                    <td>
                        {moment.unix(parseInt(retrievedMoreInformationOrder._updated.$date) / 1000).utcOffset("+0000").format('dddd, Do MMM YYYY h:mm:ss a')}
                    </td>
                </tr>
            )

        }



        return (
            <div class="modal fade" id="moreInformationDialog" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">More Information</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="table-responsive">
                                <table class="table">
                                    {orderitems}
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                            {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    handleCheckboxChange = (e) => {

        var name = e.target.getAttribute("name");
        if (name == "optionOpen") {
            $("#optionOpen").addClass("btn-primary")
            $("#optionClosed").removeClass("btn-primary")
            $("#optionAll").removeClass("btn-primary")
            this.setState({ "isopen": "true", ordersPageNumber: 1 }, this.fetchOrders)
        }
        if (name == "optionClosed") {
            $("#optionOpen").removeClass("btn-primary")
            $("#optionClosed").addClass("btn-primary")
            $("#optionAll").removeClass("btn-primary")
            this.setState({ "isopen": "false", ordersPageNumber: 1 }, this.fetchOrders)
        }
        if (name == "optionAll") {
            $("#optionOpen").removeClass("btn-primary")
            $("#optionClosed").removeClass("btn-primary")
            $("#optionAll").addClass("btn-primary")
            this.setState({ "isopen": "all", ordersPageNumber: 1 }, this.fetchOrders)
        }
    }

    handleHandlerChange = (e) => {
        this.setState({ "handler": e.target.value }, this.fetchOrders)
    }

    fetchSubOrders = (e) => {
        const that = this;
        axios.get(URL + 'orders?handler=' + this.state.handler + '&isopen=' + this.state.isopen + '&ordersPageNumber=-1&is_suborder=1&orderid=' + this.state.newSubOrderOrderID, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "retrievedSubOrders": response['data']['payload']["orders"], "totalSubOrdersCount": response['data']['payload']["totalCount"] });
                console.log(that.state.retrievedSubOrders)

            })
    }
    handleSubOrderSelect(e) {
        const itemid = e.target.getAttribute("itemid");
        selectedCustomersStore.dispatch({ type: this.state.retrievedSubOrders[itemid] })
    }
    AllSubOrdersDialog = (e) => {
        var row = [];
        for (var item in this.state.retrievedSubOrders) {
            var amountStateClass = "";
            var orderStateClass = "";
            if (this.state.retrievedSubOrders[item].orderstate == "Processing") {
                orderStateClass = "bg-warning text-dark"
            }
            if (this.state.retrievedSubOrders[item].orderstate == "Cancelled") {
                orderStateClass = "bg-danger text-white"
            }
            if (this.state.retrievedSubOrders[item].orderstate == "Ready") {
                orderStateClass = "bg-primary text-white"
            }
            if (this.state.retrievedSubOrders[item].orderstate == "Packed") {
                orderStateClass = "bg-primary text-white"
            }
            if (this.state.retrievedSubOrders[item].orderstate == "Partly Sent") {
                orderStateClass = "bg-info text-white"
            }
            if (this.state.retrievedSubOrders[item].orderstate == "Sent") {
                orderStateClass = "bg-success text-white"
            }
            if (this.state.retrievedSubOrders[item].orderstate == "Returned") {
                orderStateClass = "bg-dark text-white"
            }
            if (this.state.retrievedSubOrders[item].amountstate == "Pending") {
                amountStateClass = "bg-danger text-white"
            }
            if (this.state.retrievedSubOrders[item].amountstate == "Pooling") {
                amountStateClass = "bg-primary text-white"
            }
            if (this.state.retrievedSubOrders[item].amountstate == "Partly Received") {
                amountStateClass = "bg-warning text-dark"
            }
            if (this.state.retrievedSubOrders[item].amountstate == "Received") {
                amountStateClass = "bg-success text-white"
            }
            if (this.state.retrievedSubOrders[item].amountstate == "COD Received") {
                amountStateClass = "bg-success text-white"
            }
            if (this.state.retrievedSubOrders[item].amountstate == "Refund") {
                amountStateClass = "bg-dark text-white"
            }

            var isSuborderCloseBool = false
            var isSuborderCloseColor = "white"
            if (!this.state.retrievedSubOrders[item].isopen) {
                isSuborderCloseBool = true
                isSuborderCloseColor = "gainsboro"
            }
            var deleteTd = "";
            if (TP === "owner") {
                deleteTd = <i hidden={isSuborderCloseBool} class="fas fa-trash-alt mr-3" title="Delete Order" style={{ cursor: 'pointer' }} id={`deleteOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={this.state.retrievedSubOrders[item]._id["$oid"]} data-toggle="modal" data-target="#deleteDialog"></i>
            }
            row.push(
                <tr style={{ backgroundColor: isSuborderCloseColor }}>
                    <td>
                        {this.state.retrievedSubOrders[item].orderid}
                    </td>
                    <td>
                        {/* {moment(this.state.retrievedOrders[item].date).format('dddd, Do MMM YYYY')} */}
                        {/* {this.state.retrievedOrders[item].date} */}
                        {moment.unix(parseInt(this.state.retrievedSubOrders[item].date.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY')}
                    </td>
                    <td id={`orderTo-` + item} data-toggle="tooltip" data-placement="top" title={this.state.retrievedSubOrders[item].to_id}>
                        {this.state.retrievedSubOrders[item].to}
                    </td>
                    <td id={`orderFrom-` + item} data-toggle="tooltip" data-placement="top" title={this.state.retrievedSubOrders[item].from_id}>
                        {this.state.retrievedSubOrders[item].from}
                    </td>
                    <td id={`orderAmount-` + item}>
                        {this.state.retrievedSubOrders[item].amount}
                    </td>
                    <td id={`amountToPrint-` + item} hidden>
                        {this.state.retrievedSubOrders[item].amountToPrint}
                    </td>
                    <td id={`orderDescription-` + item}>
                        {this.state.retrievedSubOrders[item].description}
                    </td>
                    <td>
                        <select disabled={isSuborderCloseBool} style={{ minWidth: '150px' }} class={`custom-select ` + orderStateClass} name="orderstate" onChange={this.handleStateChange} value={this.state.retrievedSubOrders[item].orderstate} id={`orderState-` + item} itemid={item} lid={this.state.retrievedSubOrders[item]._id["$oid"]}>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-light text-dark" value="Processing">Processing</option>
                            <option class="bg-light text-dark" value="Cancelled">Cancelled</option>
                            <option class="bg-light text-dark" value="Ready">Ready</option>
                            <option class="bg-light text-dark" value="Packed">Packed</option>
                            <option class="bg-light text-dark" value="Partly Sent">Partly Sent</option>
                            <option class="bg-light text-dark" value="Sent">Sent</option>
                            <option class="bg-light text-dark" value="Returned">Returned</option>
                        </select>
                    </td>
                    <td>
                        <select disabled={isSuborderCloseBool} style={{ minWidth: '150px' }} class={`custom-select ` + amountStateClass} name="amountstate" onChange={this.handleStateChange} value={this.state.retrievedSubOrders[item].amountstate} id={`amountState-` + item} itemid={item} lid={this.state.retrievedSubOrders[item]._id["$oid"]}>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-light text-dark" value="Pending">Pending</option>
                            <option class="bg-light text-dark" value="Pooling">Pooling</option>
                            <option class="bg-light text-dark" value="Partly Received">Partly Received</option>
                            <option class="bg-light text-dark" value="Received">Received</option>
                            <option class="bg-light text-dark" value="COD Received">COD Received</option>
                            <option class="bg-light text-dark" value="Refund">Refund</option>
                        </select>
                    </td>
                    <td id={`orderTracking-` + item}>
                        {this.state.retrievedSubOrders[item].tracking}
                    </td>
                    <td class="d-flex flex-row align align-items-center">
                        <i class="fas fa-hand-pointer mr-3" title="Select Order" style={{ cursor: 'pointer' }} id={`selectOrder-` + item} itemid={item} onClick={this.handleSubOrderSelect} lid={this.state.retrievedSubOrders[item]._id["$oid"]} ></i>

                        <i hidden={isSuborderCloseBool} class="fas fa-edit mr-3" title="Edit Order" style={{ cursor: 'pointer' }} itemid={item} id={`editOrder-` + item} onClick={(e) => {
                            const lid = e.target.getAttribute('lid')
                            const that = this;
                            axios.get(URL + 'orders?handler=' + this.state.handler + '&_id=' + lid, {
                                headers: { Authorization: ACCT }
                            })
                                .then(function (response) {
                                    that.setState({ actionSelectedId: lid, editOrder: response.data.payload, editOrderTracking: response.data.payload.tracking, editOrderDescription: response.data.payload.description, editOrderAmount: response.data.payload.amount, editOrderTo: response.data.payload.to, editOrderFrom: response.data.payload.from, editOrderAmountToPrint: response.data.payload.amountToPrint, editOrderRatings: response.data.payload.ratings, editOrderDate: new Date(response.data.payload.date.$date) })
                                })

                        }} lid={this.state.retrievedSubOrders[item]._id["$oid"]} data-toggle="modal" data-target="#editDialog" ></i>

                        {deleteTd}

                        <i class="fas fa-exclamation-circle mr-3" title="More Information" style={{ cursor: 'pointer' }} itemid={item} id={`closeOrder-` + item} data-toggle="modal" data-target="#moreInformationDialog" lid={this.state.retrievedSubOrders[item]._id["$oid"]} onClick={(e) => {
                            const itemid = e.target.getAttribute("lid");
                            this.setState({ moreInformationId: itemid }, this.fetchSingleOrder);
                        }}></i>



                        <i hidden={isSuborderCloseBool} class="fas fa-check-circle mr-3" title="Close Order" style={{ cursor: 'pointer' }} id={`closeOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={this.state.retrievedSubOrders[item]._id["$oid"]} data-toggle="modal" data-target="#closeDialog" ></i>

                    </td>
                </tr>
            )

        }
        return (
            // <div class="modal fade" style={{width: "100%"}} id="allSubOrderDialog" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            // <div class="modal-dialog modal-lg modal-dialog-centered" style={{width: "100%"}}>
            <div id="allSubOrderDialog" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "allSubOrderDialog") {
                        $('#allSubOrderDialog').attr('hidden', 'hidden')
                    }
                }
            }>

                <div style={{ transition: "transform .3s ease-out", margin: "0 auto", zIndex: "850" }} class="col-sm-12 col-xs-12 col-sm-10 col-md-8 col-lg-10 mt-5">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">All Sub Order(s) for Order ID: {this.state.newSubOrderOrderID} </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={
                                (e) => {
                                    $('#allSubOrderDialog').attr('hidden', 'hidden')
                                }
                            }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-hover  w-100 m-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Order Id</th>
                                        <th scope="col">Order Date</th>
                                        <th scope="col">To</th>
                                        <th scope="col">From</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Order State</th>
                                        <th scope="col">Amount State</th>
                                        <th scope="col">Tracking</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {row}
                                </tbody>
                            </table>
                        </div>

                        <div class="modal-footer">
                            <button type="button" id="allSubOrderCloseButton" class="btn btn-danger" onClick={
                                (e) => {
                                    $('#allSubOrderDialog').attr('hidden', 'hidden')
                                }
                            }>Close</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div class="">

                <div class="col-xl-10 col-lg-10 col-sm-12  ">
                    <form class="form-inline m-2">
                        <span class="d-inline-flex col-sm-12 col-xl-4 col-lg-4">
                            <select class="form-control w-100" name="handler" id="inputGroupSelect01" onChange={this.handleHandlerChange}>
                                <option selected value="">Select Handler</option>
                                <this.Handlers />
                            </select>
                        </span>
                        <span class="d-inline-flex col-sm-12 col-xl-3 col-lg-4">
                            <div class="input-group-append" >
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label class="btn btn-primary border active" id="optionOpen" name="optionOpen" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange} >
                                        <input type="radio" name="optionOpen" autocomplete="off" checked />Open
                                            </label>
                                    <label class="btn border" id="optionClosed" name="optionClosed" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange}>
                                        <input type="radio" name="optionClosed" autocomplete="off" />Closed
                                            </label>
                                    <label class="btn border" id="optionAll" name="optionAll" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange}>
                                        <input type="radio" name="optionAll" autocomplete="off" />All
                                            </label>
                                </div>
                            </div>
                        </span>
                        <span class="d-inline-flex col-sm-12 col-xl-3 col-lg-4">
                            <nav aria-label="Page navigation example">
                                <this.Pagination />
                            </nav>
                        </span>
                        <span class="d-inline-flex col-sm-12 col-xl-2 col-lg-4">
                            <label class="btn p-0 m-0" id="optionOpen" name="optionOpen" onClick={this.handleCheckboxChange} >
                                <this.TotalOrderString />
                            </label>
                        </span>
                    </form>
                </div>






                <div class="table-responsive">
                    <table class="table table-hover  w-100 m-0">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Order Id</th>
                                <th scope="col">Order Date</th>
                                <th scope="col">To</th>
                                <th scope="col">From</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Description</th>
                                <th scope="col">Order State</th>
                                <th scope="col">Amount State</th>
                                <th scope="col">Tracking</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <this.AllOrderRows />
                        </tbody>
                    </table>
                </div>
                <this.Dialog name="delete" handle={this.handleDelete} />
                <this.Dialog name="close" handle={this.handleClose} />
                <this.Dialog name="edit" handle={this.handleEdit} />
                <this.MoreInformationDialog />
                <this.NewSubOrderDialog />
                <this.AllSubOrdersDialog />
            </div>
        );
    }
}

class SearchOrders extends React.Component {
    constructor(props) {
        super(props)
        this.state = { date: new Date(), type_: "all", searchTerm: "", fromDate: "", toDate: "", orderState: "", amountState: "", from: "", to: "", init: "", orderId: 0, retrievedOrders: {}, searchOrdersPageNumber: 0, retrievedMoreInformationOrder: {} }

        this.SearchBox = this.SearchBox.bind(this);
        this.SearchResults = this.SearchResults.bind(this);
        this.searchOrders = this.searchOrders.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.fetchSingleOrder = this.fetchSingleOrder.bind(this);
        this.MoreInformationDialog = this.MoreInformationDialog.bind(this);
        this.clearSearchResults = this.clearSearchResults.bind(this);
        this.Pagination = this.Pagination.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

    }

    SearchBox() {
        return (
            <div>
                <table class="w-100 m-2">

                    <tr>
                        <td class="">
                            <label class="" for="">Order ID</label>
                            <input autoComplete="new-password" autocomplete="off" type="number" name="orderId" id="editOrderOrderId" className="form-control border-1 small  form-control" value={this.state.orderId} placeholder="" key="0" onChange={this.handleChange} />
                        </td>
                        <td class="">
                            <label class="" for="orderSearchOrderType">Order Type</label><br />
                            <div id="orderSearchOrderType" class="btn-group btn-group-toggle" data-toggle="buttons">
                                <label class="btn btn-primary border active" id="orderSearchOptionAll" name="orderSearchOptionAll" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange}>
                                    <input type="radio" name="orderSearchOptionAll" autocomplete="off" checked />All
                            </label>
                                <label class="btn border" id="orderSearchOptionOpen" name="orderSearchOptionOpen" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange} >
                                    <input type="radio" name="orderSearchOptionOpen" autocomplete="off" />Open
                            </label>
                                <label class="btn border" id="orderSearchOptionClosed" name="orderSearchOptionClosed" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange}>
                                    <input type="radio" name="orderSearchOptionClosed" autocomplete="off" />Closed
                            </label>
                                <label class="btn border" id="orderSearchOptionDeleted" name="orderSearchOptionDeleted" style={{ cursor: 'pointer' }} onClick={this.handleCheckboxChange}>
                                    <input type="radio" name="orderSearchOptionDeleted" autocomplete="off" />Deleted
                            </label>
                            </div>
                        </td>
                    </tr>
                    {/* </table> */}
                    {/* <div class="input-group-append">
                        <button type="button" class="btn btn-primary" id="searchButton" onClick="">
                            Search
                    </button>
                    </div> */}
                    {/* <table class="w-100 m-2"> */}
                    {/* <tr>
                        <td>
                            From
                            </td>
                        <td>
                            To
                            </td>
                    </tr> */}
                    <tr>
                        <td>
                            <label class="" for="">From Date</label>
                            <br />
                            <DatePicker
                                selected={this.state.fromDate}
                                onChange={(date) => {
                                    this.setState({ "fromDate": date });
                                }
                                }
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </td>
                        <td>
                            <label class="" for="">To Date</label>
                            <br />
                            <DatePicker
                                selected={this.state.toDate}
                                onChange={(date) => {
                                    this.setState({ "toDate": date });
                                }
                                }
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </td>
                    </tr>

                    {/* </table> */}
                    {/* <table class="w-100 m-2"> */}
                    {/* <tr>
                        <td>
                            Order State
                            </td>
                        <td>
                            Amount State
                            </td>
                    </tr> */}
                    <tr>
                        <td>
                            <label class="" for="">Order State</label>
                            <select style={{ minWidth: '150px' }} class={`custom-select`} name="orderState" onChange={this.handleChange}>
                                <option selected value="">Select State</option>
                                <option class="bg-light text-dark" value="Processing">Processing</option>
                                <option class="bg-light text-dark" value="Cancelled">Cancelled</option>
                                <option class="bg-light text-dark" value="Ready">Ready</option>
                                <option class="bg-light text-dark" value="Packed">Packed</option>
                                <option class="bg-light text-dark" value="Partly Sent">Partly Sent</option>
                                <option class="bg-light text-dark" value="Sent">Sent</option>
                                <option class="bg-light text-dark" value="Returned">Returned</option>
                            </select>
                        </td>
                        <td>
                            <label class="" for="">Amount State</label>
                            <select style={{ minWidth: '150px' }} class={`custom-select`} name="amountState" onChange={this.handleChange}>
                                <option selected value="">Select State</option>
                                <option class="bg-light text-dark" value="Pending">Pending</option>
                                <option class="bg-light text-dark" value="Pooling">Pooling</option>
                                <option class="bg-light text-dark" value="Partly Received">Partly Received</option>
                                <option class="bg-light text-dark" value="Received">Received</option>
                                <option class="bg-light text-dark" value="COD Received">COD Received</option>
                                <option class="bg-light text-dark" value="Refund">Refund</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <label class="" for="">From</label>
                            <input autoComplete="new-password" autocomplete="off" type="text" name="from" id="searchOrdersFrom" className="form-control border-1 small  form-control" value={this.state.from} placeholder="" key="0" onChange={this.handleChange} onKeyDown={this.handleKeyPress} />
                        </td>
                        <td>
                            <label class="" for="">To</label>
                            <input autoComplete="new-password" autocomplete="off" type="text" name="to" id="searchOrdersTo" className="form-control border-1 small  form-control" value={this.state.to} placeholder="" key="0" onChange={this.handleChange} onKeyDown={this.handleKeyPress} />
                        </td>
                        <td>
                            <label class="" for="">Initiator</label>
                            <input autoComplete="new-password" autocomplete="off" type="text" name="init" id="searchOrdersInit" className="form-control border-1 small  form-control" value={this.state.init} placeholder="" key="0" onChange={this.handleChange} onKeyDown={this.handleKeyPress} />
                        </td>

                    </tr>
                    <tr>
                        <td colspan="2">
                            <label class="" for="">Search Term</label>
                            <input autoComplete="new-password" autocomplete="off" type="text" name="searchTerm" id="searchterm" className="form-control border-1 small  form-control" value={this.state.searchTerm} placeholder="" key="0" onChange={this.handleChange} onKeyDown={this.handleKeyPress} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" class="btn btn-primary" id="searchButton" onClick={this.searchOrders}>
                                Search
                            </button>
                            <button type="button" class="btn btn-primary m-2" id="searchButton" onClick={this.clearSearchResults}>
                                Clear
                            </button>
                        </td>
                    </tr>

                </table>


            </div>
        )
    }
    fetchSingleOrder = (e) => {
        const that = this;
        axios.get(URL + 'orders?_id=' + this.state.moreInformationId, {
            // axios.get(URL + 'orders?handler=' + this.state.handler + '&_id=' + this.state.moreInformationId, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ retrievedMoreInformationOrder: response["data"]["payload"] })
            })
    }
    handleSelect = (e) => {
        const itemid = e.target.getAttribute("itemid");
        selectedCustomersStore.dispatch({ type: this.state.retrievedOrders.orders[itemid] })
    }
    handlePagination = (e) => {
        var pagenumber = e.target.getAttribute('name');
        this.setState({ searchOrdersPageNumber: pagenumber }, this.searchOrders)
    }
    Pagination = (e) => {
        const navItems = [];
        var tmpTotalOrdersCount = parseInt(this.state.retrievedOrders.count)
        const ordersCount = Math.ceil(tmpTotalOrdersCount / 10);
        for (var i = 0; i < ordersCount; i++) {

            navItems.push(<li class="page-item" id={`searchOrdersPaginationItem-` + i}><a class="page-link" style={{ cursor: 'pointer' }} onClick={this.handlePagination} name={i}>{i + 1}</a></li>);

        }
        return (
            <ul class="m-4 p-1 pagination">
                {navItems}
            </ul>
        );
    }

    clearSearchResults = (e) => {
        this.setState({ retrievedOrders: {} })
    }
    SearchResults() {
        var tmpTotalOrdersCount = parseInt(this.state.retrievedOrders.totalCount)
        const ordersCount = Math.ceil(tmpTotalOrdersCount / 10);
        for (var i = 0; i < ordersCount; i++) {
            $("#searchOrdersPaginationItem-" + i.toString()).removeClass("active")
        }
        $("#searchOrdersPaginationItem-" + this.state.searchOrdersPageNumber).addClass("active")
        var tmpRetrievedOrders = this.state.retrievedOrders.orders;
        var row = [];
        for (var item in tmpRetrievedOrders) {
            var amountStateClass = "";
            var orderStateClass = "";
            if (tmpRetrievedOrders[item].orderstate == "Processing") {
                orderStateClass = "bg-warning text-dark"
            }
            if (tmpRetrievedOrders[item].orderstate == "Cancelled") {
                orderStateClass = "bg-danger text-white"
            }
            if (tmpRetrievedOrders[item].orderstate == "Ready") {
                orderStateClass = "bg-primary text-white"
            }
            if (tmpRetrievedOrders[item].orderstate == "Packed") {
                orderStateClass = "bg-primary text-white"
            }
            if (tmpRetrievedOrders[item].orderstate == "Partly Sent") {
                orderStateClass = "bg-info text-white"
            }
            if (tmpRetrievedOrders[item].orderstate == "Sent") {
                orderStateClass = "bg-success text-white"
            }
            if (tmpRetrievedOrders[item].orderstate == "Returned") {
                orderStateClass = "bg-dark text-white"
            }
            if (tmpRetrievedOrders[item].amountstate == "Pending") {
                amountStateClass = "bg-danger text-white"
            }
            if (tmpRetrievedOrders[item].amountstate == "Pooling") {
                amountStateClass = "bg-primary text-white"
            }
            if (tmpRetrievedOrders[item].amountstate == "Partly Received") {
                amountStateClass = "bg-warning text-dark"
            }
            if (tmpRetrievedOrders[item].amountstate == "Received") {
                amountStateClass = "bg-success text-white"
            }
            if (tmpRetrievedOrders[item].amountstate == "COD Received") {
                amountStateClass = "bg-success text-white"
            }
            if (tmpRetrievedOrders[item].amountstate == "Refund") {
                amountStateClass = "bg-dark text-white"
            }

            var deleteTd = "";
            if (TP === "owner") {
                deleteTd = <i class="fas fa-trash-alt mr-3" title="Delete Order" style={{ cursor: 'pointer' }} id={`deleteOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={tmpRetrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#deleteDialog"></i>
            }

            var orderid_ = tmpRetrievedOrders[item].orderid
            if (orderid_ == null) {
                orderid_ = tmpRetrievedOrders[item].originalorderid.toString() + " (Original Order ID)"
            }
            row.push(
                <tr>
                    <td>
                        {orderid_}
                    </td>
                    <td>
                        {/* {moment(tmpRetrievedOrders[item].date).format('dddd, Do MMM YYYY')} */}
                        {/* {tmpRetrievedOrders[item].date} */}
                        {moment.unix(parseInt(tmpRetrievedOrders[item].date.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY')}
                    </td>
                    <td id={`orderTo-` + item}>
                        {tmpRetrievedOrders[item].to}
                    </td>
                    <td id={`orderFrom-` + item}>
                        {tmpRetrievedOrders[item].from}
                    </td>
                    <td id={`orderAmount-` + item}>
                        {tmpRetrievedOrders[item].amount}
                    </td>
                    <td id={`amountToPrint-` + item} hidden>
                        {tmpRetrievedOrders[item].amountToPrint}
                    </td>
                    <td id={`orderDescription-` + item}>
                        {tmpRetrievedOrders[item].description}
                    </td>
                    <td>
                        <select style={{ minWidth: '150px' }} class={`custom-select ` + orderStateClass} name="orderstate" onChange={this.handleStateChange} value={tmpRetrievedOrders[item].orderstate} id={`orderState-` + item} itemid={item} lid={tmpRetrievedOrders[item]._id["$oid"]} disabled>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-light text-dark" value="Processing">Processing</option>
                            <option class="bg-light text-dark" value="Cancelled">Cancelled</option>
                            <option class="bg-light text-dark" value="Ready">Ready</option>
                            <option class="bg-light text-dark" value="Packed">Packed</option>
                            <option class="bg-light text-dark" value="Partly Sent">Partly Sent</option>
                            <option class="bg-light text-dark" value="Sent">Sent</option>
                            <option class="bg-light text-dark" value="Returned">Returned</option>
                        </select>
                    </td>
                    <td>
                        <select style={{ minWidth: '150px' }} class={`custom-select ` + amountStateClass} name="amountstate" onChange={this.handleStateChange} value={tmpRetrievedOrders[item].amountstate} id={`amountState-` + item} itemid={item} lid={tmpRetrievedOrders[item]._id["$oid"]} disabled>
                            {/* <option selected value="">Select State</option> */}
                            <option class="bg-light text-dark" value="Pending">Pending</option>
                            <option class="bg-light text-dark" value="Pooling">Pooling</option>
                            <option class="bg-light text-dark" value="Partly Received">Partly Received</option>
                            <option class="bg-light text-dark" value="Received">Received</option>
                            <option class="bg-light text-dark" value="COD Received">COD Received</option>
                            <option class="bg-light text-dark" value="Refund">Refund</option>
                        </select>
                    </td>
                    <td id={`orderTracking-` + item}>
                        {tmpRetrievedOrders[item].tracking}
                    </td>
                    <td class="d-flex flex-row align align-items-center">
                        <i class="fas fa-hand-pointer mr-3" title="Select Order" style={{ cursor: 'pointer' }} id={`searchResultSelectOrder-` + item} itemid={item} onClick={this.handleSelect} lid={tmpRetrievedOrders[item]._id["$oid"]} ></i>

                        {/* <i class="fas fa-edit mr-3" title="Edit Order" style={{ cursor: 'pointer' }} itemid={item} id={`searchResultEditOrder-` + item} onClick={(e) => {
                            const itemid = e.target.getAttribute("itemid");
                            var desc = $("#orderDescription-" + itemid).text();
                            var track = $("#orderTracking-" + itemid).text();
                            var amount = $("#orderAmount-" + itemid).text();
                            var to = $("#orderTo-" + itemid).text();
                            var from = $("#orderFrom-" + itemid).text();
                            var amounttoprint = $("#amountToPrint-" + itemid).text()
                            console.log("#orderDescription-" + itemid);
                            console.log(track)
                            this.setState({ actionSelectedId: e.target.getAttribute('lid'), editOrderTracking: track, editOrderDescription: desc, editOrderAmount: amount, editOrderTo: to, editOrderFrom: from, editOrderAmountToPrint: amounttoprint })
                        }} lid={tmpRetrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#editDialog" ></i> */}

                        {/* {deleteTd} */}
                        {/* <i class="fas fa-trash-alt mr-3" title="Delete Order" style={{ cursor: 'pointer' }} id={`deleteOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={tmpRetrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#deleteDialog"></i> */}

                        <i class="fas fa-exclamation-circle mr-3" title="More Information" style={{ cursor: 'pointer' }} itemid={item} id={`searchResultCloseOrder-` + item} data-toggle="modal" data-target="#searchOrdersMoreInformationDialog" lid={tmpRetrievedOrders[item]._id["$oid"]} onClick={(e) => {
                            const itemid = e.target.getAttribute("lid");
                            this.setState({ moreInformationId: itemid }, this.fetchSingleOrder);
                        }}></i>


                        {/* <i class="fas fa-recycle mr-3" title="Recycle Order" style={{ cursor: 'pointer' }} id={`searchResultRecycleOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={tmpRetrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#recycleOrder" ></i> */}

                        {/* <i class="fas fa-check-circle mr-3" title="Close Order" style={{ cursor: 'pointer' }} id={`closeOrder-` + item} onClick={(e) => { this.setState({ actionSelectedId: e.target.getAttribute('lid') }) }} lid={tmpRetrievedOrders[item]._id["$oid"]} data-toggle="modal" data-target="#closeDialog" ></i> */}
                    </td>
                </tr>
            )
        }

        if (row.length > 0) {

            return (
                <table class="table table-hover  w-100 m-0">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Order Id</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">To</th>
                            <th scope="col">From</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Description</th>
                            <th scope="col">Order State</th>
                            <th scope="col">Amount State</th>
                            <th scope="col">Tracking</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {row}
                    </tbody>
                </table>

            );
        }
        else {
            return ""
        }
    }

    searchOrders = (e) => {
        const that = this;
        axios.post(URL + 'orders/search', {
            pageNumber: this.state.searchOrdersPageNumber,
            searchFilters: { "description": true, "tracking": true, "to": true, "from": true },
            searchTerm: this.state.searchTerm,
            orderId: this.state.orderId,
            type: this.state.type_,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            orderState: this.state.orderState,
            amountState: this.state.amountState,
            from: this.state.from,
            to: this.state.to,
            init: this.state.init
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ retrievedOrders: response["data"]["payload"] })
                console.log(response["data"])
            })
    }

    handleChange = (e) => {
        var name = e.target.getAttribute('name');
        var tmp_state = this.state;
        tmp_state[name] = e.target.value;
        this.setState(tmp_state);
    }

    handleCheckboxChange = (e) => {
        var name = e.target.getAttribute("name");
        if (name == "orderSearchOptionOpen") {
            $("#orderSearchOptionOpen").addClass("btn-primary")
            $("#orderSearchOptionClosed").removeClass("btn-primary")
            $("#orderSearchOptionAll").removeClass("btn-primary")
            $("#orderSearchOptionDeleted").removeClass("btn-primary")
            this.setState({ "type_": "open" }, this.fetchOrders)
        }
        if (name == "orderSearchOptionClosed") {
            $("#orderSearchOptionOpen").removeClass("btn-primary")
            $("#orderSearchOptionClosed").addClass("btn-primary")
            $("#orderSearchOptionAll").removeClass("btn-primary")
            $("#orderSearchOptionDeleted").removeClass("btn-primary")
            this.setState({ "type_": "closed" }, this.fetchOrders)
        }
        if (name == "orderSearchOptionDeleted") {
            $("#orderSearchOptionOpen").removeClass("btn-primary")
            $("#orderSearchOptionClosed").removeClass("btn-primary")
            $("#orderSearchOptionAll").removeClass("btn-primary")
            $("#orderSearchOptionDeleted").addClass("btn-primary")
            this.setState({ "type_": "deleted" }, this.fetchOrders)
        }
        if (name == "orderSearchOptionAll") {
            $("#orderSearchOptionOpen").removeClass("btn-primary")
            $("#orderSearchOptionClosed").removeClass("btn-primary")
            $("#orderSearchOptionAll").addClass("btn-primary")
            $("#orderSearchOptionDeleted").removeClass("btn-primary")
            this.setState({ "type_": "all" }, this.fetchOrders)
        }
    }
    handleKeyPress = (e) => {
        if (e.keyCode == 13) {
            this.searchOrders();
        }
    }
    MoreInformationDialog(props) {
        var orderitems = []
        const retrievedMoreInformationOrder = this.state.retrievedMoreInformationOrder;

        if ("_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td class="border-0">
                        Order UID
                    </td>
                    <td class="border-0">
                        {retrievedMoreInformationOrder["_id"]["$oid"]}
                    </td>
                </tr>
            )
        }

        if ("init_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Initiator ID
                    </td>
                    <td>
                        {retrievedMoreInformationOrder["init_id"]}
                    </td>
                </tr>
            )
            orderitems.push(
                <tr>
                    <td>
                        Initiator
                </td>
                    <td>
                        {retrievedMoreInformationOrder["init"]}
                    </td>
                </tr>
            )
        }

        if ("to_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        To ID
                    </td>
                    <td>
                        {retrievedMoreInformationOrder["to_id"]}
                    </td>
                </tr>
            )
            orderitems.push(
                <tr>
                    <td>
                        To
                </td>
                    <td>
                        {retrievedMoreInformationOrder["to"]}
                    </td>
                </tr>
            )
        }

        if ("from_id" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        From ID
                    </td>
                    <td>
                        {retrievedMoreInformationOrder["from_id"]}
                    </td>
                </tr>
            )
            orderitems.push(
                <tr>
                    <td>
                        From
                </td>
                    <td>
                        {retrievedMoreInformationOrder["from"]}
                    </td>
                </tr>
            )
        }

        if ("date" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Order Date
                    </td>
                    <td>
                        {moment.unix(parseInt(retrievedMoreInformationOrder.date.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY')}
                    </td>
                </tr>
            )

        }

        if ("_created" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Order Created
                    </td>
                    <td>
                        {moment.unix(parseInt(retrievedMoreInformationOrder._created.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY h:mm:ss a')}
                    </td>
                </tr>
            )
        }
        if ("_updated" in retrievedMoreInformationOrder) {
            orderitems.push(
                <tr>
                    <td>
                        Order Updated
                    </td>
                    <td>
                        {moment.unix(parseInt(retrievedMoreInformationOrder._updated.$date) / 1000).utcOffset("+0530").format('dddd, Do MMM YYYY h:mm:ss a')}
                    </td>
                </tr>
            )

        }



        return (
            <div class="modal fade" id="searchOrdersMoreInformationDialog" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">More Information</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="table-responsive">
                                <table class="table">
                                    {orderitems}
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                            {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button> */}
                        </div>
                    </div>
                </div>
            </div>
        );


    }

    render() {
        return (
            <div>
                <div class="col-xl-6 col-md-6 col-12 m-4">
                    <this.SearchBox />
                </div>
                <div class="col-xl-12 col-md-12 col-12">
                    <this.Pagination />
                    <div class="table-responsive">
                        <this.SearchResults />
                    </div>
                </div>
                <this.MoreInformationDialog />
            </div>
        );
    }
}

class NewOrderDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = { retrievedInitData: {}, initSet: false, initId: "", initInfo: "", toFromSet: false, date: new Date(), amount: "0", shipFromOwnerCheckbox: false, description: "", retrievedToData: {}, retrievedFromData: {}, selectedTo: {}, selectedToSet: false, selectedToID: "", selectedFromSet: false, selectedFromID: "", selectedFrom: {}, shipFromOwnerCheckbox: false, statusText: {}, orderid: 0, handlerId: { "name": "Select Handler", "lid": "Select Handler" } }

        this.handleInitSearch = this.handleInitSearch.bind(this);
        this.InitSearchResults = this.InitSearchResults.bind(this);
        this.DialogBody = this.DialogBody.bind(this);
        this.handleToFromClick = this.handleToFromClick.bind(this);
        this.toSearchChangeHandle = this.toSearchChangeHandle.bind(this);
        this.ToSearchResults = this.ToSearchResults.bind(this);
        this.FromSearchResults = this.FromSearchResults.bind(this);
        this.fromSearchChangeHandle = this.fromSearchChangeHandle.bind(this);
        this.populateSelectedTo = this.populateSelectedTo.bind(this);
        this.populateSelectedFrom = this.populateSelectedFrom.bind(this);
        this.postOrder = this.postOrder.bind(this);
        this.StatusText = this.StatusText.bind(this);

        this.resetAll = this.resetAll.bind(this);
    }

    componentDidMount() {
        this.setState({ retrievedInitData: {}, initSet: false, initId: "", toId: "", fromId: "", toFromSet: false })
    }

    componentWillUnmount() {
        this.setState({ retrievedInitData: {}, initSet: false, initId: "", toId: "", fromId: "", toFromSet: false })

    }

    resetAll = (e) => {
        this.setState({ retrievedInitData: {}, initSet: false, initId: "", initInfo: "", toFromSet: false, date: new Date(), amount: "0", shipFromOwnerCheckbox: false, description: "", retrievedToData: {}, retrievedFromData: {}, selectedTo: {}, selectedToSet: false, selectedToID: "", selectedFromSet: false, selectedFromID: "", selectedFrom: {}, shipFromOwnerCheckbox: false, statusText: {}, orderid: 0, handlerId: { "name": "Select Handler", "lid": "Select Handler" } })

        $('#closeOrderButton').attr('hidden', 'hidden')
    }
    handleInitSearch = (e) => {
        $("#initSearchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error

                that.setState({ retrievedInitData: response['data']['payload']['data'] });
                $("#initSearchLoadingSpinner1").html("")
            })
    }

    handleInitResultClick = (e) => {
        var lid = e.target.getAttribute("lid")
        var info = e.target.getAttribute("name")
        console.log(lid)
        this.setState({ initSet: true, initId: lid, initInfo: info })
    }

    InitSearchResults = (e) => {
        var results = []
        for (var index in this.state.retrievedInitData) {
            results.push(
                <span class="list-group-item list-group-item-action" style={{ "cursor": "pointer" }} name={this.state.retrievedInitData[index]['firstname'] + ` ` + this.state.retrievedInitData[index]['lastname'] + ` | ` + this.state.retrievedInitData[index]['phone'] + ` | ` + this.state.retrievedInitData[index]['shippingAddressCity']} lid={this.state.retrievedInitData[index]['_id']["$oid"]} onClick={this.handleInitResultClick}>
                    {this.state.retrievedInitData[index]['firstname']}&nbsp;{this.state.retrievedInitData[index]['lastname']}&nbsp;|&nbsp;{this.state.retrievedInitData[index]['phone']}&nbsp;|&nbsp;{this.state.retrievedInitData[index]['shippingAddressCity']}
                </span>
            )
        }
        return (
            <div class="mt-2">
                <div class="list-group" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {results}
                </div>
            </div>
        );
    }

    handleToFromClick = (e) => {
        this.setState({ toFromSet: true })
    }

    toSearchChangeHandle = (e) => {
        $("#toSearchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error
                that.setState({ retrievedToData: response['data']['payload']['data'] });
                $("#toSearchLoadingSpinner1").html("")
            })
    }
    ToSearchResults = (e) => {
        var customerData_ = []
        for (var item in this.state.retrievedToData) {
            var name = this.state.retrievedToData[item]['firstname'] + " " + this.state.retrievedToData[item]['lastname'];
            var lid = this.state.retrievedToData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedTo} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedToData[item]['firstname']}&nbsp;{this.state.retrievedToData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedToData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedToData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            return (
                <span></span>
            );
        } else {
            return (
                <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {customerData_}
                </div>
            );
        }
    }
    fromSearchChangeHandle = (e) => {
        $("#fromSearchLoadingSpinner2").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
        const that = this;
        axios.post(URL + "search", {
            searchTerm: e.target.value,
            searchFilters: searchFilterCheckboxValues,
            searchGetAllFields: searchGetAllFieldsCheckboxValues,
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                // add a general function to check for error
                that.setState({ retrievedFromData: response['data']['payload']['data'] });
                $("#fromSearchLoadingSpinner2").html("")
            })
    }
    FromSearchResults = (e) => {
        var customerData_ = []
        for (var item in this.state.retrievedFromData) {
            var name = this.state.retrievedFromData[item]['firstname'] + " " + this.state.retrievedFromData[item]['lastname'];
            var lid = this.state.retrievedFromData[item]['_id']['$oid'];
            customerData_.push(
                <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedFrom} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedFromData[item]['firstname']}&nbsp;{this.state.retrievedFromData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedFromData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedFromData[item]['shippingAddressCity']}</span>
            );
        }
        if (customerData_.length === 0) {
            return (
                <span></span>
            );
        } else {
            return (
                <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {customerData_}
                </div>
            );
        }
    }
    populateSelectedTo = (e) => {
        const _id = e.target.getAttribute("lid");
        console.log(_id);
        const that = this;
        axios.post(URL + "search", {
            searchTerm: "",
            searchFilters: {
                _id: _id
            },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "selectedTo": response.data.payload.data, "selectedToID": response.data.payload.data._id.$oid, "selectedToSet": true });
            })
    }
    populateSelectedFrom = (e) => {
        const _id = e.target.getAttribute("lid");
        console.log(_id);
        const that = this;
        axios.post(URL + "search", {
            searchTerm: "",
            searchFilters: {
                _id: _id
            },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ selectedFrom: response.data.payload.data, selectedFromID: response.data.payload.data._id.$oid, selectedFromSet: true });
            })
    }
    SelectedTo = (e) => {
        if (this.state.selectedToSet) {
            const selectedCustomer = this.state.selectedTo;
            return (<table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected To</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ selectedTo: {}, selectedToID: "", selectedToSet: false })

                                }
                                }></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>);
        }
        return (<span></span>);


    }

    SelectedFrom = (e) => {
        if (this.state.selectedFromSet) {
            const selectedCustomer = this.state.selectedFrom;
            return (<table class="table p-2">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="d-flex justify-content-between">
                                <b>Selected From</b>
                                <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ selectedFrom: {}, selectedFromID: "", selectedFromSet: false })
                                }
                                }></i>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['firstname']}</td>
                        <td>{selectedCustomer['lastname']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['brand']}</td>
                        <td>{selectedCustomer['phone']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['email']}</td>
                        <td>{selectedCustomer['gstin']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressAddress1']}</td>
                        <td>{selectedCustomer['shippingAddressAddress2']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressCity']}</td>
                        <td>{selectedCustomer['shippingAddressState']}</td>
                    </tr>
                    <tr>
                        <td>{selectedCustomer['shippingAddressPincode']}</td>
                        <td>{selectedCustomer['shippingAddressCountry']}</td>
                    </tr>
                </tbody>
            </table>);
        }
        return (<span></span>);


    }

    postOrder = (e) => {
        $("#postOrderButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Order")
        console.log(this.state.amount);
        console.log(this.state.date);
        console.log(this.state.description);
        console.log(this.state.selectedToID);
        console.log(this.state.selectedFromID);

        // var to_id = "";
        // if (this.state.selectedCustomerID === "" && this.state.shipFromOwnerCheckbox) {
        //     to_id = transactDialogLid
        // } else {
        //     to_id = this.state.selectedCustomerID
        // }
        const that = this;
        axios.post(URL + "orders", {
            amount: this.state.amount,
            date: this.state.date,
            to_id: this.state.selectedToID,
            to: "",
            amountstate: "",
            init_id: this.state.initId,
            description: this.state.description,
            from_id: this.state.selectedFromID,
            from: "",
            handler: "",
            orderid: this.state.orderid.toString(),
            handlerId: this.state.handlerId["lid"],
            orderstate: "",
            tracking: "",
            shipFromOwnerCheckbox: this.state.shipFromOwnerCheckbox

        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                $("#postOrderButton").html("Order")
                that.setState({ statusText: response["data"] })
                console.log(response["data"])
            })

    }
    StatusText = (e) => {
        if (this.state.statusText["_status"] === "success") {
            $('#postOrderButton').attr('disabled', 'disabled');
            $('#backFromFinalButton').attr('disabled', 'disabled');
            $("#closeOrderButton").removeAttr('hidden');
            return (
                <span class="ml-2 text-success">
                    Order Placed
                </span>
            )
        }
        if (this.state.statusText["_status"] === "fail") {

            return (
                <span class="ml-2 text-danger">
                    Some Error Occurred!&nbsp;{this.state.statusText["payload"]}
                </span>
            )
        }
        return ("")
    }
    DialogBody = (e) => {

        var orderidHandlerRow = []


        if (account_type == "owner") {
            orderidHandlerRow.push(
                <tr>
                    <td>
                        Order ID
                    </td>
                    <td>
                        Handler
                    </td>
                </tr>
            );
            orderidHandlerRow.push(
                <tr>
                    <td>
                        <input autocomplete="off" type="number" class="form-control" value={this.state.orderid} name="newOrderOrderId" id="newOrderOrderId" onChange={(e) => {
                            this.setState({ orderid: e.target.value })
                        }} />
                    </td>
                    <td>
                        <select class="custom-select" defaultValue={this.state.handlerId["name"]} value={this.state.handlerId["name"]} onChange={(e) => {
                            var index = e.target.selectedIndex;
                            const h_lid = e.target[index].getAttribute("lid");
                            const tmp_d = { "name": e.target.value, "lid": h_lid }
                            this.setState({ handlerId: tmp_d }, () => {
                                console.log(this.state.handlerId)
                            })
                        }}>
                            <option lid="Select Handler" value="Select Handler">Select Handler</option>
                            <Handlers handler={this.state.handlerId["name"]} />
                        </select>
                    </td>
                </tr>
            )
        }



        if (!this.state.initSet && !this.state.toFromSet) {

            var InitSearchResults = ""
            var results = []
            for (var index in this.state.retrievedInitData) {
                results.push(
                    <span class="list-group-item list-group-item-action" style={{ "cursor": "pointer" }} name={this.state.retrievedInitData[index]['firstname'] + ` ` + this.state.retrievedInitData[index]['lastname'] + ` | ` + this.state.retrievedInitData[index]['phone'] + ` | ` + this.state.retrievedInitData[index]['shippingAddressCity']} lid={this.state.retrievedInitData[index]['_id']["$oid"]} onClick={this.handleInitResultClick}>
                        {this.state.retrievedInitData[index]['firstname']}&nbsp;{this.state.retrievedInitData[index]['lastname']}&nbsp;|&nbsp;{this.state.retrievedInitData[index]['phone']}&nbsp;|&nbsp;{this.state.retrievedInitData[index]['shippingAddressCity']}
                    </span>
                )
            }
            InitSearchResults = <div class="mt-2">
                <div class="list-group" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {results}
                </div>
            </div>


            return (
                <div class="d-flex justify-content-center">
                    <div class="container">
                        <div class="row">
                            <table class="m-2 w-100">

                                <tr>
                                    <td>
                                        <span class="d-flex justify-content-between w-100">
                                            <span>
                                                <span>Initiator</span>
                                            </span>
                                            <span id="initSearchLoadingSpinner1">

                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" name="init" id="newOrderInit" className="form-control border-1 small  form-control-lg" placeholder="Search" key="0" onChange={this.handleInitSearch} />
                                        {/* <this.InitSearchResults /> */}
                                        {InitSearchResults}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            );
        }

        if (this.state.initSet && !this.state.toFromSet) {

            // to search results
            var ToSearchResults = ""
            var customerData_ = []
            for (var item in this.state.retrievedToData) {
                var name = this.state.retrievedToData[item]['firstname'] + " " + this.state.retrievedToData[item]['lastname'];
                var lid = this.state.retrievedToData[item]['_id']['$oid'];
                customerData_.push(
                    <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedTo} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedToData[item]['firstname']}&nbsp;{this.state.retrievedToData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedToData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedToData[item]['shippingAddressCity']}</span>
                );
            }
            if (customerData_.length === 0) {
                ToSearchResults = <span></span>
            } else {
                ToSearchResults = <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {customerData_}
                </div>
            }

            // selected to

            var SelectedTo = <span></span>
            if (this.state.selectedToSet) {
                const selectedCustomer = this.state.selectedTo;
                SelectedTo = <table class="table p-2">
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <div class="d-flex justify-content-between">
                                    <b>Selected To</b>
                                    <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                        this.setState({ selectedTo: {}, selectedToID: "", selectedToSet: false })

                                    }
                                    }></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['firstname']}</td>
                            <td>{selectedCustomer['lastname']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['brand']}</td>
                            <td>{selectedCustomer['phone']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['email']}</td>
                            <td>{selectedCustomer['gstin']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressAddress1']}</td>
                            <td>{selectedCustomer['shippingAddressAddress2']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressCity']}</td>
                            <td>{selectedCustomer['shippingAddressState']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressPincode']}</td>
                            <td>{selectedCustomer['shippingAddressCountry']}</td>
                        </tr>
                    </tbody>
                </table>
            }

            // from search results
            var FromSearchResults = ""
            var customerData_ = []
            for (var item in this.state.retrievedFromData) {
                var name = this.state.retrievedFromData[item]['firstname'] + " " + this.state.retrievedFromData[item]['lastname'];
                var lid = this.state.retrievedFromData[item]['_id']['$oid'];
                customerData_.push(
                    <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedFrom} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedFromData[item]['firstname']}&nbsp;{this.state.retrievedFromData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedFromData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedFromData[item]['shippingAddressCity']}</span>
                );
            }
            if (customerData_.length === 0) {
                FromSearchResults = <span></span>

            } else {
                FromSearchResults = <div class="list-group w-100 m-2" style={{ "maxHeight": "300px", "-webkit-overflow-scrolling": "touch", "overflow-y": "auto" }}>
                    {customerData_}
                </div>
            }

            // selected from
            var SelectedFrom = <span></span>
            if (this.state.selectedFromSet) {
                const selectedCustomer = this.state.selectedFrom;
                SelectedFrom = <table class="table p-2">
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <div class="d-flex justify-content-between">
                                    <b>Selected From</b>
                                    <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={() => {
                                        this.setState({ selectedFrom: {}, selectedFromID: "", selectedFromSet: false })
                                    }
                                    }></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['firstname']}</td>
                            <td>{selectedCustomer['lastname']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['brand']}</td>
                            <td>{selectedCustomer['phone']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['email']}</td>
                            <td>{selectedCustomer['gstin']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressAddress1']}</td>
                            <td>{selectedCustomer['shippingAddressAddress2']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressCity']}</td>
                            <td>{selectedCustomer['shippingAddressState']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressPincode']}</td>
                            <td>{selectedCustomer['shippingAddressCountry']}</td>
                        </tr>
                    </tbody>
                </table>
            }


            return (
                <div>
                    <div class="modal-body p-4">

                        <div class="d-flex justify-content-center">
                            <div class="container">
                                <div class="row">
                                    <table class="m-2 w-100">
                                        {orderidHandlerRow}
                                        <tr>
                                            <td>
                                                Amount
                                            </td>
                                            <td>
                                                Date
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input autocomplete="off" type="number" class="form-control" value={this.state.amount} name="shippingToFromAmount" id="shippingToFromTimes" onChange={(e) => {
                                                    this.setState({ amount: e.target.value })
                                                }} />
                                            </td>
                                            <td>
                                                <DatePicker
                                                    selected={this.state.date}
                                                    onChange={(date) => {
                                                        this.setState({ "date": date });
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control w-100"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Description
                                        </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <input autocomplete="off" type="text" class="form-control" value={this.state.description} name="shippingToFromDescription" id="shippingToFromDescription" onChange={(e) => {
                                                    this.setState({ "description": e.target.value });
                                                }} />
                                            </td>
                                        </tr>
                                    </table>
                                    <form class="w-100">
                                        <div class="form-group row border m-2">
                                            <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                                <span>
                                                    <span>Ship To</span>
                                                </span>
                                                <span id="toSearchLoadingSpinner1">

                                                </span>
                                            </span>
                                            <div class="input-group p-2">
                                                <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.toSearchChangeHandle} />
                                            </div>
                                            {/* <this.ToSearchResults /> */}
                                            {ToSearchResults}
                                            <div class="w-100">
                                                {/* <this.SelectedTo /> */}
                                                {SelectedTo}
                                            </div>
                                        </div>
                                        <div class="form-group row border m-2">
                                            <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                                <span>
                                                    <span>Ship From</span>
                                                </span>
                                                <span id="fromSearchLoadingSpinner2">

                                                </span>
                                            </span>
                                            <div class="input-group p-2">
                                                <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.fromSearchChangeHandle} />
                                            </div>
                                            {/* <this.FromSearchResults /> */}
                                            {FromSearchResults}
                                            <div class="w-100">
                                                {/* <this.SelectedFrom /> */}
                                                {SelectedFrom}
                                            </div>
                                            <div class="form-group form-check m-2">
                                                <input type="checkbox" class="form-check-input" defaultChecked={this.state.shipFromOwnerCheckbox} id="shipFromOwnerCheckbox" onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setState({ shipFromOwnerCheckbox: true })
                                                    } else {
                                                        this.setState({ shipFromOwnerCheckbox: false })
                                                    }
                                                }} />
                                                <label class="form-check-label" for="shipFromOwnerCheckbox">Ship From Owner</label>
                                                {/* <button type="button" class="btn btn-warning ml-2">Confirm Transaction</button> */}
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" id="backFromSecondButton" class="btn btn-secondary" onClick={(e) => {
                            this.setState({ initSet: false })
                        }}>Back</button>
                        <button type="button" id="nextButton" class="btn btn-primary" onClick={this.handleToFromClick}>Next</button>
                    </div>
                </div>
            );
        }

        if (this.state.initSet && this.state.toFromSet) {
            var to = []
            var from = []
            var description = []
            var shipFromOwnerCheckbox = []
            var warnings = []
            var Status = ""
            if (this.state.statusText["_status"] === "success") {
                $('#postOrderButton').attr('disabled', 'disabled');
                $('#backFromFinalButton').attr('disabled', 'disabled');
                $("#closeOrderButton").removeAttr('hidden');
                Status = <span class="ml-2 text-success">
                    Order Placed
                    </span>
            }
            if (this.state.statusText["_status"] === "fail") {

                Status = <span class="ml-2 text-danger">
                    Some Error Occurred!&nbsp;{this.state.statusText["payload"]}
                </span>
            }



            if (this.state.selectedToID.length > 0) {
                to.push(
                    <tr>
                        <td>
                            To:
                            </td>
                        <td>
                            {this.state.selectedTo["firstname"]}&nbsp;{this.state.selectedTo["lastname"]}&nbsp;|&nbsp;{this.state.selectedTo["phone"]}&nbsp;|&nbsp;{this.state.selectedTo["shippingAddressCity"]}
                        </td>
                    </tr>
                )
            }
            if (this.state.selectedFromID.length > 0) {
                var shipFromOwnerCheckboxInfo = ""
                if (this.state.shipFromOwnerCheckbox) {
                    shipFromOwnerCheckboxInfo = <p class="text-danger">&nbsp;|&nbsp;Ship From Owner is set!</p>
                }
                from.push(
                    <tr>
                        <td>
                            From:
                            </td>
                        <td>
                            {this.state.selectedFrom["firstname"]}&nbsp;{this.state.selectedFrom["lastname"]}&nbsp;|&nbsp;{this.state.selectedFrom["phone"]}&nbsp;|&nbsp;{this.state.selectedFrom["shippingAddressCity"]}{shipFromOwnerCheckboxInfo}
                        </td>
                    </tr>
                );
            }
            if (this.state.shipFromOwnerCheckbox) {
                shipFromOwnerCheckbox.push(
                    <tr>
                        <td>
                            Ship From Owner:
                            </td>
                        <td>
                            True
                        </td>
                    </tr>
                );
            }
            if (this.state.description.length > 0) {
                description.push(
                    <tr>
                        <td>
                            Description:
                        </td>
                        <td>
                            {this.state.description}
                        </td>
                    </tr>
                );
            }
            if (this.state.orderid > 0) {
                warnings.push(
                    <p class="text-danger">Order ID is set!</p>
                );
            }
            if (this.state.handlerId["lid"] != "Select Handler") {
                warnings.push(
                    <p class="text-danger">Handler is set!</p>
                );
            }
            return (
                <div>
                    <div class="modal-body p-4">
                        <div class="d-flex justify-content-center">
                            <div class="table-responsive">
                                <table class="table">
                                    <tr>
                                        <td>
                                            {warnings}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Initiator:
                            </td>
                                        <td>
                                            {this.state.initInfo}
                                        </td>
                                    </tr>
                                    {to}
                                    {from}
                                    {shipFromOwnerCheckbox}
                                    <tr>
                                        <td>
                                            Amount:
                            </td>
                                        <td>
                                            {this.state.amount}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Date:
                            </td>
                                        <td>
                                            {moment(this.state.date).format('dddd, Do MMM YYYY')}
                                            {/* {this.state.date} */}
                                        </td>
                                    </tr>
                                    {description}
                                </table>
                            </div>
                        </div>
                        {/* <this.StatusText /> */}
                        {Status}
                    </div>
                    <div class="modal-footer">

                        <button type="button" id="backFromFinalButton" class="btn btn-secondary" onClick={(e) => {
                            this.setState({ toFromSet: false })
                        }}>Back</button>
                        <button type="button" id="postOrderButton" class="btn btn-primary " onClick={this.postOrder}>Order</button>
                        <button type="button" id="closeOrderButton" class="btn btn-danger " data-dismiss="modal" aria-label="Close" onClick={this.resetAll} hidden>Close</button>
                    </div>
                </div>

            );
        }

    }

    render() {
        return (
            <div class="modal fade" id="newOrderDialog" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered ">

                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">New Order</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.resetAll}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <this.DialogBody />

                    </div>
                </div>
            </div>
        );
    }
}

// const Orders = (e) => {
class Orders extends React.Component {
    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
    componentDidUpdate() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }

    render() {
        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Orders</h1>
                    {/* was d-none here, in the class */}
                    <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2"></i>New Order</span>
                </div>

                <div class="row">

                    <div class="col-xl-12 col-lg-12 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">All Orders</h6>
                            </div>
                            <div class="m-0">
                                <AllOrders />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Selected Orders</h6>
                            </div>

                            <SelectedOrder />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Search Orders</h6>
                            </div>

                            <SearchOrders />
                        </div>
                    </div>

                </div>

                <NewOrderDialog />

            </div>
        );
    }
}

export default Orders;
