
import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { isUserWhitespacable, thisExpression, tsImportEqualsDeclaration } from '@babel/types';
import axios from 'axios';
import URL from './URL';
import moment from 'moment-timezone';
import printJS from 'print-js';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-rating"
import "react-datepicker/dist/react-datepicker.css";
import JSONPretty from 'react-json-pretty';
import Rating from 'react-rating';
import csc from 'country-state-city'
import { ICountry, IState, ICity } from 'country-state-city'
import Pagination from "react-js-pagination";
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015

var country_json_1 = require("../node_modules/country-state-city/lib/country.json");
var state_json_1 = require("../node_modules/country-state-city/lib/state.json");
var city_json_1 = require("../node_modules/country-state-city/lib/city.json");

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')

if (userInfo != null) {
    userInfo = JSON.parse(userInfo);
    account_type = userInfo['account_type'];

}

function getCountryId(country) {
    if (country && country_json_1 != null) {
        var codex = country_json_1.findIndex(function (c) { return c.name === country; });
        return (codex !== -1) ? country_json_1[codex] : "";
    }
    else
        return "";
}

function getStateId(state) {
    if (state && state_json_1 != null) {
        var codex = state_json_1.findIndex(function (c) { return c.name === state; });
        return (codex !== -1) ? state_json_1[codex] : "";
    }
    else
        return "";
}

var localTz = moment.tz.guess();
// console.log(localTz);
const searchFilterCheckboxValues = {
    // "ID": true,
    "Firstname": true,
    "Lastname": true,
    "Brand": true,
    "Phone": true,
    "Email": true,
    "GSTIN": true,
    "Handler": true,
    "City": true,
    "State": true,
    "Pincode": true,
    "Country": true
};

const searchGetAllFieldsCheckboxValues = {
    // "ID": true,
    "Firstname": false,
    "Lastname": false,
    "Brand": false,
    "Phone": false,
    "Email": false,
    "GSTIN": false,
    "Handler": false,
    "City": false,
    "State": false,
    "Pincode": false,
    "Country": false
};

const defaultInfoValues = {
    "firstname": "",
    "lastname": "",
    "brand": "",
    "interests": "",
    "phone": "",
    "phones": [],
    "email": "",
    "gstin": "",
    "instagram": "",
    "facebook": "",
    "whatsapp": "",
    "website": "",
    "coupon": "",
    "type": "",
    "tag": "",
    "billingAddressAddress1": "",
    "billingAddressAddress2": "",
    "billingAddressCity": "",
    "billingAddressState": "",
    "billingAddressPincode": "",
    "billingAddressCountry": "India",
    "shippingAddressAddress1": "",
    "shippingAddressAddress2": "",
    "shippingAddressCity": "",
    "shippingAddressState": "",
    "shippingAddressPincode": "",
    "shippingAddressCountry": "India",
    "handler": "",
    "handlerId": "",
}
const json_prop_concat = (j1, j2) => {
    for (var key in j2) {
        j1[key] = j2[key];
    }
    return j1
}


class Handlers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "handlers": {} };
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "handlers": response['data']['payload'] });
            })

        this.selectedHandler = props.handler;
    }
    render() {
        var handlers = []
        for (var handler in this.state.handlers) {
            var name_ = this.state.handlers[handler].firstname + " " + this.state.handlers[handler].lastname;
            if (name_ == this.selectedHandler) {

                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_} selected>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
            else {
                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_}>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
        }
        return (

            handlers
        );
    }
}

class Data extends React.Component {
    constructor(props) {
        super(props);
        this.state = { defaultInfo: { ...defaultInfoValues }, submitButtonLoadingSpinner: false, statusStringBool: false, statusString: {}, selectedCustomers: {}, retrievedCustomerData: {}, billingSelectedCountryId: "", shippingSelectedCountryId: "", billingSelectedStateId: "", shippingSelectedStateId: "", billingSelectedCityId: "", shippingSelectedCityId: "", inputSuggestionBool: true, billingStates: csc.getStatesOfCountry("101"), shippingStates: csc.getStatesOfCountry("101"), billingCities: [], shippingCities: [], shippingAddressCitySelected: null, shippingAddressStateSelected: null, shippingAddressCountrySelected: null, shippingBillingAddressSameBool: false, interests_search_term: "", fetched_interests: [], added_interests: {} }

        this.handleDefaultInfoChange = this.handleDefaultInfoChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.postData = this.postData.bind(this);
        this.SubmitButtonLoadignSpinner = this.SubmitButtonLoadignSpinner.bind(this);
        this.StatusString = this.StatusString.bind(this);
        this.resetStatusAndField = this.resetStatusAndField.bind(this);
        this.closeStatusString = this.closeStatusString.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.addNewPhone = this.addNewPhone.bind(this);
        this.removeNewPhone = this.removeNewPhone.bind(this);
        this.handlePhonesChange = this.handlePhonesChange.bind(this);
        $('#firstname').focus();

        this.allCountries = csc.getAllCountries();
        this.billingStates = []
        this.shippingStates = []
        this.billingCities = []
        this.shippingCities = []

        this.suggestionInputRefs = {}

    }

    componentDidMount() {
        var tmp_countries = csc.getAllCountries();
        this.setState({ billingCountries: tmp_countries, shippingCountries: tmp_countries })
    }


    resetFields = (e) => {
        var shippingAddressAddress1 = document.getElementById("shippingAddressAddress1");
        var shippingAddressAddress2 = document.getElementById("shippingAddressAddress2");
        // var shippingAddressCity = document.getElementById("shippingAddressCity");
        // var shippingAddressState = document.getElementById("shippingAddressState");
        var shippingAddressPincode = document.getElementById("shippingAddressPincode");
        // var shippingAddressCountry = document.getElementById("shippingAddressCountry");

        var billingAddressAddress1 = document.getElementById("billingAddressAddress1");
        var billingAddressAddress2 = document.getElementById("billingAddressAddress2");
        // var billingAddressCity = document.getElementById("billingAddressCity");
        // var billingAddressState = document.getElementById("billingAddressState");
        var billingAddressPincode = document.getElementById("billingAddressPincode");
        // var billingAddressCountry = document.getElementById("billingAddressCountry");

        var firstname = document.getElementById("firstname");
        var lastname = document.getElementById("lastname");
        var brand = document.getElementById("brand");
        $("#interests").val("");
        $("#instagram").val("");
        $("#facebook").val("");
        $("#whatsapp").val("");
        $("#website").val("");
        var phone = document.getElementById("phone");
        var email = document.getElementById("email");
        var gstin = document.getElementById("gstin");
        var handler = document.getElementById("inputGroupSelect01");
        var customerSearchTextbox = document.getElementById("customerSearchTextbox");
        var coupon = document.getElementById("coupon");

        $("select#dataBillingAddressCountry").val("India")
        $("select#dataBillingAddressState").prop('selectedIndex', 0);
        $("select#dataBillingAddressCity").prop('selectedIndex', 0);

        $("select#dataShippingAddressCountry").val("India")
        $("select#dataShippingAddressState").prop('selectedIndex', 0);
        $("select#dataShippingAddressCity").prop('selectedIndex', 0);

        shippingAddressAddress1.value = "";
        shippingAddressAddress2.value = "";
        shippingAddressPincode.value = "";
        billingAddressAddress1.value = "";
        billingAddressAddress2.value = "";
        // billingAddressCity.value = "";
        // billingAddressState.value = "";
        billingAddressPincode.value = "";
        // billingAddressCountry.value = "";
        firstname.value = "";
        lastname.value = "";
        brand.value = "";
        phone.value = "";
        email.value = "";
        gstin.value = "";
        handler.selectedIndex = 0;
        $("select#dataEntryType").prop('selectedIndex', 0);
        $("select#dataEntryTag").prop('selectedIndex', 0);
        customerSearchTextbox.value = "";
        coupon.value = "";

        const tmpValues = {
            "firstname": "",
            "lastname": "",
            "brand": "",
            "interests": "",
            "phone": "",
            "phones": [],
            "email": "",
            "gstin": "",
            "instagram": "",
            "facebook": "",
            "whatsapp": "",
            "website": "",
            "type": "",
            "coupon": "",
            "tag": "",
            "billingAddressAddress1": "",
            "billingAddressAddress2": "",
            "billingAddressCity": "",
            "billingAddressState": "",
            "billingAddressPincode": "",
            "billingAddressCountry": "India",
            "shippingAddressAddress1": "",
            "shippingAddressAddress2": "",
            "shippingAddressCity": "",
            "shippingAddressState": "",
            "shippingAddressPincode": "",
            "shippingAddressCountry": "India",
            "handler": "",
            "handlerId": ""
        }
        this.setState({ defaultInfo: { ...tmpValues }, added_interests: {}, shippingBillingAddressSameBool: false, shippingAddressStateSelected: null }, () => { })
        this.setState({ state: this.state });

    }

    resetStatusAndField = (e) => {
        this.resetFields();
        this.setState({ statusStringBool: false });
    }
    closeStatusString = (e) => {
        this.setState({ statusStringBool: false });
    }
    StatusString = (e) => {
        if (this.state.statusStringBool == true) {
            if (this.state.statusString['_status'] == 'fail') {
                var issues = [];
                for (var index in this.state.statusString['payload']) {
                    if (index === "phones") {
                        for (var index_ in this.state.statusString['payload']['phones'][0]) {
                            issues.push(
                                <div class="row"><span>Phone #{parseInt(index_ + 1).toString()}: {this.state.statusString['payload']['phones'][0][index_]} </span></div>
                            )
                        }
                    } else {
                        issues.push(
                            <div class="row"><span>{index}: {this.state.statusString['payload'][index]} </span></div>
                        )
                    }

                }
                // issues.push(<JSONPretty id="json-pretty" data={this.state.statusString['payload']}></JSONPretty>);
                return (
                    <div class="input-group">
                        <div class="alert alert-danger m-0" role="alert">

                            {issues}
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" onClick={this.resetStatusAndField}>
                                <span>Reset</span>
                            </button>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-danger" onClick={this.closeStatusString}>
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                )

            }
            if (this.state.statusString['_status'] == 'success') {
                return (
                    <div class="input-group">
                        <div class="alert alert-success m-0" role="alert">
                            <div class="row">
                                Data successfully entered with {this.state.statusString['payload']}
                            </div>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-success" onClick={this.resetStatusAndField}>
                                <span>Reset</span>
                            </button>
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-success" onClick={this.closeStatusString}>
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div>

            </div>
        );
    }

    SubmitButtonLoadignSpinner = (e) => {
        if (this.state.submitButtonLoadingSpinner == false) {
            return (
                <span></span>
            );
        }
        if (this.state.submitButtonLoadingSpinner == true) {
            return (
                <i id="spin_submit_button" class="fa fa-circle-o-notch fa-spin mr-2"></i>
            );
        }
    }
    postData = () => {
        // setSubmitButtonLoadingSpinner(true);
        // console.log(this.state.defaultInfo);
        const that = this;
        this.setState({ submitButtonLoadingSpinner: true })
        var aggr_data = { ...this.state.defaultInfo };


        // generate interests
        // interest string
        var interests = []
        for (var i in this.state.added_interests) {
            interests.push(i)
        }

        aggr_data["interests"] = interests


        // var i = 0;
        // for (i = 0; i < this.state.selectedCustomers.length; i++) {
        //     const tmp_pair = '{"' + dataState[i]['dataKey'] + '": "' + dataState[i]['dataValue'] + '"}';
        //     aggr_data = json_prop_concat(aggr_data, JSON.parse(tmp_pair));
        // }
        // console.log(JSON.stringify(aggr_data));
        axios.post(URL + "data", aggr_data, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(response['data'])
                that.setState({ statusString: response['data'], submitButtonLoadingSpinner: false, statusStringBool: true });
                if (response['data']["_status"] == "success") {
                    that.resetFields();
                }
                //setStatusString(response['data']);
                //setStatusStringBool(true);
                //setSubmitButtonLoadingSpinner(false);
            })
            .catch(function (error) {
                // console.log(error)
            })
    }
    handleCheckBoxChange = (e) => {
        if (e.target.checked) {
            this.setState({ shippingBillingAddressSameBool: true, shippingStates: this.state.billingStates, shippingCities: this.state.billingCities }, () => {
                const updatedDefaultInfo = this.state.defaultInfo;
                var shippingAddressAddress1 = document.getElementById("shippingAddressAddress1");
                var shippingAddressAddress2 = document.getElementById("shippingAddressAddress2");
                var shippingAddressCity = document.getElementById("shippingAddressCity");
                var shippingAddressState = document.getElementById("shippingAddressState");
                var shippingAddressPincode = document.getElementById("shippingAddressPincode");
                var shippingAddressCountry = document.getElementById("shippingAddressCountry");

                if ("billingAddressAddress1" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressAddress1'] = updatedDefaultInfo["billingAddressAddress1"];
                    shippingAddressAddress1.value = updatedDefaultInfo["billingAddressAddress1"];

                }
                if ("billingAddressAddress2" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressAddress2'] = updatedDefaultInfo["billingAddressAddress2"];
                    shippingAddressAddress2.value = updatedDefaultInfo["billingAddressAddress2"];
                }
                //alert(updatedDefaultInfo['billingAddressCity'])
                if ("billingAddressCity" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressCity'] = updatedDefaultInfo["billingAddressCity"];
                    const idx = $("select#dataBillingAddressCity").prop('selectedIndex');
                    $("select#dataShippingAddressCity").prop('selectedIndex', idx);
                    // shippingAddressCity.value = updatedDefaultInfo["billingAddressCity"];
                    //  this.suggestionInputRefs['shippingAddressCity']._updateText("Great")
                }
                if ("billingAddressState" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressState'] = updatedDefaultInfo["billingAddressState"];
                    const idx = $("select#dataBillingAddressState").prop('selectedIndex');
                    $("select#dataShippingAddressState").prop('selectedIndex', idx);
                    // shippingAddressState.value = updatedDefaultInfo["billingAddressState"];
                }
                if ("billingAddressPincode" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressPincode'] = updatedDefaultInfo["billingAddressPincode"];
                    shippingAddressPincode.value = updatedDefaultInfo["billingAddressPincode"];
                }
                if ("billingAddressCountry" in updatedDefaultInfo) {
                    updatedDefaultInfo['shippingAddressCountry'] = updatedDefaultInfo["billingAddressCountry"];
                    const idx = $("select#dataBillingAddressCountry").prop('selectedIndex');
                    $("select#dataShippingAddressCountry").prop('selectedIndex', idx);
                    // shippingAddressCountry.value = updatedDefaultInfo["billingAddressCountry"];
                }
                this.setState({ "defaultInfo": updatedDefaultInfo });
            })

        } else {
            this.setState({ shippingBillingAddressSameBool: false })
        }
    };
    handleDefaultInfoChange = (e) => {
        const updatedDefaultInfo = this.state.defaultInfo;
        updatedDefaultInfo[e.target.name] = e.target.value;
        if (e.target.name === "handler") {
            var index = e.target.selectedIndex;
            const h_lid = e.target[index].getAttribute("lid");
            updatedDefaultInfo["handlerId"] = h_lid;
        }
        var checkbox = document.getElementsByName("shippingBillingCheckBox");
        if (checkbox[0].checked) {
            var shippingAddressAddress1 = document.getElementById("shippingAddressAddress1");
            var shippingAddressAddress2 = document.getElementById("shippingAddressAddress2");
            var shippingAddressCity = document.getElementById("shippingAddressCity");
            var shippingAddressState = document.getElementById("shippingAddressState");
            var shippingAddressPincode = document.getElementById("shippingAddressPincode");
            var shippingAddressCountry = document.getElementById("shippingAddressCountry");

            if ("billingAddressAddress1" in updatedDefaultInfo) {
                updatedDefaultInfo['shippingAddressAddress1'] = updatedDefaultInfo["billingAddressAddress1"];
                shippingAddressAddress1.value = updatedDefaultInfo["billingAddressAddress1"];
            }
            if ("billingAddressAddress2" in updatedDefaultInfo) {
                updatedDefaultInfo['shippingAddressAddress2'] = updatedDefaultInfo["billingAddressAddress2"];
                shippingAddressAddress2.value = updatedDefaultInfo["billingAddressAddress2"];
            }
            // if ("billingAddressCity" in updatedDefaultInfo) {
            //     updatedDefaultInfo['shippingAddressCity'] = updatedDefaultInfo["billingAddressCity"];
            //     shippingAddressCity.value = updatedDefaultInfo["billingAddressCity"];
            // }
            // if ("billingAddressState" in updatedDefaultInfo) {
            //     updatedDefaultInfo['shippingAddressState'] = updatedDefaultInfo["billingAddressState"];
            //     shippingAddressState.value = updatedDefaultInfo["billingAddressState"];
            // }
            if ("billingAddressPincode" in updatedDefaultInfo) {
                updatedDefaultInfo['shippingAddressPincode'] = updatedDefaultInfo["billingAddressPincode"];
                shippingAddressPincode.value = updatedDefaultInfo["billingAddressPincode"];
            }
            // if ("billingAddressCountry" in updatedDefaultInfo) {
            //     updatedDefaultInfo['shippingAddressCountry'] = updatedDefaultInfo["billingAddressCountry"];
            //     shippingAddressCountry.value = updatedDefaultInfo["billingAddressCountry"];
            // }
        }
        this.setState({ "defaultInfo": updatedDefaultInfo });
    }
    handleKeyPress = (e) => {
        if (e.keyCode == 13) {
            if ($("#firstname").val() == "") {

                $("#firstname").focus();
                return;
            }
            // if ($("#lastname").val() == "") {
            //     $("#lastname").focus();
            //     return;
            // }
            if ($("#phone").val() == "") {
                $("#phone").focus();
                return;
            }
            if ($("#inputGroupSelect01").val() == "" || $("#inputGroupSelect01").val() == undefined) {
                $("#inputGroupSelect01").focus();
                return;
            }
            $("#postDataButton").focus();
        }
    }

    addNewPhone = (e) => {
        var tmpDefaultInfo = this.state.defaultInfo
        tmpDefaultInfo.phones.push("")
        this.setState({ defaultInfo: { ...tmpDefaultInfo } })
    }
    removeNewPhone = (e) => {
        var index = e.target.getAttribute("itemid");
        var tmpDefaultInfo = this.state.defaultInfo
        var a = tmpDefaultInfo.phones.splice(index, 1)
        this.setState({ defaultInfo: tmpDefaultInfo })
    }
    handlePhonesChange = (e) => {
        var index = e.target.getAttribute("itemid");
        var tmpDefaultInfo = this.state.defaultInfo
        tmpDefaultInfo.phones[index] = e.target.value
        this.setState({ defaultInfo: tmpDefaultInfo })
    }

    getInterests = (query) => {
        const that = this
        axios.get(URL + "data/interests",
            {
                headers: { Authorization: ACCT },
                params: {
                    s: query
                }
            })
            .then(function (response) {
                that.setState({ fetched_interests: response.data.payload })

            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    InterestSection = () => {

        // generate badge

        var badges = []


        for (var i in this.state.added_interests) {
            badges.push(
                <span class="badge badge-pill badge-primary m-2 p-2"><b>{i} &nbsp; <span className="btn-link text-light" style={{ cursor: 'pointer' }} lid={i} onClick={(e) => {
                    const lid = e.target.getAttribute('lid')
                    var tmp_added_interest = this.state.added_interests
                    delete tmp_added_interest[lid]
                    this.setState({ added_interests: tmp_added_interest }, () => {
                    })
                }}><b lid={i}>X</b></span></b></span>
            )
        }

        return (
            <div className="w-100">
                <div>
                    {badges}
                </div>
                <AsyncTypeahead type="text"
                    placeholder="Interests"
                    minLength={1}
                    inputProps={
                        {
                            className: "form-control border-1 small  form-control-lg w-100",
                        }
                    }
                    labelKey={option => `${option.interest}`}
                    filterBy={['interest']}
                    isLoading="false"
                    options={this.state.fetched_interests}
                    maxResults="5"
                    onSearch={(query) => { this.getInterests(query) }}

                    ref={(typeahead) => this.interestSuggestion = typeahead}
                    onKeyDown={(e) => {
                        if (e.keyCode == 13) {
                            var tmp_added_interest = this.state.added_interests
                            tmp_added_interest[e.target.value] = e.target.value
                            this.setState({ added_interests: tmp_added_interest }, () => {
                            })
                        }
                    }}
                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps} className="search_menu" id="interest_search_menu">
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} className="search_item m-0"
                                    onClick={(e) => {
                                        var tmp_added_interest = this.state.added_interests
                                        tmp_added_interest[result.interest] = result.interest
                                        this.setState({ added_interests: tmp_added_interest }, () => {
                                        })
                                    }}>
                                    <div className="w-100 p-2 search_item">
                                        {`${result.interest}`}
                                    </div>
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                />
            </div>
        )
    }

    SuggestionInput = (props) => {

        return (
            <AsyncTypeahead type="text" placeholder={props.placeholder} name={props.name}
                inputProps={
                    {

                        id: props.name,
                        className: "form-control border-1 small  form-control-lg w-100",
                    }
                }

                disabled={props.disabled}
                defaultInputValue={props.defaultInputValue}
                labelKey={option => `${option.name}`}
                filterBy={['name']}
                isLoading="false"
                options={props.data}
                maxResults="10"
                selected={props.selectedOption}
                onSearch={() => {
                    if (props.name === "billingAddressState") {
                        var tmp_states = csc.getStatesOfCountry(this.state.billingSelectedCountryId)
                        this.setState({ billingStates: tmp_states })
                    }
                    if (props.name === "shippingAddressState") {
                        var tmp_states = csc.getStatesOfCountry(this.state.shippingSelectedCountryId)
                        this.setState({ shippingStates: tmp_states })
                    }
                    if (props.name === "billingAddressCity") {
                        var tmp_cities = csc.getCitiesOfState(this.state.billingSelectedStateId)
                        this.setState({ billingCities: tmp_cities })
                    }
                    if (props.name === "shippingAddressCity") {
                        var tmp_cities = csc.getCitiesOfState(this.state.shippingSelectedStateId)
                        this.setState({ shippingCities: tmp_cities })
                    }


                }}
                ref={(typeahead) => this.suggestionInputRefs[props.name] = typeahead}

                onChange={(result) => {
                    // console.log(result)
                    if (result.length === 0) {
                        return
                    }
                    const result_ = result[0]
                    if (props.name === "billingAddressCountry") {
                        $("#billingAddressState").removeAttr('disabled')
                        this.setState({ billingSelectedCountryId: result_.id })
                    }
                    if (props.name === "shippingAddressCountry") {
                        $("#shippingAddressState").removeAttr('disabled')
                        this.setState({ shippingSelectedCountryId: result_.id, shippingAddressCountrySelected: [result_] })
                    }
                    if (props.name === "billingAddressState") {
                        $("#billingAddressCity").removeAttr('disabled')
                        this.setState({ billingSelectedStateId: result_.id })
                    }
                    if (props.name === "shippingAddressState") {
                        $("#shippingAddressCity").removeAttr('disabled')
                        this.setState({ shippingSelectedStateId: result_.id, shippingAddressStateSelected: [result_] })
                    }
                    if (props.name === "shippingAddressCity") {
                        this.setState({ shippingAddressCitySelected: [result_] })
                    }
                    var updatedDefaultInfo = this.state.defaultInfo;
                    updatedDefaultInfo[props.name] = result_.name;
                    this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                        // this.setState({ inputSuggestionBool: true });
                    });
                }}
                renderMenu={(results, menuProps) => (
                    <Menu {...menuProps} className="search_menu" id="search_menu">
                        {results.map((result, index) => (
                            <MenuItem option={result} position={index} name={props.name} className="search_item m-0"
                                onClick={(e) => {

                                }}>
                                <div className="w-100 p-2 search_item">
                                    {`${result.name}`}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            />
        )

    }

    SuggestionSelect = (props) => {
        return (
            <select class="form-control form-control-lg" key={props.id} name={props.name} id={props.id} defaultValue={props.defaultValue} onChange={(e) => {
                const iid = e.target.options[e.target.selectedIndex].getAttribute('iid')

                if (props.name === "billingAddressCountry") {
                    $("select#dataBillingAddressState").prop('selectedIndex', 0);
                    $("select#dataBillingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;

                    if (this.state.shippingBillingAddressSameBool) {

                        $("select#dataShippingAddressState").prop('selectedIndex', 0);
                        $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                        updatedDefaultInfo['shippingAddressCountry'] = e.target.value;

                        var tmp_states = csc.getStatesOfCountry(iid)
                        this.setState({ billingStates: tmp_states, shippingStates: tmp_states, shippingCities: [], billingCities: [], "defaultInfo": updatedDefaultInfo }, () => {
                            const idx = $("select#dataBillingAddressCountry").prop('selectedIndex');
                            $("select#dataShippingAddressCountry").prop('selectedIndex', idx);
                        })
                    } else {

                        updatedDefaultInfo["billingAddressState"] = "";
                        updatedDefaultInfo["billingAddressCity"] = "";
                        var tmp_states = csc.getStatesOfCountry(iid)
                        this.setState({ billingStates: tmp_states, billingCities: [], "defaultInfo": updatedDefaultInfo })
                    }



                }
                if (props.name === "shippingAddressCountry") {


                    $("select#dataShippingAddressState").prop('selectedIndex', 0);
                    $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;


                    updatedDefaultInfo["shippingAddressState"] = "";
                    updatedDefaultInfo["shippingAddressCity"] = "";


                    var tmp_states = csc.getStatesOfCountry(iid)
                    this.setState({ shippingStates: tmp_states, shippingCities: [], "defaultInfo": updatedDefaultInfo })
                }
                if (props.name === "billingAddressState") {

                    $("select#dataBillingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;

                    if (this.state.shippingBillingAddressSameBool) {

                        $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                        updatedDefaultInfo['shippingAddressState'] = e.target.value;

                        var tmp_cities = csc.getCitiesOfState(iid)
                        this.setState({ billingCities: tmp_cities, shippingCities: tmp_cities, "defaultInfo": updatedDefaultInfo }, () => {
                            const idx = $("select#dataBillingAddressState").prop('selectedIndex');
                            $("select#dataShippingAddressState").prop('selectedIndex', idx);
                        })
                    } else {
                        var tmp_cities = csc.getCitiesOfState(iid)
                        updatedDefaultInfo["billingAddressCity"] = "";
                        this.setState({ billingCities: tmp_cities, "defaultInfo": updatedDefaultInfo })
                    }

                }
                if (props.name === "shippingAddressState") {
                    $("select#dataShippingAddressCity").prop('selectedIndex', 0);

                    var updatedDefaultInfo = this.state.defaultInfo;
                    updatedDefaultInfo["shippingAddressCity"] = "";

                    var tmp_cities = csc.getCitiesOfState(iid)
                    this.setState({ shippingCities: tmp_cities })
                }

                if (props.name === "billingAddressCity") {


                    var updatedDefaultInfo = this.state.defaultInfo;
                    if (this.state.shippingBillingAddressSameBool) {
                        updatedDefaultInfo['shippingAddressCity'] = e.target.value;
                        this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                            const idx = $("select#dataBillingAddressCity").prop('selectedIndex');
                            $("select#dataShippingAddressCity").prop('selectedIndex', idx);
                        })
                    }

                }

                var updatedDefaultInfo = this.state.defaultInfo;
                updatedDefaultInfo[props.name] = e.target.value;
                this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                    // this.setState({ inputSuggestionBool: true });
                });

            }}>
                <option selected value="">Select {props.what}</option>
                {
                    props.data.map((item, index) => (
                        <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                    ))
                }
            </select>
        )
    }

    render() {
        if (this.props.show_card === "noshow") {

            return (


                <form class="m-5">
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="firstname" id="firstname" className="firstname form-control border-1 small  form-control-lg" placeholder="Firstname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />

                            <input autoComplete="new-password" autocomplete="off" type="text" name="lastname" id="lastname" className="lastname form-control border-1 small  form-control-lg" placeholder="Lastname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="brand" id="brand" className="form-control border-1 small  form-control-lg" placeholder="Brand" maxlength="50" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="interests" id="interests" className="form-control border-1 small  form-control-lg" placeholder="Interests" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="phone" id="phone" className="form-control border-1 small  form-control-lg" placeholder="Phone" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={this.addNewPhone}>Add</button>
                            </div>
                        </div>
                    </div>

                    {this.state.defaultInfo.phones.map((phone, index) => (
                        <div class="form-group row">
                            <div class="input-group">
                                <input autoComplete="new-password" autocomplete="off" type="text" name={`phones-` + index} id={`phones-` + index} className="form-control border-1 small  form-control-lg" placeholder={`Phone #` + parseInt(index + 1).toString()} maxlength="16" itemid={index} onKeyDown={this.handleKeyPress} onChange={this.handlePhonesChange} value={this.state.defaultInfo.phones[index]} />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" itemid={index} onClick={this.removeNewPhone}>Remove</button>
                                </div>
                            </div>
                        </div>

                    ))}

                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="email" name="email" id="email" className="form-control border-1 small  form-control-lg" placeholder="Email" maxlength="254" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row">
                        <input autoComplete="new-password" autocomplete="off" type="text" name="gstin" id="gstin" className="form-control border-1 small  form-control-lg" placeholder="GSTIN" maxlength="15" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                    </div>
                    <div class="form-group row border">
                        <span class="m-2"><span class="btn-link" data-toggle="collapse" data-target="#interestsData" aria-expanded="false" aria-controls="interestsData" style={{ cursor: 'pointer' }}>Social</span></span>
                        <div class="collapse w-100 p-2" id="interestsData">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="instagram" id="instagram" className="form-control border-1 small  form-control-lg" placeholder="Instagram" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="facebook" id="facebook" className="form-control border-1 small  form-control-lg mt-3" placeholder="Facebook" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="whatsapp" id="whatsapp" className="form-control border-1 small  form-control-lg mt-3" placeholder="WhatsApp" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="website" id="website" className="form-control border-1 small  form-control-lg mt-3" placeholder="Website" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>

                    <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Billing Address</span>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress1" id="billingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress2" id="billingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressCity" id="billingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressState" id="billingAddressState" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>

                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressPincode" id="billingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressCountry" id="billingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Shipping Address</span>
                        <div class="input-group custom-control custom-checkbox ml-2">
                            <input type="checkbox" name="shippingBillingCheckBox" class="custom-control-input" id="customControlAutosizing" onChange={this.handleCheckBoxChange} />
                            <label class="custom-control-label" for="customControlAutosizing">Same as billing address</label>
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress1" id="shippingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress2" id="shippingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressCity" id="shippingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressState" id="shippingAddressState" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>

                        <div class="input-group p-2">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressPincode" id="shippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressCountry" id="shippingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>
                    {/* <div class="form-group row border">
                        <span class="ml-2 mr-2 mb-0 mt-2">Add Customers</span>
                        <div class="input-group p-2">
                            <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.customerSearchChangeHandle} />
                            <div class="input-group-append">
                                <input type="button" class="btn btn-secondary btn-sm" value="Add" />
                            </div>
                        </div>
                        <div class="w-100 p-2">
                            <this.SelectedCustomers />
                        </div>
                        <this.CustomerSearchResults />
                    </div> */}
                    <div class="form-group row">
                        <div class="input-group">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="coupon" id="coupon" className="coupon form-control border-1 small  form-control-lg" placeholder="Coupon" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="dataEntryType">Type</label>
                            </div>
                            <select class="custom-select" name="type" id="dataEntryType" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Data Type</option>
                                <option value="Online Re-Seller">Online Re-Seller</option>
                                <option value="Wholeseller">Wholeseller</option>
                                <option value="Personal Use">Personal Use</option>
                                <option value="Retailer">Retailer</option>
                                <option value="Ecommerce Platform">Ecommerce Platform</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="dataEntryTag">Tag</label>
                            </div>
                            <select class="custom-select" name="tag" id="dataEntryTag" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Data Tag</option>
                                <option value="Placeholder">Placeholder</option>
                                <option value="Doubt">Doubt</option>
                                <option value="Fake">Fake</option>
                                <option value="Fraud">Fraud</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Handler</label>
                            </div>
                            <select class="custom-select" name="handler" id="inputGroupSelect01" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                <option selected value="">Select Handler</option>
                                <Handlers />
                            </select>
                        </div>
                    </div>


                    <div class="form-group row">
                        <button type="button" class="btn btn-primary" id="postDataButton" onClick={this.postData}>
                            <this.SubmitButtonLoadignSpinner />
                            <span>Submit</span>
                        </button>
                    </div>
                    <div class="form-group row">
                        <this.StatusString />
                    </div>
                </form>



            );
        }
        else {
            return (

                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Database Entry</h6>
                    </div>
                    <form class="m-5">
                        <div class="form-group row">
                            <div class="input-group">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="firstname" id="firstname" className="firstname form-control border-1 small  form-control-lg" placeholder="Firstname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />

                                <input autoComplete="new-password" autocomplete="off" type="text" name="lastname" id="lastname" className="lastname form-control border-1 small  form-control-lg" placeholder="Lastname" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="brand" id="brand" className="form-control border-1 small  form-control-lg" placeholder="Brand" maxlength="50" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="form-group row">
                            <this.InterestSection />
                            {/* <input autoComplete="new-password" autocomplete="off" type="text" name="interests" id="interests" className="form-control border-1 small  form-control-lg" placeholder="Interests" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} /> */}
                        </div>
                        <div class="form-group row">
                            <div class="input-group">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="phone" id="phone" className="form-control border-1 small  form-control-lg" placeholder="Phone" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" onClick={this.addNewPhone}>Add</button>
                                </div>
                            </div>
                        </div>

                        {this.state.defaultInfo.phones.map((phone, index) => (
                            <div class="form-group row">
                                <div class="input-group">
                                    <input autoComplete="new-password" autocomplete="off" type="text" name={`phones-` + index} id={`phones-` + index} className="form-control border-1 small  form-control-lg" placeholder={`Phone #` + parseInt(index + 1).toString()} maxlength="16" itemid={index} onKeyDown={this.handleKeyPress} onChange={this.handlePhonesChange} value={this.state.defaultInfo.phones[index]} />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button" itemid={index} onClick={this.removeNewPhone}>Remove</button>
                                    </div>
                                </div>
                            </div>

                        ))}

                        <div class="form-group row">
                            <input autoComplete="new-password" autocomplete="off" type="email" name="email" id="email" className="form-control border-1 small  form-control-lg" placeholder="Email" maxlength="254" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="form-group row">
                            <input autoComplete="new-password" autocomplete="off" type="text" name="gstin" id="gstin" className="form-control border-1 small  form-control-lg" placeholder="GSTIN" maxlength="15" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                        </div>
                        <div class="form-group row border">
                            <span class="m-2"><span class="btn-link" data-toggle="collapse" data-target="#interestsData" aria-expanded="false" aria-controls="interestsData" style={{ cursor: 'pointer' }}>Social</span></span>
                            <div class="collapse w-100 p-2" id="interestsData">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="instagram" id="instagram" className="form-control border-1 small  form-control-lg" placeholder="Instagram" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                <input autoComplete="new-password" autocomplete="off" type="text" name="facebook" id="facebook" className="form-control border-1 small  form-control-lg mt-3" placeholder="Facebook" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                <input autoComplete="new-password" autocomplete="off" type="text" name="whatsapp" id="whatsapp" className="form-control border-1 small  form-control-lg mt-3" placeholder="WhatsApp" maxlength="16" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                <input autoComplete="new-password" autocomplete="off" type="text" name="website" id="website" className="form-control border-1 small  form-control-lg mt-3" placeholder="Website" maxlength="200" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>
                        </div>

                        <div class="form-group row border">
                            <span class="ml-2 mr-2 mb-0 mt-2">Billing Address</span>
                            <div class="input-group p-2">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress1" id="billingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>
                            <div class="input-group p-2">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress2" id="billingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>

                            <div class="input-group p-2">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                        {/* <this.SuggestionInput data={this.state.billingCities} placeholder="City" id="billingAddressCity" name="billingAddressCity" disabled={true} /> */}
                                        <this.SuggestionSelect data={this.state.billingCities} name="billingAddressCity" id="dataBillingAddressCity" what="City" defaultValue="" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                        {/* <this.SuggestionInput data={this.state.billingStates} placeholder="State" id name="billingAddressState" disabled={true} /> */}
                                        <this.SuggestionSelect data={this.state.billingStates} name="billingAddressState" id="dataBillingAddressState" what="State" defaultValue="" />
                                    </div>
                                </div>

                            </div>

                            <div class="input-group p-2">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressPincode" id="billingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                        <this.SuggestionSelect data={this.allCountries} name="billingAddressCountry" id="dataBillingAddressCountry" what="Country" defaultValue="India" />
                                        {/* <this.SuggestionInput data={this.allCountries} placeholder="Country" name="billingAddressCountry" /> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row border">
                            <span class="ml-2 mr-2 mb-0 mt-2">Shipping Address</span>
                            <div class="input-group custom-control custom-checkbox ml-2">
                                <input type="checkbox" name="shippingBillingCheckBox" class="custom-control-input" id="customControlAutosizing" checked={this.state.shippingBillingAddressSameBool} onChange={this.handleCheckBoxChange} />
                                <label class="custom-control-label" for="customControlAutosizing">Same as billing address</label>
                            </div>
                            <div class="input-group p-2">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress1" id="shippingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>
                            <div class="input-group p-2">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress2" id="shippingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" maxlength="100" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>
                            <div class="input-group p-2">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                        {/* {
                                            this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" value={this.state.defaultInfo.billingAddressCity} disabled /> : <this.SuggestionInput data={this.state.shippingCities} placeholder="City" name="shippingAddressCity" disabled={true} selectedOption={this.state.shippingAddressCitySelected} />
                                        } */}
                                        <this.SuggestionSelect data={this.state.shippingCities} name="shippingAddressCity" id="dataShippingAddressCity" what="City" defaultValue="" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                        {/* {
                                            this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" value={this.state.defaultInfo.billingAddressState} disabled /> : <this.SuggestionInput data={this.state.shippingStates} placeholder="State" name="shippingAddressState" disabled={true} selectedOption={this.state.shippingAddressStateSelected} />
                                        } */}
                                        <this.SuggestionSelect data={this.state.shippingStates} name="shippingAddressState" id="dataShippingAddressState" what="State" defaultValue="" />

                                    </div>
                                </div>

                            </div>

                            <div class="input-group p-2">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressPincode" id="shippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" maxlength="20" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                        {/* {
                                            this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" value={this.state.defaultInfo.billingAddressCountry} disabled /> : <this.SuggestionInput data={this.allCountries} placeholder="Country" name="shippingAddressCountry" selectedOption={this.state.shippingAddressCountrySelected} />
                                        } */}
                                        <this.SuggestionSelect data={this.allCountries} name="shippingAddressCountry" id="dataShippingAddressCountry" what="Country" defaultValue="India" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="form-group row">
                            <div class="input-group">
                                <input autoComplete="new-password" autocomplete="off" type="text" name="coupon" id="coupon" className="coupon form-control border-1 small  form-control-lg" placeholder="Coupon" maxlength="30" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="dataEntryType">Type</label>
                                </div>
                                <select class="custom-select" name="type" id="dataEntryType" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                    <option selected value="">Select Data Type</option>
                                    <option value="Online Re-Seller">Online Re-Seller</option>
                                    <option value="Wholeseller">Wholeseller</option>
                                    <option value="Personal Use">Personal Use</option>
                                    <option value="Retailer">Retailer</option>
                                    <option value="Ecommerce Platform">Ecommerce Platform</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="dataEntryTag">Tag</label>
                                </div>
                                <select class="custom-select" name="tag" id="dataEntryTag" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                    <option selected value="">Select Data Tag</option>
                                    <option value="Placeholder">Placeholder</option>
                                    <option value="Doubt">Doubt</option>
                                    <option value="Fake">Fake</option>
                                    <option value="Fraud">Fraud</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="inputGroupSelect01">Handler</label>
                                </div>
                                <select class="custom-select" name="handler" id="inputGroupSelect01" onKeyDown={this.handleKeyPress} onChange={this.handleDefaultInfoChange}>
                                    <option selected value="">Select Handler</option>
                                    <Handlers />
                                </select>
                            </div>
                        </div>


                        <div class="form-group row">
                            <button type="button" class="btn btn-primary m-1" id="postDataButton" onClick={this.postData}>
                                <this.SubmitButtonLoadignSpinner />
                                <span>Submit</span>
                            </button>
                            <button type="button" class="btn btn-primary m-1" id="clearDataButton" onClick={this.resetFields}>
                                <span>Clear</span>
                            </button>
                        </div>
                        <div class="form-group row">
                            <this.StatusString />
                        </div>
                    </form>
                </div>


            );
        }
    }
}




function Db() {
    const blankData = { dataKey: '', dataValue: '' };
    const blankDefaultInfo = { firstname: '', lastname: '' };

    const searchFilterCheckboxValues = {
        // "ID": true,
        "Firstname": true,
        "Lastname": true,
        "Brand": true,
        "Interests": true,
        "Phone": true,
        "Email": true,
        "GSTIN": true,
        "Handler": true,
        "City": true,
        "State": true,
        "Pincode": true,
        "Country": true
    };

    const searchGetAllFieldsCheckboxValues = {
        // "ID": true,
        "Firstname": false,
        "Lastname": false,
        "Brand": false,
        "Interests": false,
        "Phone": false,
        "Email": false,
        "GSTIN": false,
        "Handler": false,
        "City": false,
        "State": false,
        "Pincode": false,
        "Country": false
    };
    const searchGetNotAllFieldsCheckboxValues = {
        // "ID": true,
        "Firstname": false,
        "Lastname": false,
        "Brand": false,
        "Interests": false,
        "Phone": false,
        "Email": false,
        "GSTIN": false,
        "Handler": false,
        "City": false,
        "State": false,
        "Pincode": false,
        "Country": false
    };

    const [searchButtonLoadingSpinner, setSearchButtonLoadingSpinner] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [filterButtonBool, setFilterButtonBool] = useState(false);
    const [searchFilterCheckbox, setSearchFilterCheckbox] = useState(searchFilterCheckboxValues);
    const [searchGetAllFieldsCheckbox, setSearchGetAllFieldsCheckbox] = useState(searchGetAllFieldsCheckboxValues);
    const [searchGetNotAllFieldsCheckbox, setSearchGetNotAllFieldsCheckbox] = useState(searchGetNotAllFieldsCheckboxValues);
    const [editEntryDataLoadingBool, setEditEntryDataLoadingBool] = useState(true);
    const [editEntryData, setEditEntryData] = useState({});
    const [editEntryDataCustomers, setEditEntryDataCustomers] = useState({});
    const [editEntryRatings, setEditEntryRatings] = useState({});
    const [transactDialogLid, setTransactDialogLid] = useState("");
    const [transactDialogBool, setTransactDialogBool] = useState(false);
    const [transactDialogCustomerData, setTransactDialogCustomerData] = useState({});
    const [transactDialogRetailData, setTransactDialogRetailData] = useState({});
    const [transactDialogRetailBool, setTransactDialogRetailBool] = useState(false);


    const [searchPageNumber, setSearchPageNumber] = useState(1);

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    }



    class EditEntryDialog extends React.Component {
        constructor(props) {
            super(props);
            this.state = { "data": props.state.data, "statusString": "", "statusStringBool": false, selectedCustomers: editEntryDataCustomers.data, retrievedCustomerData: {}, ratings: props.ratings, billingSelectedCountryId: "", shippingSelectedCountryId: "", billingSelectedStateId: "", shippingSelectedStateId: "", billingSelectedCityId: "", shippingSelectedCityId: "", inputSuggestionBool: true, billingStates: [], shippingStates: [], billingCities: [], shippingCities: [], shippingAddressCitySelected: null, shippingAddressStateSelected: null, shippingAddressCountrySelected: null, shippingBillingAddressSameBool: false, initDataSetBool: false, fetched_interests: [], added_interests: {} };

            this.handleEditDataFields = this.handleEditDataFields.bind(this);
            this.handleEditDataCheckBoxChange = this.handleEditDataCheckBoxChange.bind(this);
            this.updateEditData = this.updateEditData.bind(this);
            this.deleteEditData = this.deleteEditData.bind(this);
            this.StatusText = this.StatusText.bind(this);
            this.handleRatingChange = this.handleRatingChange.bind(this);
            this.removeNewPhone = this.removeNewPhone.bind(this);
            this.addNewPhone = this.addNewPhone.bind(this);
            this.handlePhonesChange = this.handlePhonesChange.bind(this);
            this.render = this.render.bind(this);
            this.Ratings = this.Ratings.bind(this);

            this.allCountries = csc.getAllCountries();

            if (props.state != undefined && 'data' in props.state) {
                if ('shippingAddressState' in props.state.data && props.state.data['shippingAddressState'].length > 0) {

                }
            }
        }

        Ratings = (e) => {
            if (editEntryDataLoadingBool == false) {
                var init_rating = 0;
                if ("init" in this.state.ratings) {
                    init_rating = this.state.ratings.init
                }
                return (
                    <div class="form-group row" key="0">
                        <label for="editEntryRating" class="col-sm-2 col-form-label">Rating</label>
                        <div class="col-sm-10" key="0">
                            <Rating
                                //id="editEntryRating"
                                // name={item}
                                // initialRating={parseInt((this.state.ratings.to + this.state.ratings.from + this.state.ratings.init) / 3)}
                                initialRating={init_rating}
                                emptySymbol="fa fa-star-o fa-2x"
                                fullSymbol="fa fa-star fa-2x"
                                readonly
                            />
                        </div>
                    </div>

                    // <div class="form-group row" key="0">
                    //     <label for="editEntryRating" class="col-sm-2 col-form-label">Rating</label>
                    //     <div class="col-sm-10" key="0">
                    //         <input type="text" name="firstname" id="editEntryRating" className="form-control border-1 small  form-control-lg" value={this.state.ratings.to} key="0" disabled />
                    //     </div>
                    // </div>
                );
            }
        }
        deleteEditData() {
            const that = this;
            this.setState({ "statusStringBool": false })
            const tmpEditEntryData = this.state['data'];
            axios.delete(URL + "data/" + tmpEditEntryData["_id"]["$oid"]
                , {
                    headers: { Authorization: ACCT }
                })
                .then(function (response) {
                    that.setState({ "statusString": response['data'], "statusStringBool": true })
                })
        }
        StatusText() {
            if (this.state.statusStringBool == true) {
                if (this.state.statusString['_status'] == 'fail') {

                    var issues = [];
                    for (var index in this.state.statusString['payload']) {
                        if (index === "phones") {
                            for (var index_ in this.state.statusString['payload']['phones'][0]) {
                                issues.push(
                                    <div class="row"><span>phone #{index_}: {this.state.statusString['payload']['phones'][0][index_]} </span></div>
                                )
                            }
                        } else {
                            issues.push(
                                <div class="row"><span>{index}: {this.state.statusString['payload'][index]} </span></div>
                            )
                        }
                    }

                    // if (issues.length() ==0) {

                    // }
                    return (
                        <div class="input-group d-flex justify-content-end">
                            <div class="alert alert-danger m-0" role="alert">
                                {issues}
                            </div>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-danger" onClick={(e) => { this.setState({ "statusStringBool": false }) }}>
                                    <span>Close</span>
                                </button>
                            </div>
                        </div>
                    )
                }
                if (this.state.statusString['_status'] == 'success') {
                    return (
                        <div class="input-group d-flex justify-content-end">
                            <div class="alert alert-success m-0" role="alert">
                                <div class="row">
                                    {this.state.statusString["payload"]}
                                </div>
                            </div>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-success" onClick={(e) => { this.setState({ "statusStringBool": false }) }}>
                                    <span>Close</span>
                                </button>
                            </div>
                        </div>
                    )
                }
            }
            return (
                <div>

                </div>
            );
        }
        updateEditData() {
            const that = this;
            this.setState({ "statusStringBool": false })
            var tmpEditEntryData = { ...that.state['data'] };
            const _id = tmpEditEntryData._id.$oid;
            delete tmpEditEntryData._id;
            delete tmpEditEntryData._created;
            delete tmpEditEntryData._updated;


            var interests = []
            for (var i in this.state.added_interests) {
                interests.push(i) 
            }

            tmpEditEntryData["interests"] = interests


            //delete tmpEditEntryData["phone"]

            axios.put(URL + "data", {
                data: tmpEditEntryData,
                _id: _id
            }
                , {
                    headers: { Authorization: ACCT }
                })
                .then(function (response) {
                    that.setState({ "statusString": response['data'], "statusStringBool": true })
                })
        }
        handleEditDataFields(e) {
            var tmpEditEntryData = this.state['data'];
            tmpEditEntryData[e.target.name] = e.target.value
            if (e.target.name === "handler") {
                var index = e.target.selectedIndex;
                const h_lid = e.target[index].getAttribute("lid");
                tmpEditEntryData["handlerId"] = h_lid;
            }
            var checkbox = document.getElementById("editEntryShippingBillingCheckBox");
            if (checkbox.checked) {
                var editEntryShippingAddressAddress1 = document.getElementById("editEntryShippingAddressAddress1");
                var editEntryShippingAddressAddress2 = document.getElementById("editEntryShippingAddressAddress2");
                var editEntryShippingAddressCity = document.getElementById("editEntryShippingAddressCity");
                var editEntryShippingAddressState = document.getElementById("editEntryShippingAddressState");
                var editEntryShippingAddressPincode = document.getElementById("editEntryShippingAddressPincode");
                var editEntryShippingAddressCountry = document.getElementById("editEntryShippingAddressCountry");

                if ("billingAddressAddress1" in tmpEditEntryData) {
                    tmpEditEntryData['shippingAddressAddress1'] = tmpEditEntryData["billingAddressAddress1"];
                    editEntryShippingAddressAddress1.value = tmpEditEntryData["billingAddressAddress1"];
                }
                if ("billingAddressAddress2" in tmpEditEntryData) {
                    tmpEditEntryData['shippingAddressAddress2'] = tmpEditEntryData["billingAddressAddress2"];
                    editEntryShippingAddressAddress2.value = tmpEditEntryData["billingAddressAddress2"];
                }
                if ("billingAddressCity" in tmpEditEntryData) {
                    tmpEditEntryData['shippingAddressCity'] = tmpEditEntryData["billingAddressCity"];
                    // editEntryShippingAddressCity.value = tmpEditEntryData["billingAddressCity"];
                }
                if ("billingAddressState" in tmpEditEntryData) {
                    tmpEditEntryData['shippingAddressState'] = tmpEditEntryData["billingAddressState"];
                    // editEntryShippingAddressState.value = tmpEditEntryData["billingAddressState"];
                }
                if ("billingAddressPincode" in tmpEditEntryData) {
                    tmpEditEntryData['shippingAddressPincode'] = tmpEditEntryData["billingAddressPincode"];
                    editEntryShippingAddressPincode.value = tmpEditEntryData["billingAddressPincode"];
                }
                if ("billingAddressCountry" in tmpEditEntryData) {
                    tmpEditEntryData['shippingAddressCountry'] = tmpEditEntryData["billingAddressCountry"];
                    // editEntryShippingAddressCountry.value = tmpEditEntryData["billingAddressCountry"];
                }
            }
            // setEditEntryData(this.state);
            this.setState({ "data": tmpEditEntryData });
        }

        handleEditDataCheckBoxChange(e) {

            if (e.target.checked) {
                this.setState({ shippingBillingAddressSameBool: true, shippingStates: this.state.billingStates, shippingCities: this.state.billingCities }, () => {

                    const tmpEditEntryData = this.state['data'];
                    var editEntryShippingAddressAddress1 = document.getElementById("editEntryShippingAddressAddress1");
                    var editEntryShippingAddressAddress2 = document.getElementById("editEntryShippingAddressAddress2");
                    var editEntryShippingAddressCity = document.getElementById("editEntryShippingAddressCity");
                    var editEntryShippingAddressState = document.getElementById("editEntryShippingAddressState");
                    var editEntryShippingAddressPincode = document.getElementById("editEntryShippingAddressPincode");
                    var editEntryShippingAddressCountry = document.getElementById("editEntryShippingAddressCountry");

                    if ("billingAddressAddress1" in tmpEditEntryData) {
                        tmpEditEntryData['shippingAddressAddress1'] = tmpEditEntryData["billingAddressAddress1"];
                        editEntryShippingAddressAddress1.value = tmpEditEntryData["billingAddressAddress1"];
                    }
                    if ("billingAddressAddress2" in tmpEditEntryData) {
                        tmpEditEntryData['shippingAddressAddress2'] = tmpEditEntryData["billingAddressAddress2"];
                        editEntryShippingAddressAddress2.value = tmpEditEntryData["billingAddressAddress2"];
                    }
                    if ("billingAddressCity" in tmpEditEntryData) {
                        tmpEditEntryData['shippingAddressCity'] = tmpEditEntryData["billingAddressCity"];
                        const idx = $("select#editDataBillingAddressCity").prop('selectedIndex');
                        $("select#editDataShippingAddressCity").prop('selectedIndex', idx);
                        // shippingAddressCity.value = updatedDefaultInfo["billingAddressCity"];
                        //  this.suggestionInputRefs['shippingAddressCity']._updateText("Great")
                    }
                    if ("billingAddressState" in tmpEditEntryData) {
                        tmpEditEntryData['shippingAddressState'] = tmpEditEntryData["billingAddressState"];
                        const idx = $("select#editDataBillingAddressState").prop('selectedIndex');
                        $("select#editDataShippingAddressState").prop('selectedIndex', idx);
                        // shippingAddressState.value = updatedDefaultInfo["billingAddressState"];
                    }
                    if ("billingAddressPincode" in tmpEditEntryData) {
                        tmpEditEntryData['shippingAddressPincode'] = tmpEditEntryData["billingAddressPincode"];
                        editEntryShippingAddressPincode.value = tmpEditEntryData["billingAddressPincode"];
                    }
                    if ("billingAddressCountry" in tmpEditEntryData) {
                        tmpEditEntryData['shippingAddressCountry'] = tmpEditEntryData["billingAddressCountry"];
                        const idx = $("select#editDataBillingAddressCountry").prop('selectedIndex');
                        $("select#editDataShippingAddressCountry").prop('selectedIndex', idx);
                        // shippingAddressCountry.value = updatedDefaultInfo["billingAddressCountry"];
                    }

                    this.setState({ "data": tmpEditEntryData });

                })
            } else {
                const tmpEditEntryData = this.state['data'];
                if (this.state.shippingAddressCitySelected != null) {
                    tmpEditEntryData['shippingAddressCity'] = this.state.shippingAddressCitySelected[0].name;
                } else {
                    tmpEditEntryData['shippingAddressCity'] = ""
                }
                if (this.state.shippingAddressStateSelected != null) {
                    tmpEditEntryData['shippingAddressState'] = this.state.shippingAddressStateSelected[0].name;
                } else {
                    tmpEditEntryData['shippingAddressState'] = ""
                }
                if (this.state.shippingAddressCountrySelected != null) {
                    tmpEditEntryData['shippingAddressCountry'] = this.state.shippingAddressCountrySelected[0].name;
                } else {
                    tmpEditEntryData['shippingAddressCountry'] = ""
                }
                this.setState({ shippingBillingAddressSameBool: false, "data": tmpEditEntryData })
            }
        };
        handleRatingChange = (rate) => {

        }
        handlePhonesChange = (e) => {
            var index = e.target.getAttribute("itemid");
            var tmpDefaultInfo = this.state.data
            tmpDefaultInfo.phones[index] = e.target.value
            this.setState({ data: tmpDefaultInfo })
        }
        removeNewPhone = (e) => {
            var index = e.target.getAttribute("itemid");
            var tmpDefaultInfo = this.state.data
            var a = tmpDefaultInfo.phones.splice(index, 1)
            this.setState({ data: tmpDefaultInfo })
        }
        addNewPhone = (e) => {
            var tmpDefaultInfo = this.state.data
            tmpDefaultInfo["phones"].push("")
            this.setState({ data: { ...tmpDefaultInfo } })
        }
        SuggestionInput = (props) => {

            return (
                <AsyncTypeahead type="text" placeholder={props.placeholder} name={props.name}
                    inputProps={
                        {
                            id: 'Edit' + props.name,
                            className: "form-control border-1 small  form-control-lg w-100"

                        }
                    }
                    disabled={props.disabled}
                    defaultInputValue={props.defaultValue}
                    labelKey={option => `${option.name}`}
                    filterBy={['name']}
                    isLoading="false"
                    options={props.data}
                    maxResults="10"
                    selected={props.selectedOption}
                    onSearch={() => {
                        if (props.name === "billingAddressState") {
                            var tmp_states = csc.getStatesOfCountry(this.state.billingSelectedCountryId)
                            this.setState({ billingStates: tmp_states })
                        }
                        if (props.name === "shippingAddressState") {
                            var tmp_states = csc.getStatesOfCountry(this.state.shippingSelectedCountryId)
                            this.setState({ shippingStates: tmp_states })
                        }
                        if (props.name === "billingAddressCity") {
                            var tmp_cities = csc.getCitiesOfState(this.state.billingSelectedStateId)
                            this.setState({ billingCities: tmp_cities })
                        }
                        if (props.name === "shippingAddressCity") {
                            var tmp_cities = csc.getCitiesOfState(this.state.shippingSelectedStateId)
                            this.setState({ shippingCities: tmp_cities })
                        }


                    }}
                    ref={(typeahead) => this.typeahead_customer = typeahead}
                    onChange={(result) => {
                        if (result.length === 0) {
                            return
                        }
                        const result_ = result[0]
                        if (props.name === "billingAddressCountry") {
                            $("#EditbillingAddressState").removeAttr('disabled')
                            this.setState({ billingSelectedCountryId: result_.id })
                        }
                        if (props.name === "shippingAddressCountry") {
                            $("#EditshippingAddressState").removeAttr('disabled')
                            this.setState({ shippingSelectedCountryId: result_.id, shippingAddressCountrySelected: [result_] })
                        }
                        if (props.name === "billingAddressState") {
                            $("#EditbillingAddressCity").removeAttr('disabled')
                            this.setState({ billingSelectedStateId: result_.id })
                        }
                        if (props.name === "shippingAddressState") {
                            $("#EditshippingAddressCity").removeAttr('disabled')
                            this.setState({ shippingSelectedStateId: result_.id, shippingAddressStateSelected: [result_] })
                        }

                        if (props.name === "shippingAddressCity") {
                            this.setState({ shippingAddressCitySelected: [result_] })
                        }

                        var tmpEditEntryData = this.state['data'];
                        tmpEditEntryData[props.name] = result_.name
                        this.setState({ "data": tmpEditEntryData });

                    }}
                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps} className="search_menu" id="search_menu">
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} name={props.name} className="search_item m-0"
                                    onClick={(e) => {

                                    }}>
                                    <div className="w-100 p-2 search_item">
                                        {`${result.name}`}
                                    </div>
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                />
            )

        }

        SuggestionSelect = (props) => {
            return (
                <select class="form-control form-control-lg" key={props.id} name={props.name} id={props.id} defaultValue={props.defaultValue} onChange={(e) => {
                    const iid = e.target.options[e.target.selectedIndex].getAttribute('iid')

                    if (props.name === "billingAddressCountry") {
                        $("select#editDataBillingAddressState").prop('selectedIndex', 0);
                        $("select#editDataBillingAddressCity").prop('selectedIndex', 0);

                        var updatedDefaultInfo = this.state['data'];

                        if (this.state.shippingBillingAddressSameBool) {

                            $("select#editDataShippingAddressState").prop('selectedIndex', 0);
                            $("select#editDataShippingAddressCity").prop('selectedIndex', 0);

                            updatedDefaultInfo['shippingAddressCountry'] = e.target.value;

                            var tmp_states = csc.getStatesOfCountry(iid)
                            this.setState({ billingStates: tmp_states, shippingStates: tmp_states, shippingCities: [], billingCities: [], "defaultInfo": updatedDefaultInfo }, () => {
                                const idx = $("select#editDataBillingAddressCountry").prop('selectedIndex');
                                $("select#editDataShippingAddressCountry").prop('selectedIndex', idx);
                            })
                        } else {

                            updatedDefaultInfo["billingAddressState"] = "";
                            updatedDefaultInfo["billingAddressCity"] = "";
                            var tmp_states = csc.getStatesOfCountry(iid)
                            this.setState({ billingStates: tmp_states, billingCities: [], "defaultInfo": updatedDefaultInfo })
                        }



                    }
                    if (props.name === "shippingAddressCountry") {


                        $("select#editDataShippingAddressState").prop('selectedIndex', 0);
                        $("select#editDataShippingAddressCity").prop('selectedIndex', 0);

                        var updatedDefaultInfo = this.state['data'];


                        updatedDefaultInfo["shippingAddressState"] = "";
                        updatedDefaultInfo["shippingAddressCity"] = "";


                        var tmp_states = csc.getStatesOfCountry(iid)
                        this.setState({ shippingStates: tmp_states, shippingCities: [], "defaultInfo": updatedDefaultInfo })
                    }
                    if (props.name === "billingAddressState") {

                        $("select#editDataBillingAddressCity").prop('selectedIndex', 0);

                        var updatedDefaultInfo = this.state['data'];

                        if (this.state.shippingBillingAddressSameBool) {

                            $("select#editDataShippingAddressCity").prop('selectedIndex', 0);

                            updatedDefaultInfo['shippingAddressState'] = e.target.value;

                            var tmp_cities = csc.getCitiesOfState(iid)
                            this.setState({ billingCities: tmp_cities, shippingCities: tmp_cities, "defaultInfo": updatedDefaultInfo }, () => {
                                const idx = $("select#editDataBillingAddressState").prop('selectedIndex');
                                $("select#editDataShippingAddressState").prop('selectedIndex', idx);
                            })
                        } else {
                            var tmp_cities = csc.getCitiesOfState(iid)
                            updatedDefaultInfo["billingAddressCity"] = "";
                            this.setState({ billingCities: tmp_cities, "defaultInfo": updatedDefaultInfo })
                        }

                    }
                    if (props.name === "shippingAddressState") {
                        $("select#editDataShippingAddressCity").prop('selectedIndex', 0);

                        var updatedDefaultInfo = this.state['data'];
                        updatedDefaultInfo["shippingAddressCity"] = "";

                        var tmp_cities = csc.getCitiesOfState(iid)
                        this.setState({ shippingCities: tmp_cities })
                    }

                    if (props.name === "billingAddressCity") {


                        var updatedDefaultInfo = this.state['data'];
                        if (this.state.shippingBillingAddressSameBool) {
                            updatedDefaultInfo['shippingAddressCity'] = e.target.value;
                            this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                                const idx = $("select#editDataBillingAddressCity").prop('selectedIndex');
                                $("select#editDataShippingAddressCity").prop('selectedIndex', idx);
                            })
                        }

                    }

                    var updatedDefaultInfo = this.state['data'];
                    updatedDefaultInfo[props.name] = e.target.value;
                    this.setState({ "defaultInfo": updatedDefaultInfo }, () => {
                        // this.setState({ inputSuggestionBool: true });
                    });

                }}>
                    <option value="">Select {props.what}</option>
                    {
                        props.data.map((item, index) => (
                            <option value={item["name"]} iid={item["id"]}>{item["name"]}</option>
                        ))
                    }
                </select>
            )
        }

        getInterests = (query) => {
            const that = this
            axios.get(URL + "data/interests",
                {
                    headers: { Authorization: ACCT },
                    params: {
                        s: query
                    }
                })
                .then(function (response) {
                    that.setState({ fetched_interests: response.data.payload })

                })
                .catch(function (error) {
                    // console.log(error)
                })
        }

        InterestSection = () => {

            // generate badge

            var badges = []


            for (var i in this.state.added_interests) {
                badges.push(
                    <span class="badge badge-pill badge-primary m-2 p-2"><b>{i} &nbsp; <span className="btn-link text-light" style={{ cursor: 'pointer' }} lid={i} onClick={(e) => {
                        const lid = e.target.getAttribute('lid')
                        var tmp_added_interest = this.state.added_interests
                        delete tmp_added_interest[lid]
                        this.setState({ added_interests: tmp_added_interest }, () => {
                        })
                    }}><b lid={i}>X</b></span></b></span>
                )
            }

            return (
                <div className="w-100">
                    <div>
                        {badges}
                    </div>
                    <AsyncTypeahead type="text"
                        // placeholder="Interests"

                        minLength={1}
                        inputProps={
                            {
                                className: "form-control border-1 small  form-control-lg w-100",
                                maxLength: 25
                            }
                        }
                        labelKey={option => `${option.interest}`}
                        filterBy={['interest']}
                        // isLoading="false"
                        options={this.state.fetched_interests}
                        maxResults="5"
                        onSearch={(query) => { this.getInterests(query) }}

                        ref={(typeahead) => this.interestSuggestion = typeahead}
                        onKeyDown={(e) => {
                            if (e.keyCode == 13) {
                                var tmp_added_interest = this.state.added_interests
                                tmp_added_interest[e.target.value] = e.target.value
                                this.setState({ added_interests: tmp_added_interest }, () => {
                                })
                            }
                        }}
                        renderMenu={(results, menuProps) => (
                            <Menu {...menuProps} className="search_menu" id="interest_search_menu">
                                {results.map((result, index) => (
                                    <MenuItem option={result} position={index} className="search_item m-0"
                                        onClick={(e) => {
                                            var tmp_added_interest = this.state.added_interests
                                            tmp_added_interest[result.interest] = result.interest
                                            this.setState({ added_interests: tmp_added_interest }, () => {
                                            })
                                        }}>
                                        <div className="w-100 p-2 search_item">
                                            {`${result.interest}`}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Menu>
                        )}
                    />
                </div>
            )
        }



        render(e) {
            if (editEntryDataLoadingBool == true) {
                return (

                    <div class="modal-body">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>


                );
            }

            if (editEntryDataLoadingBool == false && this.state.initDataSetBool == false) {
                if (this.state.initDataSetBool == false) {
                    var billingCountryId = getCountryId(this.state.data["billingAddressCountry"])["id"]
                    var shippingCountryId = getCountryId(this.state.data["shippingAddressCountry"])["id"]
                    var billingStateId = getStateId(this.state.data["billingAddressState"])["id"]
                    var shippingStateId = getStateId(this.state.data["shippingAddressState"])["id"]

                    var tmp_billingStates = csc.getStatesOfCountry(billingCountryId)
                    var tmp_shippingStates = csc.getStatesOfCountry(shippingCountryId)
                    var tmp_billingCities = csc.getCitiesOfState(billingStateId)
                    var tmp_shippingCities = csc.getCitiesOfState(shippingStateId)

                    var interest_array = this.state.data["interests"]
                    var tmp_interests = {}
                    for (var i in interest_array) {
                        tmp_interests[interest_array[i]] = interest_array[i]
                    }

                    this.setState({ billingStates: tmp_billingStates, shippingStates: tmp_shippingStates, billingCities: tmp_billingCities, shippingCities: tmp_shippingCities, initDataSetBool: true, added_interests: tmp_interests })
                }
            }

            if (editEntryDataLoadingBool == false && this.state.initDataSetBool == true) {
                var phones = []


                if ("phones" in this.state['data']) {
                    const that = this;
                    this.state.data.phones.forEach(function (v, i) {
                        phones.push(
                            <div class="form-group row">
                                <label for={`editEntryPhones-` + i} class="col-sm-2 col-form-label">Phone #{parseInt(i + 1).toString()}</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name={`phones-` + i} id={`editEntryPhones-` + i} className="form-control border-1 small  form-control-lg" maxlength="16" itemid={i} onChange={that.handlePhonesChange} value={v} />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" type="button" itemid={i} onClick={that.removeNewPhone}>Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        );
                    })

                } else {
                    var tmpDefaultInfo = this.state.data
                    tmpDefaultInfo["phones"] = []
                    this.setState({ data: { ...tmpDefaultInfo } })
                }

                // get state id country id and cities


                ///


                return (
                    <div>
                        <div class="modal-body">
                            <form class="ml-3 mr-3 mb-0 mt-0" key="editDataForm">

                                <this.Ratings />
                                <div class="form-group row" key="0">
                                    <label for="editEntryFirstname" class="col-sm-2 col-form-label">Firstname</label>
                                    <div class="col-sm-10" key="0">
                                        <input type="text" name="firstname" id="editEntryFirstname" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['firstname']} key="0" />
                                    </div>
                                </div>
                                <div class="form-group row" key="0">
                                    <label for="editEntryLastname" class="col-sm-2 col-form-label">Lastname</label>
                                    <div class="col-sm-10" key="0">
                                        <input type="text" name="lastname" id="editEntryLastname" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['lastname']} key="0" />
                                    </div>
                                </div>
                                <div class="form-group row" key="0">
                                    <label for="editEntryBrand" class="col-sm-2 col-form-label">Brand</label>
                                    <div class="col-sm-10" key="0">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="brand" id="editEntryBrand" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['brand']} key="0" />
                                    </div>
                                </div>
                                <div class="form-group row" key="0">
                                    <label for="editEntryInterests" class="col-sm-2 col-form-label">Interests</label>
                                    <div class="col-sm-10" key="0">
                                        <this.InterestSection />
                                        {/* <input autoComplete="new-password" autocomplete="off" type="text" name="interests" id="editEntryInterests" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['interests']} key="0" /> */}
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <label for="editEntryPhone" class="col-sm-2 col-form-label">Phone</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <input autoComplete="new-password" autocomplete="off" type="text" name="phone" id="editEntryPhone" className="form-control border-1 small  form-control-lg" maxlength="16" onChange={this.handleEditDataFields} value={this.state['data']['phone']} key="editEntryPhone" />
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" type="button" onClick={this.addNewPhone}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {phones}
                                <div class="form-group row">
                                    <label for="editEntryEmail" class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-10">
                                        <input autoComplete="new-password" autocomplete="off" type="email" name="email" id="editEntryEmail" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['email']} key="editEntryEmail" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="editEntryGstin" class="col-sm-2 col-form-label">GSTIN</label>
                                    <div class="col-sm-10">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="gstin" id="editEntryGstin" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['gstin']} key="editEntryGstin" />
                                    </div>
                                </div>



                                <div class="form-group row">
                                    <label for="editEntryInstagram" class="col-sm-2 col-form-label">Instagram</label>
                                    <div class="col-sm-10">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="instagram" id="editEntryInstagram" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['instagram']} key="editEntryInstagram" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="editEntryFacebook" class="col-sm-2 col-form-label">Facebook</label>
                                    <div class="col-sm-10">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="facebook" id="editEntryFacebook" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['facebook']} key="editEntryFacebook" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="editEntryWhatsapp" class="col-sm-2 col-form-label">WhatsApp</label>
                                    <div class="col-sm-10">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="whatsapp" id="editEntryWhatsapp" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['whatsapp']} key="editEntryWhatsapp" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="editEntryWebsite" class="col-sm-2 col-form-label">Website</label>
                                    <div class="col-sm-10">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="website" id="editEntryWebsite" className="form-control border-1 small  form-control-lg" onChange={this.handleEditDataFields} value={this.state['data']['website']} key="editEntryWebsite" />
                                    </div>
                                </div>


                                <div class="form-group row border">
                                    <span class="ml-2 mr-2 mb-0 mt-2">Billing Address</span>
                                    <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress1" id="editEntryBillingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressAddress1']} key="editEntryBillingAddressAddress1" />
                                    </div>
                                    <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressAddress2" id="editEntryBillingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressAddress2']} key="editEntryBillingAddressAddress2" />
                                    </div>
                                    <div class="input-group p-2">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                                {/* <this.SuggestionInput data={this.state.billingCities} placeholder="City" name="billingAddressCity" disabled={true} defaultValue={this.state['data']['billingAddressCity']} /> */}

                                                <this.SuggestionSelect data={this.state.billingCities} name="billingAddressCity" id="editDataBillingAddressCity" what="City" defaultValue={this.state['data']['billingAddressCity']} />


                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                                {/* <this.SuggestionInput data={this.state.billingStates} placeholder="State" name="billingAddressState" disabled={true} defaultValue={this.state['data']['billingAddressState']} /> */}

                                                <this.SuggestionSelect data={this.state.billingStates} name="billingAddressState" id="editDataBillingAddressState" what="State" defaultValue={this.state['data']['billingAddressState']} />
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div class="input-group p-2">
                                        
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressCity" id="editEntryBillingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="City" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressCity']} key="editEntryBillingAddressCity" />
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressState" id="editEntryBillingAddressState" className="form-control border-1 small  form-control-lg" placeholder="State" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressState']} key="editEntryBillingAddressState" />
                                    </div> */}
                                    <div class="input-group p-2">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                                <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressPincode" id="editEntryBillingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressPincode']} key="editEntryBillingAddressPincode" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">
                                                {/* <this.SuggestionInput data={this.allCountries} placeholder="Country" name="billingAddressCountry" defaultValue={this.state['data']['billingAddressCountry']} /> */}

                                                <this.SuggestionSelect data={this.allCountries} name="billingAddressCountry" id="editDataBillingAddressCountry" what="Country" defaultValue={this.state['data']['billingAddressCountry']} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressPincode" id="editEntryBillingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressPincode']} key="editEntryBillingAddressPincode" />
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="billingAddressCountry" id="editEntryBillingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="Country" onChange={this.handleEditDataFields} value={this.state['data']['billingAddressCountry']} key="editEntryBillingAddressCountry" />
                                    </div> */}
                                </div>
                                <div class="form-group row border">
                                    <span class="ml-2 mr-2 mb-0 mt-2">Shipping Address</span>
                                    <div class="input-group custom-control custom-checkbox ml-2">
                                        <input type="checkbox" name="editEntryShippingBillingCheckBox" class="custom-control-input" id="editEntryShippingBillingCheckBox" onChange={this.handleEditDataCheckBoxChange} />
                                        <label class="custom-control-label" for="editEntryShippingBillingCheckBox">Same as billing address</label>
                                    </div>
                                    <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress1" id="editEntryShippingAddressAddress1" className="form-control border-1 small  form-control-lg" placeholder="Address Line 1" onChange={this.handleEditDataFields} value={this.state['data']['shippingAddressAddress1']} key="editEntryShippingAddressAddress1" />
                                    </div>
                                    <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressAddress2" id="editEntryShippingAddressAddress2" className="form-control border-1 small  form-control-lg" placeholder="Address Line 2" onChange={this.handleEditDataFields} value={this.state['data']['shippingAddressAddress2']} key="editEntryShippingAddressAddress2" />
                                    </div>

                                    <div class="input-group p-2">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                                {/* {
                                                    this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="City" maxlength="20" value={this.state.data.billingAddressCity} disabled /> : <this.SuggestionInput data={this.state.shippingCities} placeholder="City" name="shippingAddressCity" disabled={true}
                                                        defaultValue={this.state['data']['shippingAddressCity']}

                                                        selectedOption={this.state.shippingAddressCitySelected}
                                                    />
                                                } */}

                                                <this.SuggestionSelect data={this.state.shippingCities} name="shippingAddressCity" id="editDataShippingAddressCity" what="City" defaultValue={this.state['data']['shippingAddressCity']} />

                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                                {/* {
                                                    this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="State" maxlength="20" value={this.state.data.billingAddressState} disabled /> : <this.SuggestionInput data={this.state.shippingStates} placeholder="State" name="shippingAddressState" disabled={true} defaultValue={this.state['data']['shippingAddressState']} selectedOption={this.state.shippingAddressStateSelected} />
                                                } */}

                                                <this.SuggestionSelect data={this.state.shippingStates} name="shippingAddressState" id="editDataShippingAddressState" what="State" defaultValue={this.state['data']['shippingAddressState']} />

                                            </div>
                                        </div>

                                    </div>

                                    <div class="input-group p-2">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-inline p-0">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                                <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressPincode" id="editEntryShippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" onChange={this.handleEditDataFields} value={this.state['data']['shippingAddressPincode']} key="editEntryShippingAddressPincode" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-1 pr-1">

                                                {/* {
                                                    this.state.shippingBillingAddressSameBool ? <input autoComplete="new-password" autocomplete="off" type="text" className="form-control border-1 small  form-control-lg" placeholder="Country" maxlength="20" value={this.state.data.shippingAddressCountry} disabled /> : <this.SuggestionInput data={this.allCountries} placeholder="Country" name="shippingAddressCountry" defaultValue={this.state['data']['shippingAddressCountry']} selectedOption={this.state.shippingAddressCountrySelected} />
                                                } */}

                                                <this.SuggestionSelect data={this.allCountries} name="shippingAddressCountry" id="editDataShippingAddressCountry" what="Country" defaultValue={this.state['data']['shippingAddressCountry']} />


                                            </div>
                                        </div>
                                    </div>


                                    {/* <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressCity" id="editEntryShippingAddressCity" className="form-control border-1 small  form-control-lg" placeholder="City" onChange={this.handleEditDataFields} value={this.state['data']['shippingAddressCity']} key="editEntryShippingAddressCity" />
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressState" id="editEntryShippingAddressState" className="form-control border-1 small  form-control-lg" placeholder="State" onChange={this.handleEditDataFields}
                                            value={this.state['data']['shippingAddressState']} key="editEntryShippingAddressState" />
                                    </div>

                                    <div class="input-group p-2">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressPincode" id="editEntryShippingAddressPincode" className="form-control border-1 small  form-control-lg" placeholder="Pincode" onChange={this.handleEditDataFields} value={this.state['data']['shippingAddressPincode']} key="editEntryShippingAddressPincode" />
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="shippingAddressCountry" id="editEntryShippingAddressCountry" className="form-control border-1 small  form-control-lg" placeholder="Country" onChange={this.handleEditDataFields} value={this.state['data']['shippingAddressCountry']} key="editEntryShippingAddressCountry" />
                                    </div> */}
                                </div>
                                {/* <div class="form-group row border">
                                    <span class="ml-2 mr-2 mb-0 mt-2">Add Customers</span>
                                    <div class="input-group p-2">
                                        <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.customerSearchChangeHandle} />

                                    </div>
                                    <div class="w-100 p-2">
                                        <this.SelectedCustomers />
                                    </div>
                                    <this.CustomerSearchResults />
                                </div> */}
                                <div class="form-group row">
                                    <div class="input-group">
                                        <input autoComplete="new-password" autocomplete="off" type="text" name="coupon" id="editDataEntryCoupon" className="coupon form-control border-1 small  form-control-lg" placeholder="Coupon" maxlength="30" value={this.state['data']['coupon']} onKeyDown={this.handleKeyPress} onChange={this.handleEditDataFields} />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="editDataEntryType">Type</label>
                                        </div>
                                        <select class="custom-select" name="type" id="editDataEntryType" defaultValue={this.state['data']['type']} onKeyDown={this.handleKeyPress} onChange={this.handleEditDataFields}>
                                            <option selected value="">Select Data Type</option>
                                            <option value="Online Re-Seller">Online Re-Seller</option>
                                            <option value="Wholeseller">Wholeseller</option>
                                            <option value="Personal Use">Personal Use</option>
                                            <option value="Retailer">Retailer</option>
                                            <option value="Ecommerce Platform">Ecommerce Platform</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="editDataEntryTag">Tag</label>
                                        </div>
                                        <select class="custom-select" name="tag" id="editDataEntryTag" defaultValue={this.state['data']['tag']} onKeyDown={this.handleKeyPress} onChange={this.handleEditDataFields}>
                                            <option selected value="">Select Data Tag</option>
                                            <option value="Placeholder">Placeholder</option>
                                            <option value="Doubt">Doubt</option>
                                            <option value="Fake">Fake</option>
                                            <option value="Fraud">Fraud</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Handler</label>
                                        </div>
                                        <select class="custom-select" name="handler" id="inputGroupSelect01" defaultValue={this.state['data']['handler']} onKeyDown={this.handleKeyPress} onChange={this.handleEditDataFields}>
                                            <option value="">Select Handler</option>
                                            <Handlers handler={this.state['data']['handler']} />
                                        </select>
                                    </div>
                                </div>


                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" onClick={this.deleteEditData}>Delete Entry</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={this.updateEditData}>
                                Update
                            </button>
                        </div>
                        <div class="row m-3 d-flex justify-content-center">
                            <this.StatusText />
                        </div>
                    </div>
                );
            }

            return ("")
        }
    }


    // class MoreInfoDialog extends React.Component {

    const MoreInfoDialog = (props) => {

        if (editEntryDataLoadingBool == true) {
            return (

                <div class="modal-body">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>


            );
        }
        if (editEntryDataLoadingBool == false) {
            const data = editEntryData.data;
            // console.log(data);
            var items = [];
            const _id = data['_id'];
            var phones = [];
            if ("phones" in data) {
                for (var i = 0; i < data["phones"].length; i++) {
                    if (i == data["phones"].length - 1) {
                        phones.push(data["phones"][i])
                    } else {
                        phones.push(data["phones"][i] + ", ")
                    }
                }
            }
            items.push(<div class="m-2"><label><b>ID: </b></label>&nbsp;{_id['$oid']}</div>)
            items.push(<div class="m-2"><label><b>REF ID: </b></label>&nbsp;{data['ref_id']}</div>)

            items.push(<div class="m-2"><label><b>First Name: </b></label>&nbsp;{data['firstname']}</div>)
            items.push(<div class="m-2"><label><b>Last Name: </b></label>&nbsp;{data['lastname']}</div>)
            items.push(<div class="m-2"><label><b>Brand: </b></label>&nbsp;{data['brand']}</div>)
            items.push(<div class="m-2"><label><b>Phone: </b></label>&nbsp;{data['phone']}</div>)
            items.push(<div class="m-2"><label><b>Phones (NP): </b></label>&nbsp;{phones}</div>)
            items.push(<div class="m-2"><label><b>Email: </b></label>&nbsp;{data['email']}</div>)
            items.push(<div class="m-2"><label><b>GSTIN: </b></label>&nbsp;{data['gstin']}</div>)
            items.push(<div class="m-2"><label><b>Instagram: </b></label>&nbsp;{data['instagram']}</div>)
            items.push(<div class="m-2"><label><b>Facebook: </b></label>&nbsp;{data['facebook']}</div>)
            items.push(<div class="m-2"><label><b>WhatsApp: </b></label>&nbsp;{data['whatsapp']}</div>)
            items.push(<div class="m-2"><label><b>Website: </b></label>&nbsp;{data['website']}</div>)
            items.push(<div class="m-2"><label><b>Handler: </b></label>&nbsp;{data['handler']}</div>)
            items.push(<div class="m-2"><label><b>Tag: </b></label>&nbsp;{data['tag']}</div>)

            items.push(<div class="m-2"><label><b>Billing Address Line 1: </b></label>&nbsp;{data['billingAddressAddress1']}</div>)
            items.push(<div class="m-2"><label><b>Billing Address Line 2: </b></label>&nbsp;{data['billingAddressAddress2']}</div>)
            items.push(<div class="m-2"><label><b>Billing Address City: </b></label>&nbsp;{data['billingAddressCity']}</div>)
            items.push(<div class="m-2"><label><b>Billing Address Sate: </b></label>&nbsp;{data['billingAddressState']}</div>)
            items.push(<div class="m-2"><label><b>Billing Address Pincode: </b></label>&nbsp;{data['billingAddressPincode']}</div>)
            items.push(<div class="m-2"><label><b>Billing Address Country: </b></label>&nbsp;{data['billingAddressCountry']}</div>)

            items.push(<div class="m-2"><label><b>Shipping Address Line 1: </b></label>&nbsp;{data['shippingAddressAddress1']}</div>)
            items.push(<div class="m-2"><label><b>Shipping Address Line 2: </b></label>&nbsp;{data['shippingAddressAddress2']}</div>)
            items.push(<div class="m-2"><label><b>Shipping Address City: </b></label>&nbsp;{data['shippingAddressCity']}</div>)
            items.push(<div class="m-2"><label><b>Shipping Address Sate: </b></label>&nbsp;{data['shippingAddressState']}</div>)
            items.push(<div class="m-2"><label><b>Shipping Address Pincode: </b></label>&nbsp;{data['shippingAddressPincode']}</div>)
            items.push(<div class="m-2"><label><b>Shipping Address Country: </b></label>&nbsp;{data['shippingAddressCountry']}</div>)

            items.push(<div class="m-2"><label><b>Entry Created: </b></label>&nbsp;{moment.unix(parseInt(data['_created']['$date']) / 1000).utcOffset("+0000").format('dddd, Do MMM YYYY, hh:mm:ss A')}</div>)
            items.push(<div class="m-2"><label><b>Entry Updated: </b></label>&nbsp;{moment.unix(parseInt(data['_updated']['$date']) / 1000).utcOffset("+0000").format('dddd, Do MMM YYYY, hh:mm:ss A')}</div>)

            return (
                <div class="modal-body">
                    <div class="container">{items}</div>
                </div>
            );
        }
    }

    const populateEditEntryData = (e) => {
        setEditEntryDataLoadingBool(true);
        var entry_id = e.target.getAttribute('lid');
        axios.post(URL + 'search', {
            searchTerm: "",
            searchFilters: { _id: entry_id },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                // console.log(resp.data);
                // axios.post(URL + 'search', {
                axios.post(URL + 'data/rating', {
                    // searchTerm: "",
                    _id: response['data']['payload'].data._id['$oid'],
                    // searchFilters: { _id: response['data']['payload'].data._id['$oid'], customers: true },
                    // searchGetAllFields: {},
                    // searchGetNotAllFields: {}
                }, {
                    headers: { Authorization: ACCT }
                })
                    .then(function (response_) {
                        // console.log(resp.data);
                        // console.log(response_['data'])
                        //setTransactDialogCustomerData(response['data']['payload']);
                        //console.log(response['data'])
                        //setTransactDialogBool(false);
                        setEditEntryData(response['data']['payload']);
                        setEditEntryRatings(response_['data']['payload']);

                        // setEditEntryDataCustomers(response_['data']['payload']);
                        // console.log(response_['data']['payload']);
                        setEditEntryDataLoadingBool(false);
                    })

            })


    }

    class OrderDialog extends React.Component {
        constructor(props) {
            super(props);
            this.state = { "selectedOption": "", "printData": {}, "selctedCustomer": {}, "selectedCustomerID": "", selectedCustomerSet: false, "selectedFrom": {}, "selectedFromId": "", selectedFromSet: false, date: new Date(), amount: "0", shipFromOwnerCheckbox: false, description: "", statusText: "", orderid: 0, handlerId: { "name": "Select Handler", "lid": "Select Handler" } };

            this.close = this.close.bind(this);
            this.customerSearchChangeHandle = this.customerSearchChangeHandle.bind(this);
            this.CustomerSearchResults = this.CustomerSearchResults.bind(this);
            this.SelectedCustomer = this.SelectedCustomer.bind(this);
            this.handleShipFromOwnerCheckbox = this.handleShipFromOwnerCheckbox.bind(this);
            this.populateSelectedCustomer = this.populateSelectedCustomer.bind(this);
            this.handleShippingToFromAmountChange = this.handleShippingToFromAmountChange.bind(this);
            this.handleShippingToFromDateChange = this.handleShippingToFromDateChange.bind(this);
            this.handleShippingToFromDescriptionChange = this.handleShippingToFromDescriptionChange.bind(this);
            this.postOrder = this.postOrder.bind(this);
            this.StatusText = this.StatusText.bind(this);
            this.removeSelectedCustomer = this.removeSelectedCustomer.bind(this);
            this.FromSearchResults = this.FromSearchResults.bind(this);
            this.fromSearchChangeHandle = this.fromSearchChangeHandle.bind(this);
            this.FromSelected = this.FromSelected.bind(this);
            this.populateSelectedFrom = this.populateSelectedFrom.bind(this);
            this.removeSelectedFrom = this.removeSelectedFrom.bind(this)
            //     $('#datepicker').datepicker({
            //         uiLibrary: 'bootstrap4'
            // });
        }

        StatusText = (e) => {
            if (this.state.statusText["_status"] === "success") {
                $('#postOrderButton').attr('disabled', 'disabled');
                return (
                    <span class="ml-2 text-success">
                        Order Placed
                    </span>
                )
            }
            if (this.state.statusText["_status"] === "fail") {

                return (
                    <span class="ml-2 text-danger">
                        Some Error Occurred.
                        {/* {this.state.statusText["payload"]} */}
                    </span>
                )
            }
            return ("")
        }

        postOrder = (e) => {
            $("#postOrderButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Order")
            // console.log(this.state.amount);
            // console.log(this.state.date);
            // console.log(this.state.selectedCustomerID)
            // console.log(this.state.description);

            // var to_id = "";
            // if (this.state.selectedCustomerID === "" && this.state.shipFromOwnerCheckbox) {
            //     to_id = transactDialogLid
            // } else {
            //     to_id = this.state.selectedCustomerID
            // }
            const that = this;
            axios.post(URL + "orders", {
                amount: this.state.amount,
                date: this.state.date,
                to_id: this.state.selectedCustomerID,
                to: "",
                amountstate: "",
                init_id: transactDialogLid,
                description: this.state.description,
                from_id: this.state.selectedFromId,
                from: "",
                handler: "",
                orderid: this.state.orderid.toString(),
                handlerId: this.state.handlerId["lid"],
                orderstate: "",
                tracking: "",
                shipFromOwnerCheckbox: this.state.shipFromOwnerCheckbox

            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {
                    $("#postOrderButton").html("Order")
                    that.setState({ statusText: response["data"] })

                })

        }
        populateSelectedCustomer = (e) => {
            const _id = e.target.getAttribute("lid");

            const that = this;
            axios.post(URL + "search", {
                searchTerm: "",
                searchFilters: {
                    _id: _id
                },
                searchGetAllFields: {},
                searchGetNotAllFields: {}
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {
                    that.setState({ "selectedCustomer": response.data.payload.data, "selectedCustomerID": response.data.payload.data._id.$oid, "selectedCustomerSet": true });
                })
        }
        populateSelectedFrom = (e) => {
            const _id = e.target.getAttribute("lid");

            const that = this;
            axios.post(URL + "search", {
                searchTerm: "",
                searchFilters: {
                    _id: _id
                },
                searchGetAllFields: {},
                searchGetNotAllFields: {}
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {
                    that.setState({ selectedFrom: response.data.payload.data, selectedFromId: response.data.payload.data._id.$oid, selectedFromSet: true });
                })
        }
        close() {
            $('#orderModal').modal('hide');
            $(function () {
                $("#firstname").focus();
            });
        }
        customerSearchChangeHandle = (e) => {
            $("#searchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
            const that = this;
            axios.post(URL + "search", {
                searchTerm: e.target.value,
                searchFilters: searchFilterCheckboxValues,
                searchGetAllFields: searchGetAllFieldsCheckboxValues,
                searchGetNotAllFields: {}
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {

                    // add a general function to check for error

                    that.setState({ retrievedCustomerData: response['data']['payload']['data'] });
                    $("#searchLoadingSpinner1").html("")
                })
        }
        fromSearchChangeHandle = (e) => {
            $("#fromSearchLoadingSpinner1").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
            const that = this;
            axios.post(URL + "search", {
                searchTerm: e.target.value,
                searchFilters: searchFilterCheckboxValues,
                searchGetAllFields: searchGetAllFieldsCheckboxValues,
                searchGetNotAllFields: {}
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {

                    // add a general function to check for error

                    that.setState({ fromRetrievedCustomerData: response['data']['payload']['data'] });
                    $("#fromSearchLoadingSpinner1").html("")
                })
        }
        CustomerSearchResults = (e) => {
            var customerData_ = []
            for (var item in this.state.retrievedCustomerData) {
                var name = this.state.retrievedCustomerData[item]['firstname'] + " " + this.state.retrievedCustomerData[item]['lastname'];
                var lid = this.state.retrievedCustomerData[item]['_id']['$oid'];
                customerData_.push(
                    <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedCustomer} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedCustomerData[item]['firstname']}&nbsp;{this.state.retrievedCustomerData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedCustomerData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedCustomerData[item]['shippingAddressCity']}</span>
                );
            }
            if (customerData_.length === 0) {
                return (
                    <span></span>
                );
            } else {
                return (
                    <div class="list-group w-100 m-2">
                        {customerData_}
                    </div>
                );
            }
        }

        FromSearchResults = (e) => {
            var customerData_ = []
            for (var item in this.state.fromRetrievedCustomerData) {
                var name = this.state.fromRetrievedCustomerData[item]['firstname'] + " " + this.state.fromRetrievedCustomerData[item]['lastname'];
                var lid = this.state.fromRetrievedCustomerData[item]['_id']['$oid'];
                customerData_.push(
                    <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedFrom} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.fromRetrievedCustomerData[item]['firstname']}&nbsp;{this.state.fromRetrievedCustomerData[item]['lastname']}&nbsp;|&nbsp;{this.state.fromRetrievedCustomerData[item]['phone']}&nbsp;|&nbsp;{this.state.fromRetrievedCustomerData[item]['shippingAddressCity']}</span>
                );
            }
            if (customerData_.length === 0) {
                return (
                    <span></span>
                );
            } else {
                return (
                    <div class="list-group w-100 m-2">
                        {customerData_}
                    </div>
                );
            }
        }
        removeSelectedCustomer = (e) => {
            this.setState({ "selctedCustomer": {}, "selectedCustomerID": "", selectedCustomerSet: false })
        }

        removeSelectedFrom = (e) => {
            this.setState({ selectedFrom: {}, selectedFromId: "", selectedFromSet: false })
        }


        SelectedCustomer = (e) => {
            if (this.state.selectedCustomerSet) {
                const selectedCustomer = this.state.selectedCustomer;
                return (<table class="table p-2">
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <div class="d-flex justify-content-between">
                                    <b>Selected Customer</b>
                                    <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={this.removeSelectedCustomer}></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['firstname']}</td>
                            <td>{selectedCustomer['lastname']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['brand']}</td>
                            <td>{selectedCustomer['phone']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['email']}</td>
                            <td>{selectedCustomer['gstin']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressAddress1']}</td>
                            <td>{selectedCustomer['shippingAddressAddress2']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressCity']}</td>
                            <td>{selectedCustomer['shippingAddressState']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressPincode']}</td>
                            <td>{selectedCustomer['shippingAddressCountry']}</td>
                        </tr>
                    </tbody>
                </table>);
            }
            return (<span></span>);


        }

        FromSelected = (e) => {
            if (this.state.selectedFromSet) {
                const selectedFrom = this.state.selectedFrom;
                return (<table class="table p-2">
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <div class="d-flex justify-content-between">
                                    <b>Selected Customer</b>
                                    <i class="fas fa-times-circle" style={{ cursor: 'pointer' }} onClick={this.removeSelectedFrom}></i>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{selectedFrom['firstname']}</td>
                            <td>{selectedFrom['lastname']}</td>
                        </tr>
                        <tr>
                            <td>{selectedFrom['brand']}</td>
                            <td>{selectedFrom['phone']}</td>
                        </tr>
                        <tr>
                            <td>{selectedFrom['email']}</td>
                            <td>{selectedFrom['gstin']}</td>
                        </tr>
                        <tr>
                            <td>{selectedFrom['shippingAddressAddress1']}</td>
                            <td>{selectedFrom['shippingAddressAddress2']}</td>
                        </tr>
                        <tr>
                            <td>{selectedFrom['shippingAddressCity']}</td>
                            <td>{selectedFrom['shippingAddressState']}</td>
                        </tr>
                        <tr>
                            <td>{selectedFrom['shippingAddressPincode']}</td>
                            <td>{selectedFrom['shippingAddressCountry']}</td>
                        </tr>
                    </tbody>
                </table>);
            }
            return (<span></span>);
        }
        handleShipFromOwnerCheckbox = (e) => {
            if (e.target.checked) {
                this.setState({ shipFromOwnerCheckbox: true })
            } else {
                this.setState({ shipFromOwnerCheckbox: false })
            }
        }
        handleShippingToFromAmountChange = (e) => {
            this.setState({ "amount": e.target.value });
        }
        handleShippingToFromDateChange = (date) => {
            this.setState({ "date": date });
        }
        handleShippingToFromDescriptionChange = (e) => {
            this.setState({ "description": e.target.value });
        }

        render() {
            var orderidHandlerRow = []
            if (account_type == "owner") {
                orderidHandlerRow.push(
                    <tr>
                        <td>
                            Order ID
                        </td>
                        <td>
                            Handler
                        </td>
                    </tr>
                );
                orderidHandlerRow.push(
                    <tr>
                        <td>
                            <input autocomplete="off" type="number" class="form-control" value={this.state.orderid} name="newOrderOrderId" id="newOrderOrderId" onChange={(e) => {
                                this.setState({ orderid: e.target.value })
                            }} />
                        </td>
                        <td>
                            <select class="custom-select" defaultValue={this.state.handlerId["name"]} value={this.state.handlerId["name"]} onChange={(e) => {
                                var index = e.target.selectedIndex;
                                const h_lid = e.target[index].getAttribute("lid");
                                const tmp_d = { "name": e.target.value, "lid": h_lid }
                                this.setState({ handlerId: tmp_d }, () => {

                                })
                            }}>
                                <option lid="Select Handler" value="Select Handler">Select Handler</option>
                                <Handlers handler={this.state.handlerId["name"]} />
                            </select>
                        </td>
                    </tr>
                )
            }

            return (
                <div class="modal-body">
                    <div class="d-flex justify-content-center">
                        <div class="container">
                            <div class="row">
                                <table class="m-2">
                                    {orderidHandlerRow}
                                    <tr>
                                        <td>
                                            Amount
                                            </td>
                                        <td>
                                            Date
                                            </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input autocomplete="off" type="text" class="form-control" value={this.state.amount} name="shippingToFromAmount" id="shippingToFromTimes" onChange={this.handleShippingToFromAmountChange} />
                                        </td>
                                        <td>
                                            <DatePicker
                                                selected={this.state.date}
                                                onChange={this.handleShippingToFromDateChange}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Description
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <input autocomplete="off" type="text" class="form-control" value={this.state.description} name="shippingToFromDescription" id="shippingToFromDescription" onChange={this.handleShippingToFromDescriptionChange} />
                                        </td>
                                    </tr>
                                </table>
                                <form class="w-100">
                                    <div class="form-group row border m-2">
                                        <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                            <span>
                                                <span>Ship To</span>&nbsp;|&nbsp;
                                                    <span class="btn-link" onClick={this.close} style={{ cursor: 'pointer' }}>Add New</span>
                                            </span>
                                            <span id="searchLoadingSpinner1">

                                            </span>
                                        </span>
                                        <div class="input-group p-2">
                                            <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.customerSearchChangeHandle} />
                                        </div>
                                        <this.CustomerSearchResults />
                                        <div class="w-100">
                                            <this.SelectedCustomer />
                                        </div>
                                    </div>
                                    <div class="form-group row border m-2">
                                        <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                            <span>
                                                <span>Ship From</span>&nbsp;|&nbsp;
                                                    <span class="btn-link" onClick={this.close} style={{ cursor: 'pointer' }}>Add New</span>
                                            </span>
                                            <span id="fromSearchLoadingSpinner1">

                                            </span>
                                        </span>
                                        <div class="input-group p-2">
                                            <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.fromSearchChangeHandle} />
                                        </div>
                                        <this.FromSearchResults />
                                        <div class="w-100">
                                            <this.FromSelected />
                                        </div>
                                        <div class="form-group form-check m-2">
                                            <input type="checkbox" class="form-check-input" id="shipFromOwnerCheckbox" onChange={this.handleShipFromOwnerCheckbox} />
                                            <label class="form-check-label" for="shipFromOwnerCheckbox">Ship From Owner</label>
                                            {/* <button type="button" class="btn btn-warning ml-2">Confirm Transaction</button> */}
                                        </div>
                                    </div>
                                    <div class="form-group m-2">
                                        <button type="button" id="postOrderButton" class="btn btn-primary" onClick={this.postOrder}>Order</button>
                                        <this.StatusText />
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div >
            );
        }
    }

    class TransactDialog extends React.Component {
        // const TransactDialog = (e) => {
        constructor(props) {
            super(props);
            this.state = { "selectedOption": "", "printData": {}, "selctedCustomer": {}, "selectedCustomerID": "", selectedCustomerSet: false, times: "2", columns: "2", amount: "0", shipFromOwnerCheckbox: false };
            this.handleTransactDialogSelectChange = this.handleTransactDialogSelectChange.bind(this);
            this.getPrintData = this.getPrintData.bind(this);
            this.newprint = this.newprint.bind(this);
            this.customerSearchChangeHandle = this.customerSearchChangeHandle.bind(this);
            this.CustomerSearchResults = this.CustomerSearchResults.bind(this);
            this.populateSelectedCustomer = this.populateSelectedCustomer.bind(this);
            this.SelectedCustomer = this.SelectedCustomer.bind(this);
            this.handleShippingToFromTimesChange = this.handleShippingToFromTimesChange.bind(this);
            this.handleShippingToFromColumnChange = this.handleShippingToFromColumnChange.bind(this);
            this.close = this.close.bind(this);
            this.handleShipFromOwnerCheckbox = this.handleShipFromOwnerCheckbox.bind(this);
            this.handleShippingToFromAmountChange = this.handleShippingToFromAmountChange.bind(this);
        }

        handleShipFromOwnerCheckbox = (e) => {
            if (e.target.checked) {
                this.setState({ shipFromOwnerCheckbox: true })
            } else {
                this.setState({ shipFromOwnerCheckbox: false })
            }
        }

        close() {
            $('#transactModal').modal('hide');
            $(function () {
                $("#firstname").focus();
            });
        }
        customerSearchChangeHandle = (e) => {
            $("#searchLoadingSpinner").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i>")
            const that = this;
            axios.post(URL + "search", {
                searchTerm: e.target.value,
                searchFilters: searchFilterCheckboxValues,
                searchGetAllFields: searchGetAllFieldsCheckboxValues,
                searchGetNotAllFields: {}
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {

                    // add a general function to check for error

                    that.setState({ retrievedCustomerData: response['data']['payload']['data'] });
                    $("#searchLoadingSpinner").html("")
                })
        }
        populateSelectedCustomer = (e) => {
            const _id = e.target.getAttribute("lid");


            const that = this;
            axios.post(URL + "search", {
                searchTerm: "",
                searchFilters: {
                    _id: _id
                },
                searchGetAllFields: {},
                searchGetNotAllFields: {}
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {
                    that.setState({ "selectedCustomer": response.data.payload.data, "selectedCustomerID": response.data.payload.data._id.$oid, "selectedCustomerSet": true });
                })
        }
        CustomerSearchResults = (e) => {
            var customerData_ = []
            for (var item in this.state.retrievedCustomerData) {
                var name = this.state.retrievedCustomerData[item]['firstname'] + " " + this.state.retrievedCustomerData[item]['lastname'];
                var lid = this.state.retrievedCustomerData[item]['_id']['$oid'];
                customerData_.push(
                    <span style={{ cursor: 'pointer' }} onClick={this.populateSelectedCustomer} lid={lid} name={name} class="list-group-item list-group-item-action">{this.state.retrievedCustomerData[item]['firstname']}&nbsp;{this.state.retrievedCustomerData[item]['lastname']}&nbsp;|&nbsp;{this.state.retrievedCustomerData[item]['phone']}&nbsp;|&nbsp;{this.state.retrievedCustomerData[item]['shippingAddressCity']}</span>
                );
            }
            if (customerData_.length === 0) {
                return (
                    <span></span>
                );
            } else {
                return (
                    <div class="list-group w-100 m-2">
                        {customerData_}
                    </div>
                );
            }
        }
        newprint(id) {
            printJS(URL + "transaction/print/" + id)
            $("#formPrintButton").html("Print");
        }

        getPrintData = (e) => {
            if (this.state.selectedCustomer === undefined) {
                return;
            }
            $("#formPrintButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin mr-2\"></i>\
            Print");
            const toID = this.state.selectedCustomerID;
            const fromID = transactDialogLid;
            const shipFromOwner = this.state.shipFromOwnerCheckbox;
            const amount = this.state.amount;
            const that = this;
            axios.post(URL + "transaction/gen", {
                to_id: toID,
                from_id: fromID,
                times: this.state.times,
                columns: this.state.columns,
                shipFromOwner: shipFromOwner,
                amount: amount
            }, {
                headers: { Authorization: ACCT }
            })
                .then(function (response) {
                    if (response['data']['_status'] == "success") {
                        that.newprint(response['data']['payload']);
                    }
                })
        }

        handleTransactDialogSelectChange = (e) => {
            this.setState({ "selectedOption": e.target.value })
            // setTransactDialogSelectedLid(e.target.value);
            // console.log(e.target.value);
        }

        SelectedCustomer = (e) => {
            if (this.state.selectedCustomerSet) {
                const selectedCustomer = this.state.selectedCustomer;
                return (<table class="table p-2">
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <b>Selected Customer</b>
                            </td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['firstname']}</td>
                            <td>{selectedCustomer['lastname']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['brand']}</td>
                            <td>{selectedCustomer['phone']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['email']}</td>
                            <td>{selectedCustomer['gstin']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressAddress1']}</td>
                            <td>{selectedCustomer['shippingAddressAddress2']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressCity']}</td>
                            <td>{selectedCustomer['shippingAddressState']}</td>
                        </tr>
                        <tr>
                            <td>{selectedCustomer['shippingAddressPincode']}</td>
                            <td>{selectedCustomer['shippingAddressCountry']}</td>
                        </tr>
                    </tbody>
                </table>);
            }
            return (<span></span>);


        }

        handleShippingToFromTimesChange = (e) => {
            this.setState({ "times": e.target.value });
        }
        handleShippingToFromColumnChange = (e) => {
            this.setState({ "columns": e.target.value });
        }
        handleShippingToFromAmountChange = (e) => {
            this.setState({ "amount": e.target.value });
        }
        render() {
            if (transactDialogBool && transactDialogRetailBool) {
                getCustomerData();
                return (
                    <div class="modal-body">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                );
            }
            if (transactDialogBool == false && transactDialogRetailBool == false) {
                var customerItems = []
                for (var item in transactDialogCustomerData.data) {
                    customerItems.push(<option value={item}>{transactDialogCustomerData.data[item]}</option>)
                }
                return (
                    <div class="modal-body">
                        <div class="d-flex justify-content-center">
                            <div class="container">
                                <div class="row">
                                    <table class="m-2">
                                        <tr>
                                            <td>
                                                Times
                                            </td>
                                            <td>
                                                Columns
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input autocomplete="off" type="text" class="form-control" value={this.state.times} name="shippingToFromTimes" id="shippingToFromTimes" onChange={this.handleShippingToFromTimesChange} />
                                            </td>
                                            <td>
                                                <input autocomplete="off" type="text" class="form-control" value={this.state.columns} name="shippingToFromColumns" id="shippingToFromColumns" onChange={this.handleShippingToFromColumnChange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                        </tr>
                                        <tr>
                                            <input autocomplete="off" type="text" class="form-control" value={this.state.amount} name="shippingToFromAmount" id="shippingToFromAmount" onChange={this.handleShippingToFromAmountChange} />
                                        </tr>
                                    </table>
                                </div>
                                <div class="row">
                                    <form class="w-100">
                                        <div class="form-group row border m-2">
                                            <span class="ml-2 mr-2 mb-0 mt-2 d-flex justify-content-between w-100">
                                                <span>
                                                    <span>Ship To</span>&nbsp;|&nbsp;
                                                    <span class="btn-link" onClick={this.close} style={{ cursor: 'pointer' }}>Add New</span>
                                                </span>
                                                <span id="searchLoadingSpinner">

                                                </span>
                                            </span>
                                            <div class="input-group p-2">
                                                <input autocomplete="off" type="text" class="form-control border-1 small form-control-lg" placeholder="Search" name="customerSearch" id="customerSearchTextbox" onChange={this.customerSearchChangeHandle} />
                                            </div>
                                            <this.CustomerSearchResults />
                                            <div class="w-100">
                                                <this.SelectedCustomer />
                                            </div>
                                        </div>

                                        {/* <div class="form-group">
                                        <label for="transactCustomerSelect">Ship To</label>
                                        <select class="custom-select" id="transactCustomerSelect" value={this.state.selectedOption} onChange={this.handleTransactDialogSelectChange}>
                                            <option selected >Select Customer</option>
                                            {customerItems}
                                        </select>
                                    </div> */}
                                        <div class="form-group form-check m-2">
                                            <input type="checkbox" class="form-check-input" id="shipFromOwnerCheckbox" onChange={this.handleShipFromOwnerCheckbox} />
                                            <label class="form-check-label" for="shipFromOwnerCheckbox">Ship From Owner</label>
                                            {/* <button type="button" class="btn btn-warning ml-2">Confirm Transaction</button> */}
                                        </div>
                                        <div class="form-group m-2">

                                            <button type="button" id="formPrintButton" class="btn btn-primary" onClick={this.getPrintData}>Print</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                );
            }
            return ("");
        }

    }

    const getCustomerData = (e) => {

        setTransactDialogRetailBool(true);
        setTransactDialogBool(true);

        axios.post(URL + 'search', {
            searchTerm: "",
            searchFilters: { _id: transactDialogLid },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                setTransactDialogRetailData(response['data']['payload']);
                setTransactDialogRetailBool(false);
            })

        axios.post(URL + 'search', {
            searchTerm: "",
            searchFilters: { _id: transactDialogLid, customers: true },
            searchGetAllFields: {},
            searchGetNotAllFields: {}
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                setTransactDialogCustomerData(response['data']['payload']);
                //console.log(response['data'])
                setTransactDialogBool(false);
            })
    }

    const handelTransactLid = (e) => {
        setTransactDialogLid(e.target.getAttribute('lid'));
        setTransactDialogBool(true);
        getCustomerData();
    }

    const handleSearchResultPagination = (e) => {
        const page = e.target.getAttribute('page');
        // setSearchPageNumber(page);
        // console.log(searchPageNumber);
        setSearchButtonLoadingSpinner(true);
        axios.post(URL + "search", {
            searchTerm: searchTerm,
            searchFilters: searchFilterCheckbox,
            searchGetAllFields: searchGetAllFieldsCheckbox,
            searchGetNotAllFields: searchGetNotAllFieldsCheckbox,
            // pageNumber: page
            pageNumber: searchPageNumber
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                setSearchButtonLoadingSpinner(false);

                // add a general function to check for error

                setSearchResult(response['data']['payload']);
                // console.log(searchResult);
            })
        // search_result();
    }

    const SearchPagination = (props) => {

        const searchDataCount = props.data.totalCount;
        const currentDataCount = props.data.currentCount;



        const items = []

        if (searchDataCount > 0) {

            var tmpItems = null
            // if (searchDataCount == 1) {
                tmpItems = <p class="text-primary"><b>{searchDataCount}</b> Entry Found. Showing <b>{currentDataCount}</b>.</p>
                // tmpItems.push(<p class="text-primary"><b>{searchDataCount}</b> Entry Found. Showing <b>{currentDataCount}</b>.</p>);
            // }
            // else {
                tmpItems = <p class="text-primary"><b>{searchDataCount}</b> Entries Found. Showing <b>{currentDataCount}</b>.</p>
                // tmpItems.push(<p class="text-primary"><b>{searchDataCount}</b> Entries Found. Showing <b>{currentDataCount}</b>.</p>);
            // }

            // const navItems = [];
            // const pageCount = Math.ceil(searchDataCount / 10);
            // for (var i = 0; i < pageCount; i++) {

            //     navItems.push(<li class="page-item"><a style={{ cursor: 'pointer' }} class="page-link" onClick={handleSearchResultPagination} page={i + 1}>{i + 1}</a></li>);

            // }

            //     tmpItems.push
            //         (
            //             <nav aria-label="Page navigation example">
            //                 <ul class="pagination justify-content-center">
            //                     {/* <li class="page-item"><a class="page-link" onClick={handleSearchResultPagination} href="#">Previous</a></li> */}
            //                     {navItems}
            //                     {/* <li class="page-item"><a class="page-link" onClick={handleSearchResultPagination} href="#">Next</a></li> */}
            //                 </ul>
            //             </nav>
            //         );

            items.push(
                <div class="row mt-2">
                    <div class="container w-100 p-0">
                        {tmpItems}
                    </div>
                </div>
            )
        }
        // return (
        //     <div>
        //         {items}
        //     </div>
        // );



        return [

            items,
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        // activePage={this.state.page_number}
                        activePage={searchPageNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={searchDataCount}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            setSearchButtonLoadingSpinner(true);
                            axios.post(URL + "search", {
                                searchTerm: searchTerm,
                                searchFilters: searchFilterCheckbox,
                                searchGetAllFields: searchGetAllFieldsCheckbox,
                                searchGetNotAllFields: searchGetNotAllFieldsCheckbox,
                                // pageNumber: page
                                pageNumber: pageNumber
                            }, {
                                headers: { Authorization: ACCT }
                            })
                                .then(function (response) {
                                    setSearchButtonLoadingSpinner(false);

                                    // add a general function to check for error

                                    setSearchResult(response['data']['payload']);
                                    // console.log(searchResult);
                                    setSearchPageNumber(pageNumber);
                                })

                            // this.setState({ "page_number": parseInt(pageNumber) }, )
                        }}
                    />
                </div>
            </div>
        ]
    }
    const SearchResults = (props) => {
        {
            const elements = props.data.data;

            const searchDataCount = props.data.totalCount;
            const currentDataCount = props.data.currentCount;

            const items = []


            for (var index in elements) {
                var tagborder = "";
                if (("tag" in elements[index]) && (elements[index]['tag'].length > 0)) {
                    tagborder = "border-danger"
                    if (elements[index]["tag"] == "Placeholder") {
                        tagborder = "border-warning"
                    }
                }
                items.push(
                    <div class="row mt-2" key={elements[index]['_id']["$oid"]}>
                        <div class="container w-100 p-0">
                            <div class={`card ` + tagborder}>
                                <div class="card-body">
                                    <div class="container m-0 p-0">
                                        <div class="row">
                                            <div class="card m-1 mt-0 border-0 p-0">
                                                <div class="card-body">
                                                    <h5 class="card-title"><b>{elements[index]['firstname']} {elements[index]['lastname']}</b></h5>
                                                    <label>Email:</label> {elements[index]['email']}<br />
                                                    <label>Phone:</label> {elements[index]['phone']}<br />
                                                    <label>Handler:</label>&nbsp;{elements[index]['handler']}<br />
                                                    <label>Brand:</label>&nbsp;{elements[index]['brand']}
                                                </div>
                                            </div>
                                            <div class="card m-1 border-0 p-0">
                                                <div class="card-body">
                                                    <h5 class="card-title">Shipping Address</h5>
                                                    {/* <h5 class="card-subtitle mb-2 text-muted">Shipping Address</h5> */}
                                                    <label></label>
                                                    <label>{elements[index]['shippingAddressAddress1']}</label><br />
                                                    <label>{elements[index]['shippingAddressAddress2']}</label><br />
                                                    <label>{elements[index]['shippingAddressCity']}, {elements[index]['shippingAddressState']}</label><br />
                                                    <label>{elements[index]['shippingAddressPincode']}, {elements[index]['shippingAddressCountry']}</label>
                                                </div>
                                            </div>
                                            <div class="card m-1 border-0 p-0">
                                                <div class="card-body">
                                                    <h5 class="card-title">Billing Address</h5>
                                                    {/* <h5 class="card-subtitle mb-2 text-muted">Billing Address</h5> */}
                                                    <label>{elements[index]['billingAddressAddress1']}</label><br />
                                                    <label>{elements[index]['billingAddressAddress2']}</label><br />
                                                    <label>{elements[index]['billingAddressCity']}, {elements[index]['billingAddressState']}</label><br />
                                                    <label>{elements[index]['billingAddressPincode']}, {elements[index]['billingAddressCountry']}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" class="btn btn-primary mt-2" onClick={populateEditEntryData} data-toggle="modal" data-target="#dataEditModal" lid={elements[index]['_id']['$oid']}>Edit</button>&nbsp;&nbsp;
                                    <button href="#" class="btn btn-primary mt-2" lid={elements[index]['_id']['$oid']} data-toggle="modal" data-target="#moreInfoModal" onClick={populateEditEntryData}>More Info</button>&nbsp;&nbsp;
                                    <button href="#" class="btn btn-primary mt-2" lid={elements[index]['_id']['$oid']} data-toggle="modal" data-target="#orderModal" onClick={handelTransactLid}>Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div>

                    {items}
                </div>
            )
        }
    }

    const SearchButtonLoadignSpinner = (e) => {
        if (searchButtonLoadingSpinner == false) {
            return (
                <span></span>
            );
        }
        if (searchButtonLoadingSpinner == true) {
            return (
                <i id="spin_submit_button" class="fa fa-circle-o-notch fa-spin mr-2"></i>
            );
        }
    }

    const SearchFilterCheckBox = (props) => {
        return (
            <div class="custom-control custom-checkbox ml-2">
                <input type="checkbox" name={props.name} class="custom-control-input" id={props.id} onChange={props.onChange} defaultChecked={searchFilterCheckbox[props.name]} />
                <label class="custom-control-label" for={props.id}>{props.name}</label>
            </div>
        );
    }

    const SearchGetAllFieldsCheckBox = (props) => {
        return (
            <div class="custom-control custom-checkbox ml-2">
                <input type="checkbox" name={props.name} class="custom-control-input" id={props.id} onChange={props.onChange} defaultChecked={searchGetAllFieldsCheckbox[props.name]} />
                <label class="custom-control-label" for={props.id}>{props.name}</label>
            </div>
        );
    }

    const SearchGetNotAllFieldsCheckBox = (props) => {
        return (
            <div class="custom-control custom-checkbox ml-2">
                <input type="checkbox" name={props.name} class="custom-control-input" id={props.id} onChange={props.onChange} defaultChecked={searchGetNotAllFieldsCheckbox[props.name]} />
                <label class="custom-control-label" for={props.id}>{props.name}</label>
            </div>
        );
    }

    const handleSearchGetAllFieldsCheckBoxs = (e) => {
        clearAllSearchGetNotAllFieldsCheckboxs();
        var tmpSearchGetAllFieldsCheckBoxs = searchGetAllFieldsCheckbox;
        tmpSearchGetAllFieldsCheckBoxs[e.target.name] = e.target.checked;
        setSearchGetAllFieldsCheckbox(tmpSearchGetAllFieldsCheckBoxs);
        // console.log(searchGetAllFieldsCheckbox);
    }

    const handleSearchGetNotAllFieldsCheckBoxs = (e) => {
        clearAllSearchGetAllFieldsCheckboxs();
        var tmpSearchGetNotAllFieldsCheckBoxs = searchGetNotAllFieldsCheckbox;
        tmpSearchGetNotAllFieldsCheckBoxs[e.target.name] = e.target.checked;
        setSearchGetNotAllFieldsCheckbox(tmpSearchGetNotAllFieldsCheckBoxs);
        // console.log(searchGetAllFieldsCheckbox);
    }

    const handleSearchFilterCheckBoxs = (e) => {
        var tmpSearchFilterCheckBoxs = searchFilterCheckbox;
        tmpSearchFilterCheckBoxs[e.target.name] = e.target.checked;
        setSearchFilterCheckbox(tmpSearchFilterCheckBoxs);
    }

    const FilterButtonCaret = (e) => {
        if (filterButtonBool) {
            return (
                <i class="fas fa-caret-up"></i>
            );
        } else {
            return (
                <i class="fas fa-caret-down"></i>
            );
        }
    }

    const filterButtonBoolToggle = (e) => {
        setFilterButtonBool(!filterButtonBool);
    }


    const search_result = (e) => {
        setSearchButtonLoadingSpinner(true);
        axios.post(URL + "search", {
            searchTerm: searchTerm,
            searchFilters: searchFilterCheckbox,
            searchGetAllFields: searchGetAllFieldsCheckbox,
            searchGetNotAllFields: searchGetNotAllFieldsCheckbox,
            pageNumber: 1
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                setSearchButtonLoadingSpinner(false);

                // add a general function to check for error

                setSearchResult(response['data']['payload']);

                setSearchPageNumber(1);
                // console.log(searchResult);
            })
    }


    const clearAllSearchFilterCheckboxs = (e) => {
        const tmpSearchFilterCheckBoxsValues = searchFilterCheckboxValues
        for (var item in tmpSearchFilterCheckBoxsValues) {
            tmpSearchFilterCheckBoxsValues[item] = false
        }
        setSearchFilterCheckbox(tmpSearchFilterCheckBoxsValues);
    }
    const markAllSearchFilterCheckboxs = (e) => {
        setSearchFilterCheckbox(searchFilterCheckboxValues);
    }

    const clearAllSearchGetAllFieldsCheckboxs = (e) => {
        setSearchGetAllFieldsCheckbox(searchGetAllFieldsCheckboxValues);
    }
    const clearAllSearchGetNotAllFieldsCheckboxs = (e) => {
        setSearchGetNotAllFieldsCheckbox(searchGetNotAllFieldsCheckboxValues);
    }
    const markAllSearchGetAllFieldsCheckboxs = (e) => {
        const tmpSearchGetAllFieldsCheckBoxsValues = searchGetAllFieldsCheckboxValues
        for (var item in tmpSearchGetAllFieldsCheckBoxsValues) {
            tmpSearchGetAllFieldsCheckBoxsValues[item] = true
        }
        setSearchGetAllFieldsCheckbox(tmpSearchGetAllFieldsCheckBoxsValues)
    }
    const markAllSearchGetNotAllFieldsCheckboxs = (e) => {
        const tmpSearchGetNotAllFieldsCheckBoxsValues = searchGetNotAllFieldsCheckboxValues
        for (var item in tmpSearchGetNotAllFieldsCheckBoxsValues) {
            tmpSearchGetNotAllFieldsCheckBoxsValues[item] = true
        }
        setSearchGetNotAllFieldsCheckbox(tmpSearchGetNotAllFieldsCheckBoxsValues)
    }
    const handleSearchKeyDown = (e) => {
        if (e.keyCode == 13) {
            search_result();
            // put the login here
        }
    }

    return (
        <div>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Database</h1>
                <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-7 align-items-center justify-content-between">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Database Search</h6>
                        </div>
                        <div class="m-5">

                            <div class="row">
                                <div class="input-group">
                                    <input type="text" className="search_term" class="form-control border-1 small form-control-lg" placeholder="Search" name="search_term" onKeyDown={handleSearchKeyDown} onChange={handleSearchTermChange} />
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-primary" id="searchButton" onClick={search_result}>
                                            <SearchButtonLoadignSpinner />
                                            Search
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <button type="button" class="btn btn-secondary mt-2" data-toggle="collapse" data-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter" onClick={filterButtonBoolToggle}>Filters <FilterButtonCaret /></button>
                            </div>
                            <div class="row">
                                <div class="collapse mt-2" id="collapseFilter">
                                    <div class="input-group">

                                        <div class="card card-body">
                                            <form>
                                                <div class="form-group row mb-0 mr-1">
                                                    <SearchFilterCheckBox name="Firstname" id="searchFirstnameCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Lastname" id="searchLastnameCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Brand" id="searchBrandCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Interests" id="searchInterestsCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Phone" id="searchPhoneCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Email" id="searchEmailCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="GSTIN" id="searchGSTINCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Handler" id="searchHandlerCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="City" id="searchCityCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="State" id="searchStateCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Pincode" id="searchPincodeCheckbox" onChange={handleSearchFilterCheckBoxs} />
                                                    <SearchFilterCheckBox name="Country" id="searchCountryCheckbox" onChange={handleSearchFilterCheckBoxs} />

                                                </div>
                                            </form>
                                            <div class="row ml-0 mt-2">
                                                <button type="button" class="btn btn-primary" onClick={clearAllSearchFilterCheckboxs}>Clear All</button>
                                                <button type="button" class="btn btn-primary ml-2" onClick={markAllSearchFilterCheckboxs}>Mark All</button>
                                            </div>
                                            <hr />
                                            <div class="form-group row mb-0 mr-1">
                                                <div>
                                                    <span class="ml-2">Get all entries with field(s):</span>
                                                </div>
                                                <div class="row ml-0">
                                                    <SearchGetAllFieldsCheckBox name="Firstname" id="searchGetAllFirstnameCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Lastname" id="searchGetAllLastnameCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Brand" id="searchGetAllBrandCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Interests" id="searchGetAllInterestsCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Phone" id="searchGetAllPhoneCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Email" id="searchGetAllEmailCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="GSTIN" id="searchGetAllGSTINCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Handler" id="searchGetAllHandlerCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="City" id="searchGetAllCityCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="State" id="searchGetAllStateCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Pincode" id="searchGetAllPincodeCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                    <SearchGetAllFieldsCheckBox name="Country" id="searchGetAllCountryCheckbox" onChange={handleSearchGetAllFieldsCheckBoxs} />
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <button type="button" class="btn btn-primary ml-2" onClick={clearAllSearchGetAllFieldsCheckboxs}>Clear All</button>
                                                    <button type="button" class="btn btn-primary ml-2" onClick={markAllSearchGetAllFieldsCheckboxs}>Mark All</button>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="form-group row mb-0 mr-1">
                                                <div>
                                                    <span class="ml-2">Get all entries NOT with field(s):</span>
                                                </div>
                                                <div class="row ml-0">
                                                    <SearchGetNotAllFieldsCheckBox name="Firstname" id="searchGetNotAllFirstnameCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Lastname" id="searchGetNotAllLastnameCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Brand" id="searchGetNotAllBrandCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Interests" id="searchGetNotAllInterestsCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Phone" id="searchGetNotAllPhoneCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Email" id="searchGetNotAllEmailCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="GSTIN" id="searchGetNotAllGSTINCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Handler" id="searchGetNotAllHandlerCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="City" id="searchGetNotAllCityCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="State" id="searchGetNotAllStateCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Pincode" id="searchGetNotAllPincodeCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                    <SearchGetNotAllFieldsCheckBox name="Country" id="searchGetNotAllCountryCheckbox" onChange={handleSearchGetNotAllFieldsCheckBoxs} />
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <button type="button" class="btn btn-primary ml-2" onClick={clearAllSearchGetNotAllFieldsCheckboxs}>Clear All</button>
                                                    <button type="button" class="btn btn-primary ml-2" onClick={markAllSearchGetNotAllFieldsCheckboxs}>Mark All</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SearchPagination data={searchResult} />
                            <SearchResults data={searchResult} />
                        </div>
                    </div>
                </div>


                <div class="col-xl-6 col-lg-7">
                    <Data />
                </div>
            </div>
            <div class="modal fade" id="dataEditModal" tabindex="-1" role="dialog" aria-labelledby="dataEditModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Edit Entry</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <EditEntryDialog state={editEntryData} ratings={editEntryRatings} />
                    </div>
                </div>
            </div>


            <div class="modal fade" id="moreInfoModal" tabindex="-1" role="dialog" aria-labelledby="moreInfoModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">More Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <MoreInfoDialog data={editEntryData} />
                    </div>
                </div>
            </div>

            <div class="modal fade" id="transactModal" tabindex="-1" role="dialog" aria-labelledby="transactModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Transact</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <TransactDialog data={editEntryData} />
                    </div>
                </div>
            </div>

            <div class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="orderModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Order</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <OrderDialog data={editEntryData} />
                    </div>
                </div>
            </div>

        </div>
    )
};

export default Db