import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import Cookies from 'js-cookie';
import URL from './URL';
import './Inquiry.css';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DebounceInput } from 'react-debounce-input';
import Pagination from "react-js-pagination";
import Quill from 'quill';

var randomBytes = require('randombytes');
var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')

class AddCoupons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            coupon: "",
            coupon_discount: "",
            coupon_discount_type: "",
            coupon_type: "",
            description: "",
            coupon_valid_for_type: "",
            coupon_valid_for_object: {},
            validity: null,
            lifetime_validity_check: true,
            response: {}
        }
    }
    Status = (e) => {
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }
    postCoupon = () => {
        var error = {
            "_status": "",
            "payload": []
        }

        var tmpPayload = {}
        var errorSet = false

        if (this.state.coupon_discount.length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Discount"] = "Please Add Discount."

        }

        if (this.state.validity == null && this.state.lifetime_validity_check === false) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Validity"] = "Please Add Validity."
        }

        if (errorSet) {
            error["payload"] = [tmpPayload]
            this.setState({ response: error })
            return
        }

        var validity = this.state.validity;
        if (validity === null) {
            validity = ""
        }

        var tmp_coupon_valid_for_object = []
        for (var i in this.state.coupon_valid_for_object) {
            tmp_coupon_valid_for_object.push(
                i
            )
        }

        $("#addCouponButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Add")
        const that = this
        this.setState({ response: {} })
        axios.post(URL + "coupon", {
            coupon: this.state.coupon,
            coupon_discount: this.state.coupon_discount,
            coupon_discount_type: this.state.coupon_discount_type,
            coupon_type: this.state.coupon_type,
            validity: validity,
            lifetime_validity_check: this.state.lifetime_validity_check,
            coupon_valid_for_type: this.state.coupon_valid_for_type,
            coupon_valid_for_object: tmp_coupon_valid_for_object,
            description: this.state.description
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {

                that.setState({ response: response["data"] })
                // console.log("server", response["data"])
                $("#addCouponButton").html("Add")

            })
    }
    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }
    SelectedList = () => {
        var list_ = []
        for (var i in this.state.coupon_valid_for_object) {
            list_.push(
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span>{this.state.coupon_valid_for_object[i]} </span>
                    <i lid={i} style={{ cursor: 'pointer' }} class="fas fa-times-circle" onClick={(e) => {
                        const lid = e.target.getAttribute('lid')
                        var tmp_object = this.state.coupon_valid_for_object
                        delete tmp_object[lid]
                        this.setState({ coupon_valid_for_object: tmp_object })
                    }}></i>
                </li>
            )
        }
        return (
            <div style={{ maxHeight: '300px', overflow: 'scroll', overflowX: 'hidden', WebkitOverflowScrolling: 'touch' }}>
                <ul class="list-group">
                    {list_}
                </ul>
            </div>
        )
    }
    render() {
        return (
            <div className="container p-4">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="coupon"><b>Coupon</b></label>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                            <input maxlength="12" type="text" id="coupon" className="form-control border-1 small" name="coupon" value={this.state.coupon} onChange={(e) => {
                                this.setState({ coupon: e.target.value })
                            }} />
                            <small id="couponHelp" class="form-text text-muted">Use Only When Custom Coupon Is Needed.</small>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="couponRadios" id="couponRadio1" value="d"
                                    onChange={(e) => {
                                        this.setState({ coupon_type: e.target.value })
                                    }}
                                />
                                <label class="form-check-label" for="couponRadio1">
                                    Discount
                                </label>
                            </div>
                            <div class="form-check ml-2">
                                <input class="form-check-input" type="radio" name="couponRadios" id="couponRadio2" value="l"
                                    onChange={(e) => {

                                        this.setState({ coupon_type: e.target.value })
                                    }}
                                />
                                <label class="form-check-label" for="couponRadio2">
                                    Less
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="coupondiscount"><b>Discount</b></label>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">

                            <input type="number" id="coupondiscount" className="form-control border-1 small" name="coupondiscount" value={this.state.coupon_discount} onChange={(e) => {
                                this.setState({ coupon_discount: e.target.value })
                            }} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="discountRadios" id="discountRadio1" value="%"
                                    onClick={(e) => {
                                        this.setState({ coupon_discount_type: e.target.value })

                                    }}
                                />
                                <label class="form-check-label" for="discountRadio1">
                                    Percentage
                                </label>
                            </div>
                            <div class="form-check ml-2">
                                <input class="form-check-input" type="radio" name="discountRadios" id="discountRadio2" value="d"
                                    onClick={(e) => {
                                        this.setState({ coupon_discount_type: e.target.value })

                                    }}
                                />
                                <label class="form-check-label" for="discountRadio2">
                                    Direct
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="validity"><b>Validity</b></label>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                            <DatePicker
                                id="validitydatepicker"
                                showTimeSelect
                                selected={this.state.validity}
                                onChange={(date) => { this.setState({ validity: date, lifetime_validity_check: false }) }}
                                dateFormat="dd/MM/yyyy hh:mm:ss"
                                className="form-control"
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center ">

                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="lifetimeValidityCheck" checked={this.state.lifetime_validity_check} onChange={(e) => {
                                    if (e.target.checked) {
                                        this.setState({ lifetime_validity_check: e.target.checked, validity: null })
                                    }
                                    else {
                                        this.setState({ lifetime_validity_check: e.target.checked })
                                    }

                                }} />
                                <label class="form-check-label" for="lifetimeValidityCheck">Lifetime Validity</label>
                            </div>


                        </div>
                    </div>

                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="couponDescription"><b>Description</b></label>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                            <textarea type="text" id="couponDescription" className="form-control border-1 small" name="couponDescription" value={this.state.description} onChange={(e) => {
                                this.setState({ description: e.target.value })
                            }} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="validity"><b>Valid For</b></label>
                    <div className="row m-0 p-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                            <AsyncTypeahead type="text" placeholder="Search" name="to" id="invoiceCustomer"
                                labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                                filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                                onSearch={this.handle_search}
                                {...this.state.asyncsearch}
                                maxResults="10"
                                ref={(typeahead) => this.typeahead_select_customer = typeahead}
                                renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps} className="search_menu" id="search_menu">
                                        {results.map((result, index) => (
                                            <MenuItem option={result} position={index} className="search_item m-0">
                                                <div className="w-100 p-2 search_item" onClick={(e) => {
                                                    //   $("#search_menu").hide();
                                                    var tmp_object = this.state.coupon_valid_for_object
                                                    tmp_object[result._id.$oid] = result.firstname + result.lastname + " | " + result.phone + " | " + result.billingAddressCity + " | " + result.handler
                                                    this.setState({ coupon_valid_for_object: tmp_object }, () => {
                                                        const ref_ = this.typeahead_select_customer.getInstance()
                                                        ref_.clear()
                                                    })
                                                }}>

                                                    {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex align-items-center m-2">

                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="validForRadios" id="validForRadios1" value="e"
                            onClick={(e) => {
                                this.setState({ coupon_valid_for_type: e.target.value })

                            }}
                        />
                        <label class="form-check-label" for="validForRadios1">
                            Everyone
                                </label>
                    </div>
                    <div class="form-check ml-2">
                        <input class="form-check-input" type="radio" name="validForRadios" id="validForRadios2" value="si"
                            onClick={(e) => {
                                this.setState({ coupon_valid_for_type: e.target.value })

                            }}
                        />
                        <label class="form-check-label" for="validForRadios2">
                            Select (Include)
                        </label>
                    </div>
                    <div class="form-check ml-2">
                        <input class="form-check-input" type="radio" name="validForRadios" id="validForRadios3" value="se"
                            onClick={(e) => {
                                this.setState({ coupon_valid_for_type: e.target.value })

                            }}
                        />
                        <label class="form-check-label" for="validForRadios3">
                            Select (Exclude)
                        </label>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <this.SelectedList />
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <button type="button" id="addCouponButton" class="btn btn-primary m-0" onClick={this.postCoupon}>Add</button>
                    <button type="button" class="btn btn-primary m-2" onClick={() => {
                        $("#discountRadio1").prop('checked', false);
                        $("#discountRadio2").prop('checked', false);
                        $("#couponRadio1").prop('checked', false);
                        $("#couponRadio2").prop('checked', false);
                        $("#validForRadios1").prop('checked', false);
                        $("#validForRadios2").prop('checked', false);
                        $("#validForRadios3").prop('checked', false);
                        this.setState(
                            {
                                coupon: "",
                                coupon_discount: "",
                                coupon_discount_type: "",
                                coupon_type: "",
                                description: "",
                                coupon_valid_for_type: "",
                                coupon_valid_for_object: {},
                                validity: null,
                                lifetime_validity_check: true,
                                response: {}
                            }
                        )
                    }}>Clear</button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <this.Status />
                </div>
            </div >

        )
    }
}


class SearchCoupons extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            retrivedCoupons: {},
            page_number: 1,
            search_term: "",
            show: "",
            selected_coupon_id: "",

            editCouponBool: false,
            selectedCoupon: {},
            response: {}
        }
    }

    initTimer = () => {
        this.timer = setInterval(() => this.get_all_coupons(), 5000);
    }

    deInitTimer = () => {
        clearInterval(this.timer)
        this.timer = null; // here...
    }

    componentWillUnmount = () => {
        this.deInitTimer();
    }

    componentDidMount() {
        this.get_all_coupons()
    }

    get_all_coupons = () => {
        const that = this;

        var params = { page_number: this.state.page_number - 1 }

        if (this.state.show.length > 0) {
            params["s"] = this.state.show;
        } else {
            delete params["s"]
        }

        if (this.state.search_term.length > 0) {
            params["st"] = this.state.search_term
        } else {
            delete params["st"];
        }

        axios.get(URL + "coupon", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ retrivedCoupons: response["data"]["payload"] })
            })
    }

    get_single_coupon = () => {
        const that = this;

        var params = { "_id": this.state.selected_coupon_id }
        axios.get(URL + "coupon", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {

                that.setState({ selectedCoupon: response["data"]["payload"], editCouponBool: true }, that.deInitTimer)
            })
    }

    Status = (e) => {
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            $("#add_inquiry_button").prop('disabled', true);
            setTimeout(function () { $("#add_inquiry_button").prop('disabled', false); }, 3000);
            return (
                <div class="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div class="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }

    update_coupon = () => {
        const that = this;

        var error = {
            "_status": "",
            "payload": []
        }

        var tmpPayload = {}
        var errorSet = false

        if (this.state.selectedCoupon.coupon_discount.length < 1) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Discount"] = "Please Add Discount."

        }

        if (this.state.selectedCoupon.validity == null && this.state.selectedCoupon.lifetime_validity_check === false) {
            if (!errorSet) {
                error["_status"] = "fail"
                errorSet = true
            }
            tmpPayload["Validity"] = "Please Add Validity."
        }

        if (errorSet) {
            error["payload"] = [tmpPayload]
            this.setState({ response: error })
            return
        }

        var validity = this.state.selectedCoupon.validity;
        if (validity === null) {
            validity = ""
        }

        var tmp_coupon_valid_for_object = []
        for (var i in this.state.selectedCoupon.coupon_valid_for_object) {
            tmp_coupon_valid_for_object.push(
                i
            )
        }


        $("#updateCouponButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Update")

        axios.patch(URL + 'coupon', {
            _id: this.state.selected_coupon_id,
            coupon: this.state.selectedCoupon.coupon,
            coupon_discount: this.state.selectedCoupon.coupon_discount,
            coupon_discount_type: this.state.selectedCoupon.coupon_discount_type,
            coupon_type: this.state.selectedCoupon.coupon_type,
            validity: validity,
            lifetime_validity_check: this.state.selectedCoupon.lifetime_validity_check,
            coupon_valid_for_type: this.state.selectedCoupon.coupon_valid_for_type,
            coupon_valid_for_object: tmp_coupon_valid_for_object,
            description: this.state.selectedCoupon.description
        }, {

            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                $("#updateCouponButton").html("Update")
                that.setState({ response: response["data"] })
            })
    }

    delete_coupon = () => {
        const that = this;
        $("#deleteCouponButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Delete")

        var params = { _id: this.state.selected_coupon_id }
        axios.delete(URL + "coupon", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] })
                $("#deleteCouponButton").html("Delete")
            })
    }

    Pagination = () => {
        const total_pages = this.state.retrivedCoupons[1]
        const page_count = parseInt(Math.ceil(total_pages / 15))

        var pages = []

        return (
            <div class="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div class="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.page_number}
                        itemsCountPerPage={15}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ page_number: parseInt(pageNumber) }, this.get_all_coupons)
                        }}
                    />
                </div>
            </div>
        )
    }


    Section_header_search_reset = (e) => {
        if (this.state.search_term === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ search_term: "" }, this.get_all_coupons);
                }}></i>
            )
        }
    }

    Coupons = () => {
        var coupons_ = []

        for (var item in this.state.retrivedCoupons[0]) {
            var info_string = ""

            if (this.state.retrivedCoupons[0][item].coupon_discount_type == "d") {
                info_string += "Direct ₹" + this.state.retrivedCoupons[0][item].coupon_discount
            }
            else {
                info_string += this.state.retrivedCoupons[0][item].coupon_discount + "%"
            }
            if (this.state.retrivedCoupons[0][item].coupon_type == "d") {
                info_string += " Discount"
            } else {
                info_string += " Less"
            }

            if (this.state.retrivedCoupons[0][item].lifetime_validity_check) {
                info_string += " | Valid For Lifetime"
            } else {
                info_string += " | Valid Until " + moment(this.state.retrivedCoupons[0][item].validity).format('D MMM YYYY h:mm A')
            }
            coupons_.push(

                <a class="inquiry_item list-group-item d-flex justify-content-between align-items-center border" lid={this.state.retrivedCoupons[0][item]._id.$oid} onClick={(e) => {
                    const lid_ = e.target.getAttribute("lid")
                    this.setState({ selected_coupon_id: lid_ }, this.get_single_coupon)
                }}><span lid={this.state.retrivedCoupons[0][item]._id.$oid}><b lid={this.state.retrivedCoupons[0][item]._id.$oid}>{this.state.retrivedCoupons[0][item].coupon}</b><br />{info_string}</span><span lid={this.state.retrivedCoupons[0][item]._id.$oid}>{moment(this.state.retrivedCoupons[0][item]["_created"].$date).format('D MMM')}</span></a>

            )
        }

        return (
            <div class="list-group">
                {coupons_}
            </div>
        )
    }

    Dialog = (props) => {
        return (
            <div class="modal fade" id={props.label} tabindex="-1" role="dialog" aria-labelledby={props.label} aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{props.h_text}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{props.msg}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" name={props.b_name} class={`btn ` + props.c_text} onClick={props.handler} data-dismiss="modal">{props.b_label}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
    handle_search = (query) => {

        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'data', {
            params: {
                page_number: 0,
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"]["data"] } })

            })

    }

    SelectedList = () => {
        var list_ = []
        for (var i in this.state.selectedCoupon.coupon_valid_for_object) {
            list_.push(
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span>{this.state.selectedCoupon.coupon_valid_for_object[i]} </span>
                    <i lid={i} style={{ cursor: 'pointer' }} class="fas fa-times-circle" onClick={(e) => {
                        const lid = e.target.getAttribute('lid')
                        var tmp_object = this.state.selectedCoupon
                        delete tmp_object.coupon_valid_for_object[lid]
                        this.setState({ selectedCoupon: tmp_object })
                    }}></i>
                </li>
            )
        }
        return (
            <div style={{ maxHeight: '300px', overflow: 'scroll', overflowX: 'hidden', WebkitOverflowScrolling: 'touch' }}>
                <ul class="list-group">
                    {list_}
                </ul>
            </div>
        )
    }
    EditCoupon = () => {
        return (
            <div className="container p-4">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">

                        <label for="editCoupon"><b>Coupon</b></label>
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                                <input maxlength="12" type="text" id="editCoupon" className="form-control border-1 small" name="coupon" value={this.state.selectedCoupon.coupon} onChange={(e) => {
                                    var tmp_coupon = this.state.selectedCoupon
                                    tmp_coupon.coupon = e.target.value
                                    this.setState({ selectedCoupon: tmp_coupon })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="editCouponRadios" id="editCouponRadio1" value="d"
                                        checked={this.state.selectedCoupon.coupon_type === "d"}
                                        onChange={(e) => {
                                            var tmp_coupon = this.state.selectedCoupon
                                            tmp_coupon.coupon_type = e.target.value
                                            this.setState({ selectedCoupon: tmp_coupon })
                                        }}
                                    />
                                    <label class="form-check-label" for="editCouponRadio1">
                                        Discount
                                </label>
                                </div>
                                <div class="form-check ml-2">
                                    <input class="form-check-input" type="radio" name="editCouponRadios" id="editCouponRadio2" value="l"
                                        checked={this.state.selectedCoupon.coupon_type === "l"}
                                        onChange={(e) => {
                                            var tmp_coupon = this.state.selectedCoupon
                                            tmp_coupon.coupon_type = e.target.value
                                            this.setState({ selectedCoupon: tmp_coupon })
                                        }}
                                    />
                                    <label class="form-check-label" for="editCouponRadio2">
                                        Less
                                </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="editCouponDiscount"><b>Discount</b></label>
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">

                                <input type="number" id="editCouponDiscount" className="form-control border-1 small" name="coupondiscount" value={this.state.selectedCoupon.coupon_discount} onChange={(e) => {
                                    var tmp_coupon = this.state.selectedCoupon
                                    tmp_coupon.coupon_discount = e.target.value
                                    this.setState({ selectedCoupon: tmp_coupon })
                                }} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="editDiscountRadios" id="editDiscountRadio1" value="%"
                                        checked={this.state.selectedCoupon.coupon_discount_type === "%"}
                                        onClick={(e) => {

                                            var tmp_coupon = this.state.selectedCoupon
                                            tmp_coupon.coupon_discount_type = e.target.value
                                            this.setState({ selectedCoupon: tmp_coupon })
                                        }}
                                    />
                                    <label class="form-check-label" for="editDiscountRadio1">
                                        Percentage
                                </label>
                                </div>
                                <div class="form-check ml-2">
                                    <input class="form-check-input" type="radio" name="editDiscountRadios" id="editDiscountRadio2" value="d"
                                        checked={this.state.selectedCoupon.coupon_discount_type === "d"}
                                        onClick={(e) => {
                                            var tmp_coupon = this.state.selectedCoupon
                                            tmp_coupon.coupon_discount_type = e.target.value
                                            this.setState({ selectedCoupon: tmp_coupon })
                                        }}
                                    />
                                    <label class="form-check-label" for="editDiscountRadio2">
                                        Direct
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="validity"><b>Validity</b></label>
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                                <DatePicker
                                    id="validitydatepicker"
                                    showTimeSelect
                                    selected={Date.parse(this.state.selectedCoupon.validity)}
                                    onChange={(date) => {
                                        var tmp_coupon = this.state.selectedCoupon
                                        tmp_coupon.validity = date
                                        tmp_coupon.lifetime_validity_check = false
                                        this.setState({ selectedCoupon: tmp_coupon })
                                    }}
                                    dateFormat="dd/MM/yyyy hh:mm:ss"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center ">

                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="editLifetimeValidityCheck" checked={this.state.selectedCoupon.lifetime_validity_check} onChange={(e) => {
                                        var tmp_coupon = this.state.selectedCoupon
                                        tmp_coupon.lifetime_validity_check = e.target.checked
                                        if (e.target.checked) {
                                            tmp_coupon.validity = null
                                            this.setState({ selectedCoupon: tmp_coupon })
                                        }
                                        else {
                                            this.setState({ selectedCoupon: tmp_coupon })
                                        }

                                    }} />
                                    <label class="form-check-label" for="editLifetimeValidityCheck">Lifetime Validity</label>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="couponDescription"><b>Description</b></label>
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                <textarea type="text" id="couponDescription" className="form-control border-1 small" name="couponDescription" value={this.state.selectedCoupon.description} onChange={(e) => {
                                    var tmp_coupon = this.state.selectedCoupon
                                    tmp_coupon.description = e.target.value
                                    this.setState({ selectedCoupon: tmp_coupon })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="validity"><b>Valid For</b></label>
                        <div className="row m-0 p-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                <AsyncTypeahead type="text" placeholder="Search" name="to" id="invoiceCustomer"
                                    labelKey={option => `${option.firstname} ${option.lastname} | ${option.phone} | ${option.billingAddressCity} | ${option.handler}`}
                                    filterBy={['firstname', 'lastname', 'phone', 'handler', 'billingAddressCity']}
                                    onSearch={this.handle_search}
                                    {...this.state.asyncsearch}
                                    maxResults="10"
                                    ref={(typeahead) => this.typeahead_select_customer = typeahead}
                                    renderMenu={(results, menuProps) => (
                                        <Menu {...menuProps} className="search_menu" id="search_menu">
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} className="search_item m-0">
                                                    <div className="w-100 p-2 search_item" onClick={(e) => {
                                                        //   $("#search_menu").hide();
                                                        var tmp_coupon = this.state.selectedCoupon
                                                        tmp_coupon['coupon_valid_for_object'][result._id.$oid] = result.firstname + result.lastname + " | " + result.phone + " | " + result.billingAddressCity + " | " + result.handler
                                                        this.setState({ selectedCoupon: tmp_coupon }, () => {
                                                            const ref_ = this.typeahead_select_customer.getInstance()
                                                            ref_.clear()
                                                        })

                                                    }}>

                                                        {`${result.firstname} ${result.lastname} | ${result.phone} | ${result.billingAddressCity} | ${result.handler}`}


                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex align-items-center m-2">

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="editValidForRadios" id="editValidForRadios1" value="e"
                                checked={this.state.selectedCoupon.coupon_valid_for_type === "e"}
                                onClick={(e) => {

                                    var tmp_coupon = this.state.selectedCoupon
                                    tmp_coupon.coupon_valid_for_type = e.target.value
                                    this.setState({ selectedCoupon: tmp_coupon })

                                }}
                            />
                            <label class="form-check-label" for="editValidForRadios1">
                                Everyone
                                </label>
                        </div>
                        <div class="form-check ml-2">
                            <input class="form-check-input" type="radio" name="editValidForRadios" id="editValidForRadios2" value="si"
                                checked={this.state.selectedCoupon.coupon_valid_for_type === "si"}
                                onClick={(e) => {
                                    var tmp_coupon = this.state.selectedCoupon
                                    tmp_coupon.coupon_valid_for_type = e.target.value
                                    this.setState({ selectedCoupon: tmp_coupon })

                                }}
                            />
                            <label class="form-check-label" for="editValidForRadios2">
                                Select (Include)
                        </label>
                        </div>
                        <div class="form-check ml-2">
                            <input class="form-check-input" type="radio" name="editValidForRadios" id="editValidForRadios3" value="se"
                                checked={this.state.selectedCoupon.coupon_valid_for_type === "se"}
                                onClick={(e) => {
                                    var tmp_coupon = this.state.selectedCoupon
                                    tmp_coupon.coupon_valid_for_type = e.target.value
                                    this.setState({ selectedCoupon: tmp_coupon })

                                }}
                            />
                            <label class="form-check-label" for="editValidForRadios3">
                                Select (Exclude)
                        </label>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <this.SelectedList />
                    </div>
                </div>



                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <button type="button" id="updateCouponButton" class="btn btn-primary m-0 mt-2" data-toggle="modal" data-target="#updateCouponDialog">Update</button>
                        <button type="button" class="btn btn-primary ml-2 mt-2" onClick={() => {
                            $("#editDiscountRadio1").prop('checked', false);
                            $("#editDiscountRadio2").prop('checked', false);
                            $("#editCouponRadio1").prop('checked', false);
                            $("#editCouponRadio2").prop('checked', false);
                            $("#editValidForRadios1").prop('checked', false);
                            $("#editValidForRadios2").prop('checked', false);
                            $("#editValidForRadios3").prop('checked', false);
                            
                            this.setState(
                                {
                                    selectedCoupon: {
                                        coupon: "",
                                        coupon_discount: "",
                                        coupon_discount_type: "",
                                        coupon_type: "",
                                        validity: null,
                                        lifetime_validity_check: true,
                                        description: "",
                                        coupon_valid_for_type: "",
                                        coupon_valid_for_object: {},

                                    },
                                    response: {}
                                }
                            )
                        }}>Clear</button>
                        <button type="button" id="deleteCouponButton" class="btn btn-danger ml-2 mt-2" data-toggle="modal" data-target="#deleteCouponDialog">Delete</button>
                    </div>
                </div>
                <this.Dialog name="deleteCoupon" b_name="Delete" msg={`Delete Coupon ` + this.state.selectedCoupon.coupon + ` ?`} h_text="Delete Coupon" c_text="btn-danger" b_label="Delete" handler={this.delete_coupon} label="deleteCouponDialog" />
                <this.Dialog name="updateCoupon" b_name="Update" msg={`Update Coupon ` + this.state.selectedCoupon.coupon + ` ?`} h_text="Update Coupon" c_text="btn-primary" b_label="Update" handler={this.update_coupon} label="updateCouponDialog" />

            </div >
        )
    }

    render() {
        if (this.state.editCouponBool) {
            return [
                <div className="container p-4">
                    <div class="row p-0 m-0">
                        <span class=""><span class="btn-link" style={{ cursor: 'pointer' }} onClick={() => {

                            this.setState({ editCouponBool: false, response: {} }, () => {
                                this.get_all_coupons();
                                // this.initTimer()
                            })

                        }}><h5><i class="fas fa-arrow-left"></i> Back</h5></span></span>
                    </div>
                </div>,
                <this.EditCoupon />,
                <div className="container pl-4 pr-4">
                    <this.Status />
                </div>
            ]
        }
        return (
            <div className="contrainer p-4">
                <div className="d-inline-flex align-items-center w-100">
                    <DebounceInput minLength={2} debounceTimeout={500} type="text" class="form-control my-1 w-100" id="sectionHeaderSearch" aria-describedby="search coupons" placeholder="Search Coupons" value={this.state.search_term} onChange={(e) => {
                        this.setState({ search_term: e.target.value }, () => {
                            this.setState({ page_number: 1 }, this.get_all_coupons)
                        })
                    }} />
                    <this.Section_header_search_reset />
                </div>
                <this.Pagination />
                <this.Coupons />
            </div>
        )
    }
}

class Coupons extends React.Component {
    render() {
        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Coupons</h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div class="row">

                    <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Add Coupons</h6>
                            </div>
                            <div class="m-0">
                                <AddCoupons />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Search Coupons</h6>
                            </div>
                            <div class="m-0">
                                <SearchCoupons />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Coupons 