

// var JWURL = "http://localhost:8080/api/ecom/"
// var JWURL = "http://192.168.0.110:5000/api/"
var JWURL = "https://846cd69b01e9f66a.joypurwala.com/api/ecom/"
var JWURLFRONT = "https://qnevg3dzke3wukhcewbc.joypurwala.com/"

export {
    JWURL,
    JWURLFRONT
} 