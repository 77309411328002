import React from 'react';
import { ItemSearch } from './ItemSearch';
import { fullDialogDispatcher } from './FullDialog';
import { DateTime } from 'luxon';
import axios from 'axios';
import URL from './URL';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

var ACCT = Cookies.get('ACCT')


class Handlers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { "handlers": {} };
        const that = this;
        axios.post(URL + 'company/handlers', {
        }, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ "handlers": response['data']['payload'] });
            })

        this.selectedHandler = props.handler;
    }
    render() {
        var handlers = []
        for (var handler in this.state.handlers) {
            var name_ = this.state.handlers[handler].firstname + " " + this.state.handlers[handler].lastname;
            if (name_ == this.selectedHandler) {

                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_} selected>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
            else {
                handlers.push(
                    <option lid={this.state.handlers[handler]["_id"]["$oid"]} value={name_}>{this.state.handlers[handler].firstname}&nbsp;{this.state.handlers[handler].lastname}&nbsp;|&nbsp;{this.state.handlers[handler].phone}</option>
                )
            }
        }
        return (
            handlers
        );
    }
}

class ResellerRegistrationApproveData extends React.Component {
    constructor(props) {
        super()
        this.data = props.data

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            handlerID: "",
        }
    }


    handleApproveRegistration = (response) => {

        if (!("_status" in response) || !("payload" in response)) {
            // alert("Unable to approve description")
            this.setState({ isLoading: false, isError: true })
            fullDialogDispatcher.dispatch({
                actionType: 'show-dialog',
                title: 'Approve Registration?',
                message: <div>
                    <h3>
                        <span className="text-danger mr-2">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                        <span>
                            Some unknown error has occurred. Please try again.
                    </span>
                    </h3>
                </div>
            })
            return
        }
        if (response["_status"] !== "success") {
            this.setState({ isLoading: false, isError: true })
            fullDialogDispatcher.dispatch({
                actionType: 'show-dialog',
                title: 'Approve Registration?',
                message: <div>
                    <h3>
                        <span className="text-danger mr-2">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                        <span>
                            Failed to approve data. {response.payload}.
                </span>
                    </h3>
                </div>
            })
            return;
        }

        this.setState({ isLoading: false, isError: false, isSuccess: true })

        fullDialogDispatcher.dispatch({
            actionType: 'show-dialog',
            title: 'Approve Registration?',
            message: <div>
                <h3>
                    <span className="text-success mr-2">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <span>
                        {response.payload}.
            </span>
                </h3>
            </div>
        })
    }

    approveRegistration = () => {


        var handlerDOM = document.getElementById("approvedatahandler")
        // reset error
        handlerDOM.classList.remove("is-invalid")

        // if handler is selected or not

        if (this.state.handlerID == "") {
            handlerDOM.classList.add("is-invalid")
            document.getElementsByClassName("is-invalid")[0].focus()
            return
        }

        const that = this
        this.setState({ isLoading: true })
        axios.post(URL + "intermediatory", {
            id: this.data._id.$oid,
            hid: this.state.handlerID
        }, {
            params: {
                g: "apr"
            },
            headers: {
                Authorization: ACCT
            },
        })
            .then(function (response) {
                that.handleApproveRegistration(response.data)
            })
            .catch(function (error) {
                console.log(error)
                that.handleApproveRegistration({})
            })
    }

    render() {
        return (
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="approvedatahandler">Select Handler</label>
                    <select id="approvedatahandler" className="custom-select" onChange={(e) => {
                        const handlerID = e.target.selectedOptions[0].getAttribute("lid")
                        this.setState({ handlerID: handlerID })
                    }}>
                        <option value="">Select Handler</option>
                        <Handlers />
                    </select>
                    <div className="invalid-feedback">
                        Please select a Handler
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="approvedatadata">Data</label>
                    <table id="approvedatadata" class="table">
                        <thead>
                            <tr>
                                <th scope="col">Field</th>
                                <th scope="col">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Firstname
                                </td>
                                <td>
                                    {this.data.firstname}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    Lastname
                                </td>
                                <td>
                                    {this.data.lastname}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    Phone
                                </td>
                                <td>
                                    {this.data.phone}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    Email
                                </td>
                                <td>
                                    {this.data.email}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    GSTIN
                                </td>
                                <td>
                                    {this.data.gstin}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    Brand
                                </td>
                                <td>
                                    {this.data.brand}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Twitter
                            </td>
                                <td>
                                    {this.data.twitter}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Facebook
                            </td>
                                <td>
                                    {this.data.facebook}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Instagram
                            </td>
                                <td>
                                    {this.data.instagram}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Website
                            </td>
                                <td>
                                    {this.data.website}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    WhatsApp
                            </td>
                                <td>
                                    {this.data.whatsapp}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Billing Address 1
                            </td>
                                <td>
                                    {this.data.billingaddress1}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Billing Address 2
                            </td>
                                <td>
                                    {this.data.billingaddress2}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Billing Address Country
                            </td>
                                <td>
                                    {this.data.billingaddresscountry}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Billing Address State
                            </td>
                                <td>
                                    {this.data.billingaddressstate}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Billing Address City
                            </td>
                                <td>
                                    {this.data.billingaddresscity}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Billing Address Pincode
                            </td>
                                <td>
                                    {this.data.billingaddresspincode}
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    Shipping Address 1
                            </td>
                                <td>
                                    {this.data.shippingaddress1}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping Address 2
                            </td>
                                <td>
                                    {this.data.shippingaddress2}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping Address Country
                            </td>
                                <td>
                                    {this.data.shippingaddresscountry}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping Address State
                            </td>
                                <td>
                                    {this.data.shippingaddressstate}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping Address City
                            </td>
                                <td>
                                    {this.data.shippingaddresscity}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping Address Pincode
                            </td>
                                <td>
                                    {this.data.shippingaddresspincode}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Interests
                            </td>
                                <td>
                                    <ul class="list-group list-group-flush">
                                        {this.data.interests.map((val, index) => (
                                            <li class="list-group-item">{val}</li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Time Created
                            </td>
                                <td>
                                    {DateTime.fromMillis(this.data.time.$date).toLocaleString(DateTime.DATETIME_MED)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="form-group">
                    <button disabled={this.state.isLoading} className="btn btn-primary" onClick={this.approveRegistration}>
                        <span hidden={!this.state.isLoading} className="mr-1"><FontAwesomeIcon icon={faCircleNotch} spin /></span>
                        Approve
                    </button>
                </div>
            </div>
        )
    }
}

class ViewResellerRegistrations extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }


    render() {
        return (
            <div>
                <ItemSearch nameTitleKey="firstname" subNameTitleKey="lastname" subHeadingKeys={["phone", "email"]} onClickCallback={(resultData) => {
                    // const id = this.state.slider[iid]["_id"]
                    // this.addItem(resultData)

                    fullDialogDispatcher.dispatch({
                        actionType: 'show-dialog',
                        title: 'Approve Registration?',
                        message: <ResellerRegistrationApproveData data={resultData} />
                    })
                }} resultClickable={true} isResultLink={false} timeKey="time" sortCriteria="up" searchAllowed={false} gType="get" urlEndPoint="intermediatory" />
            </div>
        )
    }
}


class ResellerRegistrations extends React.Component {
    constructor(props) {
        super()
        this.state = {
            columnedBool: false,
            columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between"
        }
    }

    render() {
        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Reseller Registrations &nbsp;&nbsp;<i class="fas fa-columns" style={{ cursor: 'pointer' }} title="Toggle Split" onClick={() => {
                        if (this.state.columnedBool) {
                            this.setState({ columnClass: "col-md-12 col-sm-12 col-xl-12 col-lg-12 align-items-center justify-content-between", columnedBool: false })
                        } else {
                            this.setState({ columnClass: "col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between", columnedBool: true })
                        }
                    }}></i></h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div className="row">

                    <div className={this.state.columnClass}>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Registrations</h6>
                            </div>
                            <div class="m-0">
                                <ViewResellerRegistrations />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default ResellerRegistrations