import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTimesCircle, faInfoCircle, faCheckCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Dispatcher = require('flux').Dispatcher;

var fullDialogV2Dispatcher = new Dispatcher()


Object.size = function (obj) {
    var size = 0,
        key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

class FullDialogV2 extends React.Component {
    constructor(props) {
        super()
        this.state = {
            show: false,
            title: "",
            message: "",
            isError: false,
            isInfo: false,
            isSuccess: false,
            isLoading: false,
            closeDisable: false,
            closeOnOutsideClick: false,
            loadingMessage: "",
            dialogWidth: 1,

            dialogIndex: 1,
            dialogs: {}

        }
    }

    getDialogIndex() {
        const dialogIndex = parseInt(this.state.dialogIndex)
        this.setState({ dialogIndex: dialogIndex + 1 })
        return dialogIndex
    }

    componentDidMount() {
        const that = this
        fullDialogV2Dispatcher.register(function (payload) {
            if (payload.actionType === "show-dialog") {

                const dialogIndex = that.getDialogIndex()
                const tmpDialogs = that.state.dialogs

                tmpDialogs[dialogIndex] = {
                    dialogIndex: dialogIndex,
                    title: payload.title,
                    message: payload.message,
                    isError: payload.isError,
                    isInfo: payload.isInfo,
                    isSuccess: payload.isSuccess,
                    isLoading: payload.isLoading,
                    closeDisable: payload.closeDisable,
                    closeOnOutsideClick: payload.closeOnOutsideClick,
                    loadingMessage: payload.loadingMessage,
                    dialogWidth: payload.dialogWidth
                }

                if (payload.dialogIndexCallback !== null && payload.dialogIndexCallback !== undefined) {
                    payload.dialogIndexCallback(dialogIndex)
                }

                that.setState({
                    dialogs: tmpDialogs
                })

            }

            if (payload.actionType === "close-dialog") {
                const tmpDialogs = that.state.dialogs
                delete tmpDialogs[payload.dialogIndex]
                that.setState({ dialogs: tmpDialogs })
            }

            if (payload.actionType === "close-all-dialogs") {
                that.setState({ dialogs: {} })
                if (payload.callback !== null && payload.callback !== undefined) {
                    payload.callback()
                }
            }

            if (payload.actionType === "close-disable") {

                const tmpDialogs = that.state.dialogs
                tmpDialogs[payload.dialogIndex].closeDisable = payload.closeDisable

                that.setState({ dialogs: tmpDialogs })
            }

        })
    }


    render() {
        // if (!this.state.show) {
        //     return (
        //         null
        //     )
        // }

        if (Object.size(this.state.dialogs) < 1) {
            return null
        }

        // set 

        var dialogs = []
        var dialogNumber = 0
        for (var i in this.state.dialogs) {
            const idx = i
            dialogNumber += 1

            // get message icon
            var messageIcon = null
            if (this.state.dialogs[i].isError) {
                messageIcon = <FontAwesomeIcon className="text-danger" icon={faTimesCircle} title="Error" />
            }
            if (this.state.dialogs[i].isInfo) {
                messageIcon = <FontAwesomeIcon className="text-info" icon={faInfoCircle} title="Error" />
            }
            if (this.state.dialogs[i].isSuccess) {
                messageIcon = <FontAwesomeIcon className="text-success" icon={faCheckCircle} title="Error" />
            }
            //

            var loadingIcon = null

            if (this.state.dialogs[i].isLoading) {
                loadingIcon = <div className="d-flex justify-content-start align-items-center">
                    <FontAwesomeIcon className="mr-1" icon={faCircleNotch} title="Loading" spin /><span>{this.state.dialogs[i].loadingMessage}</span>
                </div>
            }

            // set dialog width

            var dialogWidth = "col-lg-4 col-xl-4 xl-sm-10"

            if (this.state.dialogs[i].dialogWidth === 2) {
                dialogWidth = "col-lg-6 col-xl-6 xl-sm-10"
            } else if (this.state.dialogs[i].dialogWidth === 3) {
                dialogWidth = "col-lg-8 col-xl-8 xl-sm-10"
            } else if (this.state.dialogs[i].dialogWidth === 4) {
                dialogWidth = "col-lg-10 col-xl-10 xl-sm-10"
            } else if (this.state.dialogs[i].dialogWidth === 5) {
                dialogWidth = "col-lg-11 col-xl-11 xl-sm-10"
            }

            // background alpha

            var bgAlpha = 0.55

            if (dialogNumber > 1) {
                bgAlpha = 0.3
            }

            dialogs.push(
                <div className="d-flex justify-content-center align-items-center " style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    bottom: '0',
                    right: '0',
                    background: `rgba(0, 0, 0, ${bgAlpha})`,
                    minHeight: "100vh",
                    zIndex: 1000 + parseInt(idx),
                }} onClick={(e) => {
                    if (this.state.dialogs[i].closeOnOutsideClick) {
                        const tmpDialogs = this.state.dialogs
                        delete tmpDialogs[idx]
                        this.setState({ dialogs: tmpDialogs })
                    }
                }} hidden>
                    <div className={`card p-4 col-md-5 ${dialogWidth} full-dialog-animation ml-2 mr-2`} style={{
                        // position: 'absolute',
                        // top: '100',
                        // left: '100',
                        // background:"pink",
                        // color:"white"

                        maxHeight: "90vh",
                        borderRadius: '0',
                        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                        // marginTop: `${dialogNumber * 4}em`
                    }} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>
                                <h4>
                                    {this.state.dialogs[i].title}
                                </h4>
                            </span>
                            <a href="javascript:void()">

                                <FontAwesomeIcon icon={faTimes} title="Close" className="edit-address-button" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    const tmpDialogs = this.state.dialogs
                                    delete tmpDialogs[idx]
                                    this.setState({ dialogs: tmpDialogs })
                                    // this.setState({ show: false })
                                }} hidden={this.state.dialogs[i].closeDisable} />
                            </a>
                        </div>
                        <h3>
                            {messageIcon}
                        </h3>
                        {loadingIcon}
                        <div style={{
                            overflowY: 'auto',
                        }}>
                            {this.state.dialogs[i].message}
                        </div>
                    </div>
                </div>
            )
        }

        return (

            dialogs

        )
    }
}


export {
    FullDialogV2,
    fullDialogV2Dispatcher
}