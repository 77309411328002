import React, { useState, useEffect, useCallback } from 'react';

//import logo from './logo.svg';
// import './App.css';
import Db from './db';
import Company from './Company';
import MessageCenter from './MessageCenter';
import Reports from './Reports';
import Orders from './Orders';
import Cookies from 'js-cookie';
import axios from 'axios';
import URL from './URL';
import Inquiry from './Inquiry';
import Inventory from './Inventory';
import Invoice from './Invoice';
import Notebook from './Notebook'
import Tracking from './Tracking';
import ApprovalInvoice from './ApprovalInvoice';
import LocationServices from './LocationServices';
import { ReactSVG } from 'react-svg'
import Coupons from './Coupons';
import Ecommerce from './Ecommerce';
import { FullDialog } from './FullDialog';
import ResellerRegistrations from './ResellerRegistrations';
import Marketing from './Marketing';
import { FullDialogV2 } from './FullDialogV2';

var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')

if (userInfo != null) {
  userInfo = JSON.parse(userInfo);
  account_type = userInfo['account_type'];

}

const handleLogout = (e) => {
  Cookies.remove('ACCT');
  Cookies.remove('U');
  Cookies.remove('TP');
  window.location.reload();
}

const logout = (e) => {
  axios.post(URL + 'logout', {

  },
    {
      headers: { Authorization: ACCT }
    }
  )
    .then(function (response) {
      console.log(response['data']);
      handleLogout();
    })
    .catch(function (error) {
      console.log(error)
    })
  console.log("logging out");
}



function Dashboard() {

  const [showCompanyBool, setShowCompanyBool] = useState(false);
  const [showDatabaseBool, setShowDatabaseBool] = useState(false);
  const [showMessageCenterBool, setShowMessageCenterBool] = useState(false);
  const [showReportsBool, setShowReportsBool] = useState(false);
  const [showOrdersBool, setShowOrdersBool] = useState(false);
  const [showInquiryBool, setShowInquiryBool] = useState(false);
  const [showInventoryBool, setShowInventoryBool] = useState(false);
  const [showInvoiceBool, setShowInvoiceBool] = useState(false);
  const [showNotesBool, setShowNotesBool] = useState(false);
  const [showActivityBool, setShowActivityBool] = useState(false);
  const [showTrackingBool, setShowTrackingBool] = useState(false);
  const [showCouponsBool, setShowCouponsBool] = useState(false);
  const [showApprovalInvoiceBool, setShowApprovalInvoiceBool] = useState(false);
  const [showLocationServicesBool, setShowLocationServicesBool] = useState(false);
  const [showEcommerceBool, setShowEcommerceBool] = useState(false);
  const [showResellerRegistrationsBool, setShowResellerRegistrationsBool] = useState(false);
  const [showMarketingBool, setShowMarketingBool] = useState(false);

  document.addEventListener('keydown', event => {

    // shortcut handling here!

  });

  const BackButton = (e) => {
    return (
      <div class="back_button" onClick={(e) => {
        hideAllApps();
      }}>
        <h5>
          <i className="fas fa-arrow-left mr-2 mb-3"></i>
          <span>Back</span>
        </h5>
      </div>
    )
  }


  const DashboardApp = (e) => {
    if (account_type == 'owner') {
      if (showCompanyBool) {
        return [
          <BackButton />,
          <Company />
        ]

      }
      if (showDatabaseBool) {
        return [
          <BackButton />,
          <Db />
        ]

      }
      if (showMessageCenterBool) {
        return (
          <MessageCenter />
        );
      }
      if (showReportsBool) {
        return [
          <BackButton />,
          <Reports />
        ];
      }
      if (showOrdersBool) {
        return [
          <BackButton />,
          <Orders />
        ];
      }
      if (showInquiryBool) {
        return [
          <BackButton />,
          <Inquiry />
        ]
      }
      if (showInventoryBool) {
        return [
          <BackButton />,
          <Inventory />
        ]
      }
      if (showInvoiceBool) {
        return [
          <BackButton />,
          <Invoice />
        ]
      }

      if (showNotesBool) {
        return [
          <BackButton />,
          <Notebook />
        ]
      }
      if (showTrackingBool) {
        return [
          <BackButton />,
          <Tracking />
        ]
      }

      if (showCouponsBool) {
        return [
          <BackButton />,
          <Coupons />
        ]
      }

      if (showApprovalInvoiceBool) {
        return [
          <BackButton />,
          <ApprovalInvoice />
        ]
      }

      if (showLocationServicesBool) {
        return [
          <BackButton />,
          <LocationServices />
        ]
      }

      if (showEcommerceBool) {
        return [
          <BackButton />,
          <Ecommerce />
        ]
      }

      if (showResellerRegistrationsBool) {
        return [
          <BackButton />,
          <ResellerRegistrations />
        ]
      }

      if (showMarketingBool) {
        return [
          <BackButton/>,
          <Marketing />
        ]
      }

    }
    if (account_type == 'Handler') {
      if (showDatabaseBool) {
        return [
          <BackButton />,
          <Db />
        ];
      }
      if (showMessageCenterBool) {
        return [
          <BackButton />,
          <MessageCenter />
        ];
      }
      if (showReportsBool) {
        return [
          <BackButton />,
          <Reports />
        ];
      }
      if (showOrdersBool) {
        return [
          <BackButton />,
          <Orders />
        ];
      }
      if (showInquiryBool) {
        return [
          <BackButton />,
          <Inquiry />
        ]
      }
      if (showInventoryBool) {
        return [
          <BackButton />,
          <Inventory />
        ]
      }
      if (showInvoiceBool) {
        return [
          <BackButton />,
          <Invoice />
        ]
      }
      if (showNotesBool) {
        return [
          <BackButton />,
          <Notebook />
        ]
      }
      if (showTrackingBool) {
        return [
          <BackButton />,
          <Tracking />
        ]
      }
    }
    if (account_type == 'Clerk') {
      if (showDatabaseBool) {
        return [
          <BackButton />,
          <Db />
        ];
      }
      if (showNotesBool) {
        return [
          <BackButton />,
          <Notebook />
        ]
      }
      if (showTrackingBool) {
        return [
          <BackButton />,
          <Tracking />
        ]
      }
    }
    if (account_type == 'Sales') {
      // if (showCompanyBool) {
      //   return [
      //     <BackButton />,
      //     <Company />
      //   ]

      // }
      // if (showDatabaseBool) {
      //   return [
      //     <BackButton />,
      //     <Db />
      //   ]

      // }
      if (showMessageCenterBool) {
        return (
          <MessageCenter />
        );
      }
      if (showReportsBool) {
        return [
          <BackButton />,
          <Reports />
        ];
      }
      // if (showOrdersBool) {
      //   return [
      //     <BackButton />,
      //     <Orders />
      //   ];
      // }
      // if (showInquiryBool) {
      //   return [
      //     <BackButton />,
      //     <Inquiry />
      //   ]
      // }
      if (showInventoryBool) {
        return [
          <BackButton />,
          <Inventory />
        ]
      }
      if (showInvoiceBool) {
        return [
          <BackButton />,
          <Invoice />
        ]
      }

      if (showNotesBool) {
        return [
          <BackButton />,
          <Notebook />
        ]
      }
      if (showTrackingBool) {
        return [
          <BackButton />,
          <Tracking />
        ]
      }
      if (showCouponsBool) {
        return [
          <BackButton />,
          <Coupons />
        ]
      }
      if (showApprovalInvoiceBool) {
        return [
          <BackButton />,
          <ApprovalInvoice />
        ]
      }
    }
    if (account_type == 'Invoice') {
      // if (showCompanyBool) {
      //   return [
      //     <BackButton />,
      //     <Company />
      //   ]

      // }
      // if (showDatabaseBool) {
      //   return [
      //     <BackButton />,
      //     <Db />
      //   ]

      // }
      if (showMessageCenterBool) {
        return (
          <MessageCenter />
        );
      }
      if (showReportsBool) {
        return [
          <BackButton />,
          <Reports />
        ];
      }
      // if (showOrdersBool) {
      //   return [
      //     <BackButton />,
      //     <Orders />
      //   ];
      // }
      // if (showInquiryBool) {
      //   return [
      //     <BackButton />,
      //     <Inquiry />
      //   ]
      // }
      // if (showInventoryBool) {
      //   return [
      //     <BackButton />,
      //     <Inventory />
      //   ]
      // }
      if (showInvoiceBool) {
        return [
          <BackButton />,
          <Invoice />
        ]
      }

      if (showNotesBool) {
        return [
          <BackButton />,
          <Notebook />
        ]
      }
      if (showTrackingBool) {
        return [
          <BackButton />,
          <Tracking />
        ]
      }
      if (showApprovalInvoiceBool) {
        return [
          <BackButton />,
          <ApprovalInvoice />
        ]
      }
    }
    if (account_type == 'Inventory') {
      // if (showCompanyBool) {
      //   return [
      //     <BackButton />,
      //     <Company />
      //   ]

      // }
      // if (showDatabaseBool) {
      //   return [
      //     <BackButton />,
      //     <Db />
      //   ]

      // }
      if (showMessageCenterBool) {
        return (
          <MessageCenter />
        );
      }
      if (showReportsBool) {
        return [
          <BackButton />,
          <Reports />
        ];
      }
      // if (showOrdersBool) {
      //   return [
      //     <BackButton />,
      //     <Orders />
      //   ];
      // }
      // if (showInquiryBool) {
      //   return [
      //     <BackButton />,
      //     <Inquiry />
      //   ]
      // }
      if (showInventoryBool) {
        return [
          <BackButton />,
          <Inventory />
        ]
      }
      // if (showInvoiceBool) {
      //   return [
      //     <BackButton />,
      //     <Invoice />
      //   ]
      // }

      if (showNotesBool) {
        return [
          <BackButton />,
          <Notebook />
        ]
      }
      if (showTrackingBool) {
        return [
          <BackButton />,
          <Tracking />
        ]
      }
    }
    return (
      <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="row  w-100 d-flex align-items-center justify-content-left">

          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleDatabase}>
            <h1><i class="fas fa-database"></i></h1>
            Database
          </div>
          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleInventory}>
            <h1><i class="fas fa-truck-loading"></i></h1>
            Inventory
          </div>
          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleInquiry}>
            <h1><i class="fas fa-list"></i></h1>
            Inquiry
          </div>
          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleApprovalInvoice}>
            <h1>
              <i class="fas fa-check-circle"></i>
            </h1>
            Approval Invoice
          </div>
          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleInvoice}>
            <h1><i class="fas fa-file-alt"></i></h1>
            Invoice
          </div>
          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleOrders}>
            <h1><i class="fas fa-box-open"></i></h1>
            Orders
          </div>

          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleTracking}>
            <h1><i class="fas fa-shipping-fast"></i></h1>
            Tracking
          </div>

          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleCoupons}>
            <h1><i class="fas fa-ticket-alt"></i></h1>
            Coupons
          </div>

          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleNotes}>
            <h1><i class="fas fa-clipboard"></i></h1>
            Notebooks
          </div>

          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleLocationServices}>
            <h1><i class="fas fa-compass"></i></h1>
            Location Services
          </div>

          {/* <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleEcommerce}>
            <h1><i class="fas fa-shopping-cart"></i></h1>
            E-commerce
          </div> */}
          <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleResellerRegistrations}>
            <h1><i class="fas fa-store-alt"></i></h1>
            Reseller Registrations
          </div>

          {/* <div className="text-center m-2 inquiry_selected_buttons col-sm-6 col-md-2 col-lg-2 col-xl-2" data-toggle="collapse" data-target="#collapseTwo" href="#" onClick={handleMarketing}>
            <h1><i class="fas fa-satellite-dish"></i></h1>
            Marketing
          </div> */}

        </div>
      </div>
    )

  }

  const hideAllApps = (e) => {
    setShowCompanyBool(false);
    setShowDatabaseBool(false);
    setShowMessageCenterBool(false);
    setShowReportsBool(false);
    setShowOrdersBool(false);
    setShowInquiryBool(false);
    setShowInventoryBool(false);
    setShowInvoiceBool(false);
    setShowNotesBool(false);
    setShowActivityBool(false);
    setShowTrackingBool(false);
    setShowCouponsBool(false);
    setShowApprovalInvoiceBool(false);
    setShowLocationServicesBool(false);
    setShowEcommerceBool(false);
    setShowResellerRegistrationsBool(false);
    setShowMarketingBool(false);
  }
  const handleDatabase = (e) => {
    hideAllApps();
    setShowDatabaseBool(true);
  }

  const handleInquiry = (e) => {
    hideAllApps();
    setShowInquiryBool(true);
  }

  const handleCompany = (e) => {
    hideAllApps();
    setShowCompanyBool(true);
  }
  const handleMessageCenter = (e) => {
    hideAllApps();
    setShowMessageCenterBool(true);
  }
  const handleReports = (e) => {
    hideAllApps();
    setShowReportsBool(true);
  }
  const handleOrders = (e) => {
    hideAllApps();
    setShowOrdersBool(true);
  }

  const handleInventory = (e) => {
    hideAllApps();
    setShowInventoryBool(true);
  }

  const handleNotes = (e) => {
    hideAllApps();
    setShowNotesBool(true);
  }

  const handleLocationServices = (e) => {
    hideAllApps();
    setShowLocationServicesBool(true);
  }

  const handleEcommerce = (e) => {
    hideAllApps();
    setShowEcommerceBool(true);
  }

  const handleResellerRegistrations = (e) => {
    hideAllApps();
    setShowResellerRegistrationsBool(true);
  }

  const handleMarketing = (e) => {
    hideAllApps();
    setShowMarketingBool(true);
  }

  const handleInvoice = (e) => {
    hideAllApps();
    setShowInvoiceBool(true);
  }
  const handleActivity = (e) => {
    hideAllApps();
    setShowActivityBool(true);
  }

  const handleTracking = (e) => {
    hideAllApps();
    setShowTrackingBool(true);
  }

  const handleCoupons = (e) => {
    hideAllApps();
    setShowCouponsBool(true);
  }

  const handleApprovalInvoice = (e) => {
    hideAllApps();
    setShowApprovalInvoiceBool(true);
  }

  const handleHome = (e) => {
    hideAllApps();
  }

  const CompanyLink = (e) => {
    if (account_type == "owner") {
      return (
        <div>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#" onClick={handleCompany}>
            <i class="fas fa-building fa-sm fa-fw mr-2 text-gray-400"></i>
            Company
        </a>
        </div>
      );

    } else {
      return (
        <div></div>
      );
    }
  }




  return (
    <div id="wrapper">

      <div id='content-wrapper' style={{ minHeight: "100vh" }} class='d-flex flex-column'>
        <div id="content">
          <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            <a href="#" style={{ height: '90%' }} onClick={handleHome}>
              <img src={`/logo.svg`} style={{ height: '90%', backgroundImage: 'linear-gradient(to right bottom, rgb(129, 161, 255), #0341ff)' }}></img>
            </a>
            <FullDialog />
            <FullDialogV2 />

            <ul class="navbar-nav ml-auto">
              <li class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="mr-2 d-none d-lg-inline text-gray-600 small">{userInfo.firstname}</span>
                  <i class="fas fa-user-circle fa-lg"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                </a>
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                </a>
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                </a>
                  <CompanyLink />
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" onClick={logout}>
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </a>
                </div>
              </li>
            </ul>
          </nav>

          <div class="container-fluid" style={{ height: "100%" }}>
            <DashboardApp />
          </div>
        </div>
        <footer class="sticky-footer bg-white">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright &copy; ProOne 2022</span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Dashboard;
