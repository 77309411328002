import React from 'react';


const Reports = (e) => {
    return (
        <div>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Reports</h1>
            </div>
        </div>
    );
}

export default Reports;