import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import Cookies from 'js-cookie';
import URL from './URL';
import './Inquiry.css';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DebounceInput } from 'react-debounce-input';
import Pagination from "react-js-pagination";

var randomBytes = require('randombytes');
var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var account_type = "";
var ACCT = Cookies.get('ACCT')


class SearchTracking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            trackingOrderID: "",

            retrievedOrder: {}
        }
    }
    initTimer = () => {
        this.timer = setInterval(() => this.getOrderState(), 5000);
    }

    deInitTimer = () => {
        clearInterval(this.timer)
        this.timer = null; // here...
    }
    componentDidMount() {

    }
    componentWillUnmount() {
        this.deInitTimer();
    }

    getOrderState = () => {
        $("#getOrderButton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Ok")
        const that = this;
        axios.get(URL + 'orders?oid=' + this.state.trackingOrderID, {
            // axios.get(URL + 'orders?handler=' + this.state.handler + '&_id=' + this.state.moreInformationId, {
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ retrievedOrder: response["data"]["payload"] })
                $("#getOrderButton").html("Ok")
            })
    }

    OrderState = () => {
        var diagram = ""
        const orderstate = this.state.retrievedOrder.orderstate;
        const tracking = this.state.retrievedOrder.tracking;
        const orderid = this.state.retrievedOrder.orderid;
        const sid = this.state.retrievedOrder._id;
        const to = this.state.retrievedOrder.to;
        const from = this.state.retrievedOrder.from;
        var customerURL = "";

        if (sid != null && sid.$oid.length > 0) {
            customerURL = <h6>
                Customer URL: <b>https://tracking.joypurwala.com/tracking?tr={orderid}</b>
            </h6>
        }
        if (orderstate == "Processing") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-hammer text-warning"></i>
                </h1>
                Processing
            </div>
        }
        if (orderstate == "Cancelled") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-times text-danger"></i>
                </h1>
                Cancelled
            </div>
        }

        if (orderstate == "Ready") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-check text-primary"></i>
                </h1>
                Ready
            </div>
        }
        if (orderstate == "Packed") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-check-double text-primary"></i>
                </h1>
                Ready And Packed
            </div>
        }
        if (orderstate == "Partly Sent") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-shipping-fast text-info"></i>
                </h1>
                Partly Sent
                <br />
                <b>Tracking Info: </b> {tracking}
            </div>
        }
        if (orderstate == "Sent") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-shipping-fast text-success"></i>
                </h1>
                Sent
                <br />
                <b>Tracking Info: </b> {tracking}
            </div>
        }
        if (orderstate == "Returned") {
            diagram = <div className="d-flex  flex-column  align-items-center justify-content-center">
                <h1>
                    <i class="fas fa-undo text-secondary"></i>
                </h1>
                Returned
            </div>
        }

        if (diagram == "") {
            return ""
        }
        return (
            <div className="m-3">
                <div>
                    To: <b>{to}</b>
                    <br />
                    From: <b>{from}</b>
                    <br />
                    {customerURL}
                </div>
                <hr />
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <h5>Order State</h5>
                </div>
                <br />
                <div className="">
                    {diagram}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div class="col-md-6 col-sm-12 col-xl-12 col-lg-12 align-items-center">
                <div className="container p-2">

                    <label for="trackingOrderID"><b>Order ID</b></label>


                    <div class="input-group">
                        <input type="text" id="trackingOrderID" className="form-control border-1 small" name="name" value={this.state.trackingOrderID} onChange={(e) => {
                            this.setState({ trackingOrderID: e.target.value })
                        }} onKeyDown={(e) => {
                            if (e.keyCode == 13) {
                                this.getOrderState()
                            }
                        }} />
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary" id="getOrderButton" onClick={this.getOrderState}>
                                Ok
                        </button>
                        </div>
                    </div>
                    <this.OrderState />
                </div>
            </div>
        )
    }
}

class Tracking extends React.Component {
    render() {
        return (
            <div>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Tracking</h1>
                    {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#newOrderDialog" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-plus fa-sm text-white-50 mr-2" onClick={() => {
                    $("#addressName").focus()
                }}></i>Add New Inquiry</span> */}
                </div>

                <div class="row">


                    <div class="col-md-6 col-sm-12 col-xl-6 col-lg-6 align-items-center justify-content-between">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Search Order</h6>
                            </div>
                            <div class="m-0">
                                <SearchTracking />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Tracking;