import React from 'react';


const MessageCenter = (e) => {
    return (
        <div>
            Welcome to Message Center! 
            <i class="far fa-comments"></i>
        </div>
    );
}

export default MessageCenter;